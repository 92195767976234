import React, { useState } from 'react';
import Button from '../../../components/button';
import { PRIORITY_LEVEL } from '../../../helpers/constant';
import Input, { TextArea } from '../../../components/input';
import Select from '../../../components/input/Select';
import { FiUploadCloud } from 'react-icons/fi';

const HelpDeskForm = () => {
  const [formData, setFormData] = useState(
    {
      userId: 0,
      firstName: '',
      email: '',
      topic: '',
      title: '',
      messageBody: '',
      priorityLevel: 1
    }
  );
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    console.log(formData);
    setLoading(false);
    e.preventDefault();
  };

  return (
    <div className="w-full xs:w-[70%] mx-auto space-y-2">
      <h4 className="text-lg w-[85%] mx-auto">Send Us a Message</h4>

      <div className="pink-bg px-5  border border-white-100 w-[85%] mx-auto">
        <form action="" className="space-y-4">
         <Input
           withLabel
           label={'Topic'}
           className=" h-12"
           value={formData.topic}
           name={'topic'}
           onChange={handleChange}
          />

         <Input 
           withLabel 
           label={'Title'} 
           className=" h-12"
           value={formData.title}
           name={'title'}
           onChange={handleChange}
          />

          <TextArea 
           label={'Message Body'} 
           className=" h-20"
           value={formData.messageBody}
           name={'messageBody'}
           onChange={handleChange}
          />

         <Select 
           selectedOption={formData.priorityLevel} 
           options={PRIORITY_LEVEL}
           className='w-full'
           onChange={(e) =>
              setFormData({
                ...formData,
                priorityLevel: e?.value
              })
            }
          />

          <div className="flex flex-wrap gap-3 justify-center pt-6">
            <Button
              text="upload"
              withIcon
              icon={<FiUploadCloud />}
              className="flex gap-2 justify-center items-center"
            />
            <Button text="SEND MESSAGE" onClick={handleSubmit} isPink />
          </div>
        </form>
      </div>
    </div>
  );
};

export default HelpDeskForm;
