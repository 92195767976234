import React, { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiLoaderAlt } from 'react-icons/bi';
import { apiService } from '../../../helpers/api';
import { Toast } from '../../../components/toast/toast';
import Button from '../../../components/button';
import { FiPlusCircle } from 'react-icons/fi';
import OvertimeCard from '../../../components/cards/OvertimeCard';
import AddOvertimeModal from '../../settings/features/payroll-settings/overtime-modal';

const OvertimeContent = () => {
  const [loading, setLoading] = useState(false);
  const [overtime, setOvertime] = useState([]);
  const [showOvertimeModal, setShowOvertimeModal] = useState(false);

  const closeModal = () => {
    setShowOvertimeModal(false);
  };

  
  const fetchOvertime = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(`Overtime`, {}, true);
      const response = res?.data?.data;
      setOvertime(response);
    } catch (err) {
      console.log('Error in fetching overtime details: ', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOvertime();
  }, []);

  return (
    <div className='pink-bg my-6 space-y-5'>
      <div>
        <div className='whitespace-nowrap overflow-x-scroll w-[100%] '>
          <table>
            <thead className='uppercase tracking-[3px] text-blue-100 font-semibold text-2xs flex justify-between space-x-9 mb-4 w-fit xl:w-full '>
              <div className='flex items-center gap-9 w-[650px] justify-between flex-wrap'>
                <th className=''>Name</th>
                <th className=''>Duration</th>
                <th className=''>Rate</th>
                <th className=''>Amount</th>
                <th className=''>Actions</th>
              </div>
            </thead>

            <tbody className=' space-between flex flex-col gap-4 '>
              {overtime &&
                overtime.map((overtime, i) => {
                  return (
                    <OvertimeCard
                      key={`${i}`}
                      withIcon
                      name={overtime.name}
                      durationType={overtime.durationType}
                      rateType={overtime.rateType}
                      value={overtime.value}
                      editIcon={<FiEdit />}
                      deleteIcon={<RiDeleteBinLine />}
                    />
                  );
                })}
            </tbody>
          </table>

          <div className='pb-10 flex items-center'>
            {loading && (
              <BiLoaderAlt
                className='animate-spin my-10 flex text-center text-blue-100 justify-center mx-auto'
                size={30}
              />
            )}
            {!loading && overtime?.length === 0 && (
              <span className=' p-3'>There are currently no overtime details to show</span>
            )}
          </div>
        </div>
     </div>
     <div className='flex flex-row justify-end'>
       <Button
         icon={<FiPlusCircle size={16} />}
         isTransparent
         withIcon
         text={'ADD OVERTIME'}
         className='flex justify-between items-center space-x-1 px-6'
         isPurple
         onClick={() => setShowOvertimeModal(true)}
        />
       <AddOvertimeModal show={showOvertimeModal} closeModal={closeModal} />
     </div>
    </div>
  );
};

export default OvertimeContent;
