import React from 'react';
import { useNavigate } from 'react-router';
import Button from '../../../../components/button';
import { MANAGE_LEARNING } from '../../../../router/router';
import SearchWithFilter from '../../../../components/general/SearchWithFilter';
import LearningInfo from './LearningInfo';

const LearningDetails = () => {
  const navigate = useNavigate();

  return (
    <div className='white-bg'>
      <div className="flex flex-col gap-4 justify-between">
        <SearchWithFilter placeholder={'Search previous training'} />
        <div className='bg-purple-30 rounded-3xl px-10 py-7 space-y-5'>
          <LearningInfo />
        </div>
      </div>
    </div>
  );
}; 

export default LearningDetails;
