import React from 'react';
import { Outlet } from 'react-router-dom';
import { HeaderCard } from '../../../components/cards/Cards';
import LayoutWithSideBar from '../../../components/layout/LayoutWithSideBar';

const UserProfile = () => {
  return (
    <LayoutWithSideBar>
      <HeaderCard
        text={'Edit User Profile'}
        titleClassName={'font-bold'}
        className={''}
      />
      <Outlet />
    </LayoutWithSideBar>
  );
};

export default UserProfile;
