import React, { Fragment } from 'react';
import { ReactComponent as Logo } from '../../assets/img/logo.svg';
import { ReactComponent as RecruitLogo } from '../../assets/img/PeopleRecruit-Logo.svg';

import { Link } from 'react-router-dom';
import { ReactComponent as Ark } from '../../assets/img/ark.svg';
import { HiOutlineUser } from 'react-icons/hi';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { HorizontalAlignCard } from '../cards/Cards';
import { DASHBOARD } from '../../router/router';
import { useState } from 'react';
import UserDropDown from './user-dropdown';
import ProfileIcon from '../base/profile-icon';
import { STORAGE_KEYS } from '../../helpers/constant';
import { BiChevronDown } from 'react-icons/bi';

const Header = ({ helpdesk, link = DASHBOARD, isRecruit }) => {
  const [showMenu, setShowMenu] = useState(false);
  const name = localStorage.getItem(STORAGE_KEYS.FULL_NAME);
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  return (
    <div className=' flex flex-wrap items-center gap-2 justify-between py-8 relative mx-4 pl-2 sm:mx-16 text-center sm:text-left'>
      <Link to={link} className='mx-auto md:mx-0'>
        {isRecruit ? <RecruitLogo /> : <Logo />}
      </Link>

      {helpdesk && (
        <Fragment>
          {/*	<div className="flex items-center justify-center flex-wrap gap-4 text-base bg-white-100 p-2 rounded-[5px] px-6 text-purple-100 font-semibold">
						<span>Upgrade to a premium account and unlock more features!</span>
						<p className="text-sm underline ">Upgrade to pro</p>
			</div>*/}

          <div className='text-blue-100 flex gap-1 items-center justify-center mx-auto md:mx-0 font-semibold bg-white-100 px-2 rounded-[5px] h-10'>
            <HiOutlineUser size={16} />
            <p className='text-xs'>{'Justina Cregg'}</p>
            <RiArrowDropDownLine size={16} color='#FF365E' />
          </div>
        </Fragment>
      )}
      <div className=' flex flex-wrap gap-4 items-center '>
        {!helpdesk && (
          <HorizontalAlignCard
            path={''}
            text={name}
            icon={<Ark />}
            textClassName={'text-2xs xs:text-xs md:text-sm'}
            containerClassName={' rounded-[5px] mx-auto sm:mx-0 '}
          />
        )}

        <div className=' flex items-center gap-3'>
          <ProfileIcon big />
          <div>
            <p className='capitalize text-blue-100 text-sm font-medium '>{name}</p>
            <p className='text-[#696969] uppercase text-xs'>{role}</p>
          </div>

          <BiChevronDown
            scale={20}
            className='hover:bg-white-20 cursor-pointer text-pink-100'
            onClick={() => setShowMenu(!showMenu)}
          />
        </div>
      </div>

      <UserDropDown showMenu={showMenu} />
    </div>
  );
};

export default Header;
