import React from 'react';
import { Outlet } from 'react-router-dom';
import RecruitLayoutWithSideBar from '../../../components/layout/RecruitLayoutWithSideBar';

const RecruitReport = () => {
  return (
    <RecruitLayoutWithSideBar hText={'Report'}>
      <Outlet />
    </RecruitLayoutWithSideBar>
  );
};

export default RecruitReport;
