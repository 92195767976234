import React, { useState } from 'react';
import Input from '../../../../components/input';
import { ReactComponent as UserIcon } from '../../../../assets/img/user.svg';
import { ReactComponent as MailIcon } from '../../../../assets/img/mail.svg';

const NextOfKinForm = ({ handleInfoChange, otherFormData }) => {
  return (
    <div className='space-y-10'>
      <h4 className='text-[#404040] uppercase text-sm'>Next of Kin</h4>
      
      <div className='space-y-12'>
        {otherFormData.employeeNextofKins.map((kin, index) => (
          <div key={index} className='space-y-5'>
            <div className='w-full grid md:grid-cols-9 gap-8 items-end'>
              <Input
                withLabel
                onChange={(e) => handleInfoChange(e, index, 'employeeNextofKins')}
                value={kin.nextofKinFirstName}
                label='First Name'
                containerClass='md:col-span-5'
                name={`employeeNextofKins[${index}].nextofKinFirstName`}
                withIcon
                icon={<UserIcon />}
              />
              <Input
                withLabel
                onChange={(e) => handleInfoChange(e, index, 'employeeNextofKins')}
                value={kin.nextofKinLastName}
                label='Last Name'
                containerClass='md:col-span-4'
                name={`employeeNextofKins[${index}].nextofKinLastName`}
                withIcon
                icon={<UserIcon />}
              />
            </div>
            <div className='w-full grid md:grid-cols-3 gap-8 items-end'>
              <Input
                withLabel
                onChange={(e) => handleInfoChange(e, index, 'employeeNextofKins')}
                value={kin.address}
                label='Home Address'
                containerClass='md:col-span-2'
                name={`employeeNextofKins[${index}].address`}
              />
              <Input
                withLabel
                onChange={(e) => handleInfoChange(e, index, 'employeeNextofKins')}
                value={kin.phoneNumber}
                label='Phone Number'
                containerClass=''
                name={`employeeNextofKins[${index}].phoneNumber`}
                type='number'
              />
            </div>
            <div className='grid md:grid-cols-9 gap-8 items-end'>
              <Input
                withLabel
                onChange={(e) => handleInfoChange(e, index, 'employeeNextofKins')}
                value={kin.email}
                label='Email'
                containerClass='md:col-span-5'
                name={`employeeNextofKins[${index}].email`}
                withIcon
                icon={<MailIcon />}
              />
              <Input
                withLabel
                onChange={(e) => handleInfoChange(e, index, 'employeeNextofKins')}
                value={kin.relationship}
                label='Relationship'
                containerClass='md:col-span-4'
                name={`employeeNextofKins[${index}].relationship`}
                withIcon
                icon={<UserIcon />}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NextOfKinForm;
