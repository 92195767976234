import React, { useState, useEffect } from 'react';
import { Toast } from '../../../../components/toast/toast';
import { apiService } from '../../../../helpers/api';
import Button from '../../../../components/button';
import { FiPlusCircle } from 'react-icons/fi';
import StatusTabs from '../../../../components/base/status-tabs';
import Rates from '../payroll-settings/rates';
import Deductions from '../payroll-settings/deductions';
import TaxRelief from '../payroll-settings/tax-relief';
import OvertimeContent from '../../../compensation/features/OvertimeContent';
import AddCompetencyModal from './add-competency-modal';
import EditLeaveSettingModal from '../leave-settings/leave-type-modal/edit-leave-setting-modal';
import Competencies from './Competencies';
import { getCompetency, getBehaviouralCompetency, getTechnicalCompetency } from '../../../../utils/apis/api-modules/performance';
//import Pagination from '../../../../components/general/pagination';

const CompetencySettings = ({ id, editLeaveSettingInfo }) => {
  const [loading, setLoading] = useState(false);
  const [behavioural, setBehavioural] = useState(true);
  const [technical, setTechnical] = useState(false);
  const [competencies, setCompetencies] = useState([]);
  const [behaviouralCompetencies, setBehaviouralCompetencies] = useState([]);
  const [technicalCompetencies, setTechnicalCompetencies] = useState([]);
  const [showCompetencyModal, setShowCompetencyModal] = useState(false);

  const closeModal = () => {
    setShowCompetencyModal(false);
  };

  function handleSetBehavioural() {
    setBehavioural(true);
    setTechnical(false);
  }

  function handleSetTechnical() {
    setBehavioural(false);
    setTechnical(true);
  }

  const fetchCompetencies = async () => {
    setLoading(true);
    const res = await getCompetency();
    const BehRes = await getBehaviouralCompetency();
    const TecRes = await getTechnicalCompetency();
    if (res) setCompetencies(res);
    if (BehRes) setBehaviouralCompetencies(BehRes);
    if (TecRes) setTechnicalCompetencies(TecRes);
    setLoading(false);
  };

  useEffect(() => {
    fetchCompetencies();
  }, []);

  return (
  <div className='space-y-3'>
    <h4 className='text-[#353A3F] font-bold'>Competency Settings</h4>
   <div className='space-y-3  bg-white-30 shadow-xl rounded-3xl p-4 md:p-10'>
     {<div className='flex flex-row justify-end'>
       <Button
         isPink
         text={'ADD COMPETENCY'}
         className='flex justify-between items-center space-x-1 px-6'
         isPurple
         onClick={() => setShowCompetencyModal(true)}
        />
       <AddCompetencyModal show={showCompetencyModal} closeModal={closeModal} />
       </div>
      }
     <div className='flex flex-col'>
       <div className='w-full flex flex-wrap font-semibold tracking-tight text-blue-100 uppercase text-2xs bg-purple-30 rounded-4xl px-5 py-3 gap-6 flex flex-wrap items-center justify-center text-center mx-auto md:w-[50%] lg:w-[40%]'>
          <span
            className={`${
              behavioural && 'text-white-100 bg-pink-100 rounded-[12.63px] px-6 py-[6px] '
            } cursor-pointer transition-all duration-700 text-center`}
            onClick={handleSetBehavioural}>
            Behavioural
          </span>
          <span
            className={`${
              technical && 'text-white-100 bg-pink-100 rounded-[12.63px] px-6 py-[6px] '
            } cursor-pointer  transition-all duration-700 text-center`}
            onClick={handleSetTechnical}>
            Technical
          </span>
        </div>
        <div>
         {behavioural && <Competencies behaviouralCompetencies={behaviouralCompetencies} loading={loading} />}
        </div>
        <div>
         {technical && <Competencies technicalCompetencies={technicalCompetencies} loading={loading} />}
        </div>
        {/*<Competencies 
          loading={loading}
          competencies={competencies}
          behaviouralCompetencies={behaviouralCompetencies}
          technicalCompetencies={technicalCompetencies}
          editLeaveSettingInfo={editLeaveSettingInfo}
          id={id}
        />*/}
     </div>
   </div>
 </div>
  );
};

export default CompetencySettings;
