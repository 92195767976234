import { apiService } from '../../../helpers/api';
import { MANAGE_EVENTS } from '../../../router/router';

const base = 'Annoucement';

export const addAnnouncement = async (data, navigate) => {
  try {
    const res = await apiService.post(`${base}/Create`, data, true);

    if (res) 
     console.log('Eureka');
    navigate(MANAGE_EVENTS);
  } catch (err) {
    console.log('Error in creating announcement: ', err)
  }
};

export const getAnnouncement = async () => {
  try {
    const res = await apiService.get(`${base}/List`, {}, true);
    const response = res?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching announcement: ', err)
  }
};
