import React from 'react';

const Skills = ({ employee }) => {
  return (
    <div className="space-y-4">
      <h6 className="">qualifications</h6>

      <div className="flex flex-col justify-start gap-1">
        <span className="capitalize text-gray-100 text-sm"> Experience</span>
        {employee?.employeeWorkExperiences.length > 0 ? (
          employee?.employeeWorkExperiences.map((experience) => (
            <span key={experience.id} className="text-black-100 text-base">
              {`${experience.jobTitle} - ${experience.companyName} (${new Date(experience.startDate).getFullYear()} - ${new Date(experience.endDate).getFullYear()})`}
            </span>
          ))
        ) : (
          <span className="text-black-100 text-base">No previous experience history available</span>
        )}
      </div>

      <div className="flex flex-col justify-start gap-1">
        <span className="capitalize text-gray-100 text-sm"> Education</span>
        {employee?.employeeEducationHistories.length > 0 ? (
          employee?.employeeEducationHistories.map((education) => (
            <span key={education.id} className="text-black-100 text-base">
              {`${education.institutionName} - ${education.course} (${new Date(education.endDate).getFullYear()})`}
            </span>
          ))
        ) : (
          <span className="text-black-100 text-base">No education history available</span>
        )}
      </div>

      <div className="flex flex-col justify-start gap-1">
        <span className="capitalize text-gray-100 text-sm"> Certifications</span>
        {employee?.employeeCertifications.length > 0 ? (
          employee?.employeeCertifications.map((certification) => (
            <span key={certification.id} className="text-black-100 text-base">
              {certification.certificationProgram}
            </span>
          ))
        ) : (
          <span className="text-black-100 text-base">No certifications available</span>
        )}
      </div>
    </div>
  );
};

export default Skills;
