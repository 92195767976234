// In EmployeesHeader.js
import React from 'react';
import Button from '../../../components/button';
import Filter from '../../../components/general/filter';
import Input from '../../../components/input';
import Select from '../../../components/input/Select';
import { DEPARTMENTS, GENDER, JOB_TYPE } from '../../../helpers/constant';

const EmployeesHeader = ({ searchData, setSearchData, handleSearchChange, loading, error }) => {
  const handleChange = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  };

  return (
    <div className='space-y-10 flex flex-col'>
      <div className=' flex justify-between items-center'>
        <div className='space-y-10 flex flex-col '>
          <h4 className='mdLg:text-2xl'>Search Employee</h4>
          {error && <span className='text-sm text-red-700 font-bold'>{error}</span>}
        </div>
        <Filter />
      </div>

      <form action='' className='space-y-6'>
        <div className='grid mdLg:grid-cols-3 gap-8'>
          <Input
            withLabel
            onChange={handleSearchChange}
            value={searchData.employeeName}
            label='EMPLOYEE NAME'
            placeholder={'Enter employee first or last name'}
            className=''
            containerClass='w-full'
            name={'employeeName'}
          />
          <Input
            withLabel
            onChange={handleSearchChange}
            value={searchData.employeeId}
            label='EMPLOYEE ID'
            placeholder={'Enter employee id'}
            className=''
            containerClass='w-full'
            name={'employeeId'}
          />
          <Input
            withLabel
            onChange={handleSearchChange}
            value={searchData.employeeEmail}
            label='EMPLOYEE EMAIL'
            placeholder={'Enter employee email'}
            className=''
            containerClass='w-full'
            name={'employeeEmail'}
          />
        </div>
        <div className='grid mdLg:grid-cols-3 gap-8'>
          <div className=' flex flex-col gap-2 w-full'>
            <h6 className=' text-xs'>department</h6>
            <Select
              selectedOption={searchData.department}
              className=' w-full'
              options={DEPARTMENTS}
              onChange={(e) =>
                setSearchData({
                  ...searchData,
                  department: e?.label
                })
              }
            />
          </div>
          <div className=' flex flex-col gap-2 w-full'>
            <Input
              withLabel
              onChange={handleSearchChange}
              value={searchData.role}
              label='ROLE TITLE'
              placeholder={'e.g. Sales Representative, Customer Care Officer, etc.'}
              className=''
              containerClass=' w-full'
              name={'role'}
            />
            {/*<h6 className=' text-xs'> role</h6>
            <Select
              selectedOption={searchData.role}
              className=' w-full'
              //options={JOB_TYPE}
              onChange={(e) =>
                setSearchData({
                  ...searchData,
                  role: e?.label
                })
              }
            />*/}
          </div>
          <div className=' flex flex-col gap-2 w-full'>
            <h6 className='text-xs'> gender</h6>
            <Select
              selectedOption={searchData.gender}
              className=' w-full'
              options={GENDER}
              onChange={(e) =>
                setSearchData({
                  ...searchData,
                  gender: e?.label
                })
              }
            />
          </div>
        </div>
      </form>

      <Button 
        text={'Search'}
        isPurple
        className={'ml-auto md:px-20'}
        onClick={() => handleSearchChange(searchData)}
        loading={loading}
      />
    </div>
  );
};

export default EmployeesHeader;
