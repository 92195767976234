import React from 'react';
import { HomeLayout } from '../../components/layout';

const Home = () => {
  return (
    <div>
      <HomeLayout />
    </div>
  );
};

export default Home;

// <Input isRounded />
