import React from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiLoaderAlt } from 'react-icons/bi';
import SalaryComponentCard from '../../../components/cards/SalaryComponentCard';

const SalaryComponentContent = ({ salaryComponent, loading }) => {

  return (
    <div className='pink-bg my-6'>
      <div>
        <div className='whitespace-nowrap overflow-x-scroll w-[100%] '>
          <table>
            <thead className='uppercase tracking-[3px] text-blue-100 font-semibold text-2xs flex justify-between space-x-9 mb-4 w-fit xl:w-full '>
              <div className='flex items-center gap-9 w-[650px] justify-between flex-wrap'>
                <th className=''>Name</th>
                <th className=''>Duration</th>
                <th className=''>Gross Amount</th>
                <th className=''>Amount</th>
                <th className=''>Actions</th>
              </div>
            </thead>

            <tbody className=' min-w-fit  flex flex-col gap-4 '>
              {salaryComponent?.basicAllowance &&
                salaryComponent?.basicAllowance.map((compensation, i) => {
                  return (
                    <SalaryComponentCard
                      key={`${i}`}
                      withIcon
                      name={compensation.name}
                      duration={compensation.duration}
                      grossAmount={compensation.value}
                      amount={compensation.maxAmount}
                      editIcon={<FiEdit />}
                      deleteIcon={<RiDeleteBinLine />}
                    />
                  );
                })}
            </tbody>
            <tbody className=' min-w-fit  flex flex-col gap-4 '>
              {salaryComponent?.deductions &&
                salaryComponent?.deductions.map((compensation, i) => {
                  return (
                    <SalaryComponentCard
                      key={`${i}`}
                      withIcon
                      name={compensation.name}
                      duration={compensation.duration}
                      grossAmount={compensation.value}
                      amount={compensation.maxAmount}
                      editIcon={<FiEdit />}
                      deleteIcon={<RiDeleteBinLine />}
                    />
                  );
                })}
            </tbody>
            <tbody className=' min-w-fit  flex flex-col gap-4 '>
              {salaryComponent?.taxRelief &&
                salaryComponent?.taxRelief.map((compensation, i) => {
                  return (
                    <SalaryComponentCard
                      key={`${i}`}
                      withIcon
                      name={compensation.name}
                      duration={compensation.duration}
                      grossAmount={compensation.value}
                      amount={compensation.maxAmount}
                      editIcon={<FiEdit />}
                      deleteIcon={<RiDeleteBinLine />}
                    />
                  );
                })}
            </tbody>
          </table>

          <div className='pb-10 flex items-center'>
            {loading && (
              <BiLoaderAlt
                className='animate-spin my-10 flex text-center text-blue-100 justify-center mx-auto'
                size={30}
              />
            )}
            {!loading && salaryComponent?.basicAllowance.length === 0 && salaryComponent?.deductions.length === 0 && (
              <span className=' p-3'>There are currently no salary components to show</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryComponentContent;
