import React, { useState } from 'react';
import Button from '../../../../../components/button';
import { FiPlusCircle } from 'react-icons/fi';
import AddComponentModal from '../../component-modal';
import AddOvertimeDetailsModal from '../../overtime-modal';
import OvertimeContent from '../../OvertimeContent';

const RightSalary = ({ id, loading, overtime }) => {
  const [showComponentModal, setShowComponentModal] = useState(false);
  const [showOvertimeModal, setShowOvertimeModal] = useState(false);
  const [showSalaryComponentContent, setShowSalaryComponentContent] = useState(false);

  const closeModal = () => {
    setShowComponentModal(false);
    setShowOvertimeModal(false);
  };

  const showSalaryComponent = () => {
    setShowSalaryComponentContent(true);
  };

  return (
    <div className='flex flex-wrap items-center gap-4 justify-end'>
      {/*<Button
        text={'OVERTIME'}
        className='flex justify-between items-center space-x-1 px-6'
        isPink
        onClick={showSalaryComponent}
      />*/}
     <Button
        icon={<FiPlusCircle size={16} />}
        withIcon
        text={'ADD OVERTIME'}
        className='flex justify-between items-center space-x-1 px-6'
        isPurple
        onClick={() => setShowOvertimeModal(true)}
      />
      <Button
        icon={<FiPlusCircle size={16} />}
        withIcon
        text={'ADD COMPONENT'}
        className='flex justify-between items-center space-x-1 px-6'
        isPurple
        onClick={() => setShowComponentModal(true)}
      />
      <AddComponentModal 
        show={showComponentModal} 
        closeModal={closeModal} 
      />
      <AddOvertimeDetailsModal show={showOvertimeModal} closeModal={closeModal} />
      {showSalaryComponentContent && <OvertimeContent loading={loading} overtime={overtime} id={id} />}
    </div>
  );
};

export default RightSalary;
