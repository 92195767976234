import React, { useState } from 'react';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import RadioGroup from '../../../../../components/input/RadioGroup';
import Modal from '../../../../../components/modal';

const CreateAssessment = () => {
  const [a, setA] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [question, setQuestion] = useState('');
  const [title, setTitle] = useState('');
  const [introMsg, setIntroMsg] = useState('');
  // const [active, setActive] = useState('create');

  const [suggestedField, setSuggestedField] = useState([
    {
      question: 'I almost always complete what I started',
      response: 'Stronly Agree'
    },

    {
      question: ' I am punctual',
      response: 'Strongly Agree'
    },
    {
      question: 'I almost always complete what I started',
      response: 'Stronly Agree'
    },

    {
      question: ' I am punctual',
      response: 'Strongly Agree'
    }
  ]);

  // const [templates, setTemplates] = useState('');

  const addQuestions = () => {
    setSuggestedField([...suggestedField, { question: question }]);
    setQuestion('');
  };
  return (
    <div>
      <div className="w-full rounded-md bg-gray-20 bg-opacity-25 p-4 flex flex-wrap justify-between items-center gap-4 ">
        <div className="space-y-4">
          <p className="text-lg font-semibold">Assessment Title</p>
          <p className="text-gray-600 text-sm ">Enter a short, descriptive name for this form.</p>
          <Input
            placeholder=""
            className={'border-0 w-[100%]'}
            withLabel
            onChange={(e) => setTitle(e.target.value)}
            label={'Assesment Title'}
            normalLabel
            value={title}
          />
          <Input
            placeholder=""
            className={'border-0 h-32'}
            onChange={(e) => setIntroMsg(e.target.value)}
            withLabel
            label={'Intro Message.'}
            normalLabel
            value={introMsg}
          />
        </div>
        <div className="flex flex-col gap-3 items-end">
          <Button text={'Send Assessment'} className="text-pink-100" />
          <Button
            isPink
            onClick={() => setShowModal(!showModal)}
            text={'Preview assessment'}
            className=" "
          />
        </div>
      </div>

      <Modal
        showModal={showModal}
        closeModal={() => setShowModal(!showModal)}
        content={
          <div className=" overflow-scroll h-[80vh]">
            <h4> {title} </h4>
            <h6> {introMsg} </h6>

            {suggestedField.map((item, i) => {
              return (
                <div className="p-4 pb-8 space-y-4" key={i}>
                  <div className="space-y-2 text-gray-600">
                    <p className=" font-bold text-black-100">
                      {1 + i}. {item?.question}
                    </p>
                    {i === 0 && (
                      <p className=" text-sm">
                        Note: Please indicate whether you “strongly disagree” , “disagree”, “are
                        neutral etc
                      </p>
                    )}
                    <RadioGroup
                      name="gender"
                      selectedOption={a}
                      options={[
                        { title: 'Strongly Agree', value: 'sa' },
                        { title: 'Agree', value: 'a' },
                        { title: 'Disagree', value: '' },
                        { title: 'Strongly Disagree', value: 'sd' }
                      ]}
                      onChange={(e) => setA(e.target.value)}
                      orientation={'vertical'}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        }
      />

      <div className="mt-4">
        <div className="rounded-lg border border-gray-20">
          <div className=" bg-pink-40 p-2 rounded-[3px] space-y-3 py-2 flex flex-wrap justify-between w-full">
            <div className=" w-[80%] space-y-2">
              {' '}
              <p className=" font-bold"> Type Question</p>
              <Input
                className={'h-20 w-[60%]'}
                onChange={(e) => setQuestion(e.target.value)}
                value={question}
              />
            </div>

            <div
              className="flex gap-2 items-center text-gray-200 font-semibold cursor-pointer"
              onClick={addQuestions}>
              <p className="rounded-full border-[2px] border-gray-200  w-6 h-6 flex justify-center items-center ">
                +
              </p>
              <p>Add Question</p>
            </div>
          </div>
          {suggestedField.map((item, i) => {
            return (
              <div className="p-4 pb-8 space-y-4" key={i}>
                <div className="space-y-2 text-gray-600">
                  <p className=" font-bold text-black-100">
                    {1 + i}. {item?.question}
                  </p>
                  {i === 0 && (
                    <p className=" text-sm">
                      Note: Please indicate whether you “strongly disagree” , “disagree”, “are
                      neutral etc
                    </p>
                  )}
                  <RadioGroup
                    name="gender"
                    selectedOption={a}
                    options={[
                      { title: 'Strongly Agree', value: 'sa' },
                      { title: 'Agree', value: 'a' },
                      { title: 'Disagree', value: '' },
                      { title: 'Strongly Disagree', value: 'sd' }
                    ]}
                    onChange={(e) => setA(e.target.value)}
                    orientation={'vertical'}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-center gap-3 mt-4">
          <Button text={'Cancel'} />
          <Button isPink text={'create assessment'} className="bg-opacity-40 " />
        </div>
      </div>
    </div>
  );
};

export default CreateAssessment;
