import React from 'react';
// import { BsClipboard } from 'react-icons/bs';
// import { Link } from 'react-router-dom';
// import Button from '../../../../../components/button';
import { CandidateDetailsCard } from '../../../../../components/cards/Cards';
import BackWithText from '../../../../../components/general/BackWithText';
import { candidates } from '../../../../../utils/peopleRecruitArrays';

const CandidateScore = () => {
  return (
    <div className=' space-y-6'>
      <div className=' bg-pink-100 py-4 flex flex-wrap gap-4 rounded-xl items-center justify-between px-4 text-white-100'>
        <BackWithText text={'Candidate Details'} goBack />
      </div>
      <div className=' bg-white-100 rounded-md p-4 md:p-9 sm:px-10  space-y-9 '>
        <CandidateDetailsCard text={'Personal details'}>
          <div className='flex flex-wrap gap-x-8 gap-y-4'>
            <div className='flex flex-col items-start gap-3'>
              <h6>full name</h6>
              <p>{candidates[0]?.name}</p>
            </div>
            <div className='flex flex-col items-start gap-3'>
              <h6> Email address</h6>
              <p>{candidates[0]?.email}</p>
            </div>
            <div className='flex flex-col items-start gap-3'>
              <h6>Phone number</h6>
              <p>{candidates[0]?.phoneNumber}</p>
            </div>
            <div className='flex flex-col items-start gap-3'>
              <h6>Position applied</h6>
              <p>{candidates[0]?.role}</p>
            </div>
            <div className='flex flex-col items-start gap-3'>
              <h6>Years of experience</h6>
              <p>{candidates[0]?.years_of_experiece}</p>
            </div>
          </div>
        </CandidateDetailsCard>

        <div className=' flex flex-wrap  gap-10 md:gap-4 justify-between'>
          <CandidateDetailsCard
            text={'Educational Qualification [7.4/10] '}
            className='md:w-[48%] w-full'>
            <div className='flex flex-col gap-y-4'>
              <p>
                Educational Qualification Assessment: <span className='font-bold'> 2.4/3.0</span>
              </p>
              <p>
                Relevant Professional Qualifications: <span className='font-bold'>5.0/5.0 </span>
              </p>
              <p>
                Relevant Training: <span className='font-bold'>0.0/0.2 </span>
              </p>
            </div>
          </CandidateDetailsCard>
          <CandidateDetailsCard text={'Other Criteria [2.5/8.0] '} className='md:w-[48%] w-full'>
            <div className='flex flex-col gap-y-4'>
              <p>
                Multi-cultural sensitivity: <span className='font-bold'> 0.0/3.0</span>
              </p>
              <p>
                Language: <span className='font-bold'> 0.0/3.0</span>
              </p>
            </div>
          </CandidateDetailsCard>
        </div>

        <CandidateDetailsCard text={'Educational Qualification [7.4/10] '} className=''>
          <div className='flex flex-wrap gap-4 justify-between'>
            <div className='flex flex-col gap-y-4'>
              <p>
                Educational Qualification Assessment: <span className='font-bold'> 2.4/3.0</span>
              </p>
              <p>
                Relevant Professional Qualifications: <span className='font-bold'>5.0/5.0 </span>
              </p>
              <p>
                Relevant Training: <span className='font-bold'>0.0/0.2 </span>
              </p>
              <p>
                Multi-cultural sensitivity: <span className='font-bold'> 0.0/3.0</span>
              </p>
              <p>
                Language: <span className='font-bold'> 0.0/3.0</span>
              </p>
            </div>
            <div className='flex flex-col gap-y-4'>
              <p>
                Educational Qualification Assessment: <span className='font-bold'> 2.4/3.0</span>
              </p>
              <p>
                Relevant Professional Qualifications: <span className='font-bold'>5.0/5.0 </span>
              </p>
              <p>
                Relevant Training: <span className='font-bold'>0.0/0.2 </span>
              </p>
              <p>
                Multi-cultural sensitivity: <span className='font-bold'> 0.0/3.0</span>
              </p>
              <p>
                Language: <span className='font-bold'> 0.0/3.0</span>
              </p>
            </div>
          </div>
        </CandidateDetailsCard>
      </div>
    </div>
  );
};

export default CandidateScore;
