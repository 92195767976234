import React from 'react';
import Input from '../../../components/input';
import { BiSearch } from 'react-icons/bi';

const HelpDeskHeader = () => {
  return (
    <div className="grid helpdesk-bg place-content-center h-auto py-10 bg-blue-100 rounded-[5px] text-white-100 ">
      <div className="flex flex-col gap-2 text-center items-center px-6">
        <h4 className="text-yellow-100 text-xl mdLg:text-3xl">Hi, How can we help you ?</h4>
        <p className="text-sm font-semibold w-full mdLg:w-[60%]">
          Our crew of superheroes are standing by for service and support
        </p>
        <Input
          isRounded
          placeholder="Search FAQs.."
          className="h-12"
          containerClass="w-full "
          icon={<BiSearch size={20} color="#FF365E" />}
          withIcon
        />
      </div>
    </div>
  );
};

export default HelpDeskHeader;
