import React, { useState, useEffect } from 'react';
import BaseModal from '../../../../../components/base/modal';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import Select from '../../../../../components/input/Select';
import { COMPONENT_TYPE, RATE_TYPE } from '../../../../../helpers/constant';
import { API } from '../../../../../utils/apis';

const EditTaxReliefModal = ({ show, closeModal, editTaxReliefInfo }) => {
  const DEPENDENT_TYPE = [
    {
      label: 'None',
      value: 1
    },
    {
      label: 'Gross',
      value: 2
    },
    {
      label: 'Other Components',
      value: 3,
      children: [
        { label: 'Basic', value: '31' },
        { label: 'Training', value: '32' },
        { label: 'Housing', value: '33' },
        { label: 'Transport', value: '34' }
      ]
    }
  ];

  const [modalData, setModalData] = useState({
    componentId: 0,
    name: '',
    value: 0,
    dependantComponentType: 0,
    dependant: '',
    duration: 0,
    componentType: 0,
    withinGross: true,
    isCompulsory: true
  });

  useEffect(() => {
    if (editTaxReliefInfo) {
      setModalData({
        componentId: editTaxReliefInfo.componentId || 0,
        name: editTaxReliefInfo.name || '',
        value: editTaxReliefInfo.value || 0,
        dependantComponentType: editTaxReliefInfo.dependantComponentType || 0,
        dependant: editTaxReliefInfo.dependant || '',
        duration: editTaxReliefInfo.duration || 0,
        componentType: editTaxReliefInfo.componentType || 0,
        withinGross: editTaxReliefInfo.withinGross || true,
        isCompulsory: editTaxReliefInfo.isCompulsory || true
      });
    }
  }, [editTaxReliefInfo]);

  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setModalData(prevData => ({ ...prevData, [field]: value }));
  };

  const submitData = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(modalData);
    await API.createSalaryComponent(modalData);
    setLoading(false);
    closeModal();
    window.location.reload(); 
  };

  const renderDropdownOptions = (options) => {
    return options.map((option) => {
      if (option.children) {
        return (
          <Select.OptionGroup key={option.value} label={option.label}>
            {renderDropdownOptions(option.children)}
          </Select.OptionGroup>
        );
      } else {
        return (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        );
      }
    });
  };

  return (
    <BaseModal title={'Edit Tax Relief'} show={show} onClose={closeModal}>
      <div className='space-y-10 rounded-lg w-full sm:w-[400px] overflow-hidden'>
        <Input
          withLabel
          value={modalData.name}
          placeholder={editTaxReliefInfo?.name}
          label='Name'
          containerClass='w-full sm:w-auto'
          name='name'
          onChange={(e) => handleChange('name', e.target.value)}
        />
        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>TYPE</h6>
          <Select
            selectedOption={modalData.componentType}
            className='w-full sm:w-auto'
            options={COMPONENT_TYPE}
            onChange={(e) => handleChange('componentType', e?.value)}
          />
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>DEPENDENT TYPE</h6>
          <Select
            selectedOption={modalData.dependantComponentType}
            className='w-full sm:w-auto'
            options={DEPENDENT_TYPE}
            onChange={(e) =>
              handleChange('dependantComponentType', e?.value)
            }
          />
          {modalData.dependantComponentType === 3 && (
            <div className='ml-4'>
              <Select
                selectedOption={modalData.dependant}
                className='w-full sm:w-auto'
                options={DEPENDENT_TYPE.find((option) => option.value === 3)?.children || []}
                onChange={(e) => handleChange('dependant', e?.value)}
              >
                {renderDropdownOptions(DEPENDENT_TYPE.find((option) => option.value === 3)?.children || [])}
              </Select>
            </div>
          )}
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>RATE TYPE</h6>
          <Select
            selectedOption={modalData.dependant}
            className='w-full sm:w-auto'
            options={RATE_TYPE}
            onChange={(e) => handleChange('dependant', e?.value)}
          />
        </div>
        <Input
          withLabel
          value={modalData.value}
          placeholder={editTaxReliefInfo?.value}
          label='Value'
          containerClass='w-full sm:w-auto'
          name='value'
          onChange={(e) => handleChange('value', e.target.value)}
        />
        <Input
          withLabel
          value={modalData.duration}
          placeholder={editTaxReliefInfo?.duration}
          label='Duration'
          containerClass='w-full sm:w-auto'
          name='duration'
          onChange={(e) => handleChange('duration', e.target.value)}
        />
        <div className='flex gap-4 flex-wrap justify-end'>
          <Button
            className='border border-pink-100'
            text='Cancel'
            onClick={closeModal}
            isTransparent
          />
          <Button text='Submit' type='submit' onClick={submitData} isPurple />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditTaxReliefModal;
