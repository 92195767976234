import React from 'react';
import { FaUsers, FaWindowClose } from 'react-icons/fa';
import { RiBarChartBoxFill, RiShareForward2Fill } from 'react-icons/ri';
import { RecruitReportCard } from '../../../../components/cards/Cards';
import Charts from '../../../../components/charts';

const RecruitReportSuccess = ({ onJobsClick, onAppliClick, onClosedClick, onSuccessClick }) => {
  return (
    <div className="space-y-10 ">
      <div className="flex items-center gap-1">
        <h4 className="text-base w-fit">Dashboard</h4>{' '}
        <p className=" w-[100%] h-[2px]  bg-pink-100"> </p>
      </div>

      <div className="flex flex-wrap gap-3 ">
        <RecruitReportCard
          onClick={onJobsClick}
          isPink
          text={'Published Jobs'}
          value="785"
          icon={<RiShareForward2Fill />}
        />
        <RecruitReportCard
          onClick={onAppliClick}
          isPink
          text={' Applicants'}
          value="100"
          icon={<FaUsers />}
        />
        <RecruitReportCard
          onClick={onClosedClick}
          isPink
          text={'Job closed'}
          value="230"
          icon={<FaWindowClose />}
        />
        <RecruitReportCard
          onClick={onSuccessClick}
          isPink
          active
          text={'Hire success'}
          value="405"
          icon={<RiBarChartBoxFill />}
        />
      </div>

      <div className=" bg-white-20 bg-opacity-25 rounded-md p-3 space-y-6">
        <Charts text={'Chart For Hire Success'} />
      </div>
    </div>
  );
};

export default RecruitReportSuccess;
