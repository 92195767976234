import { apiService } from '../../../helpers/api';

export const fetchAllTimesheet = async () => {
  try {
    const res = await apiService.get(
      'TimeSheet/Fetch/20',
      {
        
      },
      true
    );
    const response = res?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching timesheets: ', err)
  }
};
