import React from 'react';

const TitleCard = ({ text, className }) => {
  return (
    <div
      className={`mini-card-bg p-6 text-white-100 font-semibold rounded-sm text-sm h-[114px] grid place-content-center  ${className}`}>
      <span className="">{text}</span>
    </div>
  );
};

export default TitleCard;
