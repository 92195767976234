import React, { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiLoaderAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import StatusCard from '../../../../components/cards/StatusCard';
import Pagination from '../../../../components/general/pagination';
import EditLeaveSettingModal from './leave-type-modal/edit-leave-setting-modal';
import DeleteLeaveSettingModal from './leave-type-modal/delete-leave-setting-modal';

const LeaveContent = ({ id, loading, leaveSettings }) => {
  const navigate = useNavigate();
  const [showEditLeaveSettingModal, setShowEditLeaveSettingModal] = useState(false);
  const [showDeleteLeaveSettingModal, setShowDeleteLeaveSettingModal] = useState(false);
  const [editLeaveSettingInfo, setEditLeaveSettingInfo] = useState(null);
  const [deleteLeaveSettingInfo, setDeleteLeaveSettingInfo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentLeaveSettings = leaveSettings.slice(indexOfFirstItem, indexOfLastItem);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const closeModal = () => {
    setShowEditLeaveSettingModal(false);
    setShowDeleteLeaveSettingModal(false);

  };

  return (
    <div className='rounded-[20px] bg-purple-30 p-10 my-6'>
      <div>
        <div className='whitespace-nowrap overflow-x-scroll'>
          <table className='w-full'>
            <thead className='border-collapse border-red-500'>
              <tr className='w-[100%] text-left uppercase tracking-[3px] text-blue-100 font-semibold text-2xs pl-28 mb-4'>
                <th className='p-6'>#</th>
                <th className='p-6'>EMPLOYEE GROUP</th>
                <th className='p-6'>LEAVE TYPE</th>
                <th className='p-6'>LEAVE DAYS</th>
                <th className='p-6'>ALLOW CARRYOVER</th>
                <th className='p-3 px-6'>ACTION</th>
              </tr>
            </thead>
            <tbody className='rounded-sm'>
              {currentLeaveSettings.map((leaveSetting, i) => (
                <tr
                  className='cursor-pointer border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                  key={leaveSetting?.id}
                  onClick={() => navigate(``)}
                >
                  <td className='p-3 px-6 '>{i + 1}.</td>
                  <td className='p-3 px-6 text-center'>{leaveSetting.employeeGroup !== null ? leaveSetting.employeeGroup : 'nil'}</td>
                  <td className='p-3 px-6 text-center'>{leaveSetting.leaveTypeName}</td>
                  <td className='p-3 px-6 text-center'>
                    {leaveSetting.entitlement}
                  </td>
                  <div className='grid place-items-center'>
                    <td className='p-3 px-6'>
                      {leaveSetting.allowCarryOver ? (
                        <StatusCard isYes />
                      ) : (
                        <StatusCard isNo />
                      )}
                    </td>
                  </div>        
                  <td className='p-3 px-9 justify-between gap-5'>
                   <FiEdit
                      className='cursor-pointer hover:bg-pink-30 transition-all'
                      onClick={() => {
                        setShowEditLeaveSettingModal(true);
                        setEditLeaveSettingInfo(leaveSetting);
                      }}
                    />
                    <RiDeleteBinLine
                      className='cursor-pointer hover:bg-pink-30 transition-all'
                      onClick={() => {
                        setShowDeleteLeaveSettingModal(true);
                        setDeleteLeaveSettingInfo(leaveSetting.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='pb-10 flex flex-col items-center'>
            {loading && (
              <BiLoaderAlt
                className='animate-spin my-10 flex text-center text-blue-100 justify-center mx-auto'
                size={30}
              />
            )}
            {!loading && leaveSettings.length === 0 && (
              <span className='p-3'>There are currently no leave settings to show</span>
            )}
          </div>
        </div>
      </div>
      <Pagination
        totalPages={Math.ceil(leaveSettings.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

     <EditLeaveSettingModal
        show={showEditLeaveSettingModal}
        editLeaveSettingInfo={editLeaveSettingInfo}
        closeModal={closeModal}
        id={id}
      />

     <DeleteLeaveSettingModal
        showDeleteLeaveSettingModal={showDeleteLeaveSettingModal}
        deleteLeaveSettingInfo={deleteLeaveSettingInfo}
        closeModal={closeModal}
      />
    </div>
  );
};

export default LeaveContent;
