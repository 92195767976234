import React, { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiLoaderAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../components/general/pagination';

const ObjectivesDetails = ({ id, loading, objectivesSettings }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentObjectivesSettings = objectivesSettings.slice(indexOfFirstItem, indexOfLastItem);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className='rounded-[20px] bg-purple-30 p-10 my-6'>
      <div>
        <div className='whitespace-nowrap overflow-x-scroll'>
          <table className='w-full'>
            <thead className='border-collapse border-red-500'>
              <tr className='w-[100%] text-left uppercase tracking-[3px] text-blue-100 font-semibold text-2xs pl-28 mb-4'>
                <th className='p-6'>#</th>
                <th className='p-6 text-center'>OBJECTIVE</th>
                <th className='p-6 text-center'>WEIGHT</th>
                <th className='p-6 text-center'>CYCLE</th>
                <th className='p-6 text-center'>DUE DATE</th>
                <th className='p-3 px-6 text-center'>ACTION</th>
              </tr>
            </thead>
            <tbody className='rounded-sm'>
              {currentObjectivesSettings.map((objectiveSetting, i) => (
                <tr
                  className='cursor-pointer border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                  key={i}
                  onClick={() => navigate(``)}
                >
                  <td className='p-3 px-6 '>{i + 1}.</td>
                  <td className='p-3 px-6 text-center'>
                   {objectiveSetting.objective ? objectiveSetting.objective : '-'}
                  </td>
                  <td className='p-3 px-6 text-center'>{objectiveSetting.metrics ? objectiveSetting.metrics : '-'}</td>
                  <td className='p-3 px-6 text-center'>
                    {objectiveSetting.weight ? objectiveSetting.weight : '-'}
                  </td>
                  <td className='p-3 px-6 text-center'>{objectiveSetting.dueDate}</td>        
                  <td className='p-3 px-9 flex gap-2'>
                   <FiEdit
                      className='cursor-pointer hover:bg-pink-30 transition-all'
                      onClick={() => {
                        //setShowEditLeaveSettingModal(true);
                        //setEditLeaveSettingInfo(leaveSetting);
                      }}
                    />
                    <RiDeleteBinLine
                      className='cursor-pointer hover:bg-pink-30 transition-all'
                      onClick={() => {
                        //setShowDeleteLeaveSettingModal(true);
                        //setDeleteLeaveSettingInfo(leaveSetting.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='pb-10 flex flex-col items-center'>
            {loading && (
              <BiLoaderAlt
                className='animate-spin my-10 flex text-center text-blue-100 justify-center mx-auto'
                size={30}
              />
            )}
            {!loading && objectivesSettings.length === 0 && (
              <span className='p-3'>There are currently no objectives to show</span>
            )}
          </div>
        </div>
      </div>
      <Pagination
        totalPages={Math.ceil(objectivesSettings.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default ObjectivesDetails;
