import { Toast } from '../../../components/toast/toast';
import { apiService } from '../../../helpers/api';

const base = 'Compensation/';

export const createCompensation = async (payload) => {
  try {
    const res = await apiService.post(`${base}Create`, payload, true);
    console.log('res', res);
  } catch (err) {
    console.log('Error in creating compensation: ', err)
  }
};

export const updateCompensationComponent = async (payload) => {
  try {
    const res = await apiService.post(`${base}Component/Update`, payload, true);
    console.log('res', res);
  } catch (err) {
    console.log('Error in updating compensation component: ', err)
  }
};

export const fetchComponentDetail = async (id) => {
  try {
    const res = await apiService.get(`Compensation/${id}`, {}, true);
    console.log('Fetched Component Detail:', res);
    return res?.data?.data;
  } catch (err) {
    console.log('Error in fetching compensation details', err);
  }
};

export const previewCompensation = async (payload) => {
  try {
    const res = await apiService.post(`${base}Breakdown`, payload, true);

    if (res && res.data.breakdowns) {
      return res.data.breakdowns;
    } else {
      return null;
    }
  } catch (err) {
    console.log('Error in previewing compensation: ', err)
    throw err;
  }
};

export const createAdditionalComponent = async (payload) => {
  try {
    const res = await apiService.post(`${base}Additional/Create`, payload, true);
    console.log('res', res);
    if (res)
      Toast({
        type: 'success',
        message: 'Component Added Successfully'
      });
  } catch (err) {
    console.log('Error in creating component: ', err)
  }
};

export const updateAdditionalComponent = async (payload) => {
  try {
    const res = await apiService.post(`${base}Additional/Create`, payload, true);
    console.log('res', res);
    if (res)
      Toast({
        type: 'success',
        message: 'Component Updated Successfully'
      });
  } catch (err) {
    console.log('Error in updating compensation: ', err)
  }
};

export const createOvertimeComponent = async (payload) => {
  try {
    const res = await apiService.post(`${base}Overtime/Create`, payload, true);
    console.log('res', res);
    if (res)
      Toast({
        type: 'success',
        message: 'Overtime Added Successfully'
      });
  } catch (err) {
    console.log('Error in creating overtime component: ', err)
  }
};

export const createSalaryComponent = async (payload) => {
  try {
    const res = await apiService.post(`SalaryComponent/CreateUpdate`, payload, true);
    console.log('res', res);
    if (res)
      Toast({
        type: 'success',
        message: 'Salary Component Created Successfully'
      });
  } catch (err) {
    console.log('Error in creating salary component: ', err)
  }
};

export const fetchSalaryComponent = async () => {
  try {
    const res = await apiService.get(`SalaryComponent/List`, {}, true);
    return res?.data?.data;
  } catch (err) {
    console.log('error in compensations', err);
  }
};

export const createOvertime = async (payload) => {
  try {
    const res = await apiService.post(`Overtime`, payload, true);
    console.log('res', res);
    if (res)
      Toast({
        type: 'success',
        message: 'Overtime Created Successfully'
      });
  } catch (err) {
    console.log('Error in creating overtime: ', err)
  }
};

export const fetchAllCompensations = async () => {
  try {
    const res = await apiService.get(`Compensation`, {}, true);
    return res?.data?.data;
  } catch (err) {
    console.log('Error in fetching compensations: ', err)
  }
};
