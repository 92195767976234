import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import BaseModal from '../../../../../components/base/modal';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import Select from '../../../../../components/input/Select';

import { emptyObjForm, emptyObjResultForm } from '../../../../../helpers/constant';
import { API } from '../../../../../utils/apis';

const ObjectiveModal = ({ show, toggleModal }) => {
  const [formData, setFormData] = useState([emptyObjForm]);
  const [departments, setDepartments] = useState([]);
  const [departmentsOption, setDepartmentOption] = useState([]);
  const [loading, setLoading] = useState(false);

  const addKeyResults = (index) => {
    formData[index].keyResults = [...formData[index].keyResults, emptyObjResultForm];
    setFormData([...formData]);
  };

  const deleteKeyResults = (index, i) => {
    formData[index].keyResults = formData[index].keyResults.filter((item, ind) => ind !== i);
    setFormData([...formData]);
  };

  const addObj = () => {
    setFormData([...formData, emptyObjForm]);
  };

  const deleteObj = (ind) => {
    setFormData((formData) => formData.filter((item, i) => i !== ind));
  };
  const handleChangeFormData = (i, e) => {
    const { name, value } = e.target;
    formData[i] = { ...formData[i], [name]: value };
    setFormData([...formData]);
  };

  const handleChangeKeyResults = (i, ind, e, name, isObj) => {
    if (isObj) {
      formData[i].keyResults[ind] = { ...formData[i].keyResults[ind], [name]: e };
    } else {
      const { name, value } = e.target;
      formData[i].keyResults[ind] = { ...formData[i].keyResults[ind], [name]: value };
    }
    setFormData([...formData]);
  };

  const loadData = async () => {
    setLoading(true);
    const departmentRes = await API.fetchAllDepartment();
    if (departmentRes) setDepartments(departmentRes);
    setLoading(false);
  };

  const getDepartments = (department) => {
    let DEPARTMENT_ARRAY = [];
    for (let i = 0; i < department.length; i++) {
      DEPARTMENT_ARRAY.push({ label: department[i]?.name, value: department[i]?.id });
    }
    setDepartmentOption(DEPARTMENT_ARRAY);
  };

  const submitData = async (e) => {
    setLoading(true);
    await API.saveObjective(formData);
    setLoading(false);
    e.preventDefault();
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    getDepartments(departments);
  }, [departments]);

  console.log(formData);
  return (
    <BaseModal
      title={'set coperative'}
      coloredText='objectives '
      show={show}
      onClose={toggleModal}
      wide>
      <div className='space-y-6'>
        <form className='space-y-10 mt-4'>
          {formData.map((item, i) => (
            <div key={i} className='space-y-8  bg-white-30 shadow-xl rounded-3xl p-4 md:p-10'>
              <div className=' flex flex-wrap justify-between gap-5'>
                <div className='flex gap-5 items-start w-full mdLg:w-[45%] '>
                  {formData.length > 1 && <h6>{i + 1}.</h6>}
                  <Input
                    withLabel
                    label={'INPUT Objective'}
                    containerClass='w-full'
                    value={item?.objective}
                    name='objective'
                    onChange={(e) => handleChangeFormData(i, e)}
                  />
                </div>

                <Input
                  withLabel
                  label={'metric'}
                  containerClass='w-full mdLg:w-[45%] '
                  value={item?.metrics}
                  name='metrics'
                  onChange={(e) => handleChangeFormData(i, e)}
                />
              </div>

              <div className=' flex flex-wrap justify-between gap-5'>
                <Input
                  withLabel
                  containerClass='w-full mdLg:w-[45%] '
                  label={'due date'}
                  value={item?.dueDate}
                  name='dueDate'
                  onChange={(e) => handleChangeFormData(i, e)}
                  type='date'
                />
                <Input
                  withLabel
                  label={'weight'}
                  containerClass='w-full mdLg:w-[45%] '
                  value={item?.weight}
                  name='weight'
                  onChange={(e) => handleChangeFormData(i, e)}
                />
                {i > 0 && formData.length > 1 && (
                  <RiDeleteBin6Line onClick={() => deleteObj(i)} className='cursor-pointer' />
                )}
              </div>
              {item.keyResults.map((result, ind) => (
                <div key={ind}>
                  <div className='space-y-6 mdLg:space-y-0 mdLg:flex mdLg:flex-wrap items-start gap-3 gap-y-6 justify-between'>
                    <Input
                      withLabel
                      label={'key Result'}
                      containerClass=''
                      value={result?.keyResult}
                      name='keyResult'
                      onChange={(e) => handleChangeKeyResults(i, ind, e)}
                    />
                    <div className=' flex flex-col gap-2 w-full'>
                      <h6 className=' text-xs'>lead department</h6>
                      <Select
                        placeholder={'Select lead department...'}
                        options={departmentsOption}
                        isLoading={loading}
                        onChange={({ label }, { name }) =>
                          handleChangeKeyResults(i, ind, label, name, true)
                        }
                        name='contributorDept'
                      />
                    </div>
                    <div className=' flex flex-col gap-2 w-full'>
                      <h6 className=' text-xs'>contributor department</h6>
                      <Select
                        placeholder={'Select contributor department...'}
                        options={departmentsOption}
                        isLoading={loading}
                        onChange={({ label }, { name }) =>
                          handleChangeKeyResults(i, ind, label, name, true)
                        }
                        name='leadDept'
                      />
                    </div>

                    <div className=' flex flex-col gap-2 w-full'>
                      <h6 className=' text-xs'>contributor department1</h6>
                      <Select
                        placeholder={'Select contributor department1...'}
                        options={departmentsOption}
                        isLoading={loading}
                        onChange={({ label }, { name }) =>
                          handleChangeKeyResults(i, ind, label, name, true)
                        }
                        name='contributorDept1'
                      />
                    </div>

                    {ind > 0 && (
                      <MdDelete
                        className={`ml-[4rem] self-center  cursor-pointer `}
                        onClick={() => deleteKeyResults(i, ind)}
                      />
                    )}
                  </div>
                  <h6
                    className={`underline  justify-end cursor-pointer ${
                      ind === item.keyResults.length - 1 ? 'flex' : 'hidden'
                    }`}
                    onClick={() => addKeyResults(i)}>
                    +add more key results
                  </h6>
                </div>
              ))}
            </div>
          ))}
          <div className='mx-auto flex justify-center mdLg:justify-start'>
            <Button
              text={'+ADD MORE GOAL'}
              className='bg-transparent border-purple-100 border hover:border-none '
              onClick={addObj}
            />
          </div>

          <div className='mx-auto flex justify-center mdLg:justify-start'>
            <Button
              type='submit'
              text='save'
              onClick={submitData}
              isPurple
              className={'ml-auto'}
              disabled={loading}
              loading={loading}
            />
          </div>
        </form>
      </div>
    </BaseModal>
  );
};

export default ObjectiveModal;
