import React, { useState, useEffect } from 'react';
import Button from '../../button';
import Input from '../../input';
import Select from '../../input/Select';
import BaseModal from '../../modal';
import { API } from '../../../utils/apis';
import { useNavigate } from 'react-router';
import { DURATION_PERIOD, DURATION_TYPE, STORAGE_KEYS } from '../../../helpers/constant';

const AddCalendarEvent = ({ show, closeModal, selectedEmployee, handleEmployeeSelect }) => {
  const companyId = localStorage.getItem(STORAGE_KEYS?.COMPANY_ID);
  const [modalData, setModalData] = useState({
    activityId: 0,
    time: '',
    employeeId: selectedEmployee,
    duration: 0,
    durationPeriod: 1,
    durationPeriodName: '',
    date: '',
    title: '',
    link: '',
    companyId: parseInt(companyId)
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (field, value) => {
    if (field === 'category') {
      setModalData((prevData) => ({
        ...prevData,
        category: value,
        type: value,
      }));
    } else {
      setModalData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  const handleChangePeriod = (field, selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';
    setModalData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };  

  const submitData = async (e) => {
    setLoading(true);
    console.log(modalData);
    await API.createActivity(modalData, navigate);
    closeModal();
    setLoading(false);
    e.preventDefault();
    window.location.reload();
  };

  useEffect(() => {
    setModalData(prevData => ({
      ...prevData,
      employeeId: selectedEmployee
    }));
  }, [selectedEmployee]);

  return (
    <BaseModal
      title={'Add Employee Activity'}
      showModal={show}
      closeModal={closeModal}
      content={
        <div className='space-y-10 mt-4 rounded-lg w-full sm:w-[400px] overflow-hidden'>
          <Input
            withLabel
            onChange={(e) => handleChange('title', e.target.value)}
            value={modalData.title}
            label='event name *'
            containerClass=' w-full'
            name={'title'}
          />
          <Input
            withLabel
            onChange={(e) => handleChange('date', e.target.value)}
            value={modalData.date}
            label='event date *'
            type='date'
            containerClass=' w-full'
            name={'date'}
          />
          <Input
            withLabel
            onChange={(e) => handleChange('time', e.target.value)}
            value={modalData.time}
            label='event time *'
            type='time'
            containerClass=' w-full'
            name={'time'}
          />
          <Select
            withLabel
            label='DURATION'
            containerClass=''
            selectedOption={modalData.durationPeriod}
            options={DURATION_PERIOD}
            name='durationPeriod'
            onChange={(e) => handleChangePeriod('durationPeriod', e)}
          />
          <Input
            withLabel
            label='DURATION PERIOD'
            containerClass=''
            value={modalData.duration}
            name='duration'
            onChange={(e) => handleChange('duration', e.target.value)}
          />
          <div className='flex gap-4 flex-wrap justify-end'>
            <Button
              className={'border border-pink-100'}
              text='cancel'
              onClick={closeModal}
              isTransparent
            />
            <Button 
              text='submit' 
              isPurple
              onClick={submitData}
              loading={loading}
            />
          </div>
        </div>
      }
    />
  );
};

export default AddCalendarEvent;
