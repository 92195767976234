import React from 'react';
import { FaUserCircle } from 'react-icons/fa';

const AdminCard = ({ isActive, name }) => {
  return (
    <div className="bg-pink-30 w-full flex flex-col p-6 space-y-3 rounded-sm items-center">
      <div className="relative h-16 w-16 ">
        <div className="border-[1.5px] border-white-100 rounded-circle flex justify-center items-center ">
          <FaUserCircle className="h-[100%] w-full" />
        </div>

        <div
          className={`absolute right-0 top-10 w-4 h-4 border-2 rounded-circle border-white-100 ${
            isActive ? 'bg-green-100' : 'bg-yellow-100'
          }`}></div>
      </div>
      <span className="capitalize text-purple-100 text-sm sm:text-base font-bold text-center">
        {name}
      </span>
      <h6>ADMIN</h6>
    </div>
  );
};

export default AdminCard;
