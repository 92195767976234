import React from 'react';
import { ReactComponent as Logo } from '../../assets/img/landing/LandingLogo.svg';
import { Link } from 'react-router-dom';
import NavBar from './NavBar';
import { HOME } from '../../router/landingPageRoutes';
import Button from '../button';
import { AUTH_LOGIN, SIGN_UP } from '../../router/router';

const LandingPageHeader = () => {
  return (
    <div className='flex flex-wrap items-center gap-2 justify-between py-8 px-4 pl-2 sm:px-16 text-center sm:text-left'>
      <Link to={HOME} className='mx-auto md:mx-0'>
        <Logo className='' />
      </Link>

      <div className='hidden flex-col md:flex md:flex-row items-center flex-wrap justify-between w-[55%]'>
        <NavBar />

        <div className='flex flex-wrap gap-3 items-center'>
          <Link to={AUTH_LOGIN} className='text-white-100 font-semibold text-xs tracking-tight '>
            Login
          </Link>
          <Link to={SIGN_UP}>
            {' '}
            <Button isPink text='Sign Up' />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingPageHeader;
