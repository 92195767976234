import React, { useState, useEffect } from 'react';
import { MdOutlineArrowBack, MdOutlineArrowForward } from 'react-icons/md';
import { apiService } from '../../../../helpers/api';
import UpcomingAnnouncements from '../upcoming-announcements';
import AddAnnouncement from '../announcements-modal/add-announcement';
import AnnouncementCard from '../../../../components/cards/AnnouncementCard';

const Announcements = () => {

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [announcementsPerPage] = useState(4);
 const [announcements, setAnnouncements] = useState([]);
 const [showAddAnnouncementModal, setShowAddAnnouncementModal] = useState(false);

  const fetchAnnouncements = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        'Annoucement/List',
        {
          
        },
        true
      );
      const response = res?.data?.data;
      setAnnouncements(response);
    } catch (err) {
      console.log('Error in fetching announcements: ', err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const indexOfLastAnnouncement = currentPage * announcementsPerPage;
  const indexOfFirstAnnouncement = indexOfLastAnnouncement - announcementsPerPage;
  const currentAnnouncements = announcements.slice(indexOfFirstAnnouncement, indexOfLastAnnouncement);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="space-y-3 w-full md:w-[48%]">
      <div className=" flex items-center justify-between gap-3 text-blue-100 font-semibold">
        <p className="font-bold">Announcements</p>
        <p className=" text-xs cursor-pointer flex items-center gap-1">
          <span className=" text-pink-100 text-base flex flex-wrap items-center gap-1" onClick={() => setShowAddAnnouncementModal(true)}>+ <p className='text-blue-100 text-xs'>Add New</p></span>
        </p>
      </div>
      <div className="space-y-3">
        {currentAnnouncements.map((announcement, index) => (
          <AnnouncementCard
            key={index}
            date={announcement.date}
            title={announcement.title}
            isBlue
          />
        ))}
        <AnnouncementCard title="New Client Signing" isDraft />
      </div>

      <AddAnnouncement
        show={showAddAnnouncementModal}
        closeModal={() => setShowAddAnnouncementModal(!setShowAddAnnouncementModal)}
      />
      <div className="pagination-controls flex justify-end">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <MdOutlineArrowBack />
        </button>
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={indexOfLastAnnouncement >= announcements.length}
        >
          <MdOutlineArrowForward />
        </button>
      </div>
    </div>
  );
};

export default Announcements;
