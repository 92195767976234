import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import LeftEmployeeDetails from './LeftEmployeeDetails';
import RightEmployeeDetails from './RightEmployeeDetails';
import UserProfileLayout from '../../../people_centa/profile/features';
import { BiLoaderCircle } from 'react-icons/bi';
import EmployeeHeader from './EmployeeHeader';
import { API } from '../../../../utils/apis';

const EmployeeDetails = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [employee, setEmployee] = useState([]);
  const [leaveRequest, setLeaveRequest] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const fetchEmployee = async () => {
    setLoading(true);
    const res = await API.fetchSingleEmployee(id);
    const leaveRes = await API.fetchSingleUserLeaveRequests(id);
    setEmployee(res);
    setLeaveRequest(leaveRes);
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  return (
    <>
      {loading ? (
        <span className=''>
          <BiLoaderCircle
            size={16}
            className='h-[200px] flex items-center mx-auto animate-spin-slow'
          />
        </span>
      ) : (
        <>
          {step === 1 && (
            <div className='bg-pink-30 rounded-sm pt-4 pb-14 px-2 sm:px-12'>
              <div
                className='uppercase text-xs flex items-center justify-start space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer'
                onClick={() => navigate(-1)}>
                <MdOutlineArrowBack /> <span className='text-blue-100'> BACK</span>
              </div>
              <div className='mt-4 bg-white-100 rounded-sm py-5 px-4 sm:px-12'>
                <EmployeeHeader setStep={setStep} employee={employee} />
                <div className='flex gap-4 flex-wrap justify-between items-start'>
                  <LeftEmployeeDetails employee={employee} />
                  <RightEmployeeDetails leaveRequest={leaveRequest} setStep={setStep} />
                </div>
              </div>
            </div>
          )}
          {step === 2 && <UserProfileLayout employee={employee} />}
        </>
      )}
    </>
  );
};

export default EmployeeDetails;
