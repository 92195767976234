import React, { useState } from 'react';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import Select from '../../../../components/input/Select';
import Modal from '../../../../components/modal';
import { API } from '../../../../utils/apis';
import { EVENT_CATEGORY } from '../../../../helpers/constant';
import { useNavigate } from 'react-router';
import { MANAGE_EVENTS } from '../../../../router/router';

const AddEvent = ({ show, closeModal }) => {
  const [modalData, setModalData] = useState({
    title: '',
    date: '',
    time: '',
    description: '',
    type: 3,
    location: '',
    coverPhoto: '',
    fileName: '',
    cateory: 1,
    invitees: [0],
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (field, value) => {
    if (field === 'cateory') {
      setModalData((prevData) => ({
        ...prevData,
        cateory: value,
        type: value,
      }));
    } else {
      setModalData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  const submitData = async (e) => {
    setLoading(true);
    await API.createEvent(modalData, navigate);
    closeModal();
    setLoading(false);
    e.preventDefault();
    window.location.reload();
  };
  
  return (
    <Modal
      title={'Add Event'}
      showModal={show}
      closeModal={closeModal}
      content={
        <div className=' space-y-10 mt-4 rounded-lg w-[400px]'>
          <Input
            withLabel
            onChange={(e) => handleChange('title', e.target.value)}
            value={modalData.title}
            label='event name *'
            containerClass=' w-full'
            name={'title'}
          />

         <Input
            withLabel
            onChange={(e) => handleChange('location', e.target.value)}
            value={modalData.location}
            label='event location *'
            containerClass=' w-full'
            name={'location'}
          />
          
          <Input
            withLabel
            onChange={(e) => handleChange('date', e.target.value)}
            value={modalData.date}
            label='event date *'
            type='date'
            containerClass=' w-full'
            name={'date'}
          />

         <Input
            withLabel
            onChange={(e) => handleChange('time', e.target.value)}
            value={modalData.time}
            label='event time *'
            type='time'
            containerClass=' w-full'
            name={'time'}
          /> 

         <Select
            options={EVENT_CATEGORY}
            withLabel
            onChange={(selectedOption) =>
              handleChange('cateory', selectedOption?.value)
            }
            value={{ value: modalData.cateory, label: EVENT_CATEGORY.find(item => item.value === modalData.cateory)?.label }}
            label=' category *'
            containerClass=' w-full'
            name={'cateory'}
          />
  
          <div className='flex gap-4 flex-wrap justify-end'>
            <Button
              className={'border border-pink-100'}
              text='cancel'
              onClick={closeModal}
              isTransparent
            />
            <Button 
              text='submit' 
              isPurple
              onClick={submitData}
              loading={loading}
            />
          </div>
        </div>
      }
    />
  );
};

export default AddEvent;
