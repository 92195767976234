import React, { useState, useRef } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import ImgUploader from '../../../../components/base/image-uploader';

const ProfileHeader = ({ employee }) => {
  const [file, setFile] = useState('');
  const fileInputRef = useRef(null);

  /*const handleFileSelect = (e) => {
    const file = e.target.files[0];
    handleAddEmployee(file)
  };*/

  const onFileChange = (e) => {
    const { files } = e.target;
    if (files) setFile(URL.createObjectURL(files[0]));
  };
  const navigate = useNavigate();

  return (
    <div className='space-y-4'>
      <div
        className='uppercase text-xs flex items-center justify-start space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer'
        onClick={() => navigate(-1)}>
        <MdOutlineArrowBack /> <span className='text-blue-100'> BACK</span>
      </div>
      <div className='flex flex-wrap items-center gap-8  '>
        <ImgUploader file={file} onFileChange={onFileChange} isEdit />
        <div className='flex flex-col gap-2 items-start'>
          <p className='font-bold text-lg sm:text-xl md:text-2xl text-purple-300'>{employee?.fullName}</p>
          <div className='flex flex-wrap gap-5 items-center text-white-100'>
            <p className='font-semibold text-purple-300 text-sm sm:text-base md:text-xl'>
              {employee?.jobRole}
            </p>
            <span className='font-medium text-sm sm:text-base px-4  py-1 border bg-yellow-100 rounded-[21px] border-yellow-100 '>
              {employee?.department?.name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
