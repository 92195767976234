import React from 'react';

const EventDetailsCard = ({ meetingTime, meetingTitle }) => {
  return (
    <div className=" p-2 border rounded-[5px] border-pink-100">
      <div>
        <h6>{meetingTime}</h6>
        <p className="text-blue-100 text-xs font-semibold ">{meetingTitle}</p>
      </div>
    </div>
  );
};

export default EventDetailsCard;
