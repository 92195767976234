import React from 'react';
import Input from '../../../components/input';
import Select from '../../../components/input/Select';
import { POSITION_IN_COMPANY } from '../../../helpers/constant';

const CompanyRepInfo = ({ handleChange, formData, setFormData }) => {
  return (
    <>
      <div className=' w-full flex items-end justify-between flex-wrap gap-6'>
        <Input
          withLabel
          placeholder={'Enter your first name'}
          onChange={handleChange}
          value={formData.primaryContactFirstName}
          label='First Name'
          containerClass=' w-full md:w-[45%]'
          name={'primaryContactFirstName'}
        />
        <Input
          withLabel
          placeholder={'Enter your last name'}
          onChange={handleChange}
          value={formData.primaryContactLastName}
          label='Last Name'
          className=''
          containerClass=' w-full md:w-[45%]'
          name={'primaryContactLastName'}
        />
      </div>

      <div className=' w-full flex items-end justify-between flex-wrap gap-6'>
        <Input
          withLabel
          placeholder={'Enter your email address'}
          onChange={handleChange}
          value={formData.primaryContactEmail}
          label='work email'
          className=''
          containerClass=' w-full md:w-[45%]'
          name={'primaryContactEmail'}
        />
        <div className=' flex flex-col gap-2 w-full md:w-[45%]'>
          <h6 className=' text-xs'> Position in Company</h6>
          <Select
            selectedOption={formData.primaryContactCompanyPosition}
            placeholder={'Position in the company'}
            className=' w-full'
            options={POSITION_IN_COMPANY}
            onChange={(e) =>
              setFormData({
                ...formData,
                primaryContactCompanyPosition: e
              })
            }
          />
        </div>
      </div>
      <Input
        withLabel
        placeholder={'Enter your phone number'}
        onChange={handleChange}
        value={formData.primaryContactPhoneNumber}
        label='Phone Number'
        className=''
        containerClass='w-full md:w-[45%]'
        name={'primaryContactPhoneNumber'}
        type='number'
      />
    </>
  );
};

export default CompanyRepInfo;

// <div className=' w-full flex items-end justify-between flex-wrap gap-6'>
// <Input
//   withLabel
//   onChange={handleChange}
//   value={formData.password}
//   label='Password'
//   className=''
//   containerClass=' w-full md:w-[45%]'
//   name={'password'}
//   ispassword
// />

// <Input
//   withLabel
//   onChange={handleChange}
//   value={formData.confirmPassword}
//   label='Confirm Password'
//   className=''
//   containerClass=' w-full md:w-[45%]'
//   name={'confirmPassword'}
//   ispassword
// />
// </div>
