import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { BiRightArrowAlt } from 'react-icons/bi';
import Button from '../../button';
import { Toast } from '../../toast/toast';
import { apiService } from '../../../helpers/api';
import { STORAGE_KEYS } from '../../../helpers/constant';
import EventDetailsCard from '../../cards/EventDetailsCard';
import DatePicker from '../calendar';
import { CALENDAR_DETAILS, MANAGE_EVENTS } from '../../../router/router';

const EventsDetails = () => {
  const employeeId = localStorage.getItem(STORAGE_KEYS?.EMPLOYEE_ID);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const formatDate = (date) => {
    return date.toISOString().split('T')[0]; // Formats the date as 'yyyy-mm-dd'
  };

  const handleSelect = (ranges) => {
    const selectedStartDate = formatDate(ranges.selection.startDate);
    const selectedEndDate = formatDate(new Date(ranges.selection.endDate));

    setSelectionRange({
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      key: 'selection',
    });

    fetchCalendarDetails(employeeId, selectedStartDate, selectedEndDate);
  };

  const { id } = useParams();
  const [calendarDetails, setCalendarDetails] = useState([]);
  const navigate = useNavigate();

  const fetchCalendarDetails = async (employeeId, startDate, endDate) => {
    try {
      const res = await apiService.get(
        `Activity/${employeeId}/${startDate}/${endDate}`,
        true
      );

      const calendarDetails = res?.data?.data;
      setCalendarDetails(calendarDetails);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchCalendarDetails(employeeId, selectionRange.startDate, selectionRange.endDate);
  }, [employeeId, selectionRange.startDate, selectionRange.endDate]);

  return (
    <div className="bg-white-100 max-w-[360px] flex flex-col items-center p-5 rounded-sm">
      <DatePicker selectionRange={selectionRange} handleSelect={handleSelect} />
      <div className="border-t border-[#4E008333] w-[100%] py-3 space-y-3">
        {calendarDetails.map((event) => (
          <EventDetailsCard
            key={event.activityId}
            meetingTime={event.time}
            meetingTitle={event.title}
          />
        ))}
      </div>

      <div className="w-full">
        <Button
          text={'view employees calendar'}
          isOrange
          icon={<BiRightArrowAlt size={14} />}
          withIcon
          className={'flex flex-row-reverse items-center gap-2 justify-center'}
          onClick={() => navigate(`${MANAGE_EVENTS}/${CALENDAR_DETAILS}`)}
        />
      </div>
    </div>
  );
};

export default EventsDetails;
