import React from 'react';
import { HeaderCard } from '../../components/cards/Cards';
import RequestsContent from './features';
import LayoutWithSideBar from '../../components/layout/LayoutWithSideBar';

const Requests = () => {
  return (
    <LayoutWithSideBar>
      <HeaderCard
        text={'Manage Requests'}
        titleClassName={'font-bold'}
      />
      <RequestsContent />
    </LayoutWithSideBar>
  );
};

export default Requests;
