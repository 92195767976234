import React from 'react';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';

const SalaryBreakdownCard = ({
  key,
  className,
  payrate,
  name,
  dependents,
  editIcon,
  deleteIcon
}) => {
  return (
    <Link
      key={key}
      to={''}
      className={`bg-white-100 p-4 flex items-start justify-between font-semibold rounded-[5px] ${className}`}>
      <td className='w-[120px]'>{payrate}</td>
      <td>{name}</td>
      <td>{dependents}</td>
      <td className='flex flex-row gap-4'>{editIcon}{deleteIcon}</td>
    </Link>
  );
};

export default SalaryBreakdownCard;
