import React, {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../../../helpers/api';
import Button from '../../../../../components/button';
import Modal from '../../../../../components/modal';
import JobDetailsLeft from './JobDetailsLeft';
import JobDetailsRight from './JobDetailsRight';
import { getEmployeeType, getJobType, getJobStatus } from '../../../../../helpers/functions';
import { format, isThisWeek, isThisMonth, parseISO } from 'date-fns';

const JobDetails = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [vacancyDetails, setVacancyDetails] = useState([]);
  const { id } = useParams();
  
  const fetchVacancyDetails = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        `JobVacancy/FetchAllJobVacancyByCompanyId`,
        { vacancyId: id },
        true
      );
      const response = res?.data?.data?.data;
      setVacancyDetails(response || []); // Ensure response is an array or empty
    } catch (err) {
      console.log('Error in fetching vacancy details: ', err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVacancyDetails();
  }, []);

  const jobType = getJobType(vacancyDetails?.jobType)
    ? getEmployeeType(vacancyDetails?.jobType)
    : vacancyDetails?.jobType
  ;

  const jobStatus = getJobStatus(vacancyDetails?.jobStatus);

  // Filter jobs for this week and this month based on `dateCreated`
  const thisWeekJobs = vacancyDetails.filter(job => isThisWeek(parseISO(job.dateCreated)));
  const thisMonthJobs = vacancyDetails.filter(job => isThisMonth(parseISO(job.dateCreated)) && isThisWeek(parseISO(job.dateCreated)));

  const selectedJob = thisMonthJobs.length > 0 ? thisMonthJobs[0] : null;

  return (
    <>
      <Modal
        title={'Are you sure to '}
        coloredTitle="Close Job?"
        showModal={showModal}
        closeModal={() => setShowModal(!showModal)}
        content={
          <div className=" space-y-8">
            {' '}
            <p className="text-gray-200 w-full sm:w-[70%] ">
              Please preview or scan through job description before publishing it.
            </p>{' '}
            <div className="flex gap-4 flex-wrap">
              <Button
                className={''}
                text="Go back"
                onClick={() => setShowModal(!showModal)}
                isGray
              />
              <Button text="Close Job" isPink className="bg-opacity-80" />
            </div>
          </div>
        }
      />
      <div className="space-y-10 relative">
        <div className=" bg-pink-100 py-4 flex rounded-xl items-center justify-between px-4 flex-wrap gap-4 ">
          <div className="text-white-100 flex gap-2 items-center">
            <MdOutlineArrowBack
              className=" cursor-pointer bg-white-30 w-6 h-6 p-1 rounded-full"
              onClick={() => navigate(-1)}
            />
            <p className="font-medium">Program Manager</p>
          </div>
          <div className="flex gap-4 flex-wrap items-center">
            <RiDeleteBin6Line className="text-white-100 bg-white-30 w-6 h-6 p-1 rounded-full cursor-pointer" />
            <Button
              text={'Close Applications'}
              isFaded
              className={''}
              onClick={() => setShowModal(!showModal)}
            />
            <Button text={'Start Interviews'} className={''} />
          </div>
        </div>

        <div className="flex flex-wrap gap-6 justify-between items-start bg-white-100 rounded-md p-4">
          <JobDetailsLeft job={selectedJob} vacancyDetails={vacancyDetails}/>
          <JobDetailsRight />
        </div>
      </div>
    </>
  );
};

export default JobDetails;
