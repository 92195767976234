import React, { Fragment } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import ApplicantCard from '../../../../../components/cards/ApplicantCard';
const ApplicantDetails = () => {
  return (
    <Fragment>
      <div className="flex flex-col items-center mdLg:items-start gap-3 flex-wrap mt-10">
        <h4 className=" mdLg:text-left text-center">VC-8827525</h4>
        <div className="flex gap-2 flex-wrap">
          <h4 className="font-semibold text-lg">UI Designer</h4>
          <span className="bg-yellow-100 text-white-100 px-4 text-xs flex justify-center items-center py-[2px] rounded-[20px]">
            Product Department{' '}
          </span>
        </div>
      </div>
      <div>
        <h6 className=" mdLg:text-left text-center mt-10">VACANCY DESCRIPTION</h6>
        <div className="rounded-[20px] bg-blue-100   p-10 my-6">
          <div className="whitespace-nowrap overflow-x-scroll  ">
            <div className="uppercase tracking-[3px]  font-semibold text-2xs flex justify-start pl-16 mb-4 w-fit text-white-100">
              <p className="w-[200px] mdLg:w-[200px]">EMPLOYEE NAME</p>
              <p className="w-[200px] mdLg:w-[250px]">APPLICANT ID</p>
              <p className="w-[200px] mdLg:w-[250px]"> EMAIL</p>
              <p className="">STATUS</p>
            </div>
            <div className=" min-w-fit  flex flex-col gap-4">
              <ApplicantCard
                displayPicture={<FaUserCircle size={30} color="#4E008330" />}
                applicantId="VA-67666"
                email={'augustuslanre@gmail.com'}
                name={'Augustus Lanre'}
                isApproved
              />
              <ApplicantCard
                displayPicture={<FaUserCircle size={30} color="#4E008330" />}
                applicantId="VA-67666"
                email={'augustuslanre@gmail.com'}
                name={'Augustus Lanre'}
                pendingApproval
                withAttachments
                CV
                coverLetter
              />
              <ApplicantCard
                displayPicture={<FaUserCircle size={30} color="#4E008330" />}
                applicantId="VA-67666"
                email={'augustuslanre@gmail.com'}
                name={'Augustus Lanre'}
                isRejected
              />
              <ApplicantCard
                displayPicture={<FaUserCircle size={30} color="#4E008330" />}
                applicantId="VA-67666"
                email={'augustuslanre@gmail.com'}
                name={'Augustus Lanre'}
                isRejected
              />
              <ApplicantCard
                displayPicture={<FaUserCircle size={30} color="#4E008330" />}
                applicantId="VA-67666"
                email={'augustuslanre@gmail.com'}
                name={'Augustus Lanre'}
                isApproved
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ApplicantDetails;
