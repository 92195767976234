import React, { useEffect, useState } from 'react';
import SearchWithFilter from '../../../components/general/SearchWithFilter';
import { apiService } from '../../../helpers/api';
import VacancyContent from './add-vacancy/VacancyContent';

const HiringLayout = () => {
  const [loading, setLoading] = useState(false);
  const [jobVacancies, setJobVacancies] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const fetchAllVacancies = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        'JobVacancy/FetchAllJobVacancyByCompanyId',
        {
          pageNumber: 1,
          pageSize: 20
        },
        true
      );
      const response = res?.data?.data?.data;
      setJobVacancies(response);
    } catch (err) {
      console.log('Error in fetching job vacancies: ', err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllVacancies();
  }, []);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div className='bg-white-100 rounded-sm py-5 px-4 sm:px-12'>
      <SearchWithFilter
        placeholder='Search by job title'
        isPink
        onChange={handleSearchInputChange}
        value={searchInput}
      />
      <VacancyContent jobVacancies={jobVacancies} loading={loading} searchInput={searchInput} />
    </div>
  );
};

export default HiringLayout;
