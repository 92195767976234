import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/img/landing/LandingLogo.svg';
import { HOME } from '../../router/landingPageRoutes';
import { AiOutlineTwitter, AiOutlineLinkedin } from 'react-icons/ai';
import { FiInstagram } from 'react-icons/fi';

const LandingFooter = () => {
  return (
    <div>
      <div className="flex flex-wrap items-center gap-2 justify-between py-8 px-4 pl-2 sm:px-16 text-center sm:text-left bg-blue-400">
        <Link to={HOME} className="mx-auto md:mx-0">
          <Logo className="" />
        </Link>

        <div className="flex flex-col gap-3 items-start text-white-100 text-sm">
          <div className="flex space-x-4  items-center">
            <span className="">FOLLOW US</span>

            <p className="flex text-white-100 gap-3 ">
              <div className="w-8 p-1 h-8 cursor-pointer hover:bg-pink-30 transition-all duration-200 rounded-circle bg-purple-100 flex justify-center items-center">
                <AiOutlineTwitter size={20} />
              </div>
              <div className="w-8 p-1 h-8 cursor-pointer hover:bg-pink-30 transition-all duration-200 rounded-circle bg-purple-100 flex justify-center items-center">
                <FiInstagram size={20} />
              </div>
              <div className="w-8 p-1 h-8 cursor-pointer hover:bg-pink-30 transition-all duration-200 rounded-circle bg-purple-100 flex justify-center items-center">
                <AiOutlineLinkedin size={20} />
              </div>
            </p>
          </div>

          <div className="">
            <p>Privacy Policy</p>
            <p>Terms and Conditions</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingFooter;
