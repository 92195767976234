import React, { useEffect, useRef, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import EmployeesRoleCard from '../../../../components/cards/EmployeesRoleCard';
import Input from '../../../../components/input';
import { FaUserCircle } from 'react-icons/fa';
import { debounce } from 'lodash';
import Loader from '../../../../components/base/loader';
import { API } from '../../../../utils/apis';

const EmployeesTab = ({ employees }) => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState('');
  const [employeesSearch, setEmployeesSearch] = useState(null); 

  const getSearchResults = async (val) => {
    setLoading(true);
    const res = await API.getSearchResults(val);
    console.log(res?.data?.data);
    if (res) setEmployeesSearch(res?.data?.data);
    setLoading(false);
  };

  const debouncedSearch = useRef(debounce((val) => getSearchResults(val), 500)).current;

  const handleChange = async (e) => {
    const value = e.target.value.trim();
    setSearchValue(value);

    if (value.length >= 2) {
      await debouncedSearch(value);
      setError('');
    } else {
      setError('Enter 2 characters or more to search');
      setEmployeesSearch(null); 
    }
  };

  useEffect(() => {
    if (!searchValue) {
      setError('');
      setEmployeesSearch(null);
    }
  }, [searchValue]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <div className='font-medium w-full mdLg:w-[40%] bg-pink-30 p-6 rounded-sm space-y-3 h-[100%]'>
      <div className='space-y-3'>
        <p>Employees</p>
        <Input
          isRounded
          icon={<BiSearch size={20} color='#FF9D00' />}
          placeholder={'Search by employee first or last name'}
          containerClass='w-full'
          className='placeholder:text-blue-100 bg-white-30 h-12'
          withIcon
          onChange={handleChange}
        />
        <span className='text-sm text-red-700 font-bold'>{error}</span>
      </div>

      <div className='overflow-y-scroll no-scroll-bar py-4 h-[90%]'>
        <div className='space-y-4'>
          <div className='px-1 space-y-3'>
            {loading && <Loader />}

            {(searchValue && searchValue.length >= 2) ? (
              employeesSearch?.length === 0 ? (
                <div>No Search Result For {searchValue}</div>
              ) : (
                employeesSearch?.map((employee) => (
                  <EmployeesRoleCard
                    displayPicture={<FaUserCircle size={30} color='#4E008330' />}
                    dept={employee?.department?.name}
                    email={employee?.personalEmail}
                    role={employee?.jobRole}
                    name={employee?.fullName}
                    key={employee?.id}
                    id={employee?.id}
                  />
                ))
              )
            ) : (
              employees?.length === 0 ? (
                <div>No Employees To Show</div>
              ) : (
                employees
                  ?.sort((a, b) => b?.fullName.localeCompare(a?.fullName))
                  ?.map((employee) => (
                    <EmployeesRoleCard
                      displayPicture={<FaUserCircle size={30} color='#4E008330' />}
                      dept={employee?.department?.name}
                      email={employee?.personalEmail}
                      role={employee?.jobRole}
                      name={employee?.fullName}
                      key={employee?.id}
                      id={employee?.id}
                    />
                  ))
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeesTab;
