import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';

const VacancyDetailsLayout = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-pink-30 rounded-sm pt-4 pb-14 px-6 md:px-12">
      <div
        className="uppercase text-xs flex items-center justify-start space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer"
        onClick={() => navigate(-1)}>
        <MdOutlineArrowBack /> <span className="text-blue-100"> BACK</span>
      </div>
      <Outlet />
    </div>
  );
};

export default VacancyDetailsLayout;
