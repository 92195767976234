import moment from 'moment/moment';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { apiService } from '../../../../helpers/api';
import { Toast } from '../../../../components/toast/toast';
import PayrollCard from '../../../../components/cards/PayrollCard';
import EditComponentModal from './EditComponentModal'; // Import the modal component
import { API } from '../../../../utils/apis';

const CompensationInfo = () => {
  const [loading, setLoading] = useState(false);
  const [compensationDetail, setCompensationDetail] = useState([]);
  const [overtimeDetail, setOvertimeDetail] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false); // State for showing/hiding modal
  const [selectedComponent, setSelectedComponent] = useState(null); // State for the selected component
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchEmployee = async () => {
    setLoading(true);
    const res = await API.fetchSingleEmployee(id);
    setEmployee(res);
    setLoading(false);
  };

  const fetchCompensationDetail = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(`Compensation/${id}`, {}, true);
      const response = res?.data?.data;
      console.log('Compensation Detail:', response);
      setCompensationDetail(response);
    } catch (err) {
      console.log('Error in fetching compensation details: ', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployee();
    fetchCompensationDetail();
  }, []);

  // Handler to show the edit modal
  const handleEditClick = (component) => {
    setSelectedComponent(component); // Set the selected component data
    setShowEditModal(true); // Show the modal
  };

  // Handler to close the modal
  const closeModal = () => {
    setShowEditModal(false);
    setSelectedComponent(null);
  };

  return (
    <div className="w-full space-y-10">
      <div className="flex flex-wrap items-center gap-8  w-full  ">
        <img 
         src={employee?.user?.profileImageURL} 
         height='92' width='92' 
         alt="user" className="border border-yellow-100 rounded-[50%] p-2" 
        />
        <div className="flex flex-col gap-2 items-start">
          <p className="font-bold capitalize text-lg sm:text-xl md:text-2xl text-purple-300">
            {compensationDetail.name}
          </p>
          <div className="flex flex-wrap gap-5 items-center text-white-100">
            <p className="font-semibold text-purple-300 text-sm sm:text-base md:text-xl capitalize">
              {compensationDetail.jobRole}
            </p>
            <span className="font-medium text-sm sm:text-base px-4  py-1 border bg-yellow-100 rounded-[21px] border-yellow-100 capitalize">
              {compensationDetail.department}
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap  justify-between gap-4 w-[80%] md:w-[70%]">
        <div>
          <h6>EMPLOYEE ID</h6>
          <p className="text-black-100 font-semibold">ID-{compensationDetail?.employeeId}</p>
        </div>
        <div>
          <h6>GROSS PAY</h6>
          <p className="text-black-100 font-semibold">
           ₦{compensationDetail?.gross}
          </p>
        </div>
        {/*<div>
          <h6>END DATE</h6>
          <p className="text-black-100 font-semibold">
            {moment(compensationDetail?.endDate).format('MMM Do')}
          </p>
        </div>
        {/*<div>
          <h6>LEAVE TYPE</h6>
          <p className="text-black-100 font-semibold capitalize">
            {getCompensationType(compensationDetail?.leaveType)}
          </p>
       </div>*/}
        <div>
          <h6>PAY FREQUENCY</h6>
          <p className="text-black-100 font-semibold">{compensationDetail?.durationName}</p>
        </div>
        {/*<div>
          <h6>STATUS</h6>
          <p
            className={${leaveDetail?.approvalStatus === 0
              ? 'text-yellow-100'
              : leaveDetail?.approvalStatus === 1
                ? 'text-green-800'
                : 'text-red-700'
              } font-semibold uppercase text-sm tracking-[2.5px]}>
            {getApprovalStatus(leaveDetail?.approvalStatus)}
          </p>
            </div>*/}
      </div>

      <div>
        <h6>GROSS SALARY BREAKDOWN</h6>
        <p className="text-black-100 font-semibold">{compensationDetail?.description}</p>
      </div>
      <div className='space-y-3 bg-purple-50 shadow-xl rounded-3xl p-4 md:p-10'>
        <table className='whitespace-nowrap w-[100%]'>
          <thead className='uppercase tracking-[3px] text-blue-100 font-semibold text-2xs flex justify-between mb-4 w-full xl:w-full'>
            <th>Component</th>
            <th className='mr-8'>Amount (₦)</th>
          </thead>
        </table>
        <table className="whitespace-nowrap w-[100%]">
          <tbody className='flex flex-col'>
            {compensationDetail?.allowance &&
              compensationDetail?.allowance.map((compensation, i) => {
                return (
                  <PayrollCard
                    key={`${i}`}
                    name={compensation?.salaryComponent}
                    editIcon={compensation?.amount}
                    className={'gap-x-10 justify-between mb-2'}
                    onEditClick={() => handleEditClick(compensation)} // Pass the handler to PayrollCard
                  />
                );
              })}
          </tbody>

          {/* Repeat for other components (deductions, overtime, etc.) */}

        </table>
      </div>

      {/* Edit Modal */}
      {showEditModal && (
        <EditComponentModal
          show={showEditModal}
          closeModal={closeModal}
          editLeaveSettingInfo={selectedComponent}
        />
      )}
    </div>
  );
};

export default CompensationInfo;
