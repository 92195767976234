import React from 'react';
import { MdOutlineArrowLeft, MdOutlineArrowRight } from 'react-icons/md';

const PaginationBar = ({ currentPage, totalPages, onNextPage, onPreviousPage }) => {

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      onNextPage();
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      onPreviousPage();
    }
  };

  console.log('PaginationBar - currentPage:', currentPage);
  console.log('PaginationBar - totalPages:', totalPages);

  return (
    <div className="flex justify-between items-center">
      <button
        className={`cursor-pointer ${
          currentPage > 1 ? 'text-blue-500' : 'text-gray-400'
        } p-2 rounded-full bg-purple-100`}
        onClick={goToPreviousPage}
        disabled={currentPage <= 1}
      >
        <MdOutlineArrowLeft size={16} />
      </button>
      <button
        className={`cursor-pointer ${
          currentPage < totalPages ? 'text-blue-500' : 'text-gray-400'
        } p-2 rounded-full bg-purple-100`}
        onClick={goToNextPage}
        disabled={currentPage >= totalPages}
      >
        <MdOutlineArrowRight size={16} />
      </button>
    </div>
  );
};

export default PaginationBar;
