import React from 'react';
import Button from '../../../components/button';
import Input from '../../../components/input';
import Imageee from '../../../assets/img/landing/contact-img.png';
import LandingFooter from '../../../components/landing-page/LandingFooter';
import LandingPageHeader from '../../../components/landing-page/LandingPageHeader';

const Contact = () => {
  return (
    <div className="bg-purple-100 bg-opacity-60">
      <LandingPageHeader />

      <div className="flex items-center justify-between p-32">
        <div className="space-y-12">
          <h4 className="text-base sm:text-3xl font-bold">Get In Touch With Us.</h4>

          <Input label={'full name'} withLabel className={'h-12 -mt-12'} />
          <Input label={'Email Address'} withLabel className={'h-12 -mt-12'} />
          <Input label={'Message'} withLabel className={'h-32 -mt-12'} />

          <Button isPurple text="Send Message" />

          <div className="flex flex-col font-bold space-y-4">
            <p>ADDRESS 3 Odegbemi street, Ikeja, Lagos.</p>
            <p>EMAIL hello@peoplecenta.com</p>
          </div>
        </div>

        <img src={Imageee} className="h-[600px] w-[450px]" alt="" />
      </div>

      <LandingFooter />
    </div>
  );
};

export default Contact;
