import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Input, { TextAreaWithFormatting } from '../../../../components/input';
import Button from '../../../../components/button';
import { convertToRaw, EditorState, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { API } from '../../../../utils/apis';
import Select from '../../../../components/input/Select';
import { PEOPLE_RECRUIT } from '../../../../router/router';
import { JOB_STATUS, JOB_TYPE, STORAGE_KEYS } from '../../../../helpers/constant';

const EditVacancy = ({ departments, editVacancyDetails }) => {
  const staffId = localStorage.getItem(STORAGE_KEYS?.EMPLOYEE_ID);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(editVacancyDetails || {});
  const [resEditorState, setResEditorState] = useState(EditorState.createEmpty());
  const [reqEditorState, setReqEditorState] = useState(EditorState.createEmpty());
  const [skillEditorState, setSkillEditorState] = useState(EditorState.createEmpty());
  const navigate = useNavigate();

  useEffect(() => {
    if (editVacancyDetails) {
      setFormData({
        ...editVacancyDetails,
        modifiedBy: staffId,
      });

      // Initialize the editor states based on the existing values
      setResEditorState(
        editVacancyDetails.keyResponsibility
          ? EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(editVacancyDetails.keyResponsibility))
            )
          : EditorState.createEmpty()
      );
      setReqEditorState(
        editVacancyDetails.keyRequirement
          ? EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(editVacancyDetails.keyRequirement))
            )
          : EditorState.createEmpty()
      );
      setSkillEditorState(
        editVacancyDetails.skillRequirement
          ? EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(editVacancyDetails.skillRequirement))
            )
          : EditorState.createEmpty()
      );
    }
  }, [editVacancyDetails, staffId]);

  const getResMarkDown = (e) => {
    setResEditorState(e);
    const value = e && draftToHtml(convertToRaw(e.getCurrentContent()));
    setFormData({
      ...formData,
      keyResponsibility: value
    });
  };

  const getReqMarkDown = (e) => {
    setReqEditorState(e);
    const value = e && draftToHtml(convertToRaw(e.getCurrentContent()));
    setFormData({
      ...formData,
      keyRequirement: value
    });
  };

  const getSkillMarkDown = (e) => {
    setSkillEditorState(e);
    const value = e && draftToHtml(convertToRaw(e.getCurrentContent()));
    setFormData({
      ...formData,
      skillRequirement: value
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitData = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setLoading(true);
  
    // Create a copy of formData and remove 'createdBy'
    const updatedFormData = { ...formData };
    delete updatedFormData.createdBy;
  
    try {
      // Log formData to verify it's correct
      console.log(updatedFormData);
  
      // Make the API call with updated form data
      await API.updateVacancy(updatedFormData, navigate);
    } catch (error) {
      console.error("Error updating vacancy:", error);
    } finally {
      setLoading(false); // Stop the loading indicator
    }
  };

  return (
    <form
      onSubmit={submitData}
      className=' space-y-4 md:space-y-0 w-full flex flex-col gap-6 justify-between'>
      <div className='mt-6 flex justify-between flex-wrap items-center gap-3'>
          <h4>Edit Vacancy</h4>
          <div className='flex gap-3 items-center flex-wrap'>
            <Button text={'save as draft'} isTransparent loading={loading} />
            <Button
              text={'update vacancy'}
              isPink
              loading={loading}
              onClick={submitData}
              type='submit'
            />
          </div>
      </div>  
      <div className='flex flex-wrap space-y-4 md:space-y-0 w-full gap-6 justify-between'>  
        <Input
          label={'job title'}
          withLabel
          name='jobTitle'
          placeholder={'Enter job title'}
          onChange={handleChange}
          value={formData.jobTitle}
          containerClass='w-full md:w-[45%] '
        />
        <Input
          label={'vacancy id'}
          withLabel
          name='vacancyId'
          placeholder={'Enter vacancy id'}
          onChange={handleChange}
          value={formData?.vacancyId}
          containerClass='w-full md:w-[45%] '
        />
        <div className=' flex flex-col gap-2 w-full md:w-[45%]'>
          <h6 className=' text-xs'> department</h6>
          <Select
            selectedOption={formData?.department}
            options={departments}
            placeholder={'Select department'}
            onChange={(e) =>
              setFormData({
                ...formData,
                department: e?.value
              })
            }
          />
        </div>
        <Input
          label={'BENEFITS & COMPENSATION'}
          withLabel
          name='benefitsAndCompensation'
          placeholder={'Enter benefits and compensation'}
          onChange={handleChange}
          value={formData.benefitsAndCompensation.toLocaleString()}
          containerClass='w-full md:w-[45%] '
        />

       <Select
          options={JOB_STATUS}
          placeholder={'Select job status'}
          selectedOption={formData.jobStatus}
          onChange={(e) =>
            setFormData({
              ...formData,
              jobStatus: e?.value
            })
          }
          label='job status'
          withLabel
          className='w-full md:w-[45%] '
        />

        <Select
          options={JOB_TYPE}
          placeholder={'Select job type'}
          selectedOption={formData.jobType}
          onChange={(e) =>
            setFormData({
              ...formData,
              jobType: e?.value
            })
          }
          label='job type'
          withLabel
          className='w-full md:w-[45%] '
        />

        <TextAreaWithFormatting
          className={'w-full md:w-[45%] '}
          label='key responsibilities'
          withLabel
          onEditorStateChange={getResMarkDown}
          editorState={resEditorState}
        />

        <TextAreaWithFormatting
          className={'w-full md:w-[45%] '}
          label='key requirements'
          withLabel
          onEditorStateChange={getReqMarkDown}
          editorState={reqEditorState}
        />

        <TextAreaWithFormatting
          className={'w-full md:w-[45%]'}
          label='skill requirements'
          withLabel
          onEditorStateChange={getSkillMarkDown}
          editorState={skillEditorState}
        />
      </div>

      <div className='grid justify-items-end space-y-10'>
        <Button
          text={'Do more with PeopleRecruit...'}
          className='flex uppercase justify-items-center'
          isPurple
          onClick={() => navigate(PEOPLE_RECRUIT)}
        />
      </div>

    </form>
  );
};

export default EditVacancy;
// <TextArea
//         label={'jOB REQUIREMENT'}
//         withLabel
//         name='jobRequirement'
//         onChange={handleChange}
//         value={formData?.jobRequirement}
//         containerClass='w-full md:w-[45%] '
//         className={'h-32'}
//       />
