import React, { useState, useEffect } from 'react';
import { MdLocationOn } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../helpers/api';
import { STORAGE_KEYS } from '../../../helpers/constant';
import PreviousTrainingModal from './feedback-details/PreviousModal';
import Search from '../../../components/general/Search';

const LearningContent = ({ currentPage }) => {
  const staffId = localStorage.getItem(STORAGE_KEYS?.EMPLOYEE_ID);
  const [upcoming, setUpcoming] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [showPreviousTrainingModal, setShowPreviousTrainingModal] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const navigate = useNavigate();

  const closeModal = () => {
    setShowPreviousTrainingModal(false);
  };

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedCurrentDate = `${day}-${month}-${year}`;
  const formattedStartDate = `01-01-${year}`;
  const formattedEndDate = `31-12-${year}`;

  const fetchAllUpcoming = async () => {
    try {
      const res = await apiService.get(
        'Event/Upcoming',
        {
          startDate: formattedCurrentDate,
          endDate: formattedEndDate,
          employeeId: staffId
        },
        true
      );
      const upcomingEvents = res?.data?.data || [];
      if (Array.isArray(upcomingEvents)) {
        setUpcoming(upcomingEvents);
      } else {
        setUpcoming([]);
      }
    } catch (err) {
      console.log('Error in fetching upcoming learning events: ', err);
      setUpcoming([]);
    }
  };

  const fetchAllPrevious = async () => {
    try {
      const res = await apiService.get(
        `Event?startDate=${formattedStartDate}&endDate=${formattedCurrentDate}`,
        {},
        true
      );
      const previousEvents = res?.data?.data || [];
      if (Array.isArray(previousEvents)) {
        setPrevious(previousEvents);
      } else {
        setPrevious([]);
      }
    } catch (err) {
      console.log('Error in fetching previous learning events: ', err);
      setPrevious([]);
    }
  };

  useEffect(() => {
    if (staffId) {
      fetchAllUpcoming();
      fetchAllPrevious();
    }
  }, [staffId]);

  const itemsPerPage = 6; // 2 rows * 3 columns
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentUpcoming = Array.isArray(upcoming) ? upcoming.slice(startIndex, endIndex) : [];
  const currentPrevious = Array.isArray(previous) ? previous.slice(startIndex, endIndex) : [];

  return (
    <div className='space-y-3'>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {currentUpcoming.length > 0 ? (
          currentUpcoming.map((item, index) => (
            <div 
              key={index} 
              onClick={() => navigate(`/manage-learning/${item?.eventId}`)} 
              className="flex flex-row bg-white-100 w-90 h-32 rounded-lg overflow-hidden space-y-5 space-x-5"
            >
              <div className="w-24">
                <img
                  src={item.coverPhoto}
                  alt="Card Image"
                  className="object-cover h-full w-full"
                />
              </div>
              <div className='flex flex-col space-y-2'>
                <h3 className="text-purple-100 text-sm font-bold mb-2">{item.title}</h3>
                <div className='flex flex-wrap gap-1'>
                  <p className="text-gray-700 text-sm">{item.date}</p>
                  <p className="text-yellow-100 text-sm"> | {item.time}</p>
                </div>
                <div className='flex flex-wrap space-x-1'>
                  <MdLocationOn/>
                  <a className='text-xs text-purple-100'> 
                    {item.location}
                  </a>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className='text-purple-900 mb-5 mt-5'>There are no upcoming trainings</div>
        )}
      </div>
      <Search placeholder={'Search previous training'} className='w-full'/>
      <div className="cursor-pointer grid grid-cols-1 sm:grid-cols-2 gap-4">
        {currentPrevious.length > 0 ? (
          currentPrevious.map((item, index) => (
            <div 
              key={index} 
              className="flex flex-row w-90 h-32 bg-white-100 rounded-lg overflow-hidden space-y-5 space-x-5"
              onClick={() => {
                setSelectedEventId(item.eventId);
                setShowPreviousTrainingModal(true);
              }}
            >
              <div className="w-24">
                <img
                  src={item.coverPhoto}
                  alt="Card Image"
                  className="object-cover h-full w-full"
                />
              </div>
              <div className='flex flex-col space-y-2'>
                <h3 className="text-purple-100 text-sm font-bold mb-2">{item.title}</h3>
                <div className='flex flex-wrap gap-1'>
                  <p className="text-gray-700 text-sm">{item.date}</p>
                  <p className="text-yellow-100 text-sm"> | {item.time}</p>
                </div>
                <div className='flex flex-wrap space-x-1'>
                  <MdLocationOn/>
                  <a className='text-xs text-purple-100'> 
                    {item.location}
                  </a>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className='text-purple-100 mt-5 mb-5'>There are no previous trainings</div>
        )}
      </div>
      <PreviousTrainingModal 
        show={showPreviousTrainingModal} 
        closeModal={closeModal} 
        eventId={selectedEventId} 
      />
    </div>
  );
};

export default LearningContent;
