import React from 'react';
import { IoMdClose } from 'react-icons/io';

const Modal = ({ title, showModal, closeModal, content, coloredTitle }) => {
  const handleBackdropClose = (e) => {
    const modal = document.getElementById('modal-backdrop');

    if (e.target === modal) {
      if (showModal) {
        // setShowModal(!showModal);
      }
    }
  };

  return (
    showModal && (
      <div
        id='modal-backdrop'
        className={`fixed w-[100%] h-[100%] top-0 left-0 z-[100000] grid place-content-center bg-black-100 bg-opacity-70`}
        onClick={handleBackdropClose}>
        <div className='bg-white-100 relative p-4 sm:p-10  rounded-[25px]'>
          <IoMdClose
            className='text-gray-200 cursor-pointer absolute right-3 top-3'
            size={30}
            onClick={closeModal}
          />

          <h4 className='font-bold text-base text-black-100 pb-4'>
            {' '}
            {title} {coloredTitle && <span className='text-pink-100'>{coloredTitle}</span>}
          </h4>

          {content}
        </div>
      </div>
    )
  );
};

export default Modal;
