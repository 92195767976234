import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import StatsCard from '../../../components/cards/StatsCard';
import TitleCard from '../../../components/cards/TitleCard';
import { apiService } from '../../../helpers/api';
import { ASSIGN_LEAVE } from '../../../router/router';

const AbsenceHeader = () => {
  const { pathname } = useLocation();
  const [requestCount, setRequestCount] = useState(0);

  const getRequestCount = async () => {
    try {
      const res = await apiService.get(
        'Leave/Request/Count?Days=30',
        {
          approvalStatus: 0
        },
        true
      );
      const response = res?.data?.data;
      setRequestCount(response);
    } catch (err) {
      console.log('Error in absence count: ', err)
    }
  };
  useEffect(() => {
    getRequestCount();
  }, []);
  return (
    <div
      className={`flex flex-wrap gap-4 mx-auto xs:w-[80%] sm:w-[60%] md:w-full md:mx-0 md:flex-wrap md:flex-row ${
        pathname.includes(ASSIGN_LEAVE) && 'hidden'
      }`}>
      <TitleCard
        text='Manage your employees absence.'
        className=' text-base md:text-lg w-full md:w-[40%] mdLg:w-[30%] xl:w-[25%] '
      />
      <StatsCard
        isBlue
        title='TOTAL NUMBER OF LEAVE REQUESTED'
        value={requestCount}
        incrementArrow
        percent='2.5'
        isPerformance
        className='w-full md:w-[40%] mdLg:w-[30%] xl:w-[25%]'
      />
      <StatsCard
        title='UPCOMING LEAVES FOR THE MONTH'
        value={0}
        decrementArrow
        percent='2.5'
        className='w-full md:w-[40%] mdLg:w-[30%] xl:w-[25%]'
        isPerformance
      />
    </div>
  );
};

export default AbsenceHeader;
