import React, { useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiLoaderAlt } from 'react-icons/bi';
import SalaryBreakdownCard from '../../../../components/cards/SalaryBreakdownCard';
import Button from '../../../../components/button';
import { FiPlusCircle } from 'react-icons/fi';
import AddBasicAllowancesModal from './basic-allowances-modal';
import EditBasicAllowancesModal from './basic-allowances-modal/edit-basic-allowances-modal';

const Rates = ({ salaryComponent, loading }) => {
  const basicAllowances = salaryComponent?.basicAllowance;
  const [showBasicAllowanceModal, setShowBasicAllowanceModal] = useState(false);
  const [showEditBasicAllowancesModal, setShowEditBasicAllowancesModal] = useState(false);
  const [editBasicAllowancesInfo, setEditBasicAllowancesInfo] = useState(null);

  const closeModal = () => {
    setShowBasicAllowanceModal(false);
    setShowEditBasicAllowancesModal(false);
  };

  return (
    <div className='pink-bg my-6 space-y-5'>
      <div>
        <div className='whitespace-nowrap overflow-x-scroll w-[100%] '>
          <table className='w-full'>
           <thead className='w-[100%] uppercase tracking-[3px] text-blue-100 font-semibold text-2xs flex justify-between space-x-9 mb-4'>
             <div className='flex flex-grow items-center gap-6 w-[430px] justify-between flex-wrap'>
               <th className=''>Payrate (%)</th>
               <th className=''>Component Name</th>
               <th className=''>Actions</th>
             </div>
           </thead>

           <tbody className=' min-w-fit  flex flex-col gap-4 '>
             {basicAllowances &&
              basicAllowances?.map((compensation, i) => {
                return (
                 <SalaryBreakdownCard
                    key={`${i}`}
                    withIcon
                    payrate={compensation?.value}
                    name={compensation?.name}
                    //dependent={compensation?.dependantComponentType}
                    editIcon={
                      <FiEdit 
                        className='cursor-pointer hover:bg-pink-30 transition-all'
                        onClick={() => {
                          setShowEditBasicAllowancesModal(true);
                          setEditBasicAllowancesInfo(compensation);
                        }}
                      />
                    }
                    deleteIcon={<RiDeleteBinLine />}
                  />
                );
              })}
           </tbody>
         </table>

          <div className='pb-10 flex items-center'>
            {loading && (
              <BiLoaderAlt
                className='animate-spin my-10 flex text-center text-blue-100 justify-center mx-auto'
                size={30}
              />
            )}
            {!loading && salaryComponent?.length === 0 && (
              <span className=' p-3'>There are currently no basic salary components to show</span>
            )}
          </div>
        </div>
      </div>
      <div className='flex flex-row justify-end'>
        <Button
          icon={<FiPlusCircle size={16} />}
          isPink
          withIcon
          text={'ADD ALLOWANCE'}
          className='flex justify-between items-center space-x-1 px-6'
          isPurple
          onClick={() => setShowBasicAllowanceModal(true)}
        />
        <AddBasicAllowancesModal show={showBasicAllowanceModal} closeModal={closeModal} />
        <EditBasicAllowancesModal
          show={showEditBasicAllowancesModal}
          editBasicAllowancesInfo={editBasicAllowancesInfo}
          closeModal={closeModal}
        />
      </div>
    </div>
  );
};

export default Rates;
