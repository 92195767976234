import React from 'react';
import RequestCard from '../../../../components/cards/RequestCard';
import { getLeaveType } from '../../../../helpers/functions';

const RequestsTab = ({ pendingLeaveRequests = [] }) => {

  const validPendingLeaveRequests = Array.isArray(pendingLeaveRequests) ? pendingLeaveRequests : [];

  return (
    <div className='w-full mdLg:w-[30%] space-y-2 font-medium h-[100%] '>
      <div className='flex justify-between'>
        <p>Requests</p>
        <div>Filter by</div>
      </div>
      <div className='overflow-y-scroll no-scroll-bar h-full space-y-3'>
        {validPendingLeaveRequests?.length === 0 ? (
          <span>No leave request</span>
        ) : (
          validPendingLeaveRequests?.map((request) => (
            <RequestCard
              key={request?.leaveId}
              requestType={getLeaveType(request?.leaveType)}
              name={request?.name}
              className={'justify-between'}
              id={request?.leaveId}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default RequestsTab;
