import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import { AUTH_LOGIN } from '../../../../router/router';

const PasswordReset = ({ handleChange, passwordReset, loading, error, formData }) => {
  const navigate = useNavigate();
  return (
    <>
      <form
        onSubmit={passwordReset}
        className=' flex flex-col gap-8 items-start w-[90%] md:w-[75%] my-8'>
        <Input
          withLabel
          onChange={handleChange}
          label='email'
          className=''
          containerClass='w-full'
          name={'email'}
          value={formData?.email}
        />
        <Input
          withLabel
          onChange={handleChange}
          label='Old Password'
          className=''
          containerClass='w-full'
          name={'oldPassword'}
          ispassword
          value={formData?.oldPassword}
        />

        <Input
          withLabel
          onChange={handleChange}
          label='New Password'
          className=''
          containerClass='w-full'
          name={'newPassword'}
          ispassword
          value={formData?.newPassword}
        />

        <Input
          withLabel
          onChange={handleChange}
          label='Confirm Password'
          className=''
          containerClass='w-full'
          name={'confirmPassword'}
          ispassword
          value={formData?.confirmPassword}
        />

        {error && <span className=' capitalize text-red-700 text-sm font-semibold'>{error}</span>}

        <Button
          disabled={loading}
          loading={loading}
          text={'Change Password'}
          className={'w-full mt-6'}
          isPurple
          type='submit'
        />

        <p className=' text-center mx-auto mt-6'>
          Back To{' '}
          <span
            onClick={() => navigate(AUTH_LOGIN)}
            className=' font-semibold text-black-100 underline cursor-pointer'>
            Login
          </span>
        </p>
      </form>
    </>
  );
};

export default PasswordReset;
