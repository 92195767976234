import React from 'react';
import { IoBriefcaseOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const ApplicationCards = ({ role, company, location, status, dept, vacancyId }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/source-and-attract/job-details/${vacancyId}`);
  };

  return (
    <div
      onClick={handleCardClick}
      className="flex items-center gap-4 text-white-100 bg-white-100 w-fit py-4 px-6 border-gray-20 border-[1px] rounded-md cursor-pointer">
      <IoBriefcaseOutline className="p-3 w-[50px] h-[50px] bg-gray-600 rounded-full" />

      <div className="flex flex-col text-gray-200 font-medium capitalize">
        <p className="text-black-100 text-sm md:text-base capitalize">{role}</p>
        <p>{company}</p>
        <p>{location} | {status}</p>
        <p>{dept}</p>
      </div>
    </div>
  );
};

export default ApplicationCards;
