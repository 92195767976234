import React, { useState } from 'react';
import { BiLoaderAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../../components/general/pagination';
import StatusCard from '../../../../../components/cards/StatusCard';
import { EMPLOYEE_DETAILS } from '../../../../../router/router';

const PerformaceDetails = ({ loading, performances, behaviouralObjs, technicalObjs, setActivePerformanceRequest, showApprovePerformanceModal, showRejectPerformanceModal }) => {
  const navigate = useNavigate();
  const itemsPerPage = 20;

  // State to manage current page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate start and end index based on current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  // Get data for the current page
  const currentPerformances = performances?.slice(startIndex, endIndex);
  const currentBehaviouralObjs = behaviouralObjs?.slice(startIndex, endIndex);
  const currentTechnicalObjs = technicalObjs?.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className='whitespace-nowrap overflow-x-scroll no-scroll-bar my-6 pb-8'>
      <div className="space-y-6">
        <table className='w-full'>
          <thead className='border-collapse border-red-500'>
            <tr className='w-[100%] text-left uppercase tracking-[3px] text-blue-100 font-semibold text-2xs pl-28 mb-4'>
              <th className=' p-3 pr-10 '>EMPLOYEE ID</th>
              <th className=' p-3 pr-10 text-center'>NAME</th>
              <th className=' p-3 pr-10 text-center'>DEPARTMENT</th>
              <th className=' p-3 pr-10 text-center'>GOAL</th>
              <th className=' p-3 pr-10'>NO OF TARGETS</th>
              <th className=' p-3 pr-10'>DUE DATE</th>
            </tr>
          </thead>

          <tbody className='rounded-sm'>
            <tr className="text-purple-100 font-semibold text-xs " > <td className='pb-3'>    This week </td>
            </tr>
            {currentPerformances &&
              currentPerformances?.map((performance, i) => {
                return (
                  <tr
                    className='border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                    key={`${i}`}>
                    <td className=' p-3  pr-10 underline text-blue-100 cursor-pointer'
                      onClick={() => navigate(`/manage-performance/${performance?.performanceId}`)}>
                      JKI-{performance?.performanceId}</td>
                    <td className=' p-3  pr-10 text-center'>{performance?.name}</td>
                    <td className=' p-3  pr-10 text-center'>{performance?.department}</td>
                    <td className=' p-3  pr-10 text-center'>Resolve All Pending Tickets</td>
                    <td className=' p-3  pr-10 text-center'>{performance?.targets}</td>
                    <td className=' p-3  pr-10 text-center'>{performance.dueDate}</td>
                    <td className='p-3 px-6 '>
                      <StatusCard
                        pendingApproval
                        reject
                        onClick={() => {
                          showRejectPerformanceModal();
                          setActivePerformanceRequest(performance);
                        }}
                      />
                    </td>
                    <td className='p-3 px-6 '>
                      <StatusCard
                        pendingApproval
                        approve
                        onClick={() => {
                          showApprovePerformanceModal();
                          setActivePerformanceRequest(performance);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}

            {currentBehaviouralObjs &&
              currentBehaviouralObjs?.map((behaviouralObj, i) => {
                return (
                  <tr
                    className='border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                    key={`${i}`}>
                    <td className=' p-3  pr-10 underline text-blue-100 cursor-pointer'
                      onClick={() => navigate(`/manage-performance/${behaviouralObj?.performanceId}`)}>
                      JKI-{behaviouralObj?.performanceId}</td>
                    <td className=' p-3  pr-10 text-center'>{behaviouralObj?.name}</td>
                    <td className=' p-3  pr-10 text-center'>{behaviouralObj?.department}</td>
                    <td className=' p-3  pr-10 text-center'>Resolve All Pending Tickets</td>
                    <td className=' p-3  pr-10 text-center'>{behaviouralObj?.targets}</td>
                    <td className=' p-3  pr-10 text-center'>{behaviouralObj.duedate}</td>
                  </tr>
                );
              })}

            {currentTechnicalObjs &&
              currentTechnicalObjs?.map((technicalObj, i) => {
                return (
                  <tr
                    className='cursor-pointer border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                    key={`${i}`}>
                    <td className=' p-3  pr-10 underline text-blue-100 cursor-pointer'
                      onClick={() => navigate(`/manage-performance/${technicalObj?.performanceId}`)}>
                      JKI-{technicalObj?.performanceId}</td>
                    <td className=' p-3  pr-10 text-center'>{technicalObj?.name}</td>
                    <td className=' p-3  pr-10 text-center'>{technicalObj?.department}</td>
                    <td className=' p-3  pr-10 text-center'>Resolve All Pending Tickets</td>
                    <td className=' p-3  pr-10 text-center'>{technicalObj?.targets}</td>
                    <td className=' p-3  pr-10 text-center'>{technicalObj.duedate}</td>
                  </tr>
                );
              })}

          </tbody>
        </table>

        <div className='pb-10 flex items-center'>
          {loading && (
            <BiLoaderAlt
              className='animate-spin my-10 flex text-center text-blue-100 justify-center mx-auto'
              size={30}
            />
          )}
          {!loading && performances?.length === 0 && (
            <span className=' p-3'>There are currently no performances to show</span>
          )}
          {!loading && behaviouralObjs?.length === 0 && (
            <span className=' p-3'>There are currently no performances to show</span>
          )}
          {!loading && technicalObjs?.length === 0 && (
            <span className=' p-3'>There are currently no performances to show</span>
          )}
        </div>
      </div>
      <Pagination
        totalPages={Math.ceil(performances?.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default PerformaceDetails;
