import React from 'react';
import { Outlet } from 'react-router';
import { HeaderCard } from '../../components/cards/Cards';
import AllReports from './features';
import OnboardingOffboardingReport from './features/onboading-offboarding';
import TrainingReport from './features/training';
import HRAdminReport from './features/hr-administration';
import PerformanceMgmtReport from './features/performance-mgt';
import OnboardingHeader from './features/onboading-offboarding/OnboardingHeader';
import LayoutWithSideBar from '../../components/layout/LayoutWithSideBar';

const Reports = () => {
  return (
    <LayoutWithSideBar>
      <HeaderCard text={'All Reports'} titleClassName={'font-bold'} className={''} />
      <Outlet />
    </LayoutWithSideBar>
  );
};

export default Reports;
