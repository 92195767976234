import React, { useState } from 'react';
import { MdDelete } from 'react-icons/md';
import Input from "../../../../components/input";
import moment from "moment";

const ExperienceEducationForm = ({ handleInfoChange, otherFormData, setOtherFormData }) => {
  const [type, setType] = useState('text');

  const formatDate = (date) => date && moment(date).isValid() ? moment(date).format('YYYY/MM/DD') : '';

  const addExperience = () => {
    setOtherFormData(prev => ({
      ...prev,
      employeeWorkExperiences: [...prev.employeeWorkExperiences, { companyName: '', jobTitle: '', startDate: '', endDate: '' }]
    }));
  };

  const deleteExperience = (index) => {
    setOtherFormData(prev => ({
      ...prev,
      employeeWorkExperiences: prev.employeeWorkExperiences.filter((_, i) => i !== index)
    }));
  };

  const addEducation = () => {
    setOtherFormData(prev => ({
      ...prev,
      employeeEducationHistories: [...prev.employeeEducationHistories, { institutionName: '', course: '', startDate: '', endDate: '' }]
    }));
  };

  const deleteEducation = (index) => {
    setOtherFormData(prev => ({
      ...prev,
      employeeEducationHistories: prev.employeeEducationHistories.filter((_, i) => i !== index)
    }));
  };

  const addCertification = () => {
    setOtherFormData(prev => ({
      ...prev,
      employeeCertifications: [...prev.employeeCertifications, { certificationProgram: '', expirationDate: '' }]
    }));
  };

  const deleteCertification = (index) => {
    setOtherFormData(prev => ({
      ...prev,
      employeeCertifications: prev.employeeCertifications.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className='space-y-10'>
      <h4 className='text-[#404040] uppercase text-sm'>EXPERIENCE/EDUCATION</h4>
      <div className='space-y-12'>
        <h4 className='text-black-100 uppercase text-sm'>WORK EXPERIENCE</h4>
        {otherFormData.employeeWorkExperiences.map((work, index) => (
          <div key={index} className='w-full grid md:grid-cols-2 gap-8 items-end'>
            <Input
              withLabel
              onChange={(e) => handleInfoChange(e, index, 'employeeWorkExperiences')}
              value={work.companyName}
              label='COMPANY NAME'
              name={`employeeWorkExperiences[${index}].companyName`}
            />
            <Input
              withLabel
              onChange={(e) => handleInfoChange(e, index, 'employeeWorkExperiences')}
              value={work.jobTitle}
              label='JOB TITLE'
              name={`employeeWorkExperiences[${index}].jobTitle`}
            />
            <Input
              withLabel
              onChange={(e) => handleInfoChange(e, index, 'employeeWorkExperiences')}
              value={formatDate(work.startDate)}
              onFocus={() => setType('date')}
              onBlur={() => setType('text')}
              label='START DATE'
              type={type}
              name={`employeeWorkExperiences[${index}].startDate`}
            />
            <Input
              withLabel
              onChange={(e) => handleInfoChange(e, index, 'employeeWorkExperiences')}
              value={formatDate(work.endDate)}
              onFocus={() => setType('date')}
              onBlur={() => setType('text')}
              label='END DATE'
              type={type}
              name={`employeeWorkExperiences[${index}].endDate`}
            />
            {index > 0 && (
              <MdDelete
                className='ml-[4rem] self-center cursor-pointer'
                onClick={() => deleteExperience(index)}
              />
            )}
          </div>
        ))}
        <h6
          className='underline justify-end cursor-pointer flex'
          onClick={addExperience}
        >
          + Add more work experience
        </h6>

        <h4 className='text-black-100 uppercase text-sm'>EDUCATION HISTORY</h4>
        {otherFormData.employeeEducationHistories.map((edu, index) => (
          <div key={index} className='w-full grid md:grid-cols-2 gap-8 items-end'>
            <Input
              withLabel
              onChange={(e) => handleInfoChange(e, index, 'employeeEducationHistories')}
              value={edu.institutionName}
              label='INSTITUTION NAME'
              name={`employeeEducationHistories[${index}].institutionName`}
            />
            <Input
              withLabel
              onChange={(e) => handleInfoChange(e, index, 'employeeEducationHistories')}
              value={edu.course}
              label='COURSE'
              name={`employeeEducationHistories[${index}].course`}
            />
            <Input
              withLabel
              onChange={(e) => handleInfoChange(e, index, 'employeeEducationHistories')}
              value={formatDate(edu.startDate)}
              onFocus={() => setType('date')}
              onBlur={() => setType('text')}
              label='START DATE'
              type={type}
              name={`employeeEducationHistories[${index}].startDate`}
            />
            <Input
              withLabel
              onChange={(e) => handleInfoChange(e, index, 'employeeEducationHistories')}
              value={formatDate(edu.endDate)}
              onFocus={() => setType('date')}
              onBlur={() => setType('text')}
              label='END DATE'
              type={type}
              name={`employeeEducationHistories[${index}].endDate`}
            />
            {index > 0 && (
              <MdDelete
                className='ml-[4rem] self-center cursor-pointer'
                onClick={() => deleteEducation(index)}
              />
            )}
          </div>
        ))}
        <h6
          className='underline justify-end cursor-pointer flex'
          onClick={addEducation}
        >
          + Add more education history
        </h6>

        <h4 className='text-black-100 uppercase text-sm'>CERTIFICATIONS</h4>
        {otherFormData.employeeCertifications.map((cert, index) => (
          <div key={index} className='w-full grid md:grid-cols-2 gap-8 items-end'>
            <Input
              withLabel
              onChange={(e) => handleInfoChange(e, index, 'employeeCertifications')}
              value={cert.certificationProgram}
              label='CERTIFICATION PROGRAM'
              name={`employeeCertifications[${index}].certificationProgram`}
            />
            <Input
              withLabel
              onChange={(e) => handleInfoChange(e, index, 'employeeCertifications')}
              value={formatDate(cert.expirationDate)}
              onFocus={() => setType('date')}
              onBlur={() => setType('text')}
              label='EXPIRATION DATE'
              type={type}
              name={`employeeCertifications[${index}].expirationDate`}
            />
            {index > 0 && (
              <MdDelete
                className='ml-[4rem] self-center cursor-pointer'
                onClick={() => deleteCertification(index)}
              />
            )}
          </div>
        ))}
        <h6
          className='underline justify-end cursor-pointer flex'
          onClick={addCertification}
        >
          + Add more certifications
        </h6>
      </div>
    </div>
  );
};

export default ExperienceEducationForm;
