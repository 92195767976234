import React, { useState } from 'react';
import BaseModal from '../../../../components/base/modal';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import { API } from '../../../../utils/apis';

const AddDepartmentModal = ({ show, onClose, setDepartments }) => {
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState('');
  const [error, setError] = useState('');

  const addDepartment = async () => {
    if (!department.trim()) {
      setError('Department name cannot be empty');
      return;
    }

    setLoading(true);

    const existingDepartments = await API.fetchAllDepartment();
    const departmentExists = existingDepartments.some(
      (existingDepartment) => existingDepartment.name === department
    );

    if (departmentExists) {
      setError('Department already exists');
      setLoading(false);
      return;
    }

    const res = await API.addDepartment(department);
    if (res) {
      const response = await API.fetchAllDepartment();
      setDepartments(response);
      onClose();
    }
    setLoading(false);
  };

  return (
    <BaseModal show={show} onClose={() => { onClose(); setError(''); }} title='Add Department'>
      <div className='space-y-12'>
        <Input
          name={'name'}
          placeholder={'Enter name of department'}
          label='department name'
          onChange={(e) => {
            setDepartment(e.target.value);
            setError('');
          }}
          withLabel
          isPurple
        />
        {error && <p className="text-red-500">{error}</p>}
        <Button
          text='Add Department'
          isPurple
          className='bg-opacity-80 ml-auto'
          loading={loading}
          onClick={addDepartment}
        />
      </div>
    </BaseModal>
  );
};

export default AddDepartmentModal;
