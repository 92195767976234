import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Input, { TextAreaWithFormatting } from '../../../../components/input';
import Button from '../../../../components/button';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Select from '../../../../components/input/Select';
import { PEOPLE_RECRUIT } from '../../../../router/router';
import { JOB_STATUS, JOB_TYPE } from '../../../../helpers/constant';

const VacancyForm = ({ submitData, handleChange, formData, setFormData, department }) => {
  const [resEditorState, setResEditorState] = useState('');
  const [reqEditorState, setReqEditorState] = useState('');
  const [skillEditorState, setSkillEditorState] = useState('');
  const navigate = useNavigate();

  const getResMarkDown = (e) => {
    setResEditorState(e);
    const value = resEditorState && draftToHtml(convertToRaw(resEditorState.getCurrentContent()));
    setFormData({
      ...formData,
      keyResponsibility: value
    });
  };

  const getReqMarkDown = (e) => {
    setReqEditorState(e);
    const value = reqEditorState && draftToHtml(convertToRaw(reqEditorState.getCurrentContent()));
    setFormData({
      ...formData,
      keyRequirement: value
    });
  };

  const getSkillMarkDown = (e) => {
    setSkillEditorState(e);
    const value = skillEditorState && draftToHtml(convertToRaw(skillEditorState.getCurrentContent()));
    setFormData({
      ...formData,
      skillRequirement: value
    });
  };

  return (
    <form
      onSubmit={submitData}
      className=' space-y-4 md:space-y-0 w-full flex flex-col gap-6 justify-between'>
      <div className='flex flex-wrap space-y-4 md:space-y-0 w-full gap-6 justify-between'>  
        <Input
          label={'job title'}
          withLabel
          name='jobTitle'
          placeholder={'Enter job title'}
          onChange={handleChange}
          value={formData?.jobTitle}
          containerClass='w-full md:w-[45%] '
        />
        <Input
          label={'vacancy id'}
          withLabel
          name='vacancyId'
          placeholder={'Enter vacancy id'}
          onChange={handleChange}
          value={formData?.vacancyId}
          containerClass='w-full md:w-[45%] '
        />
        <div className=' flex flex-col gap-2 w-full md:w-[45%]'>
          <h6 className=' text-xs'> department</h6>
          <Select
            selectedOption={formData?.department}
            options={department}
            placeholder={'Select department'}
            onChange={(e) =>
              setFormData({
                ...formData,
                department: e?.value
              })
            }
          />
        </div>
        <Input
          label={'BENEFITS & COMPENSATION'}
          withLabel
          name='benefitsAndCompensation'
          placeholder={'Enter benefits and compensation'}
          onChange={handleChange}
          value={formData?.benefitsAndCompensation.toLocaleString()}
          containerClass='w-full md:w-[45%] '
        />

       <Select
          options={JOB_STATUS}
          placeholder={'Select job status'}
          selectedOption={formData.jobStatus}
          onChange={(e) =>
            setFormData({
              ...formData,
              jobStatus: e?.value
            })
          }
          label='job status'
          withLabel
          className='w-full md:w-[45%] '
        />

        <Select
          options={JOB_TYPE}
          placeholder={'Select job type'}
          selectedOption={formData.jobType}
          onChange={(e) =>
            setFormData({
              ...formData,
              jobType: e?.value
            })
          }
          label='job type'
          withLabel
          className='w-full md:w-[45%] '
        />

        <TextAreaWithFormatting
          className={'w-full md:w-[45%] '}
          label='key responsibilities'
          withLabel
          onEditorStateChange={getResMarkDown}
          editorState={resEditorState}
        />

        <TextAreaWithFormatting
          className={'w-full md:w-[45%] '}
          label='key requirements'
          withLabel
          onEditorStateChange={getReqMarkDown}
          editorState={reqEditorState}
        />

        <TextAreaWithFormatting
          className={'w-full md:w-[45%]'}
          label='skill requirements'
          withLabel
          onEditorStateChange={getSkillMarkDown}
          editorState={skillEditorState}
        />
      </div>

      <div className='grid justify-items-end space-y-10'>
        <Button
          text={'Do more with PeopleRecruit...'}
          className='flex uppercase justify-items-center'
          isPurple
          onClick={() => navigate(PEOPLE_RECRUIT)}
        />
      </div>

    </form>
  );
};

export default VacancyForm;
// <TextArea
//         label={'jOB REQUIREMENT'}
//         withLabel
//         name='jobRequirement'
//         onChange={handleChange}
//         value={formData?.jobRequirement}
//         containerClass='w-full md:w-[45%] '
//         className={'h-32'}
//       />
