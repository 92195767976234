import React, { useState, useEffect } from 'react';
import BaseModal from '../../../../components/base/modal';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import { API } from '../../../../utils/apis';

const EditDepartmentModal = ({ show, onClose, setDepartments, editDepartmentInfo }) => {
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState(editDepartmentInfo?.name || '');
  const [error, setError] = useState('');
  const [allDepartments, setAllDepartments] = useState([]);

  useEffect(() => {
    const fetchAllDepartments = async () => {
      const response = await API.fetchAllDepartment();
      setAllDepartments(response);
    };

    fetchAllDepartments();
  }, []);

  const editDepartment = async () => {
    const newDepartmentName = department.trim();

    if (!newDepartmentName) {
      setError('Department name cannot be empty');
      return;
    }

    const isDepartmentExists = allDepartments.some(
      (existingDepartment) =>
        existingDepartment.name.trim().toLowerCase() === newDepartmentName.toLowerCase()
    );

    if (isDepartmentExists) {
      setError('Department already exists');
      return;
    }

    setLoading(true);

    const res = await API.updateDepartment(editDepartmentInfo?.id, newDepartmentName);

    if (res) {
      const response = await API.fetchAllDepartment();
      setDepartments(response);
      onClose();
    }

    setLoading(false);
  };

  return (
    <BaseModal show={show} onClose={() => { setError(''); onClose(); }} title='Edit Department'>
      <div className='space-y-12'>
        <Input
          name={'name'}
          placeholder={editDepartmentInfo?.name}
          label='department name'
          onChange={(e) => { setDepartment(e.target.value); setError(''); }}
          withLabel
          isPurple
        />
        {error && <p className="text-red-500">{error}</p>}
        <Button
          text='update department'
          isPurple
          className='bg-opacity-80 ml-auto'
          loading={loading}
          onClick={editDepartment}
        />
      </div>
    </BaseModal>
  );
};

export default EditDepartmentModal;
