import React from 'react';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { ReactComponent as PinkPerformance } from '../../assets/img/pink-performance.svg';
import { ReactComponent as WhitePerformance } from '../../assets/img/white-performance.svg';
import { ReactComponent as PurplePerformance } from '../../assets/img/purple-performance.svg';

const StatsCard = ({
  title,
  value,
  percent,
  isBlue,
  isYellow,
  incrementArrow,
  decrementArrow,
  isPerformance,
  className,
  icon
}) => {
  return (
    <div
      className={`p-6  h-[114px] font-semibold w-full  rounded-sm flex items-center ${isBlue
        ? 'bg-blue-100 text-white-100'
        : isYellow
          ? 'bg-yellow-100 text-white-100'
          : 'text-purple-100 bg-white-100'
        } ${className}`}>
      <div className="space-y-2">
        <h6 className={`	${isBlue || isYellow ? 'text-white-100' : 'text-purple-100'}`}> {title}</h6>
        <div className="flex gap-6 items-center">
          <div
            className={`p-1 w-11 h-11 flex justify-center items-center rounded-circle bg-purple-100 ${(isBlue || isYellow) && 'bg-white-100'
              }  `}>
            {isPerformance && isBlue ? (
              <PinkPerformance />
            ) : isPerformance && isYellow ? (
              <PurplePerformance />
            ) : (
              isPerformance && <WhitePerformance />
            )}
            {
              icon
            }
          </div>
          <span className="text-lg sm:text-xl md:text-4xl">{value}</span>
          {percent && (
            <div
              className={`px-[6px] py-[4px] rounded-sm bg-pink-100 flex justify-center items-center space-x-1 w-fit text-white-100 ${isBlue && 'bg-yellow-100  '
                } ${isYellow && 'bg-white-100 text-purple-100'} `}>
              {incrementArrow && <AiFillCaretUp size={14} />}
              {decrementArrow && <AiFillCaretDown size={14} />}
              <h6 className={`	${isYellow ? 'text-purple-100' : 'text-white-100'}`}>{percent}%</h6>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
