import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import BaseModal from '../../../../../components/base/modal';
import Button from '../../../../../components/button';
import { Toast } from '../../../../../components/toast/toast';
import { apiService } from '../../../../../helpers/api';
import { MANAGE_PERFORMANCE } from '../../../../../router/router';
import { act } from '@testing-library/react';
import { FiActivity } from 'react-icons/fi';

const ApprovePerformanceModal = ({
  showApprovePerformanceModal,
  toggleModal,
  performances,
  performance,
  activePerformanceRequest,
  setPerformances,
  setBehaviouralObjs,
  behaviouralObjs
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const performancesId = activePerformanceRequest?.performanceId;

  const approvePerformanceRequest = async () => {
    setLoading(true);
    try {
      const res = await apiService.post(
        `Performance/Goal/Approve?Id=${performancesId}&approve=true`
      );
      const filteredRequests = performances.filter(
        ({ performanceId }) => performanceId != activePerformanceRequest?.performanceId
      );

      if (res) {
        setPerformances(filteredRequests);
        setBehaviouralObjs([...behaviouralObjs, activePerformanceRequest]);
        toggleModal();
        Toast({
          type: 'success',
          message: 'Performance Request Aproved'
        });
        navigate(MANAGE_PERFORMANCE);
      }
    } catch (err) {
      console.log('Error in approving performance request: ', err)
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      title={'Are you sure you want to '}
      coloredText='Approve? '
      show={showApprovePerformanceModal}
      onClose={toggleModal}>
      <div className='flex gap-4 flex-wrap mt-6 justify-end'>
        <Button className={''} text='Go back' onClick={toggleModal} isGray />
        <Button
          text='Approve'
          isGreen
          className='bg-opacity-80'
          loading={loading}
          onClick={approvePerformanceRequest}
        />
      </div>
    </BaseModal>
  );
};

export default ApprovePerformanceModal;
