import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Charts = ({ label = 'Report Chart', text }) => {
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
    datasets: [
      {
        label: label,
        data: [10, 20, 20, 40, 50, 10, 70],
        backgroundColor: '#FF365E',
        borderColor: 'red',
        borderWidth: 1,
        pointRadius: 0,
        beginAtZero: true
      }
    ]
  };

  const lineOptions = {
    responsive: true,
    legend: {
      labels: {
        fontColor: 'blue',
        fontSize: 18,
        display: false
      }
    },
    plugins: {
      title: {
        display: true,
        text: text,
        color: '#2A004C'
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          beginAtZero: true,
          color: '#2A004C'
        }
      },

      y: {
        grid: {
          display: false
        },
        ticks: {
          beginAtZero: true,
          color: '#2A004C',
          fontColor: 'green',
          fontSize: 18,
          stepSize: 1
          // beginAtZero: true
        }
      }
    }
  };
  return (
    <div>
      <Line data={data} options={lineOptions} />
    </div>
  );
};

export default Charts;
