import React from 'react';
import BaseModal from '../../../../../components/base/modal';
import Button from '../../../../../components/button';
import Input, { TextArea } from '../../../../../components/input';

const TerminateEmployeeModal = ({ show, closeModal }) => {
  return (
    <BaseModal title={'Terminate Employment'} show={show} onClose={closeModal}>
      {' '}
      <div className=' space-y-10 rounded-lg w-[400px]'>
        <Input
          withLabel
          // onChange={handleChange}
          // value={formData.primaryContactFirstName}
          label='termination reason'
          containerClass=' w-full'
          name={'primaryContactFirstName'}
        />
        <TextArea label={'comment'} className='h-32 capitalize' />
        <div className='flex gap-4 flex-wrap justify-end'>
          <Button
            className={'border border-pink-100'}
            text='cancel'
            onClick={closeModal}
            isTransparent
          />
          <Button text='terminate' isPurple />
        </div>
      </div>{' '}
    </BaseModal>
  );
};

export default TerminateEmployeeModal;
