import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { MdLocationOn } from 'react-icons/md';
import Button from '../../../../components/button';
import { MANAGE_LEARNING } from '../../../../router/router';
import { apiService } from '../../../../helpers/api';
import { API } from '../../../../utils/apis';
import { STORAGE_KEYS } from '../../../../helpers/constant';

const LearningInfo = () => {
  const staffId = localStorage.getItem(STORAGE_KEYS?.EMPLOYEE_ID);
  const { id } = useParams();
  const emptyForm = {
    employeeId: staffId,
    eventId: id
  };
  const [formData, setFormData] = useState(emptyForm);
  const [loading, setLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const navigate = useNavigate();

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  const formattedCurrentDate = `${day}-${month}-${year}`;
  const formattedEndDate = `31-12-${year}`;

  const registerEvent = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData);
    await API.registerLearningEvent(formData, navigate);
    setLoading(false);
  };

  const fetchEventDetails = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(`Event/Upcoming`, 
        { 
          startDate: '01-01-2022',
          endDate: formattedEndDate,
          eventId: id 
        }, 
        true);
      console.log('API Response:', res.data);
      const response = res.data;

      const eventArray = response.data && Array.isArray(response.data) ? response.data : [];

      const event = eventArray.find(item => item.eventId === parseInt(id));
      console.log('Event Details:', event)
      console.log(id);

      if (event) {
        setEventDetails(event);
      } 
    } catch (err) {
      console.log('Error in fetching learning events details: ', err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchEventData = async () => {
      await fetchEventDetails();
      setFormData(prevFormData => ({
        ...prevFormData,
        eventId: eventDetails.eventId
      }));
    };

    fetchEventData();
  }, [id, eventDetails.eventId]);

  useEffect(() => {
    fetchEventDetails();
  }, [id]);

  return (
    <div>
      <div className="flex flex-col gap-4 justify-between">
        {Object.keys(eventDetails).length > 0 ? (
          <div key={eventDetails.eventId} className="flex flex-col space-y-5">
            <div className='flex flex-row w-full h-32 rounded-lg overflow-hidden space-y-5 space-x-5'>
              <div className="w-[50%] rounded-[5%]">
                <img
                  src={eventDetails.coverPhoto}
                  alt="Card Image"
                  className="object-fill h-full w-full"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <h3 className="text-purple-100 text-sm font-bold mb-2">
                  {eventDetails.title}
                </h3>
                <div className="flex flex-wrap gap-1">
                  <p className="text-gray-700 text-sm">{eventDetails.date}</p>
                  <p className="text-yellow-100 text-sm"> | {eventDetails.time}</p>
                </div>
                <div className="flex flex-wrap space-x-1">
                  <MdLocationOn />
                  <a className="text-xs text-purple-100">{eventDetails.location}</a>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-3">
              <h3 className="text-gray-500 text-base">Description</h3>
              <p className="text-purple-900">{eventDetails.description}</p>
            </div>
          </div>
        ) : (
          <p>Loading event details...</p>
        )}
        <div className="flex gap-4 flex-wrap justify-end">
          <Button
            className="border border-pink-100 text-pink-100"
            text="Cancel"
            onClick={() => navigate(MANAGE_LEARNING)}
            isTransparent
          />
          <Button 
           text="Register" 
           type="submit"
           onClick={registerEvent}
           isOrange 
          />
        </div>
      </div>
    </div>
  );
};

export default LearningInfo;
