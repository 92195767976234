import React from 'react';
import { Navigate } from 'react-router-dom';
import { STORAGE_KEYS } from '../helpers/constant';
import { AUTH_LOGIN } from './router';

const AuthRoute = ({ children }) => {
  const token = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN);
  return token ? children : <Navigate to={AUTH_LOGIN} replace={true} />;
};

export default AuthRoute;
