import React from 'react';

const BenefitsCard = ({ isPurple, isYellow, icon, title, details, isLarge, className }) => {
  return (
    <div
      className={`rounded-[40px] justify-between flex flex-col w-fit p-6 space-y-3  ${
        isPurple ? 'bg-purple-100' : isYellow ? 'bg-yellow-100' : 'bg-white-100'
      }  ${className}
      `}>
      {icon}
      <div className="flex flex-col space-y-1">
        {' '}
        <span
          className={`font-bold text-base md:text-lg ${isLarge && 'text-base md:text-3xl'} ${
            isPurple ? 'text-yellow-100' : isYellow ? 'text-white-100' : 'text-purple-400'
          } `}>
          {title}
        </span>
        <p
          className={`text-sm font-normal leading-6 ${
            isPurple ? 'text-white-100' : isYellow ? 'text-white-100' : 'text-black-100'
          }`}>
          {details}
        </p>
      </div>
    </div>
  );
};

export default BenefitsCard;
