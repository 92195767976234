import { Toast } from '../../../components/toast/toast';
import { apiService } from '../../../helpers/api';
import { STORAGE_KEYS } from '../../../helpers/constant';
import { MANAGE_EMPLOYEES, SETTINGS } from '../../../router/router';

export const addEmployee = async (data, navigate) => {
  try {
    const res = await apiService.post('Employee/CreateEmployee', data, true);
    if (res)
      Toast({
        type: 'success',
        message: 'Employee Created Successfully'
      });
    navigate(MANAGE_EMPLOYEES);
  } catch (err) {
    console.log('Error in creating employee: ', err)
  }
};

export const addEmployeeAvi = async (data, navigate) => {
  try {
    const res = await apiService.post('UserManagement/UploadProfileImage', data, true);
    if (res)
      Toast({
        type: 'success',
        message: 'Employee Profile Image Added Successfully'
      });
    navigate(MANAGE_EMPLOYEES);
  } catch (err) {
    console.log('Error in adding employing profile image: ', err)
  }
};

export const updateEmployee = async (data) => {
  try {
    const res = await apiService.put('Employee/UpdateEmployeeDetail', data, true);
    if (res)
      Toast({
        type: 'success',
        message: 'Employee Details Updated Successfully'
      });
  } catch (err) {
    console.log('Error in updating employee details: ', err)
  }
};

export const updateEmployeeExpEd = async (data, navigate) => {
  try {
    const res = await apiService.put('Employee/UpdateEmployeeExperienceAndEducation', data, true);
    if (res)
    navigate(MANAGE_EMPLOYEES);
  } catch (err) {
    console.log('Error in updating employee experience and details: ', err)
  }
};

export const addEmployeeGroup = async (data, navigate) => {
  try {
    const res = await apiService.post('EmployeeGrade/CreateGradeGroup', data, true);
    if (res)
      Toast({
        type: 'success',
        message: 'Employee Grade Group Created Successfully'
      });
    navigate(SETTINGS);
  } catch (err) {
    console.log('Error in creating employee grade group: ', err)
  }
};

export const addEmployeeGrade = async (data, navigate) => {
  try {
    const res = await apiService.post('EmployeeGrade/CreateGrade', data, true);
    if (res)
      Toast({
        type: 'success',
        message: 'Employee Grade Created Successfully'
      });
    navigate(SETTINGS);
  } catch (err) {
    console.log('Error in creating employee grade: ', err)
  }
};

export const updateEmployeeGroup = async (data, navigate) => {
  try {
    const res = await apiService.post('EmployeeGrade/UpdateGradeGroup', data, true);
    if (res)
      Toast({
        type: 'success',
        message: 'Employee Grade Group Updated Successfully'
      });
    navigate(SETTINGS);
  } catch (err) {
    console.log('Error in updating employee grade group: ', err)
  }
};

export const updateEmployeeGrade = async (data, navigate) => {
  try {
    const res = await apiService.post('EmployeeGrade/UpdateGrade', data, true);
    if (res)
      Toast({
        type: 'success',
        message: 'Employee Grade Updated Successfully'
      });
    navigate(SETTINGS);
  } catch (err) {
    console.log('Error in updating employee grade: ', err)
  }
};

export const fetchAllGradeById = async () => {
  try {
    const res = await apiService.get(
      'EmployeeGrade/FetchAllGradeByCompanyId',
      {
        pageNumber: 1,
        pageSize: 20
      },
      true
    );
    const response = res?.data?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching employee grades: ', err)
  }
};

export const fetchAllGradeGroupById = async () => {
  try {
    const res = await apiService.get(
      'EmployeeGrade/FetchAllGradeGroupByCompanyId',
      {
        pageNumber: 1,
        pageSize: 20
      },
      true
    );
    const response = res?.data?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching employee grade groups: ', err)
  }
};

export const fetchAllEmployeesById = async () => {
  try {
    const res = await apiService.get(
      'Employee/FetchAllEmployeeByCompanyId',
      {
        pageNumber: 1,
        pageSize: 20
      },
      true
    );
    const response = res?.data?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching employees: ', err)
  }
};

export const fetchEmployeeByCompanyIdAndEmployeeId = async (val) => {
  try {
    const res = await apiService.get(
      `Employee/FetchEmployeeByCompanyIdAndEmployeeId/${val}`,
      {
        employeeId: val
      },
      true
    );
    const response = res?.data?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching employee details: ', err)
  }
};

export const fetchEmployeeProfileDetail = async (employeeId) => {
  try {
    const res = await apiService.get(
      `Employee/FetchProfileDetail/${employeeId}`,
      {
        employeeId: employeeId
      },
      true
    );
    const response = res?.data?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching employee details: ', err)
  }
};

export const fetchActivitiesByEmployeeAndDate = async (selectedEmployee, date) => {
  try {
    const res = await apiService.get(
      `Activity/${selectedEmployee}/${date}`,
      {
        
      },
      true
    );
    const response = res?.data?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching employees activities: ', err)
  }
};

export const fetchEmployeeDetailsById = async (employeeId) => {
  try {
    const res = await apiService.get(
      `Employee/FetchProfileDetail/${employeeId}`, 
      {}, 
      true
    );
    const response = res?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching employee details: ', err)
  }
};


export const fetchEmployee = async (setEmployee) => {
  try {
    const res = await apiService.get(
      'Employee/FetchAllEmployee',
      {
        pageNumber: 1,
        pageSize: 20
      },
      true
    );
    const response = res?.data?.data;
    return response?.data;
  } catch (err) {
    console.log('Error in fetching employees: ', err)
  }
};

export const fetchSingleEmployee = async (id) => {
  try {
    const res = await apiService.get(
      `Employee/FetchEmployeeByCompanyIdAndEmployeeId/${id}`,
      {
        //companyId: 2
      },
      true
    );
    const response = res?.data?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching employee: ', err)
  }
};

export const fetchEmployeeAvi = async (profileimageURl) => {
  try {
    const res = await apiService.get(
      `Employee/FetchEmployeeByCompanyId/${profileimageURl}`,
      {
        //companyId: 2
      },
      true
    );
    const response = res?.data?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching employee profile image: ', err)
  }
};

export const getRequestCount = async (requestCount, setRequestCount) => {
  try {
    const leaveRequest = await apiService.get(
      'Leave/Request/Count?Days=30',
      {
        approvalStatus: 0
      },
      true
    );
    const employeeRequest = await apiService.get('Employee/EmployeeCountByCompanyId', {}, true);
    const leaveRequestCount = leaveRequest?.data?.data;
    const employeeRequestCount = employeeRequest?.data?.data?.data;
    setRequestCount({
      ...requestCount,
      leaveRequests: leaveRequestCount,
      employeesCount: employeeRequestCount
    });
    return { leaveRequestCount, employeeRequestCount };
  } catch (err) {
    console.log('Error in fetching leave request count: ', err)
  }
};

export const getSearchResults = async (val) => {
  try {
    const res = await apiService.get(
      'Employee/Search',
      {
        SearchParam: val
      },
      true
    );
    return res;
  } catch (err) {
    console.log('Error in fetching employee search results: ', err)
  }
};

export const fetchAllDepartment = async () => {
  try {
    const res = await apiService.get('Department/FetchAllDepartmentByCompanyId', {}, true);
    return res?.data?.data?.data;
  } catch (err) {
    console.log('Error in fetching departments: ', err)
  }
};

export const addDepartment = async (name) => {
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
  try {
    const res = await apiService.post(
      'Department/CreateDepartment',
      {
        name,
        createdBy: parseInt(userId),
        isActive: true
      },
      true
    );
    if (res){
      Toast({
        type: 'success',
        message: 'Department Created Successfully'
      });
      return res;
    }
  } catch (err) {
    console.log('Error in creating department: ', err)
  }
};

export const updateDepartment = async (departmentId, name) => {
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
  try {
    const res = await apiService.put(
      'Department/UpdateDepartment',
      {
        departmentId,
        name,
        modifiedBy: parseInt(userId),
        isActive: true
      },
      true
    );
    if (res)
      Toast({
        type: 'success',
        message: 'Department Updated Successfully'
      });
    return res;
  } catch (err) {
    console.log('Error in updating department: ', err)
  }
};
