import React from 'react';
import { MdArrowRightAlt } from 'react-icons/md';
import { AiFillCaretDown, AiFillCaretUp, AiOutlineArrowRight } from 'react-icons/ai';
import { ReactComponent as PinkCompensation } from '../../assets/img/pink-compensation.svg';
import { ReactComponent as WhitePerformance } from '../../assets/img/white-performance.svg';
import { ReactComponent as YellowEmployee } from '../../assets/img/yellow-employees.svg';
import { ReactComponent as PurplePerformance } from '../../assets/img/purple-performance.svg';
import { ReactComponent as YellowAbsence } from '../../assets/img/yellow-absence.svg';

const ReportsCard = ({
  title,
  value,
  percent,
  isBlue,
  isPink,
  isPurple,
  isYellow,
  onClick,
  incrementArrow,
  decrementArrow,
  isPerformance,
  className,
  icon
}) => {
  return (
    <div
      className={`p-6 h-[114px] font-semibold w-full rounded-sm flex items-center ${isBlue
        ? 'bg-blue-100 text-white-100'
        : isYellow
          ? 'bg-yellow-100 text-white-100'
          : isPink
            ? 'bg-pink-100 text-white-100'
            : 'text-white-100 bg-purple-100'
        } ${className}`}
    >
      <div className="flex items-center w-full">
        <div
          className={`p-1 w-11 h-11 flex justify-center items-center rounded-circle ${
            isPink ? 'bg-pink-100 text-white-100' : 'bg-purple-100'
          } ${(isBlue || isYellow || isPink || isPerformance) && 'bg-white-100'}`}
        >
          {isPerformance && isBlue ? (
            <YellowEmployee />
          ) : isPerformance && isYellow ? (
            <PinkCompensation />
          ) : isPerformance && isPink ? (
            <PurplePerformance />
          ) : (
            isPerformance && <YellowAbsence />
          )}
          {icon}
        </div>
        <span className={`ml-4 ${isBlue || isYellow || isPurple || isPink ? 'text-white-100' : 'text-white-100'}`}>{value}</span>
      </div>
      <div className='w-full flex justify-end mt-12'>
        <MdArrowRightAlt color='#ffffff' size={'25'} onClick={onClick}/>
      </div>
    </div>
  );
};

export default ReportsCard;
