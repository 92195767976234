import React from 'react';
import { HeaderCard, HorizontalAlignCard, VerticalAlignCard } from '../cards/Cards';
import Header from '../header';
import { ReactComponent as Overview } from '../../assets/img/overview.svg';
import { ReactComponent as Performance } from '../../assets/img/performance.svg';
import { ReactComponent as Hiring } from '../../assets/img/hiring.svg';
import { ReactComponent as Absence } from '../../assets/img/absence.svg';
import { ReactComponent as Employees } from '../../assets/img/employees.svg';
import { ReactComponent as Compensation } from '../../assets/img/compensation.svg';
import { ReactComponent as Requests } from '../../assets/img/requests.svg';
import { ReactComponent as Events } from '../../assets/img/events.svg';
import { ReactComponent as Learning } from '../../assets/img/learning.svg';
import { ReactComponent as Audit } from '../../assets/img/audit.svg';
import { ReactComponent as Reports } from '../../assets/img/reports.svg';
import { ReactComponent as Timesheet } from '../../assets/img/timesheet.svg';
import { ReactComponent as Notifications } from '../../assets/img/notifications.svg';
import { ReactComponent as HelpDesk } from '../../assets/img/helpdesk.svg';
import { ReactComponent as SettingIcon } from '../../assets/icons/setting-icon.svg';
import {
  AUDITS,
  HELPDESK,
  MANAGE_ABSENCE,
  MANAGE_COMPENSATION,
  MANAGE_EMPLOYEES,
  MANAGE_EVENTS,
  MANAGE_HIRING,
  MANAGE_LEARNING,
  MANAGE_PERFORMANCE,
  TIMESHEET,
  OVERVIEW,
  REPORTS,
  REQUESTS,
  SETTINGS
} from '../../router/router';

export const HomeLayout = () => {
  return (
    <div className='min-h-screen'>
      <Header />
      <div className='w-[87%]  mx-auto space-y-6 pb-10'>
        <HeaderCard text='dashboard' titleClassName={'font-bold'} className='card-bg' />
        <div>
          <div className='dashboard-container'>
            <VerticalAlignCard
              path={OVERVIEW}
              icon={<Overview />}
              text='Overview'
              className={''}
              containerClassName='h-[200px] sm:h-auto overview bg-yellow-100 '
            />

            <VerticalAlignCard
              path={MANAGE_PERFORMANCE}
              icon={<Performance />}
              text='Manage performance'
              className={''}
              containerClassName='h-[200px] sm:h-auto performance bg-pink-100  '
            />
            <VerticalAlignCard
              path={MANAGE_HIRING}
              icon={<Hiring />}
              text='Manage Hiring'
              className={''}
              containerClassName='h-[200px] sm:h-auto hiring bg-blue-100 '
            />
            <VerticalAlignCard
              path={MANAGE_ABSENCE}
              icon={<Absence />}
              text='Manage Absence'
              className={''}
              containerClassName='h-[200px] sm:h-auto absence bg-yellow-100 '
            />
            <VerticalAlignCard
              path={MANAGE_EMPLOYEES}
              icon={<Employees />}
              text='Manage Employees'
              className={''}
              containerClassName='h-[200px] sm:h-auto employees bg-blue-100 '
            />
            <VerticalAlignCard
              path={MANAGE_COMPENSATION}
              icon={<Compensation />}
              text='Manage Compensation'
              className={''}
              containerClassName='h-[200px] sm:h-auto compesation bg-purple-100 '
            />
            <VerticalAlignCard
              path={MANAGE_EVENTS}
              icon={<Events />}
              text='Manage Events'
              className={''}
              containerClassName='h-[200px] sm:h-auto requests bg-pink-100 '
            />
            <VerticalAlignCard
              path={MANAGE_LEARNING}
              icon={<Learning />}
              text='Manage Learning'
              className={''}
              containerClassName='h-[200px] sm:h-auto events bg-purple-100'
            />
            {/*<VerticalAlignCard
              path={MANAGE_LEARNING}
              icon={<Learning />}
              text='Manage Learning'
              className={''}
              containerClassName='h-[200px] sm:h-auto learning bg-purple-100'
            />*/}
          </div>
        </div>
        <div className=' flex flex-wrap justify-center  lg:justify-between gap-4  '>
         <HorizontalAlignCard
            path={TIMESHEET}
            icon={<Timesheet />}
            text='Timesheet'
            withArrow
            containerClassName='cursor-pointer'
          />
         <HorizontalAlignCard
            path={REQUESTS}
            icon={<Requests width='60px' height={24} />}
            text='Requests'
            withArrow
            containerClassName='cursor-pointer'
          />
          <HorizontalAlignCard
            path={AUDITS}
            icon={<Audit />}
            text='Audit Logs'
            withArrow
            containerClassName='cursor-pointer'
          />
          <HorizontalAlignCard
            path={REPORTS}
            icon={<Reports />}
            text='Reports'
            withArrow
            containerClassName='w-[95%] xs:w-[60%] md:w-[40%]  lg:w-[22%] cursor-pointer'
          />
          <HorizontalAlignCard
            path={HELPDESK}
            icon={<HelpDesk />}
            text='HelpDesk'
            withArrow
            containerClassName='cursor-pointer'
          />
          <HorizontalAlignCard
            path={SETTINGS}
            icon={<SettingIcon />}
            text='Settings'
            withArrow
            containerClassName='cursor-pointer'
          />
        </div>
      </div>
    </div>
  );
};
