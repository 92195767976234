import React from 'react';
import EventsDetails from '../../../components/base/calendar-tab';
import LeftEventsOverview from './left-events-overview';

const EventsOverview = () => {
  return (
    <div>
      <div className="flex flex-wrap gap-4 justify-between">
        <LeftEventsOverview />
        <div className="">
          <EventsDetails />
        </div>
      </div>
    </div>
  );
};

export default EventsOverview;
