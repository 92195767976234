import React, { useState, useEffect } from 'react';
import BaseModal from '../../../../components/base/modal';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import Select from '../../../../components/input/Select';
import { RATE_TYPE, REVIEW_TYPE } from '../../../../helpers/constant';
import { API } from '../../../../utils/apis';

const EditComponentModal = ({ show, closeModal, compensationComponentId }) => {
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({
    componentName: '',
    amount: 0,
    rateType: 1,
    reviewType: 1,
    value: 0
  });

  // Fetch data based on compensationComponentId
  useEffect(() => {
    const fetchComponentData = async () => {
      if (compensationComponentId) {
        setLoading(true);
        try {
          const response = await API.fetchComponentDetail(compensationComponentId);
          console.log('Fetched Data:', response); // Log the response to debug
          if (response) {
            setModalData(response);
          } else {
            console.warn('No data returned from API');
          }
        } catch (error) {
          console.error('Error fetching component data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        console.warn('Invalid compensationComponentId');
      }
    };    
    fetchComponentData();
  }, [compensationComponentId]);

  const handleChange = (field, value) => {
    setModalData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  const submitData = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await API.updateCompensationComponent(modalData);
      closeModal();
    } catch (error) {
      console.error('Error updating component:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal title={'Edit Component'} show={show} onClose={closeModal}>
      <div className='space-y-10 rounded-lg w-full sm:w-[400px] overflow-hidden'>
        <div className='flex flex-col gap-2 w-full'>
          <Input
            withLabel
            value={modalData.componentName}
            label='Component'
            containerClass='w-full sm:w-auto'
            name='componentName'
            onChange={(e) => handleChange('componentName', e.target.value)}
          />
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <Input
            withLabel
            value={modalData.amount}
            label='Amount'
            containerClass='w-full sm:w-auto'
            name='amount'
            onChange={(e) => handleChange('amount', e.target.value)}
          />
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>RATE TYPE</h6>
          <Select
            selectedOption={modalData.rateType}
            className='w-full sm:w-auto'
            options={RATE_TYPE}
            onChange={(e) => handleChange('rateType', e?.value)}
          />
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>REVIEW TYPE</h6>
          <Select
            selectedOption={modalData.reviewType}
            className='w-full sm:w-auto'
            options={REVIEW_TYPE}
            onChange={(e) => handleChange('reviewType', e?.value)}
          />
        </div>
        <Input
          withLabel
          value={modalData.value}
          label='Value'
          containerClass='w-full sm:w-auto'
          name='value'
          onChange={(e) => handleChange('value', e.target.value)}
        />

        <div className='flex gap-4 flex-wrap justify-end'>
          <Button
            className='border border-pink-100'
            text='Cancel'
            onClick={closeModal}
            isTransparent
          />
          <Button
            text='Submit'
            type='submit'
            onClick={submitData}
            isPurple
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditComponentModal;
