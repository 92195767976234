import React, { useState, useEffect } from 'react';
import { apiService } from '../../../../helpers/api';
import StatusTabs from '../../../../components/base/status-tabs';
import Rates from './rates';
import Deductions from './deductions';
import TaxRelief from './tax-relief';
import OvertimeContent from '../../../compensation/features/OvertimeContent';

const list = ['basic/allowances', 'deductions', 'tax relief']

const PayrollSettings = ({ overtime }) => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(list[0]);
  const [salaryComponent, setSalaryComponent] = useState([]);
  const [showOvertimeModal, setShowOvertimeModal] = useState(false);

  const closeModal = () => {
    setShowOvertimeModal(false);
  };

  const fetchSalaryComponent = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(`SalaryComponent/List`, {}, true);
      const response = res?.data?.data;
      setSalaryComponent(response);
    } catch (err) {
      console.log('error in compensations', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSalaryComponent();
  }, []);

  return (
  <div className='space-y-3'>
    <h4 className='text-[#353A3F] font-bold'>Payroll Settings</h4>
   <div className='space-y-3  bg-white-30 shadow-xl rounded-3xl p-4 md:p-10'>
     {/*<div className='flex flex-row justify-end'>
       <Button
         icon={<FiPlusCircle size={16} />}
         isTransparent
         withIcon
         text={'ADD OVERTIME'}
         className='flex justify-between items-center space-x-1 px-6'
         isPurple
         onClick={() => setShowOvertimeModal(true)}
        />
       <AddOvertimeModal show={showOvertimeModal} closeModal={closeModal} />
     </div>*/}
     <StatusTabs list={list} onClick={setActive} className='bg-purple-100' />
     <div className='flex flex-col'>
       {active === list[0] && (
         <Rates
           /*employees={employees}
           salaryComponent={salaryComponent}
           handleChange={handleChange}
           formData={formData}
           setFormData={setFormData}*/
           loading={loading}
           salaryComponent={salaryComponent}
          />
        )}
       {active === list[1] && (
         <Deductions loading={loading} salaryComponent={salaryComponent}
         />
       )}
       {active === list[2] && (
         <TaxRelief loading={loading} salaryComponent={salaryComponent} />
       )}
       {active === list[3] && (
         <OvertimeContent loading={loading} overtime={overtime} />
        )}
     </div>
   </div>
 </div>
  );
};

export default PayrollSettings;
