import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Display from '../../../../assets/img/user.png';
import Button from '../../../../components/button';
import { FiEdit } from 'react-icons/fi';

const EmployeeHeader = ({ setStep, employee, setModal }) => {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);

  return (
    <div className='flex justify-between items-center'>
      <div className='flex flex-wrap items-center gap-8'>
        <img src={employee?.user?.profileImageURL} alt='user' height='92' width='92' className='border border-yellow-100 rounded-[50%] p-2' />
        <div className='flex flex-col gap-2 items-start capitalize'>
          <p className='font-bold text-lg sm:text-xl md:text-2xl text-purple-300'>
            {employee?.fullName}
          </p>
          <div className='flex flex-wrap gap-5 items-center text-white-100'>
            <p className='font-semibold text-purple-300 text-sm sm:text-base md:text-xl'>
              {employee?.jobRole}
            </p>
            <span className='font-medium text-sm sm:text-base px-4 py-1 border bg-yellow-100 rounded-[21px] border-yellow-100'>
              {employee?.department?.name}
            </span>
          </div>
        </div>
      </div>
      {pathname.includes('user/') && (
        <Button
          className='flex items-center space-x-2 ml-auto'
          withIcon
          icon={<FiEdit size={16} />}
          text='EDIT DETAILS'
          isPurple
          onClick={() => setStep(2)}
        />
      )}

      {pathname.includes('tracker/') && (
        <Button
          className='flex items-center space-x-2 ml-auto'
          withIcon
          icon={<FiEdit size={16} />}
          text='ADD LOG'
          isPurple
          onClick={() => setModal(true)}
        />
      )}
    </div>
  );
};

export default EmployeeHeader;
