import React from 'react';
import { Outlet } from 'react-router-dom';
import { HeaderCard } from '../../components/cards/Cards';
import TimesheetLayout from './features';
import LayoutWithSideBar from '../../components/layout/LayoutWithSideBar';

const Timesheet = () => {
  return (
    <LayoutWithSideBar>
      <HeaderCard text={'Timesheet'} titleClassName={'font-bold'} className={''} />
      <TimesheetLayout />
    </LayoutWithSideBar>
  );
};

export default Timesheet;
