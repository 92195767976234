import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Toast } from '../../../../../components/toast/toast';
import { apiService } from '../../../../../helpers/api';
import Button from '../../../../../components/button';
import Modal from '../../../../../components/modal';
import Display from '../../../../../assets/img/user.png';
import { MANAGE_PERFORMANCE } from '../../../../../router/router';

const PerformanceDetails = ({currentPerformances, currentBehaviouralObjs, setCurrentTechnicalObjs}) => {
  const [loading, setLoading] = useState(false);
  const [performances, setPerformances] = useState([]);
  const [performanceDetail, setPerformanceDetail] = useState(null); // Change to null
  const [behaviouralObjDetail, setBehaviouralObjDetail] = useState(null);
  const [technicalObjDetail, setTechnicalObjDetail] = useState(null);
  const [showApprovalPerformanceModal, setShowApprovalPerformanceModal] = useState(false);
  const [showRejectionPerformanceModal, setShowRejectionPerformanceModal] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [activePerformanceRequest, setActivePerformanceRequest] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();

  const fetchPerformanceDetail = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(`Performance/Search`, {
        status: 0
      }, true);
      const response = res?.data?.data;
      
      const performance = response.find(item => item.performanceId === parseInt(id));

      if (performance) {
        setPerformanceDetail(performance);
      }
    } catch (err) {
      console.log('Error in fetching performance details: ', err)
    } finally {
      setLoading(false);
    }
  };

  const fetchBehaviouralObjDetail = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(`Performance/Search`, {
        status: 1
      }, true);
      const response = res?.data?.data;
      
      const behaviouralObj = response.find(item => item.performanceId === parseInt(id));

      if (behaviouralObj) {
        setBehaviouralObjDetail(behaviouralObj);
      }
    } catch (err) {
      console.log('Error in fetching performance details: ', err)
    } finally {
      setLoading(false);
    }
  };

  const fetchTechnicalObjDetail = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(`Performance/Search`, {
        status: 2
      }, true);
      const response = res?.data?.data;
      
      const technicalObj = response.find(item => item.performanceId === parseInt(id));

      if (technicalObj) {
        setTechnicalObjDetail(technicalObj);
      }
    } catch (err) {
      console.log('Error in fetching performance details: ', err)
    } finally {
      setLoading(false);
    }
  };

  const rejectPerformanceRequest = async () => {
    setRequestLoading(true);
    try {
      if (performanceDetail) {
        const res = await apiService.post(`Performance/Goal/Approve?Id=${id}&approve=false`);
        if (res) {
          await fetchPerformanceDetail();
          setCurrentTechnicalObjs(id);
          setShowRejectionPerformanceModal(!showRejectionPerformanceModal);
          Toast({
            type: 'success',
            message: 'Performance Request Rejected'
          });
        }
      } else {
        console.log('Performance detail is missing')
      }
    } catch (err) {
      console.log('Error in fetching performance details: ', err)
    } finally {
      setRequestLoading(false);
    }
  };

  const approvePerformanceRequest = async () => {
    setRequestLoading(true);
    try {
      if (performanceDetail) {
        const res = await apiService.post(`Performance/Goal/Approve?Id=${id}&approve=true`);
        const filteredRequests = performances.filter(
          ({ performanceId }) => performanceId != activePerformanceRequest?.performanceId
        );
        if (res) {
          await fetchPerformanceDetail();
          setShowApprovalPerformanceModal(!showApprovalPerformanceModal);
          Toast({
            type: 'success',
            message: 'Performance Request Approved'
          });
        }
      } else {
        console.log('Performance details is missing')
      }
    } catch (err) {
      console.log('Error in fetching performance details: ', err)
    } finally {
      setRequestLoading(false);
    }
  };

  useEffect(() => {
    fetchPerformanceDetail();
    fetchBehaviouralObjDetail();
    fetchTechnicalObjDetail();
  }, [id]);

  return (
    <div className="w-[80%] md:w-[70%] space-y-10">
      {performanceDetail && (
        <>
         <div className="flex flex-col justify-center items-center gap-8 w-full">
            <img src={Display} alt="user" className="border border-yellow-100 rounded-[50%] p-2" />
            <div className="flex flex-col gap-2 items-start">
              <p className="font-bold capitalize text-lg sm:text-xl md:text-2xl text-purple-300">
                {performanceDetail.name}
              </p>
              <div className="flex flex-wrap gap-5 items-center ml-8 justify-center text-white-100">
                <p className="flex font-semibold text-purple-300 text-center justify-center text-sm sm:text-base md:text-xl capitalize">
                  {performanceDetail.jobRole}
                </p>
              </div>
              <div className="flex flex-wrap gap-5 items-center ml-2 justify-center text-white-100">
                <span className="font-medium text-sm sm:text-base px-4 py-1 border bg-yellow-100 rounded-[21px] border-yellow-100 capitalize">
                  {performanceDetail.department}
                </span>
              </div>
            </div>
         </div>
         <div className='space-y-10'>
           <div className="flex flex-col justify-between gap-4 space-y-10">
              <div className='flex flex-wrap gap-3 items-center'>
                <h6>EMPLOYEE ID:</h6>
                <p className="text-black-100 font-semibold">{performanceDetail.employeeId}</p>
              </div>
              <div className='flex flex-wrap gap-3 items-center'>
                <h6>GOALS:</h6>
                <p className="text-black-100 font-semibold">
                  {performanceDetail.goal}
                </p>
              </div>
              <div className='flex flex-wrap gap-3 items-center'>
                <h6>TARGETS:</h6>
                <p className="text-black-100 font-semibold">
                  {performanceDetail.targets}
                </p>
              </div>
              <div className='flex flex-wrap gap-3 items-center'>
                <h6>DUE DATE:</h6>
                <p className="text-black-100 font-semibold">{performanceDetail.dueDate}</p>
              </div>
              <div className='flex flex-wrap gap-3 items-center'>
               <h6>DESCRIPTION:</h6>
               <p className="text-black-100 font-semibold">{performanceDetail.goal}</p>
             </div>
             <div className="flex flex-wrap gap-4">
               <Button text={'reject'} isPink onClick={rejectPerformanceRequest} />
               <Button text={'approve'} isGreen onClick={approvePerformanceRequest} />
             </div>
           </div>
         </div>
         <Modal
            title={'Are you sure you want to '}
            coloredTitle="Approve? "
            showModal={showApprovalPerformanceModal}
            closeModal={() => setShowApprovalPerformanceModal(!showApprovalPerformanceModal)}
            content={
              <div className="flex gap-4 flex-wrap">
                <Button
                  className={''}
                  text="Go back"  
                  onClick={() => 
                    setShowApprovalPerformanceModal(!showApprovalPerformanceModal)
                  }
                  isGray
                />
                <Button
                  text="Approve"
                  isGreen
                  className="bg-opacity-80"
                  loading={requestLoading}
                  onClick={approvePerformanceRequest}
                />
              </div>
            }
          />
         <Modal
            title={'Are you sure you want to '}
            coloredTitle="Reject? "
            showModal={showRejectionPerformanceModal}
            closeModal={() => setShowRejectionPerformanceModal(!showRejectionPerformanceModal)}
            content={
              <div className="flex gap-4 flex-wrap">
                <Button
                  className={''}
                  text="Go back"
                  onClick={() => setShowRejectionPerformanceModal(!showRejectionPerformanceModal)}
                  isGray
                />
                <Button
                  text="Reject"
                  isPink
                  className="bg-opacity-80"
                  loading={requestLoading}
                  onClick={rejectPerformanceRequest}
                />
              </div>
            }
          />
       </>
      )}

     {behaviouralObjDetail && (
        <>
         <div className="flex flex-col items-center gap-8 w-full">
            <img src={Display} alt="user" className="border border-yellow-100 rounded-[50%] p-2" />
            <div className="flex flex-col gap-2 items-start">
              <p className="font-bold capitalize text-lg sm:text-xl md:text-2xl text-purple-300">
                {behaviouralObjDetail.name}
              </p>
              <div className="flex flex-wrap gap-5 ml-8 items-center text-white-100">
                <p className="font-semibold text-purple-300 text-sm sm:text-base md:text-xl capitalize">
                  {behaviouralObjDetail.jobRole}
                </p>
              </div>
              <div className="flex flex-wrap gap-5 ml-2 items-center text-white-100">
                <span className="font-medium text-sm sm:text-base px-4 py-1 border bg-yellow-100 rounded-[21px] border-yellow-100 capitalize">
                  {behaviouralObjDetail.department}
                </span>
              </div>
            </div>
         </div>
         <div className='space-y-10'>
           <div className="flex flex-col justify-between gap-4 space-y-10">
              <div className='flex flex-wrap gap-3 items-center'>
                <h6>EMPLOYEE ID:</h6>
                <p className="text-black-100 font-semibold">{behaviouralObjDetail.employeeId}</p>
              </div>
              <div className='flex flex-wrap gap-3 items-center'>
                <h6>TARGETS:</h6>
                <p className="text-black-100 font-semibold">
                  {behaviouralObjDetail.targets}
                </p>
              </div>
              <div className='flex flex-wrap gap-3 items-center'>
                <h6>DUE DATE:</h6>
                <p className="text-black-100 font-semibold">{behaviouralObjDetail.dueDate}</p>
              </div>
              <div className='flex flex-wrap gap-3 items-center'>
               <h6>DESCRIPTION:</h6>
               <p className="text-black-100 font-semibold">{behaviouralObjDetail.goal}</p>
             </div>
           </div>
         </div>
       </>
      )}

     {technicalObjDetail && (
        <>
         <div className="flex flex-col items-center gap-8 w-full">
            <img src={Display} alt="user" className="border border-yellow-100 rounded-[50%] p-2" />
            <div className="flex flex-col gap-2 items-start">
              <p className="font-bold capitalize text-lg sm:text-xl md:text-2xl text-purple-300">
                {technicalObjDetail.name}
              </p>
              <div className="flex flex-wrap gap-5 ml-8 items-center text-white-100">
                <p className="font-semibold text-purple-300 text-sm sm:text-base md:text-xl capitalize">
                  {technicalObjDetail.jobRole}
                </p>
              </div>
              <div className="flex flex-wrap gap-5 ml-2 items-center text-white-100">
                <span className="font-medium text-sm sm:text-base px-4 py-1 border bg-yellow-100 rounded-[21px] border-yellow-100 capitalize">
                  {technicalObjDetail.department}
                </span>
              </div>
            </div>
         </div>
         <div className='space-y-10'>
           <div className="flex flex-col justify-between gap-4 space-y-10">
              <div className='flex flex-wrap gap-3 items-center'>
                <h6>EMPLOYEE ID:</h6>
                <p className="text-black-100 font-semibold">{technicalObjDetail.employeeId}</p>
              </div>
              <div className='flex flex-wrap gap-3 items-center'>
                <h6>TARGETS:</h6>
                <p className="text-black-100 font-semibold">
                  {technicalObjDetail.targets}
                </p>
              </div>
              <div className='flex flex-wrap gap-3 items-center'>
                <h6>DUE DATE:</h6>
                <p className="text-black-100 font-semibold">{technicalObjDetail.dueDate}</p>
              </div>
              <div className='flex flex-wrap gap-3 items-center'>
               <h6>DESCRIPTION:</h6>
               <p className="text-black-100 font-semibold">{technicalObjDetail.description}</p>
             </div>
           </div>
         </div>
       </>
      )}
    </div>
  );
};

export default PerformanceDetails;
