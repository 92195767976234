import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdUpload, MdOutlineArrowBack } from 'react-icons/md';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import Select from '../../../../components/input/Select';
import { TextArea } from '../../../../components/input';
import { MANAGE_LEARNING } from '../../../../router/router';
import { INVITATION_CATEGORY } from '../../../../helpers/constant';
import { API } from '../../../../utils/apis';
import { fetchAllEmployeesById } from '../../../../utils/apis/api-modules/employee';
import { getMeetingType, getEmployeesOption } from '../../../../helpers/functions';

const LearningEvent = () => {
  const emptyForm = {
    title: '',
    date: '',
    time: '',
    description: '',
    type: 1,
    location: '',
    coverPhoto: '',
    fileName: '',
    cateory: 1,
    invitees: [0],
    targetAudience: '',
    targetedCompetencies: ''
  };

  const [formData, setFormData] = useState(emptyForm);
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employeesArray, setEmployeesArray] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [departmentsOption, setDepartmentOption] = useState([]);
  const [showDepartmentSelect, setShowDepartmentSelect] = useState(false);
  const [showEmployeeSelect, setShowEmployeeSelect] = useState(false);

  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const handleChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handleCategoryChange = (selectedOption) => {
    const categoryValue = selectedOption.value;
    let invitees = [];

    if (categoryValue === 1) {
      invitees = [0];
      setShowDepartmentSelect(false);
      setShowEmployeeSelect(false);
    } else if (categoryValue === 2) {
      invitees = employeesArray.map(employee => employee.value);
      setShowDepartmentSelect(false);
      setShowEmployeeSelect(false);
    } else if (categoryValue === 3) {
      invitees = [];
      setShowDepartmentSelect(true);
      setShowEmployeeSelect(false);
    } else if (categoryValue === 4) {
      invitees = [];
      setShowDepartmentSelect(false);
      setShowEmployeeSelect(true);
    }

    setFormData({
      ...formData,
      cateory: categoryValue,
      invitees: invitees,
    });
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
  
    reader.onloadend = () => {
      const base64String = reader.result.split(',')[1];
  
      setFormData({
        ...formData,
        coverPhoto: base64String,
        fileName: file.name,
      });
    };
  
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleMultiSelectChange = (selectedOptions, field) => {
    const selectedValues = selectedOptions.map(option => option.value);

    setFormData({
      ...formData,
      invitees: selectedValues,
    });
  };

  const submitData = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData);
    //await API.createLearningEvent(formData, navigate);
    setLoading(false);
  };

  useEffect(() => {
    setEmployeesArray(getEmployeesOption(employees));
  }, [employees]);

  useEffect(() => {
    getDepartments(departments);
  }, [departments]);

  const getDepartments = (department) => {
    let DEPARTMENT_ARRAY = [];
    for (let i = 0; i < department.length; i++) {
      DEPARTMENT_ARRAY.push({ label: department[i]?.name, value: department[i]?.id });
    }
    setDepartmentOption(DEPARTMENT_ARRAY);
  };

  const loadEmployeesData = async () => {
    setLoading(true);
    const res = await fetchAllEmployeesById();
    if (res) setEmployees(res);
    setLoading(false);
  };

  const loadDepartmentData = async () => {
    setLoading(true);
    const departmentRes = await API.fetchAllDepartment();
    if (departmentRes) setDepartments(departmentRes);
    setLoading(false);
  };

  useEffect(() => {
    loadEmployeesData();
    loadDepartmentData();
  }, []);

  return (
    <div className="white-bg xs:w-[80%] sm:w-[70%] mdLg:w-full mdLg:mx-0 mx-auto">
      <div
        className="uppercase text-xs flex items-center justify-start space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer"
        onClick={() => navigate(-1)}>
        <MdOutlineArrowBack /> <span className="text-blue-100"> BACK</span>
      </div>
      <form onSubmit={submitData} className="space-y-10 mt-4">
        <div className="space-y-8 bg-pink-30 shadow-xl rounded-3xl p-4 md:p-10">
          <h4 className="text-center mdLg:text-left mb-4">Create Learning Event</h4>
          <div className="w-full mdLg:flex mdLg:space-x-3">
            <div className="flex-1 mdLg:w-1/2 mb-3 mdLg:mb-0">
              <Input
                withLabel
                label="EVENT TITLE"
                containerClass=""
                value={formData.title}
                name="title"
                onChange={(e) => handleChange(e, 'title')}
              />
            </div>
            <div className='flex-1 mdLg:w-1/4 mb-3 mdLg:mb-0'>
              <Input
                withLabel
                label='DATE'
                type='date'
                onChange={(e) => setFormData({
                  ...formData,
                  date: e?.target?.value
                })}
              />
            </div>
            <div className='flex-1 mdLg:w-1/4 mb-3 mdLg:mb-0'>
              <Input
                withLabel
                label='TIME'
                type='time'
                onChange={(e) => setFormData({
                  ...formData,
                  time: e?.target?.value
                })}
              />
            </div>
          </div>
          <div className="w-full">
            <TextArea
              withLabel
              label="DESCRIPTION"
              containerClass=""
              className='h-32'
              value={formData.description}
              name="description"
              onChange={(e) => handleChange(e, 'description')}
            />
          </div>
          <div className='w-full flex flex-wrap gap-2'>
            <span className='flex-1 w-full md:w-1/2 mb-2 md:mb-0 md:mr-2'>
              <Input
                withLabel
                label='MEETING TYPE'
                containerClass=''
                value={getMeetingType(formData.type)}
                name='type'
                onChange={(e) => setFormData({
                  ...formData,
                  type: e?.target?.value
                })}
              />
            </span>
            
            <span className='flex-1 w-full md:w-1/2 mb-2 md:mb-0 md:mr-2'> 
              <Select
                withLabel
                label='INVITE'
                options={INVITATION_CATEGORY}
                value={{ value: formData.cateory, label: INVITATION_CATEGORY.find(item => item.value === formData.cateory)?.label }}
                name='cateory'
                onChange={handleCategoryChange}
              />

             {showDepartmentSelect && (
                <div className="w-[95%] mt-3 ml-4 mb-4">
                  <Select
                    withLabel
                    label='SELECT DEPARTMENTS'
                    options={departmentsOption}
                    isMulti
                    name='departments'
                    onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, 'departments')}
                  />
                </div>
              )}
              {showEmployeeSelect && (
                <div className="w-[95%] mt-3 ml-4 mb-4">
                  <Select
                    withLabel
                    label='SELECT EMPLOYEES'
                    options={employeesArray}
                    isMulti
                    name='employees'
                    onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, 'employees')}
                  />
                </div>
              )}
            </span>
          </div>
          <div className='w-full flex flex-wrap gap-2'>
           <span className='flex-1 w-full md:w-1/2 mb-2 md:mb-0 md:mr-2'>
            <Input
              withLabel
              label='MEETING VENUE'
              containerClass=''
              className=''
              value={formData.location}
              name='location'
              onChange={(e) => setFormData({
                ...formData,
                location: e?.target?.value
              })}
            />
            </span>
            <span className='flex-1 w-full md:w-1/2 mb-2 md:mb-0 md:mr-2'>
              <Input
                withLabel
                label='UPLOAD COVER PHOTO'
                containerClass=''
                type='file'
                fileName={formData?.fileName || 'UPLOAD COVER PHOTO'}
                inputRef={fileInputRef}
                onChange={handleFileSelect}
                name='coverPhoto'
              />
            </span>
          </div>
          <div className="w-full">
            <TextArea
              withLabel
              label="TARGET AUDIENCE"
              containerClass=""
              className='h-32'
              value={formData.targetAudience}
              name="targetAudience"
              onChange={(e) => handleChange(e, 'targetAudience')}
            />
          </div>
          <div className="w-full">
            <TextArea
              withLabel
              label="TARGETED COMPETENCIES"
              containerClass=""
              className='h-32'
              value={formData.targetedCompetencies}
              name="targetedCompetencies"
              onChange={(e) => handleChange(e, 'targetedCompetencies')}
            />
          </div>
          <div className="flex gap-4 flex-wrap justify-end">
            <Button
              className="border border-pink-100 text-pink-100"
              text="Cancel"
              onClick={() => navigate(MANAGE_LEARNING)}
              isTransparent
            />
            <Button 
             text="Create Event" 
             type="submit" 
             isOrange 
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default LearningEvent;
