import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { MdLocationOn, MdOutlineArrowBack } from 'react-icons/md';
import { apiService } from '../../../../helpers/api';
import Button from '../../../../components/button';
import Pagination from '../../../../components/general/pagination';
import { STORAGE_KEYS } from '../../../../helpers/constant';

const StarRating = ({ rating }) => {
  const maxRating = 5;
  const normalizedRating = Math.min(Math.max(rating, 0), maxRating);

  return (
    <div className="flex">
      {Array.from({ length: maxRating }, (_, index) => (
        <svg
          key={index}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill={index < normalizedRating ? 'gold' : 'gray'}
          width="24"
          height="24"
        >
          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
        </svg>
      ))}
    </div>
  );
};

const TrainingReview = () => {
  const { eventId } = useParams();
  const staffId = localStorage.getItem(STORAGE_KEYS?.EMPLOYEE_ID);
  const [loading, setLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedCurrentDate = `${day}-${month}-${year}`;
  const formattedStartDate = `01-01-${year}`;

  const fetchEventDetails = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        `Event?startDate=${formattedStartDate}&endDate=${formattedCurrentDate}`,
        {},
        true
      );
      const response = res?.data;

      const eventArray = response.data && Array.isArray(response.data) ? response.data : [];

      const event = eventArray.find(item => item.eventId === parseInt(eventId));
      console.log('Event Details:', event);
      console.log('Event Feedback: ', event.feedBack);

      if (event) {
        setEventDetails([event]);
      } 
    } catch (err) {
      console.log('Error in fetching event details: ', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEventDetails();
  }, [eventId, staffId]);

  if (loading) {
    return <div>Loading event details...</div>;
  }

  if (!eventDetails.length) {
    return <div>No event details found</div>;
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const itemsPerPage = 12;
  const totalItems = eventDetails.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPrevious = eventDetails.slice(startIndex, endIndex);

  const starRatings = eventDetails.reduce(
    (acc, event) => {
      const rating = event.feedBack || 0;
      acc[rating] = (acc[rating] || 0) + 1;
      acc.total += 1;
      return acc;
    },
    { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, total: 0 }
  );

  const averageRating = (Object.entries(starRatings).reduce((acc, [key, value]) => {
    if (key !== 'total') acc += parseInt(key) * value;
    return acc;
  }, 0) / starRatings.total).toFixed(1);

  return (
    <div className='white-bg space-y-8'>
      <div className='xs:w-[80%] sm:w-[70%] space-y-8 mdLg:w-full mdLg:mx-0 mx-auto'>
        <div className='flex flex-wrap gap-4 justify-between mt-3 items-start'>
          <div
            className='uppercase text-xs flex flex-wrap items-center justify-between space-x-80 text-pink-100 tracking-[3px] font-bold cursor-pointer'
            onClick={() => navigate(-1)}>
            <div className='flex flex-row gap-2'>
              <MdOutlineArrowBack /> <span className='text-blue-100'> BACK</span>
            </div>
          </div>
          <Button
            isPurple
            text={'DOWNLOAD REVIEW'}
            onClick={() => navigate()}
          />
        </div>
        <div className='flex flex-col gap-4 justify-between'>
          {currentPrevious.length > 0 ? (
            currentPrevious.map(event => (
              <div key={event.eventId} className='flex flex-col space-y-5'>
                <div className='flex flex-row w-full h-32 rounded-lg overflow-hidden space-y-5 space-x-5'>
                  <div className='w-[50%] rounded-[5%]'>
                    <img
                      src={event.coverPhoto}
                      alt='Card Image'
                      className='object-fill h-full w-full rounded-[5%]'
                    />
                  </div>
                  <div className='flex flex-col space-y-2'>
                    <h3 className='text-purple-100 text-sm font-bold mb-2'>
                      {event.title}
                    </h3>
                    <div className='flex flex-wrap gap-1'>
                      <p className='text-gray-700 text-sm'>
                        {event.date}
                      </p>
                      <p className='text-yellow-100 text-sm'>
                        {' '}
                        | {event.time}
                      </p>
                    </div>
                    <div className='flex flex-wrap space-x-1'>
                      <MdLocationOn />
                      <a className='text-xs text-purple-100'>
                        {event.location}
                      </a>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col space-y-3'>
                  <h3 className='text-gray-500 text-base'>Description</h3>
                  <p className='text-purple-900'>
                    {event.description}
                  </p>
                </div>
                <div className="flex items-center">
                  <StarRating rating={event.feedBack} />
                </div>
              </div>
            ))
          ) : (
            <p>Loading event details...</p>
          )}
        </div>
        <div className='flex-col'>
          <div className='flex flex-wrap gap-2 items-end mb-2'>
            <p className='text-black-100 text-6xl font-bold'>
              {starRatings.total > 0 ? averageRating : 'No ratings'}
            </p>
            {starRatings.total > 0 ? (
              <p className='text-black-100 text-lg font-bold'>
                ★ ({starRatings.total} {starRatings.total === 1 ? 'rating' : 'ratings'})
              </p>
            ) : (
              <p className='text-black-100 text-lg font-bold'>★ (No ratings)</p>
            )}
          </div>
          {starRatings.total > 0 ? (
            Array.from({ length: 5 }, (_, i) => (
              <div key={i + 1} className='mb-2'>
                <p className='text-black-100'>
                  {i + 1} ★ ({starRatings[i + 1]})
                </p>
                <div className='progress-container'>
                  <div
                    className='progress-bar'
                    style={{
                      width: `${(starRatings[i + 1] / starRatings.total) * 100}%`,
                    }}
                  >
                    {((starRatings[i + 1] / starRatings.total) * 100).toFixed(1)}%
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No ratings</p>
          )}
        </div>
        <div className='purple-bg rounded-[2px] space-y-8 mt-8 bg-pink-30 shadow-xl rounded-3xl p-4 md:p-10'>
          <div
            className='cursor-pointer grid grid-cols-1 sm:grid-cols-3 gap-4'
          >
            {eventDetails.length > 0 ? (
              eventDetails.map((item, index) => (
                <div
                  key={index}
                  className='flex flex-col w-90 h-32 bg-white-100 rounded-lg overflow-hidden space-y-2 space-x-5'
                >
                  <div className='flex flex-row space-x-3 items-center w-full mt-3 ml-3'>
                    <img
                      src={'https://www.ndia.org/-/media/sites/ndia/meetings-and-events/2022/11/17/3ldr---leadership-conference/leadership22_eventlisting.png'}
                      alt='Card Image'
                      className='object-cover h-9 w-9 rounded-full'
                    />
                    <p className='text-xs'>{item.username || 'Reviewer\'s name'}</p>
                  </div>
                  <div className='flex flex-col'>
                    <h3 className='text-purple-100 text-sm font-bold mb-2'>
                      {item.rating || '★ ★ ★ ★ ★'}
                    </h3>
                    <div className='flex flex-wrap gap-1'>
                      <p className='text-gray-700 text-sm'>{item.comment || 'It was an interesting and educative conference.'}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className='w-full text-purple-100 mt-5 mb-5'>
                There are no reviews for this training
              </div>
            )}
          </div>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default TrainingReview;
