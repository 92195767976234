import React from 'react';
import { FiSearch, FiSettings } from 'react-icons/fi';
import { IoMdNotifications } from 'react-icons/io';
import { GoCalendar } from 'react-icons/go';
import { MdArrowRightAlt } from 'react-icons/md';
import { Link } from 'react-router-dom';
import SearchWithFilter from '../general/SearchWithFilter';
import BackWithText from '../general/BackWithText';
import Button from '../button';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BsBriefcase } from 'react-icons/bs';
import { BiDotsVerticalRounded, BiRefresh } from 'react-icons/bi';

export const VerticalAlignCard = ({
  icon,
  text,
  imgOnly,
  className,
  containerClassName,
  cardFlexClassName,
  onClick,
  path,
  isRecruit
}) => {
  return (
    <div className={`${containerClassName} rounded-xl  `}>
      {imgOnly && (
        <Link to={path}>
          <div
            className={`sidebar text-white-100 font-semibold text-sm w-fit pl-7 pr-6 py-1 rounded-r-[10px] h-[50px] flex items-center  ${className} `}>
            <div className='flex items-center gap-6'>
              <div>{icon}</div>
              <span className='capitalize ]'>{text}</span>
            </div>
          </div>
        </Link>
      )}

      {!imgOnly && (
        <div onClick={onClick} className={`${className} pl-7 h-full py-4 px-4`}>
          <Link to={path}>
            <div
              className={`flex flex-col ${
                isRecruit ? 'justify-end gap-6' : 'justify-between'
              } h-[100%]  ${cardFlexClassName}`}>
              {icon}
              <div className='h-[1px] w-[28px] bg-white-100 '></div>
              <div className='flex items-center justify-between w-full'>
                <span className='font-semibold capitalize text-white-100 text-base md:text-lg'>
                  {text}
                </span>
                <MdArrowRightAlt color='#ffffff' size={'25'} />
              </div>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export const HorizontalAlignCard = ({
  containerClassName,
  icon,
  text,
  withArrow,
  textClassName,
  path,
  arrowClassName,
  onClick,
  onMouseEnter,
  onMouseLeave
}) => {
  return (
    <div
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      className={`w-fit flex justify-between bg-white-100 bg-opacity-40 rounded-sm py-4 px-6 items-center ${containerClassName} `}>
      <Link to={path} className='flex justify-between w-full items-center gap-2'>
        <div className='flex items-center space-x-3 font-semibold'>
          {icon && <div> {icon}</div>}
          <span className={`capitalize text-blue-100 text-sm xs:text-base ${textClassName}`}>
            {text}
          </span>
        </div>
        {withArrow && (
          <div>
            <MdArrowRightAlt color='#2A004C' className={arrowClassName} size={'30'} />
          </div>
        )}
      </Link>
    </div>
  );
};

export const HeaderCard = ({
  text,
  withButton,
  className,
  button,
  titleClassName,
  link = '',
  linkClassName,
  isRecruit,
  children
}) => {
  return (
    <div
      className={`bg-blue-100 rounded-md py-4 ${
        isRecruit ? ' sm:px-20 mdLg:px-32 px-8' : 'px-8'
      }   ${
        withButton || isRecruit
          ? 'flex justify-center sm:justify-between items-center py-4 flex-wrap gap-4'
          : ''
      } ${className}`}>
      <span
        className={`text-white-100 capitalize font-semibold text-base md:text-lg ${titleClassName}`}>
        {text}
      </span>

      <div className=' flex flex-wrap gap-3'>
        {withButton && (
          <Link className={linkClassName} to={link}>
            {button}
          </Link>
        )}

        {children}
      </div>

      {isRecruit && (
        <div className='flex flex-wrap gap-2 items-center text-white-100'>
          <FiSearch className='bg-white-30 rounded-full p-[8px] w-[30px] h-[30px] sm:w-[35px] sm:h-[35px] ' />
          <IoMdNotifications className='bg-white-30 rounded-full p-[8px] w-[30px] h-[30px] sm:w-[35px] sm:h-[35px]' />
          <GoCalendar className='bg-white-30 rounded-full p-[8px] w-[30px] h-[30px] sm:w-[35px] sm:h-[35px] ' />
          <FiSettings className='bg-white-30 rounded-full p-[8px] w-[30px] h-[30px] sm:w-[35px] sm:h-[35px] ' />
        </div>
      )}
    </div>
  );
};

export const PinkHeaderWithFilter = ({ goBack, text, onClick, withReset }) => {
  return (
    <div className=' bg-pink-100 py-4 flex flex-wrap gap-4 rounded-xl items-center justify-between px-4 text-white-100'>
      <BackWithText text={text} goBack={goBack} />

      <div className=' flex gap-1'>
        <SearchWithFilter
          className={'text-white-100'}
          height='h-10'
          placeholder={'Search created jobs'}
          isRecruit
        />
        {withReset && (
          <p onClick={onClick} className='text-white-100 flex items-center gap-1 cursor-pointer '>
            {' '}
            <BiRefresh /> Reset{' '}
          </p>
        )}
      </div>
    </div>
  );
};

export const CandidateDetailsCard = ({ text, children, className }) => {
  return (
    <div
      className={`relative border-[1.3px] text-gray-600 border-gray-20  bg-white-100  rounded-md p-6 py-14 sm:py-8 text-sm sm:text-base ${className}`}>
      <p className=' text-pink-100 font-semibold absolute -top-[14.5px] sm:left-10 left-[50%] -translate-x-[50%] sm:-translate-x-0 bg-white-100 px-3 text-sm sm:text-base '>
        {text}
      </p>
      {children}
    </div>
  );
};

export const RecruitReportCard = ({
  text,
  icon,
  link = '',
  value,
  className,
  active,
  isPink,
  onClick
}) => {
  return (
    <Link
      to={link}
      onClick={onClick}
      className={` w-[150px] border-[1.3px] border-gray-20  ${
        active ? 'bg-pink-100 text-white-100' : 'bg-white-100 text-pink-600 '
      }   rounded-md p-4 drop-shadow-md flex flex-col items-center  ${className}`}>
      <div
        className={` text-lg sm:text-2xl ${
          active ? 'text-white-100' : isPink ? 'text-pink-100' : 'text-gray-600 text-opacity-70  '
        }  `}>
        {icon}
      </div>
      <p className={`${!isPink && 'text-gray-200'}`}>{text}</p>
      <p
        className={`${
          !isPink && 'text-pink-100 text-opacity-50'
        } text-lg sm:text-xl md:text-3xl font-bold`}>
        {value}
      </p>
    </Link>
  );
};

export const JobSeekerCard = ({ status, className, dept, img, name, role, invited }) => {
  return (
    <div
      className={` w-[250px] text-gray-600 bg-white-100   rounded-md p-4 drop-shadow-md text-xs flex flex-col items-start gap-2 font-medium  ${className}`}>
      <div className='flex gap-2 items-center'>
        <img src={img} alt='' className='w-9 h-9' />
        <div className=' font-bold'>
          <p className='text-purple-100 text-base'>{name}</p>
          <p className=' '>{role}</p>
        </div>
      </div>

      <div className='flex gap-2 items-center'>
        <p className='flex gap-1 items-center'>
          {' '}
          <AiOutlineInfoCircle /> {status}{' '}
        </p>
        <p className='flex gap-1 items-center'>
          {' '}
          <BsBriefcase /> {dept}{' '}
        </p>
      </div>

      <Button text={invited ? 'invited' : ''} isPink className={'bg-opacity-50 w-full mt-2'} />
    </div>
  );
};

export const RecentJobCard = ({
  className,
  location,
  company,
  noOfApplicants,
  role,
  date,
  remote,
  description
}) => {
  return (
    <div
      className={` w-[270px] sm:w-[350px]  text-gray-600 bg-white-100   rounded-md drop-shadow-md text-sm flex flex-col items-start gap-2 font-medium  ${className}`}>
      <div className='  border-b-[2px] border-b-gray-20 p-4 w-full'>
        <div className='flex flex-col gap-1 items-start w-full'>
          <div className='flex justify-between w-full '>
            <p className='text-lg md:text-xl font-bold text-purple-100'>{role}</p>
            <div className=' flex gap-1 items-center'>
              <p>{date}</p>
              <BiDotsVerticalRounded />
            </div>
          </div>
          <p className=' text-base font-normal'>{location}</p>

          <p className='flex flex-wrap gap-1 text-pink-100 font-bold w-full'>
            <span>{company}</span> . <span>{location}</span> .{' '}
            <span>({remote ? 'Remote' : 'Onsite'})</span> .{' '}
            <span>
              {noOfApplicants} {noOfApplicants == 1 ? 'Applicant' : 'Applicants'}{' '}
            </span>
          </p>
        </div>
      </div>
      <p className=' py-6 px-4 w-full'> {description}</p>
    </div>
  );
};
