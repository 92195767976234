import React from 'react';
import { IoBriefcaseOutline } from 'react-icons/io5';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import CheckBox from '../../../../../components/input/CheckBox';
import { ReactComponent as Desktop } from '../../../../../assets/img/apply.svg';

const JobCategoryDetailsRight = () => {
  return (
    <div className="  w-full space-y-1">
      <div className=" w-full  bg-white-100 text-gray-100 py-10">
        <div className="w-[85%] mx-auto flex flex-col gap-8 justify-center items-center">
          <Button isGray text={'APPLY'} className="rounded-none w-full  " />
          <p className="uppercase">LOGIN TO APPLY NOW</p>

          <div className="flex gap-2 items-center">
            <IoBriefcaseOutline className="p-2 w-[30px] h-[30px] bg-gray-600 text-white-100 rounded-full " />

            <p className="font-semibold text-base md:text-lg">Product Manager</p>
          </div>
          <div className="space-y-4">
            <Input placeholder={'EMAIL ADDRESS'} className="h-10 placeholder:text-xs " />
            <Input placeholder={'PASSWORD'} className="h-10 placeholder:text-xs " />
            <div className="flex justify-between flex-wrap gap-2 items-center py-3">
              <CheckBox title={'Remember me'} className="text-gray-100 font-semibold text-xs" />

              <p className=" italic text-xs text-pink-100"> forgot password</p>
            </div>

            <div className="pb-4">
              <Button isPink className={' w-full rounded-none '} text="LOGIN" />
            </div>
          </div>
        </div>
      </div>

      <div className=" w-full  bg-white-100 text-gray-100 py-10">
        <div className="w-[85%] mx-auto flex flex-col gap-8 justify-center items-center">
          <p>Don't have an account</p>
          <Button isTransparent text={'REGISTER'} className="border border-pink-100 w-full" />
        </div>
      </div>

      <div className="bg-blue-100 mt-3">
        <div className="flex flex-col items-center">
          <Desktop className=" scale-75 " />
          <div className="pb-4">
            <Button isPink className={' w-full rounded-none '} text="POST A JOB" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCategoryDetailsRight;
