import React from 'react';
import { FaUsers, FaWindowClose } from 'react-icons/fa';
import { RiBarChartBoxFill, RiShareForward2Fill } from 'react-icons/ri';
import {
  JobSeekerCard,
  RecentJobCard,
  RecruitReportCard
} from '../../../../components/cards/Cards';
import DP from '../../../../assets/img/DP.png';

const RecruitReportLeft = ({ onJobsClick, onAppliClick, onClosedClick, onSuccessClick }) => {
  return (
    <div className="space-y-10 ">
      <div className="flex items-center gap-1">
        <h4 className="text-base w-fit">Dashboard</h4>{' '}
        <p className=" w-[100%] h-[2px]  bg-pink-100"> </p>
      </div>

      <div className="flex flex-wrap gap-3 ">
        <RecruitReportCard
          onClick={onJobsClick}
          text={'Published Jobs'}
          value="785"
          icon={<RiShareForward2Fill />}
        />
        <RecruitReportCard
          onClick={onAppliClick}
          text={' Applicants'}
          value="100"
          icon={<FaUsers />}
        />
        <RecruitReportCard
          onClick={onClosedClick}
          text={'Job closed'}
          value="230"
          icon={<FaWindowClose />}
        />
        <RecruitReportCard
          onClick={onSuccessClick}
          text={'Hire success'}
          value="405"
          icon={<RiBarChartBoxFill />}
        />
      </div>

      <div className=" bg-white-20 bg-opacity-25 rounded-md p-3 space-y-6">
        <div className="flex items-center gap-1">
          <h4 className="text-base w-[210px]">Suggested Job Seekers</h4>{' '}
          <p className=" w-[100%] h-[2px]  bg-pink-100"> </p>
        </div>
        <div>
          <div className="flex flex-wrap gap-3 ">
            <JobSeekerCard
              status={'Full Time'}
              role="Product Manager"
              name="Bolade Taiwo James"
              dept={'Engineering'}
              img={DP}
              invited
            />
            <JobSeekerCard
              status={'Full Time'}
              role="Product Manager"
              name="Bolade Taiwo James"
              dept={'Engineering'}
              img={DP}
              invited
            />
            <JobSeekerCard
              status={'Full Time'}
              role="Product Manager"
              name="Bolade Taiwo James"
              dept={'Engineering'}
              img={DP}
              invited
            />
          </div>
        </div>
        <div className="flex items-center gap-1">
          <h4 className="text-base w-[210px]">Recent Posted Jobs</h4>{' '}
          <p className=" w-[100%] h-[2px]  bg-pink-100"> </p>
        </div>
        <div>
          <div className="flex flex-wrap gap-3 ">
            <RecentJobCard
              location={'Lagos Nigeria'}
              noOfApplicants="1"
              role={'Product Designer'}
              date="3 days ago"
              company={'JTexh'}
              remote
              description={
                'We believe in a world where growth thrives across borders and cultures. That’s why our Founder and CEO Jacob Jones started JTech, coining the term Employer of Record (EOR) simultaneously. '
              }
            />
            <RecentJobCard
              location={'Kaduna Nigeria'}
              noOfApplicants="22"
              role={'Software Developer>'}
              date="1 day ago"
              company={'PeopleRecruit'}
              description={
                'We believe in a world where growth thrives across borders and cultures. That’s why our Founder and CEO Jacob Jones started JTech, coining the term Employer of Record (EOR) simultaneously. '
              }
            />
            <RecentJobCard
              location={'Lagos Nigeria'}
              noOfApplicants="22"
              role={'Product Designer'}
              date="1 day ago"
              company={'PeopleCenta'}
              description={
                'We believe in a world where growth thrives across borders and cultures. That’s why our Founder and CEO Jacob Jones started JTech, coining the term Employer of Record (EOR) simultaneously. '
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitReportLeft;
