import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/button';
import DashWithNumbers from '../../../components/landing-page/DashWithNumbers';
import { SIGN_UP } from '../../../router/router';

const WorkForceContent = () => {
  return (
    <div className='workforce-bg relative '>
      <DashWithNumbers no={'02'} />
      <div className='flex md:flex-row flex-col justify-between md:w-[80%] mx-auto py-24 items-center gap-4'>
        <div className='flex gap-10'>
          <div className='bg-black-100 bg-opacity-30 py-12 px-6 w-[50%] lg:w-[40%] space-y-10 rounded-xl text-white-100 mx-auto md:mx-0'>
            <div className='space-y-6'>
              <p className='font-bold text-base mdLg:text-2xl'>
                Take your entire workforce with you,
                <span className='text-yellow-100'>everywhere you go.</span>
              </p>
              <p className='text-sm leading-6'>
                We simplify HR for business owners so they can grow a professional and productive
                business on the go. See us as your one-stop for everything human resource.
              </p>
            </div>

            <Link to={SIGN_UP}>
              <Button text={'Get Started'} />
            </Link>
          </div>
        </div>
        <div className='w-[50%] lg:w-[40%] xl:w-[30%] space-y-2 text-white-100  '>
          <p className='text-sm leading-6 font-bold flex items-center gap-5'>
            Become a member
            <p className='w-[60px] h-[1.5px] bg-white-100'></p>
          </p>

          <p className='text-base mdLg:text-2xl font-bold '>Ready to try a new work experience?</p>
          <p className='text-xs leading-6'>
            Get the most essential HR services for your business, on the go.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WorkForceContent;
