import React from 'react';
import { Navigate } from 'react-router-dom';
import { STORAGE_KEYS } from '../helpers/constant';
import { DASHBOARD } from './router';

const UnAuthRoute = ({ children }) => {
  const token = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN);
  return !token ? children : <Navigate to={DASHBOARD} replace={true} />;
};

export default UnAuthRoute;
