import React from 'react';
import { Outlet } from 'react-router-dom';
import { HeaderCard } from '../../components/cards/Cards';
import LayoutWithSideBar from '../../components/layout/LayoutWithSideBar';

const HelpDesk = () => {
  return (
    <LayoutWithSideBar helpdesk>
      <HeaderCard text={'Help Desk'} titleClassName={'font-bold'} className={''} />
      <Outlet />
    </LayoutWithSideBar>
  );
};

export default HelpDesk;
