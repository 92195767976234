import React from 'react';
import { BsClipboard } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/button';
import { PinkHeaderWithFilter } from '../../../../../components/cards/Cards';
import {
  CANDIDATE_DETAILS,
  JOB_APPLICANTS,
  SCREENING_SCORES,
  SHORTLIST
} from '../../../../../router/router';
import { candidates } from '../../../../../utils/peopleRecruitArrays';

const JobApplicants = () => {
  return (
    <div className="space-y-6">
      <div>
        <Link to={SCREENING_SCORES}>
          <Button
            icon={<BsClipboard />}
            withIcon
            text={'View screening scores '}
            className="flex items-center gap-2"
            isPink
          />
        </Link>
      </div>
      <PinkHeaderWithFilter goBack text={'Product Manager (1024 candidates)'} />
      <div className=" bg-white-100 rounded-md p-4 sm:px-10  space-y-6">
        <div className="w-full overflow-scroll whitespace-nowrap no-scroll-bar  ">
          <table className="w-full  border-spacing-2  table-auto">
            <thead className=" border-collapse border-red-500  ">
              <tr className="text-left text-blue-100">
                <th></th>
                <th className="p-3 ">Name</th>
                <th className="p-3 "> Grade</th>
                <th className="p-3 "> Qualification</th>
                <th className="p-3 ">Email Address</th>
                <th className="p-3 ">Phone Number</th>
              </tr>
            </thead>

            <tbody className="text-gray-100">
              {candidates.map((candidate, i) => {
                return (
                  <tr className="" key={i}>
                    <td className="p-3">{i + 1}.</td>
                    <td className="p-3">{candidate.name}</td>
                    <td className="p-3 text-ellipsis">{candidate.grade}</td>
                    <td className="p-3">{candidate.qualification}</td>
                    <td className="p-3">{candidate.email}</td>
                    <td className="p-3 capitalize">{candidate.phoneNumber}</td>
                    <Link
                      to={`${SHORTLIST}/${JOB_APPLICANTS}/${CANDIDATE_DETAILS}`}
                      className="p-3 text-pink-100 italic">
                      view details
                    </Link>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default JobApplicants;
