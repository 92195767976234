import React, { useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import Pagination from '../../../components/general/pagination';
import { BiLoaderAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const Tooltip = ({ text }) => {
  return (
    <div className='absolute'>
      <div className='relative top-0 left-1/2 transform -translate-x-1/2'>
        <div className='absolute top-0 left-1/2 transform -translate-x-1/2 border-solid border-black-100 border-2 border-t-0 border-r-2 w-4 h-2 bg-black-100 rotate-45'></div>
        <div className='bg-black-100 text-white-100 rounded-lg py-2 px-4 h-8 shadow-lg whitespace-normal'>
          {text}
        </div>
      </div>
    </div>
  );
};

const EmployeeContent = ({ searchValue, employeesSearch, employeesProfile, employeesEmail, employees, loading }) => {
  const navigate = useNavigate();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Determine the data to display based on searchValue and search results
  let displayedData;
  if (searchValue && searchValue.length >= 2) {
    if (employeesSearch.length > 0) {
      displayedData = employeesSearch;
    } else if (employeesProfile) {
      displayedData = [employeesProfile]; // Wrap employeesProfile in an array
    } else if (employeesEmail) {
      displayedData = [employeesEmail]; // Wrap employeesEmail in an array
    } else {
      displayedData = [];
    }
  } else {
    displayedData = employees;
  }

  console.log('Search value: ', searchValue);
  console.log('Displayed profile: ', employeesProfile);
  console.log('Displayed email: ', employeesEmail);
  console.log('Displayed data: ', displayedData);

  const totalItems = displayedData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  return (
    <div className='rounded-[20px] bg-purple-30 p-10 my-6'>
      <div>
        <div className='whitespace-nowrap overflow-x-scroll'>
          <table className='w-full'>
            <thead className='border-collapse border-red-500'>
              <tr className='w-[100%] text-left uppercase tracking-[3px] text-blue-100 font-semibold text-2xs pl-28 my-4'>
                <th></th>
                <th></th>
                <th className='p-6 text-center'>EMPLOYEE NAME</th>
                <th className='p-6 text-center'>EMPLOYEE ID</th>
                <th className='p-6 text-center'>EMPLOYEE EMAIL</th>
                <th className='p-6 text-center'>DEPARTMENT</th>
                <th className='p-6 text-center'>ROLE</th>
              </tr>
            </thead>
            <tbody className='rounded-sm'>
              {Array.isArray(displayedData) ? (
                displayedData.slice(startIndex, endIndex).map((employee, i) => (
                  <tr
                    className='cursor-pointer border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px]'
                    key={employee?.id}
                    onClick={() => navigate(`user/${employee?.id}`)}
                    onMouseEnter={() => handleMouseEnter(i)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <td className='p-3 px-6'>
                      {startIndex + i + 1}.
                      {hoveredRow === i && <Tooltip text="Click to view details" />}
                    </td>
                    <td className='p-3 px-6'>
                      <FaUserCircle size={30} color='#4E008330' />
                    </td>
                    <td className='p-3 px-6 text-center capitalize'>
                      {employee?.firstName + ' ' + employee?.lastName}
                    </td>
                    <td className='p-3 px-6 text-center'>{employee?.id !== null ? employee?.id : 'nil'}</td>
                    <td className='p-3 px-6 text-center'>
                      {employee?.personalEmail !== null ? employee?.personalEmail : 'nil'}
                    </td>
                    <td className='p-3 px-6 text-center capitalize'>
                      {employee?.department?.name !== null ? employee?.department?.name : 'nil'}
                    </td>
                    <td className='p-3 px-6 text-center capitalize'>
                      {employee?.jobRole !== null ? employee?.jobRole : 'nil'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className='p-3 px-6'>1.</td>
                  <td className='p-3 px-6'>
                    <FaUserCircle size={30} color='#4E008330' />
                  </td>
                  <td className='p-3 px-6 text-center capitalize'>
                    {displayedData?.fullName || `${displayedData?.firstName || ''} ${displayedData?.lastName || ''}`}
                  </td>
                  <td className='p-3 px-6 text-center'>{displayedData?.employeeId || 'nil'}</td>
                  <td className='p-3 px-6 text-center'>{displayedData?.personalEmail || 'nil'}</td>
                  <td className='p-3 px-6 text-center capitalize'>{displayedData?.jobRole || 'nil'}</td>
                  <td className='p-3 px-6 text-center capitalize'>{displayedData?.position || 'nil'}</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className='pb-10 flex items-center'>
            {loading && (
              <BiLoaderAlt
                className='animate-spin my-10 flex text-center text-blue-100 justify-center mx-auto'
                size={30}
              />
            )}
            {!loading && totalItems === 0 && (
              <span className='p-3'>There are currently no employees to show</span>
            )}
          </div>
        </div>
        <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
      </div>
    </div>
  );
};

export default EmployeeContent;
