import React from 'react';
import { useNavigate } from 'react-router';
import Button from '../../../components/button';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { AiOutlineForward } from 'react-icons/ai';
import { PERFORMANCE_REPORT, HR_ADMIN_REPORT, RECRUITMENT_REPORT,
ONBOARDING_OFFBOARDING_REPORT, COMPENSATION_REPORT } from '../../../router/router';
import OnboardingHeader from './onboading-offboarding/OnboardingHeader';
import ReportHeader from '../ReportHeader';

const AllReports = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full  space-y-6">
      <div className="flex xs:justify-between gap-2 items-center xs:items-end flex-wrap justify-center flex-col xs:flex-row">
        <h4 className="text-lg"></h4>
        <p className="font-bold text-2xs underline text-pink-100"></p>
      </div>
      <ReportHeader />

      {/*<div className="flex flex-row md:w-[100%] items-start xl:flex-row xl:flex-wrap gap-3">
        
        <Button
          text="Employment Information Report"
          className={
            'flex items-center text-left justify-between tracking-normal text-sm capitalize flex-row-reverse bg-purple-100 bg-opacity-80 border border-purple-100 text-purple-100 px-4 w-[90%] xs:w-[50%] md:w-full  mdLg:w-[70%] xl:w-[43%]'
          }
          icon={<AiOutlineArrowRight size={15} className="" /> }
          withIcon
        />
        <Button
          text="Recruitment Reports"
          className={
            'flex items-center text-left justify-between tracking-normal text-sm capitalize flex-row-reverse bg-white-100 bg-opacity-60 border border-yellow-100 text-yellow-100 px-4 w-[90%] xs:w-[50%] md:w-full  mdLg:w-[70%] xl:w-[43%]'
          }
          icon={<AiOutlineArrowRight size={15} className="" />}
          withIcon
          onClick={() => navigate(RECRUITMENT_REPORT)}
        />
        <Button
          text="Compensation Reports"
          className={
            'flex items-center text-left justify-between tracking-normal text-sm capitalize flex-row-reverse bg-white-100 bg-opacity-60 border border-yellow-100 text-yellow-100 px-4 w-[90%] xs:w-[50%] md:w-full  mdLg:w-[70%] xl:w-[43%]'
          }
          icon={<AiOutlineArrowRight size={15} className="" />}
          withIcon
          onClick={() => navigate(COMPENSATION_REPORT)}
        />
      </div>
      <div className="flex flex-row items-start xl:flex-row xl:flex-wrap gap-3">
        <Button
          text="Onboarding and Offboarding Reports"
          className={
            'flex items-center text-left justify-between tracking-normal text-sm capitalize flex-row-reverse bg-pink-100 bg-opacity-80 border border-pink-100 text-pink-100 px-4 w-[90%] xs:w-[50%] md:w-full  mdLg:w-[70%] xl:w-[45%]'
          }
          icon={<AiOutlineArrowRight size={15} className="" />}
          withIcon
          onClick={() => navigate(ONBOARDING_OFFBOARDING_REPORT)}
        />
        <Button
          text="Performance Management Reports"
          className={
            'flex items-center text-left justify-between tracking-normal text-sm capitalize flex-row-reverse bg-green-100 bg-opacity-80 border border-green-100 text-green-100 px-4 w-[90%] xs:w-[50%] md:w-full  mdLg:w-[70%] xl:w-[45%]'
          }
          icon={<AiOutlineArrowRight size={15} className="" />}
          withIcon
          onClick={() => navigate(PERFORMANCE_REPORT)}
        />
        <Button
          text="HR Administration Reports"
          className={
            'flex items-center text-left justify-between tracking-normal text-sm capitalize flex-row-reverse bg-green-100 bg-opacity-80 border border-green-100 text-green-100 px-4 w-[90%] xs:w-[50%] md:w-full  mdLg:w-[70%] xl:w-[45%]'
          }
          icon={<AiOutlineArrowRight size={15} className="" />}
          withIcon
          onClick={() => navigate(HR_ADMIN_REPORT)}
        />
        </div>*/}
    </div>
  );
};

export default AllReports;
