import { Toast } from '../../../components/toast/toast';
import { apiService } from '../../../helpers/api';
import { MANAGE_EVENTS } from '../../../router/router';

const base = 'Activity/';

export const createActivity = async (data, navigate) => {
  try {
    const res = await apiService.post(`${base}Create`, data, true);
    if (res)
    Toast({
      type: 'success',
      message: 'Employee Activity Created Successfully'
     });
    navigate(MANAGE_EVENTS);
  } catch (err) {
    console.log('Error in creating employee activity: ', err)
  }
};
