import { apiService } from '../../../helpers/api';

const base = 'Overtime';

export const createOvertime = async (data) => {
  try {
    const res = await apiService.post(`${base}`, data, true);
    return res;
  } catch (err) {
    console.log('Error in creating overtime details: ', err)
  }
};

export const getOvertime = async () => {
  try {
    const res = await apiService.get(`${base}`, {}, true);
    const response = res?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching overtime details: ', err)
  }
};
