import React, { useState } from 'react';
import BaseModal from '../../../../components/base/modal';
import Button from '../../../../components/button';
import { TextArea } from '../../../../components/input';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

const AddLogs = ({ show, onClose }) => {
  const RATE_EMPLOYEE = [
    {
      name: 'positive',
      icon: <FaThumbsUp className='text-green-100' />
    },
    {
      name: 'negative',
      icon: <FaThumbsDown className='text-red-500' />
    }
  ];

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(RATE_EMPLOYEE[0]?.name);
  const addLogs = async () => {
    setLoading(true);
    console.log('addLogs');
    setLoading(false);
  };

  const styles = {
    active: 'bg-[#EBEBEB] rounded-[50px] px-6 py-3'
  };

  return (
    <BaseModal show={show} onClose={onClose} title='Add' coloredText='Logs'>
      <div className='space-y-8'>
        <TextArea label={'Logs'} className='h-20' isPurple />
        <div className=' flex gap-6 flex-wrap items-center'>
          {RATE_EMPLOYEE.map(({ name, icon }) => (
            <div
              key={name}
              className={`flex items-center gap-2 transition-all duration-700 cursor-pointer ${
                active === name && styles.active
              }`}
              onClick={() => setActive(name)}>
              <h6> {name}</h6>
              {icon}
            </div>
          ))}
        </div>

        <TextArea label={'Comment'} className='h-32' isPurple />

        <Button
          text='add logs'
          isPurple
          className='bg-opacity-80 ml-auto'
          loading={loading}
          onClick={addLogs}
        />
      </div>
    </BaseModal>
  );
};

export default AddLogs;
