import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReportsLayout from '../reports-layout';
import StatsCard from '../../../../components/cards/StatsCard';
import ReportCard from '../../../../components/cards/ReportCard';
import { apiService } from '../../../../helpers/api';

const RecruitmentReport = () => {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [objectivesCount, setObjectivesCount] = useState(0);
  const [behaviouralCount, setBehaviouralCount] = useState(0);
  const [technicalCount, setTechnicalCount] = useState(0);

  const fetchObjectivesCount = async () => {
      const res = await apiService.get(
        'Performance/Search', {}, true
      );
      const response = res?.data?.data;
      if (response)
      setObjectivesCount(response.length);  
  };

  const fetchBehaviouralCount = async () => {
    const res = await apiService.get(
      'Meta/Competence/List?Category=1', {}, true
    );
    const response = res?.data?.data;
    if (response)
    setBehaviouralCount(response.length);  
};

const fetchTechnicalCount = async () => {
  const res = await apiService.get(
    'Meta/Competence/List?Category=2', {}, true
  );
  const response = res?.data?.data;
  if (response)
  setTechnicalCount(response.length);  
};

  useEffect(() => {
    fetchObjectivesCount();
  }, []);

  useEffect(() => {
    fetchBehaviouralCount();
  }, []);

  useEffect(() => {
    fetchTechnicalCount();
  }, []);
  
  return (
    <div className='space-y-10 mt-10'>
      <ReportsLayout />
      <div
        className={`flex flex-wrap gap-4 mx-auto xs:w-[80%] sm:w-[60%] md:w-full md:mx-0 md:flex-wrap md:flex-row`}
      >
        <ReportCard
          text='Total people hired'
          className='w-full justify-start md:w-[40%] mdLg:w-[30%] text-base base:w-[25%]'
          isBlue
          value={'78,236'}
        />
        <StatsCard
          title='People hired in November'
          value={`${objectivesCount}`}
          className='w-full md:w-[40%] mdLg:w-[30%] text-[2px] xs:w-[25%]'
          isPerformance
        />
        <StatsCard
          isPerformance
          title='IT Department'
          value={`${behaviouralCount}`}
          className='w-full md:w-[40%] mdLg:w-[30%] xl:w-[25%]'
        />
        <ReportCard
          text='Number of interview'
          className='w-full justify-start md:w-[40%] mdLg:w-[30%] text-base xs:w-[25%]'
          isBlue
          value={'78,236'}
        />
        <StatsCard
          title='Offer acceptance rate'
          value={`${behaviouralCount}`}
          className='w-full md:w-[40%] mdLg:w-[30%] xl:w-[25%]'
        />
        <StatsCard
          title='Turn around time'
          value={`${behaviouralCount}`}
          className='w-full md:w-[40%] mdLg:w-[30%] xl:w-[25%]'
        />
      </div>
    </div>
  );
};

export default RecruitmentReport;
