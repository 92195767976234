import React from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const BackWithText = ({ text = 'Back', className }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`text-pink-100 flex gap-2 items-center ${className} cursor-pointer`}
      onClick={() => navigate(-1)}>
      <MdOutlineArrowBack className='  ' />
      <h6 className='font-bold text-xs'>{text}</h6>
    </div>
  );
};

export default BackWithText;
