import React, { useState, useEffect } from 'react';
import { emptyPerformanceForm } from '../../../../../../helpers/constant';
import { getObjectiveOption } from '../../../../../../helpers/functions';
import { API } from '../../../../../../utils/apis';
import GoalsLayout from '../../goals-layout';
import OkrOnly from '../okr-only';
import { useNavigate } from 'react-router';

const OkrGoals = () => {
  const emptyForm = {
    performanceId: 0,
    employeeId: 0,
    departmentId: 0,
    employeeGroupId: 0,
    performanceTemplate: 0,
    approverId: 0,
    isDraft: true,
    goal: [
      {
        objectiveId: 0,
        description: '',
        goalType: 1,
        targets: [
          {
            target: '',
            metrics: 1,
            value: '',
            dueDate: '',
            weight: 0,
          },
        ],
        performanceGoalCategory: 1,
      },
    ],
    behavioural: [],
    technical: [],
    groupType: 1,
  };
  const [formData, setFormData] = useState(emptyForm);
  const [objectiveOption, setObjectiveOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const loadObjectiveOptions = async () => {
    const res = await API.getObjective();
    setObjectiveOption(getObjectiveOption(res));
  };

  useEffect(() => {
    loadObjectiveOptions();
  }, []);

  const submitData = async (e) => {
    setLoading(true);
    console.log(formData);
    await API.submitData(formData, navigate);
    setLoading(false);
    e.preventDefault();
  };

  return (
    <GoalsLayout title='Okr Goals' submitData={submitData}>
      <OkrOnly 
       formData={formData} 
       setFormData={setFormData}
       objectiveOption={objectiveOption} 
      />
    </GoalsLayout>
  );
};

export default OkrGoals;
