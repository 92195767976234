import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { BiRightArrow } from 'react-icons/bi';
import Button from '../../../../components/button';
import { apiService } from '../../../../helpers/api';
import { STORAGE_KEYS } from '../../../../helpers/constant';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/big-calendar.svg';

const UpcomingOutings = () => {
  const { id } = useParams();
  const staffId = localStorage.getItem(STORAGE_KEYS?.EMPLOYEE_ID);
  const [loading, setLoading] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [currentOutingIndex, setCurrentOutingIndex] = useState(0);
  const [showNextOutings, setShowNextOutings] = useState(false);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedCurrentDate = `${day}-${month}-${year}`;
  const formattedEndDate = `31-12-${year}`;

  const fetchMeetings = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        'Event',
        {
          startDate: formattedCurrentDate,
          endDate: formattedEndDate
        },
        true
      );
      const response = res?.data?.data || [];
      // Sort the meetings by date to get the closest upcoming outing
      const sortedMeetings = response.sort((a, b) => new Date(a.date) - new Date(b.date));
      setMeetings(sortedMeetings); // Set to a sorted array of meetings
    } catch (err) {
      console.log('Error in fetching meetings: ', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMeetings();
  }, []);

  const handleNextOutingsClick = () => {
    setShowNextOutings(!showNextOutings);
  };

  const closestMeeting = meetings[currentOutingIndex] || {};
  const nextMeetings = meetings.slice(currentOutingIndex + 1, currentOutingIndex + 5); // Next 4 meetings

  return (
    <div className="space-y-3">
      <p className="font-bold">Upcoming Outings</p>

      <div className="bg-white-100 rounded-sm p-6 relative">
        {meetings.length === 0 ? (
          <h4 className="text-pink-100 text-2xl">No upcoming meetings</h4>
        ) : (
          <div>
            <div className="space-y-4 mb-4">
              <h6>Team Outings</h6>
              <h4 className="text-pink-100 text-2xl">{closestMeeting.title}</h4>

              <div className="flex items-end gap-6 flex-wrap m-4 pl-10">
                <div className="space-y-2 text-blue-100 font-semibold text-xs">
                  <p>Location: {closestMeeting.location}</p>
                  <p>Time: {closestMeeting.time}</p>
                  <p>Date: {closestMeeting.date}</p>
                </div>
                <Button text={'Set a Reminder'} isPink className={'h-9 px-4'} />
                <CalendarIcon className="absolute left-0 bottom-0" />
                {meetings.length > 1 && (
                  <BiRightArrow
                    className="text-pink-100 cursor-pointer ml-4"
                    onClick={handleNextOutingsClick}
                    size={24}
                  />
                )}
              </div>
            </div>

            {showNextOutings && nextMeetings.length > 0 && (
              <div className="mt-4 bg-gray-100 p-4 rounded-sm">
                <h6 className="font-bold text-pink-100">Next Outings</h6>
                {nextMeetings.map((meeting, index) => (
                  <div key={index} className="space-y-2 mb-2">
                    <h4 className="text-pink-100 text-xl">{meeting.title}</h4>
                    <div className='flex flex-col ml-9 items-start space-y-2 text-blue-100 font-semibold text-xs'>
                      <p>Location: {meeting.location}</p>
                      <p>Time: {meeting.time}</p>
                      <p>Date: {meeting.date}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UpcomingOutings;
