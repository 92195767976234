import React from 'react';
import { Outlet } from 'react-router-dom';
import RecruitLayoutWithSideBar from '../../../components/layout/RecruitLayoutWithSideBar';

const Schedule = () => {
  return (
    <RecruitLayoutWithSideBar hText={'Schedule & Engage'}>
      <Outlet />
    </RecruitLayoutWithSideBar>
  );
};

export default Schedule;
