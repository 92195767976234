import React from 'react';
import Button from '../../../components/button';
import DashWithNumbers from '../../../components/landing-page/DashWithNumbers';
import Man from '../../../assets/img/landing/home-boy-1.png';
import Woman1 from '../../../assets/img/landing/home-girl-1.png';
import Woman2 from '../../../assets/img/landing/home-girl-2.png';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { SIGN_UP } from '../../../router/router';
import { Link } from 'react-router-dom';

const HeaderContent = () => {
  return (
    <div className='relative'>
      <div className='flex justify-center mt-24 relative'>
        <DashWithNumbers no={'01'} />
        <div className='font-bold text-xl xs:text-2xl sm:text-4xl md:text-[55px] text-center space-y-5 text-white-100 tracking-[2px] '>
          <div className='flex justify-center gap-2 sm:gap-6 items-center text-center '>
            <p>We</p>
            <img
              className='w-[50px] xs:w-[65px] sm:w-[75px] md:w-[110px] border-2 border-purple-100 rounded-full p-2'
              src={Man}
              alt=''
            />
            <p>Make</p>
          </div>
          <div className='flex justify-center  gap-2  sm:gap-6 items-center text-center'>
            <p>everything</p>
            <img
              className='w-[50px] xs:w-[65px] sm:w-[75px] md:w-[110px] border-2 border-pink-100 rounded-full p-2'
              src={Woman1}
              alt=''
            />
            <p>HR</p>
          </div>
          <div className='flex justify-center  gap-2  sm:gap-6 items-center text-center'>
            <img
              className='w-[50px] xs:w-[65px] sm:w-[75px] md:w-[110px] border-2 border-yellow-100 rounded-full p-2'
              src={Woman2}
              alt=''
            />
            <p>simple for you</p>
          </div>
        </div>
      </div>

      <div
        className=' flex flex-col md:flex-row
			 items-center w-[90%] mx-auto py-24 justify-between gap-4'>
        <p className='text-white-100 font-thin w-[90%] sm:w-[70%] text-center md:text-left md:w-[30%] leading-8'>
          PeopleCenta aims to develop a Human Resource Management platform that gives MSMEs the most
          essential HR services on their smartphones.
        </p>
        <div className=' hidden -ml-32 md:inline-flex items-center text-gray-100 space-x-1'>
          <AiOutlineArrowDown />
          <p className=' uppercase'>SCROLL</p>
        </div>
        <Link to={SIGN_UP}>
          <Button text={'Get Started'} />
        </Link>
      </div>
    </div>
  );
};

export default HeaderContent;
