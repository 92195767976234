import React from 'react';
import BenefitsCard from '../../../components/landing-page/BenefitsCard';
import DashWithNumbers from '../../../components/landing-page/DashWithNumbers';
import { ReactComponent as Absence } from '../../../assets/img/landing/absence.svg';
import { ReactComponent as Compensation } from '../../../assets/img/landing/compensation.svg';

const WhyPeopleCenta = () => {
  return (
    <div className='bg-blue-300 py-24 px-12 md:px-32 relative text-white-100  '>
      <DashWithNumbers no={'05'} />
      <div className='space-y-16 w-[70%] mx-auto '>
        <div className='flex flex-col space-y-1 '>
          <span className='font-bold text-lg sm:text-2xl'>Why Use</span>
          <span className='font-bold text-lg sm:text-2xl text-pink-100'>PeopleCenta</span>
          <p className='text-base md:text-lg font-normal w-full md:w-[80%] leading-7 '>
            We provide affordable quality human resource services to businesses in Africa - on the
            go. Your productivity is our priority.
          </p>
        </div>
        <div className='space-y-6'>
          <div className='flex gap-6'>
            <BenefitsCard
              icon={<Absence />}
              title={'Professional HRM Services'}
              details={
                'Create and post job openings; track, screen, hire and onboard new employees with aid of visual dashboard, Create and post job openings; track, screen, hire and onboard new employees with aid of visual dashboard'
              }
              className=''
              isLarge
              isPurple
            />
            <div className='flex gap-6'>
              <div className='flex flex-col gap-6'>
                <BenefitsCard
                  className={''}
                  icon={<Compensation />}
                  title={'Affordable Services'}
                  details={'Create and post job openings;track, screen, hire and onboard new empty'}
                />
                <BenefitsCard
                  className={''}
                  icon={<Compensation />}
                  title={'Affordable Services'}
                  details={'Create and post job openings;track, screen, hire and onboard new empty'}
                />
              </div>
              <div className='flex flex-col gap-6'>
                <BenefitsCard
                  className={''}
                  icon={<Compensation />}
                  title={'Affordable Services'}
                  details={'Create and post job openings;track, screen, hire and onboard new empty'}
                />
                <BenefitsCard
                  className={''}
                  icon={<Compensation />}
                  title={'Affordable Services'}
                  details={'Create and post job openings;track, screen, hire and onboard new empty'}
                />
              </div>
            </div>
          </div>
          <div className='flex gap-6'>
            <BenefitsCard
              className={''}
              icon={<Compensation />}
              title={'Affordable Services'}
              details={'Create and post job openings;track, screen, hire and onboard new empty'}
            />
            <BenefitsCard
              className={'w-[250px] sm:w-[524px]'}
              icon={<Compensation />}
              title={'Affordable Services'}
              details={'Create and post job openings;track, screen, hire and onboard new empty'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyPeopleCenta;

// <div className="space-y-6">
// <div className="flex gap-6">
// 	<BenefitsCard
// 		icon={<Absence />}
// 		title={"Professional HRM Services"}
// 		details={
// 			"Create and post job openings; track, screen, hire and onboard new employees with aid of visual dashboard, Create and post job openings; track, screen, hire and onboard new employees with aid of visual dashboard"
// 		}
// 		className="w-[250px] sm:w-[350px] "
// 		isLarge
// 		isPurple
// 	/>
// 	<div className="flex gap-6">
// 		<div className="flex flex-col gap-6">
// 			<BenefitsCard
// 				className={"w-[250px]"}
// 				icon={<Compensation />}
// 				title={"Affordable Services"}
// 				details={
// 					"Create and post job openings;track, screen, hire and onboard new empty"
// 				}
// 			/>
// 			<BenefitsCard
// 				className={"w-[250px]"}
// 				icon={<Compensation />}
// 				title={"Affordable Services"}
// 				details={
// 					"Create and post job openings;track, screen, hire and onboard new empty"
// 				}
// 			/>
// 		</div>
// 		<div className="flex flex-col gap-6">
// 			<BenefitsCard
// 				className={"w-[250px]"}
// 				icon={<Compensation />}
// 				title={"Affordable Services"}
// 				details={
// 					"Create and post job openings;track, screen, hire and onboard new empty"
// 				}
// 			/>
// 			<BenefitsCard
// 				className={"w-[250px]"}
// 				icon={<Compensation />}
// 				title={"Affordable Services"}
// 				details={
// 					"Create and post job openings;track, screen, hire and onboard new empty"
// 				}
// 			/>
// 		</div>
// 	</div>
// </div>
// <div className="flex gap-6">
// 	<BenefitsCard
// 		className={"w-[250px] sm:w-[350px]"}
// 		icon={<Compensation />}
// 		title={"Affordable Services"}
// 		details={
// 			"Create and post job openings;track, screen, hire and onboard new empty"
// 		}
// 	/>
// 	<BenefitsCard
// 		className={"w-[250px] sm:w-[524px]"}
// 		icon={<Compensation />}
// 		title={"Affordable Services"}
// 		details={
// 			"Create and post job openings;track, screen, hire and onboard new empty"
// 		}
// 	/>
// </div>
// </div>
