import React, { useState, useEffect } from 'react';
import { Toast } from '../../../../components/toast/toast';
import { apiService } from '../../../../helpers/api';
import Button from '../../../../components/button';
import { FiPlusCircle } from 'react-icons/fi';
import StatusTabs from '../../../../components/base/status-tabs';
import Rates from '../payroll-settings/rates';
import Deductions from '../payroll-settings/deductions';
import TaxRelief from '../payroll-settings/tax-relief';
import OvertimeContent from '../../../compensation/features/OvertimeContent';
import AddEmployeeGradeModal from './employee-grade-modal/add-grade-modal';
import AddEmployeeGroupModal from './employee-group-modal/add-group-modal';
import AddCompetencyModal from '../competency-settings/add-competency-modal';
import EditLeaveSettingModal from '../leave-settings/leave-type-modal/edit-leave-setting-modal';
import Competencies from '../competency-settings/Competencies';
import { getCompetency } from '../../../../utils/apis/api-modules/performance';
import EmployeeGradeGroup from './EmployeeGroup';
import { fetchAllGradeById, fetchAllGradeGroupById } from '../../../../utils/apis/api-modules/employee';
//import Pagination from '../../../../components/general/pagination';

const EmployeeGroupSettings = ({ id, editEmployeeGroupInfo, editEmployeeGradeInfo }) => {
  const [loading, setLoading] = useState(false);
  const [salaryComponent, setSalaryComponent] = useState([]);
  const [competencies, setCompetencies] = useState([]);
  const [groups, setGroups] = useState(true);
  const [grades, setGrades] = useState(false);
  const [employeeGroups, setEmployeeGroups] = useState([]);
  const [employeeGrades, setEmployeeGrades] = useState([]);
  const [showEmployeeGradeModal, setShowEmployeeGradeModal] = useState(false);
  const [showEmployeeGroupModal, setShowEmployeeGroupModal] = useState(false);

  const closeModal = () => {
    setShowEmployeeGradeModal(false);
    setShowEmployeeGroupModal(false);
  };

  function handleSetGroups() {
    setGroups(true);
    setGrades(false);
  }

  function handleSetGrades() {
    setGroups(false);
    setGrades(true);
  }

  const fetchEmployeeGradeGroup = async () => {
    setLoading(true);
    const groupRes = await fetchAllGradeGroupById();
    const gradeRes = await fetchAllGradeById();
    if (groupRes) setEmployeeGroups(groupRes);
    if (gradeRes) setEmployeeGrades(gradeRes);
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployeeGradeGroup();
  }, []);

  return (
  <div className='space-y-3'>
    <h4 className='text-[#353A3F] font-bold'>Employee Grade/Group Settings</h4>
   <div className='space-y-3  bg-white-30 shadow-xl rounded-3xl p-4 md:p-10'>
      <div className='flex flex-row gap-3 justify-end'>
       <Button
         isPink
         text={'ADD GRADE'}
         className='flex justify-between items-center space-x-1 px-6'
         isPurple
         onClick={() => setShowEmployeeGradeModal(true)}
        />
        <Button
         isPink
         text={'ADD GROUP'}
         className='flex justify-between items-center space-x-1 px-6'
         isPurple
         onClick={() => setShowEmployeeGroupModal(true)}
        />
       <AddEmployeeGradeModal show={showEmployeeGradeModal} closeModal={closeModal} />
       <AddEmployeeGroupModal show={showEmployeeGroupModal} closeModal={closeModal} />
      </div>
    
      <div className='flex flex-col'>
       <div className='w-full flex flex-wrap font-semibold tracking-tight text-blue-100 uppercase text-2xs bg-purple-30 rounded-4xl px-5 py-3 gap-6 flex flex-wrap items-center justify-center text-center mx-auto md:w-[50%] lg:w-[40%]'>
          <span
            className={`${
              groups && 'text-white-100 bg-pink-100 rounded-[12.63px] px-6 py-[6px] '
            } cursor-pointer transition-all duration-700 text-center`}
            onClick={handleSetGroups}>
            Groups
          </span>
          <span
            className={`${
              grades && 'text-white-100 bg-pink-100 rounded-[12.63px] px-6 py-[6px] '
            } cursor-pointer  transition-all duration-700 text-center`}
            onClick={handleSetGrades}>
            Grades
          </span>
        </div>
        <div>
         {groups && 
           <EmployeeGradeGroup 
              loading={loading}
              employeeGroups={employeeGroups}
              editEmployeeGroupInfo={editEmployeeGroupInfo}
              id={id}
            />
          }
        </div>
        <div>
         {grades && 
           <EmployeeGradeGroup 
              loading={loading}
              employeeGrades={employeeGrades}
              editEmployeeGradeInfo={editEmployeeGradeInfo}
              id={id}
            />
          }
        </div>
     </div>
   </div>
 </div>
  );
};

export default EmployeeGroupSettings;
