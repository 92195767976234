/** @format */

import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastMessage } from './index';

export const Toast = ({ message, type, isNetworkError = false }) => {
  return (
    <div>
      {toast(<ToastMessage message={message} type={type} isNetworkError={isNetworkError} />)}
    </div>
  );
};
