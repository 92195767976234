import React from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import Button from '../../components/button';
import { HeaderCard } from '../../components/cards/Cards';
import LayoutWithSideBar from '../../components/layout/LayoutWithSideBar';
import { ADD_PEOPLE, DEPARTMENT } from '../../router/router';

const Employees = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  return (
    <LayoutWithSideBar>
      <HeaderCard
        text={'Manage Employees'}
        withButton
        titleClassName={'font-bold'}
        button={<Button text={'ADD NEW EMPLOYEE'} isPink />}
        className={''}
        link={ADD_PEOPLE}
        linkClassName={`${pathname.includes(ADD_PEOPLE) && 'hidden'}`}>
        <Link to={DEPARTMENT} className={`${pathname.includes(DEPARTMENT) && 'hidden'}`}>
          <Button text={'DEPARTMENT'} isPink />
        </Link>
        <Link to={`tracker/${id}`} className={`${!pathname.includes('user/') && 'hidden'}`}>
          <Button text={'Employee Tracker'} isPink />
        </Link>
      </HeaderCard>
      <Outlet />
    </LayoutWithSideBar>
  );
};

export default Employees;
