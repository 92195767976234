import React from 'react';
import { VerticalAlignCard } from '../cards/Cards';
import { ReactComponent as Overview } from '../../assets/img/overview.svg';
import { ReactComponent as Performance } from '../../assets/img/performance.svg';
import { ReactComponent as Hiring } from '../../assets/img/hiring.svg';
import { ReactComponent as Absence } from '../../assets/img/absence.svg';
import { ReactComponent as Employees } from '../../assets/img/employees.svg';
import { ReactComponent as Compensation } from '../../assets/img/compensation.svg';
import { ReactComponent as Request } from '../../assets/img/request.svg';
import { ReactComponent as Events } from '../../assets/img/events.svg';
import { ReactComponent as Learning } from '../../assets/img/learning.svg';
import { ReactComponent as Audit } from '../../assets/img/audit.svg';
import { ReactComponent as Reports } from '../../assets/img/reports.svg';
import { ReactComponent as Timesheet } from '../../assets/img/timesheet.svg';
import { ReactComponent as Notifications } from '../../assets/img/notifications.svg';
import { ReactComponent as HelpDesk } from '../../assets/img/helpdesk.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/setting-icon.svg';

import {
  AUDITS,
  HELPDESK,
  MANAGE_ABSENCE,
  MANAGE_COMPENSATION,
  MANAGE_EMPLOYEES,
  MANAGE_EVENTS,
  MANAGE_HIRING,
  MANAGE_PERFORMANCE,
  MANAGE_LEARNING,
  TIMESHEET,
  OVERVIEW,
  PEOPLE_RECRUIT_REPORT,
  RECRUITER,
  REPORTS,
  REQUESTS,
  SCHEDULE,
  SETTINGS,
  SHORTLIST,
  SOURCE_AND_ATTRACT
} from '../../router/router';
import { IoPersonOutline } from 'react-icons/io5';

const SideBar = ({ isPeopleCenta, isRecruit }) => {
  return (
    <div className='hidden md:block py-12 w-[8%] z-10'>
      <div className='flex flex-col gap-3'>
        {isPeopleCenta && (
          <>
            <VerticalAlignCard
              path={OVERVIEW}
              imgOnly
              text={'Overview'}
              icon={<Overview width={25} height={40} />}
              className=' bg-yellow-100'
            />
            <VerticalAlignCard
              path={MANAGE_EMPLOYEES}
              imgOnly
              icon={<Employees width={25} height={40} />}
              text='Manage Employees'
              className={'bg-blue-100'}
            />
            <VerticalAlignCard
              path={MANAGE_COMPENSATION}
              imgOnly
              icon={<Compensation width={25} height={40} />}
              text='Manage Compensation'
              className={'bg-purple-100 '}
            />
            <VerticalAlignCard
              path={MANAGE_PERFORMANCE}
              icon={<Performance width={25} height={40} />}
              text='Manage performance'
              className={'bg-pink-100'}
              imgOnly
            />
            <VerticalAlignCard
              path={MANAGE_HIRING}
              icon={<Hiring width={25} height={40} />}
              text='Manage Hiring'
              className={'bg-blue-100'}
              imgOnly
            />
            <VerticalAlignCard
              path={MANAGE_ABSENCE}
              imgOnly
              icon={<Absence width={25} height={40} />}
              text='Manage Absence'
              className={'bg-yellow-100'}
            />
            <VerticalAlignCard
              path={REQUESTS}
              imgOnly
              icon={<Request width={25} height={40} />}
              text='Requests'
              className={'bg-pink-100'}
            />
            <VerticalAlignCard
              path={MANAGE_EVENTS}
              imgOnly
              icon={<Events width={25} height={40} />}
              text='Manage Events'
              className={'bg-purple-100'}
            />
            <VerticalAlignCard
              path={MANAGE_LEARNING}
              imgOnly
              icon={<Learning width={45} height={70} />}
              text='Manage Learning'
              className={'bg-blue-100'}
            />
            <VerticalAlignCard
              path={TIMESHEET}
              imgOnly
              icon={<Timesheet width={25} height={40} />}
              text='Timesheet'
              className={'bg-white-100 text-blue-100'}
            />
            <VerticalAlignCard
              path={AUDITS}
              imgOnly
              icon={<Audit width={25} height={40} />}
              text='Audit Logs'
              className={'bg-white-100 text-blue-100'}
            />
            <VerticalAlignCard
              path={REPORTS}
              imgOnly
              icon={<Reports width={25} height={40} />}
              text='Reports'
              className={'bg-white-100 text-blue-100'}
            />
            <VerticalAlignCard
              path={HELPDESK}
              imgOnly
              icon={<HelpDesk width={25} height={40} />}
              text='HelpDesk'
              className={'bg-white-100 text-blue-100'}
            />
            <VerticalAlignCard
              path={SETTINGS}
              imgOnly
              icon={<SettingsIcon width={25} height={40} />}
              text='Settings'
              className={'bg-white-100 text-blue-100'}
            />
          </>
        )}

        {isRecruit && (
          <>
            <VerticalAlignCard
              path={SOURCE_AND_ATTRACT}
              icon={<Hiring width={25} height={40} />}
              text='Source '
              className={'bg-blue-100'}
              imgOnly
            />
            <VerticalAlignCard
              path={SHORTLIST}
              icon={<Employees width={25} height={40} />}
              text='Shortlist'
              className={'bg-yellow-100'}
              imgOnly
            />
            <VerticalAlignCard
              path={PEOPLE_RECRUIT_REPORT}
              icon={<Reports width={25} height={40} />}
              text='Report'
              className={'bg-pink-100'}
              imgOnly
            />
            <VerticalAlignCard
              path={SCHEDULE}
              icon={<Events width={25} height={40} />}
              text='Schedule'
              className={'bg-purple-100'}
              imgOnly
            />
            <VerticalAlignCard
              path={RECRUITER}
              icon={<IoPersonOutline className=' opacity-90' size={25} color='#fff' />}
              text='Recruiter'
              className={'bg-yellow-100'}
              imgOnly
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SideBar;
