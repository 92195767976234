import React from 'react';
import HelpDeskForm from './HelpDeskForm';
import HelpDeskHeader from './HelpDeskHeader';
import HelpDeskTopics from './HelpDeskTopics';

const HelpDeskLayout = () => {
  return (
    <div className="md:px-10 space-y-4">
      <HelpDeskHeader />
      <div className="flex flex-col gap-6 md:flex-row">
        <HelpDeskTopics />
        <HelpDeskForm />
      </div>
    </div>
  );
};

export default HelpDeskLayout;
