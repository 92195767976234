import React from 'react';
import { BiComment, BiCopy } from 'react-icons/bi';
import { HiOutlineVideoCamera } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { AssesmentIcon } from '../../../../assets/icons';
import { FaUserCircle } from 'react-icons/fa';
import Button from '../../../../components/button';

const ScheduleRight = () => {
  return (
    <div className="space-y-4">
      <Button
        text={'Start Meeting'}
        icon={<HiOutlineVideoCamera size={18} />}
        withIcon
        isPink
        className={'flex gap-2 text-white-100 items-center'}
      />
      <div className=" flex gap-2 items-center font-medium justify-between">
        <AssesmentIcon />
        <div className="w-full mdLg:w-[60%] flex flex-col">
          <p className="text-sm  ">Assessment Page</p>
          <Link to="" className="text-sm text-gray-600 truncate">
            https://peoplecentra.com/?125..
          </Link>
        </div>
        <BiCopy className="bg-gray-20 rounded-full font-normal p-[4px] text-gray-600" size={26} />
      </div>

      <div className=" flex gap-2 items-center font-medium justify-between">
        <AssesmentIcon />
        <div className="w-full mdLg:w-[60%] flex flex-col">
          <p className="text-sm  ">Join Zoom Meeting</p>
          <Link to="" className="text-sm text-gray-600 truncate">
            ID: 256633835
          </Link>
        </div>
        <BiCopy className="bg-gray-20 rounded-full font-normal p-[4px] text-gray-600" size={26} />
      </div>

      <div className=" flex gap-4 items-start font-medium ">
        <BiComment size={26} className="text-gray-600 font-normal" />
        <div className="w-full flex flex-col">
          <p className="text-sm font-semibold  ">Comment</p>
          <Link to="" className="text-sm text-gray-600 ">
            There are many variations of passages of Lorem Ipsum available, but the majority have
            suffered alteration in some form, by injected humour, or randomised words which don't
            look even slightly believable.
          </Link>
        </div>
      </div>

      <div className="space-y-4">
        <h5 className=" font-semibold ">Interviewer(s)</h5>
        <div className=" border-gray-20 p-4 border-[1.5px] rounded-md">
          <div className=" flex gap-4 items-start font-medium  border-b  py-3 border-b-gray-20">
            <FaUserCircle size={26} className="text-gray-600 font-normal" />
            <div className="w-full flex flex-col ">
              <p className="text-sm font-semibold  ">James Scott</p>
              <Link to="" className="text-sm text-gray-600 ">
                Senior Product Manager
              </Link>
            </div>
          </div>
          <div className=" flex gap-4 items-start font-medium  border-b  py-3 border-b-gray-20">
            <FaUserCircle size={26} className="text-gray-600 font-normal" />
            <div className="w-full flex flex-col ">
              <p className="text-sm font-semibold  ">James Scott</p>
              <Link to="" className="text-sm text-gray-600 ">
                Senior Product Manager
              </Link>
            </div>
          </div>
          <div className=" flex gap-4 items-start font-medium  border-b  py-3 border-b-gray-20">
            <FaUserCircle size={26} className="text-gray-600 font-normal" />
            <div className="w-full flex flex-col ">
              <p className="text-sm font-semibold  ">James Scott</p>
              <Link to="" className="text-sm text-gray-600 ">
                Senior Product Manager
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleRight;
