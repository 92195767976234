import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/img/logo.svg';
import authService from '../../../services/auth.service';
import Login from './Login';
import './styles.scss';
import { LOGIN } from '../../../router/landingPageRoutes';

const AuthLogin = () => {
  const emptyForm = {
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    keepSignedIn: false
  };

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(emptyForm);
  const [error, setError] = useState('');
  const [loginDetailsError, setLoginDetailsError] = useState('');
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [step, setStep] = useState(0);

  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();
  
    if (!formData.username || !formData.password) {
      setError('Email and password are required.');
      setShowValidationMessage(true);
      return;
    }
  
    // Reset the validation message state if the inputs are valid
    setShowValidationMessage(false);
  
    let payload = {
      username: formData.username,
      password: formData.password,
      keepSignedIn: formData.keepSignedIn
    };
  
    setLoading(true);
    const loginResult = await authService.login(payload, navigate);
    setLoading(false);
  
    if (!loginResult.success) {
      setLoginDetailsError('Login details are incorrect.');
    } else {
      setLoginDetailsError('');
    }
    setShowValidationMessage(true);
  };

  // const passwordReset = async (e) => {
  //   e.preventDefault();
  //   if (step === 2 && formData.password !== formData.confirmPassword)
  //     setError('passwords do not match!');
  //   setLoading(true);
  //   if (step === 1) {
  //     let payload = formData.email;
  //     await authService.forgotPassword(payload, navigate);
  //   } else if (step === 2) {
  //     let payload = {
  //       email: formData.email,
  //       newPassword: formData.password,
  //       confirmPassword: formData.confirmPassword
  //     };
  //     console.log(payload);
  //     await authService.resetPassword(payload, navigate);
  //   }
  //   setLoading(false);
  // };

  const handleChange = (e) => {
    setError('');
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  return (
    <div className=' grid md:grid-cols-2 md:h-[100vh] '>
      <div className='flex flex-col items-center justify-center'>
        <Logo className='' />
        <Login
          formData={formData}
          handleChange={handleChange}
          loading={loading}
          login={login}
          setFormData={setFormData}
          step={step}
          setStep={setStep}
          error={error}
          loginDetailsError={loginDetailsError}
          showValidationMessage={showValidationMessage}
        />
      </div>

      <div className='bg-[#fff4e2] h-[100vh]'>
        <div className='login-bg'></div>
      </div>
    </div>
  );
};

export default AuthLogin;
