import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/button';
import DashWithNumbers from '../../../components/landing-page/DashWithNumbers';
import { SIGN_UP } from '../../../router/router';

const Services = () => {
  return (
    <div className='bg-blue-200'>
      <div className=' px-5 sm:px-24 mdLg:px-12  flex justify-center py-24  text-white-100  mx-auto relative'>
        <DashWithNumbers no={'04'} />

        <div className=''>
          <div className='text-center space-y-10'>
            <div className='space-y-3 flex flex-col items-center'>
              <h2 className='font-bold text-lg sm:text-2xl'>
                Access HR Services, Anytime Anywhere
              </h2>
              <p className='font-thin text-sm text-center w[80%]  sm:w-[60%]'>
                We provide affordable quality human resource services to businesses in Africa - on
                the go. Your productivity is our priority.
              </p>
            </div>

            <div className='bg-yellow-100 p-8 py-16 rounded-xl'>
              <div className='flex flex-col gap-6 mdLg:flex-row justify-between items-center'>
                <div className='flex flex-col items-center mdLg:items-start gap-4'>
                  <h2 className='font-bold text-lg sm:text-2xl'>Startup</h2>
                  <ul className='flex  flex-col sm:flow-row gap-3 sm:gap-6'>
                    <div className='space-y-3'>
                      <li>- Feature label goes here</li>
                      <li>- Feature label goes here</li>
                      <li>- Feature label goes here</li>
                    </div>
                    <div className='space-y-3'>
                      <li>- Feature label goes here</li>
                      <li>- Feature label goes here</li>
                      <li>- Feature label goes here</li>
                    </div>
                  </ul>
                </div>

                <div className='flex flex-col gap-2'>
                  <h2 className='font-bold text-lg sm:text-2xl'>₦500/$1</h2>
                  <Link to={SIGN_UP}>
                    <Button text={'Get Started'} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white-100  font-semibold text-blue-100 flex justify-between items-center sm:flex-row flex-col py-20 px-5 xs:p-20 gap-6'>
        <p className='w-full text-center sm:text-left sm:w-[60%] md:w-[45%] text-xl'>
          Bringing you affordable, accessible, and professional human resource management
        </p>
        <Link to={SIGN_UP}>
          <Button isPurple text='Get Started' />
        </Link>
      </div>
    </div>
  );
};

export default Services;
