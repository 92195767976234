import React from 'react';
import { BiLoaderAlt } from 'react-icons/bi';
import Pagination from '../../../components/general/pagination';
import { useNavigate } from 'react-router';

const CompensationContent = ({ compensations = [], filteredCompensations = [], loading }) => {
  const navigate = useNavigate();

  // State to manage current page
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 20;

  // Determine the data to display
  const dataToDisplay = filteredCompensations.length > 0 ? filteredCompensations : compensations;

  // Calculate total pages
  const totalPages = Math.ceil(dataToDisplay.length / itemsPerPage);

  // Get data for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const currentCompensations = dataToDisplay.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className='rounded-[20px] bg-purple-30 p-10 my-6'>
      <div>
        <div className='whitespace-nowrap overflow-x-scroll'>
          <table>
            <thead>
              <tr className='uppercase tracking-[3px] text-blue-100 font-semibold text-2xs'>
                <th className='p-6 text-left w-[20%]'>Employee ID</th>
                <th className='p-6 text-center w-[25%]'>Email</th>
                <th className='p-6 text-center w-[20%]'>Department</th>
                <th className='p-6 text-center w-[20%]'>Role</th>
                <th className='p-6 text-right w-[15%]'>Compensation (₦)</th>
              </tr>
            </thead>
            <tbody className='rounded-sm'>
              {currentCompensations.length > 0 ? (
                currentCompensations.map((compensation, i) => (
                  <tr
                    className='bg-white-100 border-b-[15px] border-pink-30 cursor-pointer font-semibold rounded-[5px] pl-28 mb-4'
                    key={i}
                    onClick={() => navigate(`/manage-compensation/${compensation?.employeeId}`)}
                  >
                    <td className='p-6 text-left w-[20%]'>{compensation?.employeeNumber}</td>
                    <td className='p-6 text-center w-[25%]'>{compensation?.email}</td>
                    <td className='p-6 text-center w-[20%]'>{compensation?.department}</td>
                    <td className='p-6 text-center w-[20%]'>{compensation?.role}</td>
                    <td className='p-6 text-center w-[15%]'>{compensation?.gross}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className='text-center p-6'>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className='pb-10 flex items-center'>
            {loading && (
              <BiLoaderAlt
                className='animate-spin my-10 flex text-center text-blue-100 justify-center mx-auto'
                size={30}
              />
            )}
            {!loading && dataToDisplay.length === 0 && (
              <span className='p-3'>There are currently no compensations to show</span>
            )}
          </div>
        </div>
        <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
      </div>
    </div>
  );
};

export default CompensationContent;
