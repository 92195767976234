import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import BaseModal from '../../../../components/base/modal';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import Select from '../../../../components/input/Select';
import { Toast } from '../../../../components/toast/toast';
import { COMPONENT_TYPE, RATE_TYPE } from '../../../../helpers/constant';
import { API } from '../../../../utils/apis';
import { apiService } from '../../../../helpers/api';

const AddComponentModal = ({ show, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [compensationDetail, setCompensationDetail] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  const [modalData, setModalData] = useState({
    employeeId: id,
    rateType: 1
  });

  const fetchCompensationDetail = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(`Compensation/${id}`, {}, true);
      const response = res?.data?.data;
      setCompensationDetail(response);
      console.log(compensationDetail);
    } catch (err) {
      Toast({
        type: 'error',
        message: err.message
      });
    } finally {
      setLoading(false);
    }
  };

  const DEPENDENT_TYPE = [
    {
      label: 'None',
      value: 1
    },
    {
      label: 'Gross',
      value: 2
    },
    {
      label: 'Other Components',
      value: 3,
      children: [
        { label: 'Basic', value: '31' },
        { label: 'Training', value: '32' },
        { label: 'Housing', value: '33' },
        { label: 'Transport', value: '34' }
      ]
    }
  ];

  const renderDropdownOptions = (options) => {
    return options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
  };  

  const handleChange = (field, value) => {
    if (field === 'amount' && modalData.rateType === 1 && value > 100) {
      alert('Value cannot exceed 100%');
      return;
    }
    setModalData((prevData) => ({
      ...prevData,
      [field]: value
    }));
  };

  const submitData = async (e) => {
    setLoading(true);
    console.log(modalData);
    console.log(id);
    await API.createAdditionalComponent(modalData);
    closeModal();
    setLoading(false);
    e.preventDefault();
    window.location.reload();
  };

  return (
    <BaseModal title={'Add Component'} show={show} onClose={closeModal}>
      <div className='space-y-10 rounded-lg w-full sm:w-[400px] overflow-hidden'>
        <Input
          withLabel
          value={modalData.name || ''}
          label='Name'
          containerClass='w-full sm:w-auto'
          name='name'
          onChange={(e) => handleChange('name', e.target.value)}
        />
        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>TYPE</h6>
          <Select
            selectedOption={modalData.componentType || ''}
            className='w-full sm:w-auto'
            options={COMPONENT_TYPE}
            onChange={(e) => handleChange('componentType', e?.value)}
          />
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>DEPENDENT TYPE</h6>
          <Select
            selectedOption={modalData.dependantComponentType || ''}
            className='w-full sm:w-auto'
            options={DEPENDENT_TYPE}
            onChange={(e) =>
              handleChange('dependantComponentType', e?.value)
            }
          />
          {modalData.dependantComponentType === 3 && (
            <div className='ml-4'>
              <Select
                selectedOption={modalData.dependant || ''}
                className='w-full sm:w-auto'
                options={DEPENDENT_TYPE.find((option) => option.value === 3)?.children || []}
                onChange={(e) => handleChange('dependant', e?.value)}
              >
                {renderDropdownOptions(DEPENDENT_TYPE.find((option) => option.value === 3)?.children || [])}
              </Select>  
            </div>
          )}
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>RATE TYPE</h6>
          <Select
            selectedOption={modalData.rateType || ''}
            className='w-full sm:w-auto'
            options={RATE_TYPE}
            onChange={(e) => {
              handleChange('rateType', e?.value);
            }}
          />
        </div>
        <Input
          withLabel
          value={modalData.amount || ''}
          label={modalData.rateType === 1 ? 'Value' : 'Amount'}
          containerClass='w-full sm:w-auto'
          name='amount'
          onChange={(e) => handleChange('amount', e.target.value)}
        />
        <Input
          withLabel
          placeholder={'Number of months'}
          value={modalData.duration || ''}
          label='Duration'
          containerClass='w-full sm:w-auto'
          name='duration'
          onChange={(e) => handleChange('duration', e.target.value)}
        />
        <div className='flex gap-4 flex-wrap justify-end'>
          <Button
            className='border border-pink-100'
            text='Cancel'
            onClick={closeModal}
            isTransparent
          />
          <Button text='Submit' type='submit' onClick={submitData} isPurple />
        </div>
      </div>
    </BaseModal>
  );
};

export default AddComponentModal;
