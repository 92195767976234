import React from 'react';
import { Outlet } from 'react-router-dom';
import RecruitLayoutWithSideBar from '../../../components/layout/RecruitLayoutWithSideBar';

const Shortlist = () => {
  return (
    <RecruitLayoutWithSideBar hText={'Shortlist & Screen'}>
      <Outlet />
    </RecruitLayoutWithSideBar>
  );
};

export default Shortlist;
