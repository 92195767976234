import React from 'react';
import { BsClipboard } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/button';
import { CandidateDetailsCard } from '../../../../../components/cards/Cards';
import BackWithText from '../../../../../components/general/BackWithText';
import { CANDIDATE_SCORE, JOB_APPLICANTS, SHORTLIST } from '../../../../../router/router';
import { candidates } from '../../../../../utils/peopleRecruitArrays';

const CandidateDetails = () => {
  return (
    <div className="space-y-6">
      <div className=" bg-pink-100 py-4 flex flex-wrap gap-4 rounded-xl items-center justify-between px-4 text-white-100">
        <BackWithText text={'Candidate Details'} goBack />
        <div className="flex gap-2 flex-wrap">
          <Link to={`${SHORTLIST}/${JOB_APPLICANTS}/${CANDIDATE_SCORE}`}>
            <Button
              icon={<BsClipboard />}
              withIcon
              text={' View Score'}
              className="flex items-center gap-2"
              isFaded
            />
          </Link>
          <Link to={''}>
            <Button
              icon={<BsClipboard />}
              withIcon
              text={'Download CV'}
              className="flex items-center gap-2"
              isFaded
            />
          </Link>
        </div>
      </div>
      <div className=" bg-white-100 rounded-md p-4 md:p-9 sm:px-10  space-y-9 ">
        <CandidateDetailsCard text={'Personal details'}>
          <div className="flex flex-wrap gap-x-8 gap-y-4">
            <div className="flex flex-col items-start gap-3">
              <h6>full name</h6>
              <p>{candidates[0]?.name}</p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6> Email address</h6>
              <p>{candidates[0]?.email}</p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6>Phone number</h6>
              <p>{candidates[0]?.phoneNumber}</p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6>Position applied</h6>
              <p>{candidates[0]?.role}</p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6>Years of experience</h6>
              <p>{candidates[0]?.years_of_experiece}</p>
            </div>
          </div>
        </CandidateDetailsCard>

        <CandidateDetailsCard text={'Educational Background'}>
          <div className="flex flex-wrap gap-x-8 gap-y-4">
            <div className="flex flex-col items-start gap-3">
              <h6> Institution attended</h6>
              <p>{candidates[0]?.institution}</p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6> Course studied</h6>
              <p>{candidates[0]?.course}</p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6>DEGREE</h6>
              <p>{candidates[0]?.degree}</p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6>grade</h6>
              <p>{candidates[0]?.grade}</p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6>Year of Graduation</h6>
              <p>{candidates[0]?.year_of_grad}</p>
            </div>
          </div>
        </CandidateDetailsCard>

        <CandidateDetailsCard text={'Work Experience'}>
          <div className="flex flex-wrap gap-x-8 gap-y-4 border-b border-gray-20 pb-7">
            <div className="flex flex-col items-start gap-3">
              <h6>organization</h6>
              <p>{candidates[0]?.work_experience[0]?.org}</p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6> position held</h6>
              <p>{candidates[0]?.work_experience[0]?.role}</p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6>period of work</h6>
              <p>
                {candidates[0]?.work_experience[0]?.start_date} -{' '}
                {candidates[0]?.work_experience[0]?.end_date}
              </p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6>Duties carried out</h6>
              <p>{candidates[0]?.role}</p>
              <ul className="list-disc">
                {candidates[0]?.work_experience[0]?.duties.map((item, i) => {
                  return (
                    <li className="" key={i}>
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="flex flex-wrap gap-x-8 gap-y-4 pt-8">
            <div className="flex flex-col items-start gap-3">
              <h6>organization</h6>
              <p>{candidates[0]?.work_experience[1]?.org}</p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6> position held</h6>
              <p>{candidates[0]?.work_experience[1]?.role}</p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6>period of work</h6>
              <p>
                {candidates[0]?.work_experience[1]?.start_date} -{' '}
                {candidates[0]?.work_experience[1]?.end_date}
              </p>
            </div>
            <div className="flex flex-col items-start gap-3">
              <h6>Duties carried out</h6>
              <p>{candidates[0]?.role}</p>
              <ul className="list-disc">
                {candidates[0]?.work_experience[1]?.duties.map((item, i) => {
                  return (
                    <li className="" key={i}>
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </CandidateDetailsCard>
      </div>
    </div>
  );
};

export default CandidateDetails;
