import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Button from '../../components/button';
import { HeaderCard } from '../../components/cards/Cards';
import LayoutWithSideBar from '../../components/layout/LayoutWithSideBar';
import { ADD_VACANCY } from '../../router/router';

const Hiring = () => {
  const { pathname } = useLocation();
  return (
    <LayoutWithSideBar>
      <HeaderCard
        text={'Manage Hiring'}
        withButton
        titleClassName={'font-bold'}
        button={<Button text={'ADD NEW VACANCY'} isPink />}
        className={''}
        link={ADD_VACANCY}
        linkClassName={`${pathname.includes(ADD_VACANCY) && 'hidden'}`}
      />
      <Outlet />
    </LayoutWithSideBar>
  );
};

export default Hiring;
