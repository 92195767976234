import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Toast } from '../../../../components/toast/toast';
import { apiService } from '../../../../helpers/api';
import Button from '../../../../components/button';
import { FiPlusCircle } from 'react-icons/fi';
import StatusTabs from '../../../../components/base/status-tabs';
import Rates from '../payroll-settings/rates';
import Deductions from '../payroll-settings/deductions';
import TaxRelief from '../payroll-settings/tax-relief';
import OvertimeContent from '../../../compensation/features/OvertimeContent';
import SetObjectives from './Objectives';
import ObjectivesDetails from './ObjectivesDetails';
import { SET_OBJECTIVES, SETTINGS } from '../../../../router/router';
import AddLeaveTypeModal from '../leave-settings/leave-type-modal';
import EditLeaveSettingModal from '../leave-settings/leave-type-modal/edit-leave-setting-modal';
import LeaveContent from '../leave-settings/LeaveContent';
import { getObjective } from '../../../../utils/apis/api-modules/performance';
//import Pagination from '../../../../components/general/pagination';

const list = ['basic/allowances', 'deductions', 'tax relief']

const ObjectivesSettings = ({ id, editLeaveSettingInfo }) => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(list[0]);
  const [salaryComponent, setSalaryComponent] = useState([]);
  const [objectivesSettings, setObjectivesSettings] = useState([]);
  const [showSetObjectives, setShowSetObjectives] = useState(false);
  const navigate = useNavigate();

  const closeModal = () => {
    setShowSetObjectives(false);
  };

  const fetchObjectivesSettings = async () => {
    setLoading(true);
    const res = await getObjective();
    if (res) setObjectivesSettings(res);
    setLoading(false);
  };

  useEffect(() => {
    fetchObjectivesSettings();
  }, []);

  if (showSetObjectives) {
    return <SetObjectives />;
  }

  return (
  <div className='space-y-3'>
    <h4 className='text-[#353A3F] font-bold'>Corporate Objectives Settings</h4>
   <div className='space-y-3  bg-white-30 shadow-xl rounded-3xl p-4 md:p-10'>
     {<div className='flex flex-row justify-end'>
       <Button
         isPink
         text={'ADD OBJECTIVE'}
         className='flex justify-between items-center space-x-1 px-6'
         isPurple
         onClick={() => setShowSetObjectives(true)}
        />
        {showSetObjectives && <SetObjectives />}
       </div>
      }
     <div className='flex flex-col'>
        <ObjectivesDetails 
          loading={loading}
          objectivesSettings={objectivesSettings}
          editLeaveSettingInfo={editLeaveSettingInfo}
          id={id}
        />
     </div>
   </div>
 </div>
  );
};

export default ObjectivesSettings;
