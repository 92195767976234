import React, { Fragment, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { apiService } from '../../../helpers/api';
import Filter from '../../../components/general/filter';
import TimesheetContent from './TimesheetContent';
import { fetchAllEmployeesById } from '../../../utils/apis/api-modules/employee';
import { fetchAllTimesheet } from '../../../utils/apis/api-modules/timesheet';

const TimesheetLayout = () => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState({
    employeeName: '',
    employeeId: '',
    employeeEmail: '',
    department: '',
    role: '',
    gender: ''
  });
  const [error, setError] = useState('');
  const [timesheet, setTimesheet] = useState([]);
  const [employeesSearch, setEmployeesSearch] = useState([]);

  const fetchTimesheet = async () => {
    setLoading(true);
    const res = await fetchAllTimesheet();
    if (res) setTimesheet(res);
    setLoading(false);
  };

  useEffect(() => {
    fetchTimesheet();
  }, []);

  const getSearchResults = async (val) => {
    setLoading(true);
    try {
      const res = await apiService.get(
        'Employee/Search',
        {
          SearchParam: val
        },
        true
      );
      const response = res?.data?.data;
      if (response) setEmployeesSearch(response);
    } catch (err) {
      console.log('Error in fetching employee search results: ', err)
    }
    setLoading(false);
  };

  const debouncedSearch = useRef(debounce((val) => getSearchResults(val), 500)).current;

  const handleSearchChange = async (e) => {
    setLoading(true);
    setSearchValue(e.target.value);
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  
    if (e.target.value.length >= 2) {
      await debouncedSearch(e.target.value);
      setError('');
    } else {
      setEmployeesSearch([]);
      setError('Enter two characters or more to search');
    }
  
    setLoading(false);
  };  

  useEffect(() => {
    if (!searchValue) setError('');
  }, [searchValue]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <Fragment>
      <div className='bg-white-100 rounded-sm py-5 px-4 sm:px-12 space-y-10'>
        <div className='flex justify-end'><Filter /></div>
        <TimesheetContent
          loading={loading}
          timesheet={timesheet}
          employeesSearch={employeesSearch}
          searchValue={searchValue}
        />
      </div>
    </Fragment>
  );
};

export default TimesheetLayout;
