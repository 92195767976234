import React, { useState, useEffect } from 'react';
import { Toast } from '../../../../components/toast/toast';
import { apiService } from '../../../../helpers/api';
import Button from '../../../../components/button';
import { FiPlusCircle } from 'react-icons/fi';
import StatusTabs from '../../../../components/base/status-tabs';
import Rates from '../payroll-settings/rates';
import Deductions from '../payroll-settings/deductions';
import TaxRelief from '../payroll-settings/tax-relief';
import OvertimeContent from '../../../compensation/features/OvertimeContent';
import AddLeaveTypeModal from './leave-type-modal';
import EditLeaveSettingModal from './leave-type-modal/edit-leave-setting-modal';
import LeaveContent from './LeaveContent';
import { fetchAllLeaveSettings } from '../../../../utils/apis/api-modules/leave';
//import Pagination from '../../../../components/general/pagination';

const list = ['basic/allowances', 'deductions', 'tax relief']

const LeaveSettings = ({ id, editLeaveSettingInfo }) => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(list[0]);
  const [salaryComponent, setSalaryComponent] = useState([]);
  const [leaveSettings, setLeaveSettings] = useState([]);
  const [showLeaveSettingsModal, setShowLeaveSettingsModal] = useState(false);

  const closeModal = () => {
    setShowLeaveSettingsModal(false);
  };

  const fetchLeaveSettings = async () => {
    setLoading(true);
    const res = await fetchAllLeaveSettings();
    if (res) setLeaveSettings(res);
    setLoading(false);
  };

  useEffect(() => {
    fetchLeaveSettings();
  }, []);

  return (
  <div className='space-y-3'>
    <h4 className='text-[#353A3F] font-bold'>Absence Settings (Leave)</h4>
   <div className='space-y-3  bg-white-30 shadow-xl rounded-3xl p-4 md:p-10'>
     {<div className='flex flex-row justify-end'>
       <Button
         isPink
         text={'ADD LEAVE TYPE'}
         className='flex justify-between items-center space-x-1 px-6'
         isPurple
         onClick={() => setShowLeaveSettingsModal(true)}
        />
       <AddLeaveTypeModal show={showLeaveSettingsModal} closeModal={closeModal} />
       </div>
      }
     <div className='flex flex-col'>
        <LeaveContent 
          loading={loading}
          leaveSettings={leaveSettings}
          editLeaveSettingInfo={editLeaveSettingInfo}
          id={id}
        />
     </div>
   </div>
 </div>
  );
};

export default LeaveSettings;
