import moment from 'moment';
import React from 'react';

const Profile = ({ employee }) => {
  return (
    <div className="space-y-4">
      <h6 className="">personal</h6>
      <div className="font-semibold space-y-6">
        <div className="flex justify-between gap-4 flex-wrap ">
          <div className="flex flex-col justify-start gap-1">
            <span className="capitalize text-gray-100 text-sm"> Email</span>
            <span className="text-black-100 text-base"> {employee?.officeEmail}</span>
          </div>

          <div className="flex flex-col justify-start gap-1">
            <span className="capitalize text-gray-100 text-sm"> DOB</span>
            <span className="text-black-100 text-base">
              {moment(employee?.dob).format('Do MMM YYYY')}
            </span>
          </div>
        </div>
        <div className="flex flex-col justify-start gap-1">
          <div className='space-x-3'><span className="capitalize text-gray-100 text-sm"> Phone Number</span><span className="capitalize text-gray-100 text-sm">Alternate Phone Number</span></div>
          <p className="flex space-x-6 flex-wrap ">
            <a href={`tel:${employee?.phoneNumber}`}>{employee?.phoneNumber}</a>
            {employee?.alternatePhoneNumber && (
              <a href={`tel:${employee?.alternatePhoneNumber}`}>{employee?.alternatePhoneNumber}</a>
            )}
          </p>
        </div>
        <h6 className="">OTHERS</h6>

        <div className="space-y-6">
          <div className="flex flex-col justify-start gap-1">
            <span className="capitalize text-gray-100 text-sm"> Home Address</span>
            <span className="text-black-100 text-base"> {employee?.homeAddress}</span>
          </div>

          {employee?.employeeNextofKins?.length > 0 && (
            <div>
              <div className="flex flex-col justify-start gap-1 space-y-2">
                <span className="capitalize text-gray-100 text-sm"> Next Of Kin</span>
                {employee?.employeeNextofKins?.map((nextOfKin, i) => (
                  <div key={i}>
                    <div>
                      <span className="text-black-100 text-base"> {nextOfKin?.nextofKinName}</span>
                      <span>{nextOfKin?.relationship}</span>
                    </div>
                    <div className="flex space-x-4 items-center flex-wrap">
                      <span className="text-black-100 text-base"> {nextOfKin.phoneNumber}</span>
                      <div className="w-2 h-2 bg-purple-100 rounded-[50%]"></div>
                      <span className="text-black-100 text-base"> {nextOfKin.email}</span>
                    </div>
                    <span className="text-black-100 text-base"> {nextOfKin.address}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
