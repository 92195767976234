import React from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import SalaryComponentContent from '../../SalaryComponentContent';
import RightSalary from '../right-payroll/RightSalary';
import { useNavigate } from 'react-router';

const SalaryComponents = ({ loading, salaryComponent }) => {
  const navigate = useNavigate();
  return (
    // <div className="payroll-form-grid">
    //   <Input withLabel label="name" className="-mt-2" containerClass="payroll-name" />
    //   <Input withLabel label="email" className="-mt-2" containerClass="payroll-email" />
    //   <Input withLabel label="position" className="-mt-2" containerClass="payroll-position" />
    //   <Input withLabel label="Department" className="-mt-2" containerClass="payroll-dept" />
    //   <Input withLabel label="employee id" className="-mt-2" containerClass="payroll-employee-id" />
    //   <Button text={'add new components'} disabled className={'opacity-50 w-full h-16'} />
    // </div>
    <div>
      <div
        className='uppercase text-xs flex items-center justify-start space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer'
        onClick={() => navigate(-1)}>
        <MdOutlineArrowBack /> <span className='text-blue-100'> BACK</span>
      </div>
      <div>
        <RightSalary loading={loading} salaryComponent={salaryComponent}/>
      </div>
      
      <SalaryComponentContent loading={loading} salaryComponent={salaryComponent} />
    </div>
  );
};

export default SalaryComponents;
