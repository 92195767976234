import React from 'react';
import { Outlet } from 'react-router-dom';
import Button from '../../components/button';
import { HeaderCard } from '../../components/cards/Cards';
import LayoutWithSideBar from '../../components/layout/LayoutWithSideBar';

const Events = () => {
  return (
    <LayoutWithSideBar>
      <HeaderCard
        text={'Manage Events'}
        withButton
        titleClassName={'font-bold'}
        //button={<Button text={'ADD EVENTS'} isPink />}
        className={''}
      />
      <Outlet />
    </LayoutWithSideBar>
  );
};

export default Events;
