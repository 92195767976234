import React, { useEffect, useState } from 'react';
import { apiService } from '../../../../../helpers/api';
import PerformaceDetails from './PerformaceDetails';
import ApprovePerformanceModal from '../performance-modals/approve';
import RejectPerformanceModal from '../performance-modals/reject';

const PerformanceContent = ({ performanceSearchTerm }) => {
  const [loading, setLoading] = useState(false);
  const [performance, setPerformance] = useState(true);
  const [behavioural, setBehavioural] = useState(false);
  const [technical, setTechnical] = useState(false);
  const [performances, setPerformances] = useState([]);
  const [behaviouralObjs, setBehaviouralObjs] = useState([]);
  const [technicalObjs, setTechnicalObjs] = useState([]);
  const [showApprovePerformanceModal, setShowApprovePerformanceModal] = useState(false);
  const [showRejectPerformanceModal, setShowRejectPerformanceModal] = useState(false);
  const [activePerformanceRequest, setActivePerformanceRequest] = useState({});

  function handleSetPerformance() {
    setPerformance(true);
    setBehavioural(false);
    setTechnical(false);
  }

  function handleSetBehavioural() {
    setPerformance(false);
    setBehavioural(true);
    setTechnical(false);
  }

  function handleSetTechnical() {
    setPerformance(false);
    setBehavioural(false);
    setTechnical(true);
  }

  const fetchPerformancesByStatus = async (status) => {
    setLoading(true);
    try {
      const res = await apiService.get(
        'Performance/Search',
        {
          status: status
        },
        true
      );
      const response = res?.data?.data;
      return response;
    } catch (err) {
      console.log('Error in fetching performances: ', err)
    } finally {
      setLoading(false);
    }
  };

  const fetchAllData = async () => {
    const pending = await fetchPerformancesByStatus(0);
    const approved = await fetchPerformancesByStatus(1);
    const rejected = await fetchPerformancesByStatus(2);

    setPerformances(pending);
    setBehaviouralObjs(approved);
    setTechnicalObjs(rejected);
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <div className='bg-purple-30 rounded-3xl px-10 py-7'>
      <div className='font-semibold tracking-tight text-blue-100 uppercase text-2xs bg-white-100 rounded-4xl px-5 py-3 gap-6 flex flex-wrap items-center justify-center text-center mx-auto md:w-[50%] lg:w-[40%]'>
        <span
          className={`${
            performance && 'text-white-100 bg-pink-100 rounded-[12.63px] px-6 py-[6px] '
          } cursor-pointer  transition-all duration-700 text-center`}
          onClick={handleSetPerformance}>
          pending
        </span>
        <span
          className={`${
            behavioural && 'text-white-100 bg-pink-100 rounded-[12.63px] px-6 py-[6px] '
          } cursor-pointer transition-all duration-700 text-center`}
          onClick={handleSetBehavioural}>
          approved
        </span>
        <span
          className={`${
            technical && 'text-white-100 bg-pink-100 rounded-[12.63px] px-6 py-[6px] '
          } cursor-pointer  transition-all duration-700 text-center`}
          onClick={handleSetTechnical}>
          rejected
        </span>
      </div>

      <div>
        {
          performance && 
          <PerformaceDetails 
            performances={performanceSearchTerm || performances} 
            loading={loading} 
            setActivePerformanceRequest={setActivePerformanceRequest}
            showRejectPerformanceModal={() => setShowRejectPerformanceModal(!showRejectPerformanceModal)}
            showApprovePerformanceModal={() => setShowApprovePerformanceModal(!showApprovePerformanceModal)}
          />
        }
      </div>
      <div>
        {behavioural && <PerformaceDetails behaviouralObjs={performanceSearchTerm || behaviouralObjs} loading={loading} />}
      </div>
      <div>{technical && <PerformaceDetails technicalObjs={performanceSearchTerm || technicalObjs} loading={loading} />}</div>

      <ApprovePerformanceModal
        showApprovePerformanceModal={showApprovePerformanceModal}
        toggleModal={() => setShowApprovePerformanceModal(!showApprovePerformanceModal)}
        performances={performanceSearchTerm || performances}
        activePerformanceRequest={activePerformanceRequest}
        setPerformances={setPerformances}
        setBehaviouralObjs={setBehaviouralObjs}
        behaviouralObjs={behaviouralObjs}
      />
      <RejectPerformanceModal
        showRejectModal={showRejectPerformanceModal}
        toggleModal={() => setShowRejectPerformanceModal(!showRejectPerformanceModal)}
        performances={performanceSearchTerm || performances}
        activePerformanceRequest={activePerformanceRequest}
        setPerformances={setPerformances}
        setTechnicalObjs={setTechnicalObjs}
        technicalObjs={technicalObjs}
      />
    </div>
  );
};

export default PerformanceContent;
