import React from 'react';
import { HeaderCard, VerticalAlignCard } from '../../../components/cards/Cards';
import { IoPersonOutline } from 'react-icons/io5';
import Header from '../../../components/header';
import { ReactComponent as Hiring } from '../../../assets/img/hiring.svg';
import { ReactComponent as Employees } from '../../../assets/img/employees.svg';
import { ReactComponent as Requests } from '../../../assets/img/requests.svg';
import { ReactComponent as Events } from '../../../assets/img/events.svg';
import {
  PEOPLE_RECRUIT_REPORT,
  RECRUITER,
  SCHEDULE,
  SHORTLIST,
  SOURCE_AND_ATTRACT
} from '../../../router/router';
import './styles.css';

export const PeopleRecruitHome = () => {
  return (
    <div className=" 2xl:h-[100vh] h-auto">
      <Header isRecruit helpdesk link="/" />

      <div className="w-[87%]  mx-auto space-y-6 pb-10">
        <HeaderCard text="dashboard" titleClassName={'font-bold'} className="" isRecruit />
        <div>
          <div className="recruit-dashboard-container">
            <VerticalAlignCard
              path={SOURCE_AND_ATTRACT}
              icon={<Hiring className="w-[40px] h-[40px]" />}
              text="Source 
              & Attract"
              className={''}
              isRecruit
              containerClassName="h-[400px] sm:h-auto 2xl:h-[650px] source bg-blue-100 "
            />
            <VerticalAlignCard
              path={SHORTLIST}
              icon={<Employees className="w-[40px] h-[40px]" />}
              text="Shortlist
              & Screen"
              className={''}
              isRecruit
              containerClassName="h-[200px] sm:h-auto 2xl:h-[300px] shortlist bg-yellow-100 "
            />
            <VerticalAlignCard
              path={PEOPLE_RECRUIT_REPORT}
              icon={<Requests className="w-[40px] h-[40px]" />}
              text="Report"
              className={''}
              isRecruit
              containerClassName="h-[200px] sm:h-auto 2xl:h-[300px] recruit-report bg-pink-100 "
            />
            <VerticalAlignCard
              path={SCHEDULE}
              icon={<Events className="w-[40px] h-[40px]" />}
              text="Schedule & Engage"
              className={''}
              isRecruit
              containerClassName="h-[200px] sm:h-auto 2xl:h-[300px] schedule bg-purple-100"
            />
            <VerticalAlignCard
              path={RECRUITER}
              icon={<IoPersonOutline className=" opacity-90" size={35} color="#fff" />}
              text="Recruiter"
              className={''}
              isRecruit
              containerClassName="h-[200px] sm:h-auto 2xl:h-[300px] recruiter bg-yellow-100 "
            />
          </div>
        </div>
      </div>
    </div>
  );
};
