import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { apiService } from '../../../helpers/api';
import SearchWithFilter from '../../../components/general/SearchWithFilter';
import PerformanceContent from './edit-goals/performance-features/PerformanceContent';
import PerformanceHeader from './edit-goals/performance-features/PerformanceHeader';

const PerformanceLayout = () => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState({
    employeeName: '',
  });
  const [error, setError] = useState('');
  const [performanceSearchTerm, setPerformanceSearchTerm] = useState(false);

  const getSearchResults = async (val) => {
    setLoading(true);
    try {
      const res = await apiService.get(
        'Performance/Search',
        {
          SearchParam: val
        },
        true
      );
      const response = res?.data?.data;
      setPerformanceSearchTerm(response);
    } catch (err) {
      console.log('Error in fetching performance search results: ', err)
    }
    setLoading(false);
  };

  const debouncedSearch = useRef(debounce((val) => getSearchResults(val), 500)).current;

  const handleSearchChange = async (e) => {
    setLoading(true);
    setSearchValue(e.target.value);
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  
    if (e.target.value.length >= 2) {
      await debouncedSearch(e.target.value);
      setError('');
    } else {
      setPerformanceSearchTerm([]);
      setError('Enter two characters or more to search');
    }
  
    setLoading(false);
  };  

  useEffect(() => {
    if (!searchValue) setError('');
  }, [searchValue]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <div className='px-6 space-y-6'>
      <PerformanceHeader />
      <div className='space-y-6'>
        <div className='space-y-4'>
          <SearchWithFilter
            placeholder='Search by employee ID, department'
            className='-mt-3'
            isPink
            onChange={handleSearchChange}
            value={searchData.employeeName}
            name='employeeName'
          />
        </div>
        <PerformanceContent performanceSearchTerm={performanceSearchTerm} />
      </div>
    </div>
  );
};

export default PerformanceLayout;
