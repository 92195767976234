import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReportsCard from '../../components/cards/ReportsCard';
import { apiService } from '../../helpers/api';
import { LEAVE_REPORT, EMPLOYEE_REPORT, COMPENSATION_REPORT, PERFORMANCE_REPORT, REPORTS } from '../../router/router';
import LeaveReport from './features/leave';
import EmployeeReport from './features/employment-info';
import CompensationReport from './features/compensation';
import PerformanceReport from './features/performance-mgt';

const ReportHeader = () => {
  const { pathname } = useLocation();
  const [requestCount, setRequestCount] = useState(0);
  const [selectedReport, setSelectedReport] = useState(LEAVE_REPORT); // Set default report to 'LEAVE'
  const navigate = useNavigate();

  const getRequestCount = async () => {
    try {
      const res = await apiService.get(
        'Leave/Request/Count?Days=30',
        {
          approvalStatus: 0
        },
        true
      );
      const response = res?.data?.data;
      setRequestCount(response);
    } catch (err) {
      console.log('Error in fetching request count: ', err)
    }
  };

  useEffect(() => {
    getRequestCount();
  }, []);

  const handleReportCardClick = (reportType) => {
    setSelectedReport(reportType);
    navigate(REPORTS);
  };

  return (
    <div className='w-full'>
      <div
        className={`flex flex-wrap gap-4 mx-auto xs:w-[80%] sm:w-[60%] md:w-full md:mx-0 md:flex-wrap md:flex-row ${
          pathname.includes() && 'hidden'
        }`}>
        <ReportsCard
          value={'LEAVE'}
          isPerformance
          className='w-full md:w-[40%] mdLg:w-[30%] xl:w-[25%] cursor-pointer'
          onClick={() => handleReportCardClick(LEAVE_REPORT)}
        />
        <ReportsCard
          isYellow
          value={'COMPENSATION'}
          isPerformance
          className='w-full md:w-[40%] mdLg:w-[30%] xl:w-[25%] cursor-pointer'
          onClick={() => handleReportCardClick(COMPENSATION_REPORT)}
        />
        <ReportsCard
          isPink
          value={'PERFORMANCE'}
          isPerformance
          className='w-full md:w-[40%] mdLg:w-[30%] xl:w-[25%] cursor-pointer'
          onClick={() => handleReportCardClick(PERFORMANCE_REPORT)}
        />
        <ReportsCard
          isBlue
          value={'EMPLOYEES'}
          isPerformance
          className='w-full md:w-[40%] mdLg:w-[30%] xl:w-[25%] cursor-pointer'
          onClick={() => handleReportCardClick(EMPLOYEE_REPORT)}
        />
      </div>
      {selectedReport === LEAVE_REPORT && <LeaveReport />}
      {selectedReport === EMPLOYEE_REPORT && <EmployeeReport />}
      {selectedReport === COMPENSATION_REPORT && <CompensationReport />}
      {selectedReport === PERFORMANCE_REPORT && <PerformanceReport />}
    </div>
  );
};

export default ReportHeader;
