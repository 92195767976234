import React, { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiLoaderAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import StatusCard from '../../../../components/cards/StatusCard';
import Pagination from '../../../../components/general/pagination';
import EditLeaveSettingModal from '../leave-settings/leave-type-modal/edit-leave-setting-modal';
import DeleteLeaveSettingModal from '../leave-settings/leave-type-modal/delete-leave-setting-modal';

const Competencies = ({ id, loading, competencies = [], behaviouralCompetencies = [], technicalCompetencies = [] }) => {
  const navigate = useNavigate();
  const [showEditCompetencySettingModal, setShowEditCompetencySettingModal] = useState(false);
  const [showDeleteCompetencySettingModal, setShowDeleteCompetencySettingModal] = useState(false);
  const [editCompetencySettingInfo, setEditCompetencySettingInfo] = useState(null);
  const [deleteCompetencySettingInfo, setDeleteCompetencySettingInfo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCompetencies = Array.isArray(competencies)
    ? competencies.slice(indexOfFirstItem, indexOfLastItem)
    : []
  ;
  const currentBehaviouralCompetencies = Array.isArray(behaviouralCompetencies)
    ? behaviouralCompetencies.slice(indexOfFirstItem, indexOfLastItem)
    : []
  ;
  const currentTechnicalCompetencies = Array.isArray(technicalCompetencies)
    ? technicalCompetencies.slice(indexOfFirstItem, indexOfLastItem)
    : []
  ;

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const closeModal = () => {
    setShowEditCompetencySettingModal(false);
    setShowDeleteCompetencySettingModal(false);

  };

  return (
    <div className='rounded-[20px] bg-purple-30 p-10 my-6'>
      <div>
        <div className='whitespace-nowrap overflow-x-scroll'>
          <table className='w-full'>
            <thead className='border-collapse border-red-500'>
              <tr className='w-[100%] text-left uppercase tracking-[3px] text-blue-100 font-semibold text-2xs pl-28 mb-4'>
                <th className='p-6'>#</th>
                <th className='p-6 text-center'>COMPETENCY</th>
                <th className='p-6 text-center'>DESCRIPTION</th>
                <th className='p-3 px-6'>ACTION</th>
              </tr>
            </thead>
            <tbody className='rounded-sm'>
              {currentCompetencies.map((competencies, i) => (
                <tr
                  className='cursor-pointer border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                  key={competencies?.id}
                  onClick={() => navigate(``)}
                >
                  <td className='p-3 px-6 '>{i + 1}.</td>
                  <td className='p-3 px-6 text-center'>{competencies.name !== null ? competencies.name : 'nil'}</td>
                  <td className='p-3 px-6 text-center'>
                   {competencies.description !== null ? competencies.description : 'nil'}
                  </td>
                  
                  <td className='p-3 px-9 justify-between gap-5'>
                   <FiEdit
                      className='cursor-pointer hover:bg-pink-30 transition-all'
                      onClick={() => {
                        setShowEditCompetencySettingModal(true);
                        setEditCompetencySettingInfo(competencies);
                      }}
                    />
                    <RiDeleteBinLine
                      className='cursor-pointer hover:bg-pink-30 transition-all'
                      onClick={() => {
                        setShowDeleteCompetencySettingModal(true);
                        setDeleteCompetencySettingInfo(competencies.value);
                      }}
                    />
                  </td>
                </tr>
              ))}

             {currentBehaviouralCompetencies.map((competencies, i) => (
                <tr
                  className='cursor-pointer border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                  key={competencies?.id}
                  onClick={() => navigate(``)}
                >
                  <td className='p-3 px-6 '>{i + 1}.</td>
                  <td className='p-3 px-6 text-center'>{competencies.name !== null ? competencies.name : 'nil'}</td>
                  <td className='p-3 px-6 text-center'>
                   {competencies.description !== null ? competencies.description : 'nil'}
                  </td>
                  
                  <td className='p-3 px-9 justify-between gap-5'>
                   <FiEdit
                      className='cursor-pointer hover:bg-pink-30 transition-all'
                      onClick={() => {
                        setShowEditCompetencySettingModal(true);
                        setEditCompetencySettingInfo(competencies);
                      }}
                    />
                    <RiDeleteBinLine
                      className='cursor-pointer hover:bg-pink-30 transition-all'
                      onClick={() => {
                        setShowDeleteCompetencySettingModal(true);
                        setDeleteCompetencySettingInfo(competencies.value);
                      }}
                    />
                  </td>
                </tr>
              ))}

             {currentTechnicalCompetencies.map((competencies, i) => (
                <tr
                  className='cursor-pointer border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                  key={competencies?.id}
                  onClick={() => navigate(``)}
                >
                  <td className='p-3 px-6 '>{i + 1}.</td>
                  <td className='p-3 px-6 text-center'>{competencies.name !== null ? competencies.name : 'nil'}</td>
                  <td className='p-3 px-6 text-center'>
                   {competencies.description !== null ? competencies.description : 'nil'}
                  </td>
                  
                  <td className='p-3 px-9 justify-between gap-5'>
                   <FiEdit
                      className='cursor-pointer hover:bg-pink-30 transition-all'
                      onClick={() => {
                        setShowEditCompetencySettingModal(true);
                        setEditCompetencySettingInfo(competencies);
                      }}
                    />
                    <RiDeleteBinLine
                      className='cursor-pointer hover:bg-pink-30 transition-all'
                      onClick={() => {
                        setShowDeleteCompetencySettingModal(true);
                        setDeleteCompetencySettingInfo(competencies.value);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='pb-10 flex flex-col items-center'>
            {loading && (
              <BiLoaderAlt
                className='animate-spin my-10 flex text-center text-blue-100 justify-center mx-auto'
                size={30}
              />
            )}
            {!loading && behaviouralCompetencies.length === 0 && technicalCompetencies.length === 0 && (
              <span className='p-3'>There are currently no competency settings to show</span>
            )}
          </div>
        </div>
      </div>
      <Pagination
        totalPages={Math.ceil(competencies.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

     <EditLeaveSettingModal
        show={showEditCompetencySettingModal}
        editCompetencySettingInfo={editCompetencySettingInfo}
        closeModal={closeModal}
        id={id}
      />

     <DeleteLeaveSettingModal
        showDeleteCompetencySettingModal={showDeleteCompetencySettingModal}
        deleteCompetencySettingInfo={deleteCompetencySettingInfo}
        closeModal={closeModal}
      />
    </div>
  );
};

export default Competencies;
