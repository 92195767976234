import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Button from '../../../../components/button';
import { apiService } from '../../../../helpers/api';
import ApplicationCards from '../../../../components/cards/people_recruit/ApplicationCards';
import SearchWithFilter from '../../../../components/general/SearchWithFilter';
import { getEmployeeType, getJobType, getJobStatus } from '../../../../helpers/functions';
import { CREATE_JOB, JOB_CATEGORIES, JOB_DETAILS } from '../../../../router/router';
import { format, isThisWeek, isThisMonth, parseISO } from 'date-fns';

const SourceLayout = () => {
  const [loading, setLoading] = useState(false);
  const [vacancyDetails, setVacancyDetails] = useState([]);
  const { id } = useParams();
  
  const fetchVacancyDetails = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        `JobVacancy/FetchAllJobVacancyByCompanyId`,
        { vacancyId: id },
        true
      );
      const response = res?.data?.data?.data;
      setVacancyDetails(response || []); // Ensure response is an array or empty
    } catch (err) {
      console.log('Error in fetching vacancy details: ', err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVacancyDetails();
  }, []);

  const jobType = getJobType(vacancyDetails?.jobType)
    ? getEmployeeType(vacancyDetails?.jobType)
    : vacancyDetails?.jobType
  ;

  const jobStatus = getJobStatus(vacancyDetails?.jobStatus);

  // Filter jobs for this week and this month based on `dateCreated`
  const thisWeekJobs = vacancyDetails.filter(job => isThisWeek(parseISO(job.dateCreated)));
  const thisMonthJobs = vacancyDetails.filter(job => isThisMonth(parseISO(job.dateCreated)) && isThisWeek(parseISO(job.dateCreated)));

  const selectedJob = thisWeekJobs.length > 0 ? thisWeekJobs[0] : null;

  return (
    <div className="space-y-10">
      <div className=" bg-pink-100 py-4 flex gap-4 flex-wrap rounded-xl items-center justify-between px-4 ">
        <div className="flex flex-wrap  gap-2 items-center ">
          <Link to={CREATE_JOB}>
            <Button isFaded text={' + Create a Job '} type="button" />
          </Link>
          <Link to={JOB_CATEGORIES}>
            <Button isFaded text={'Job Categories'} type="button" />
          </Link>
        </div>

        <SearchWithFilter
          className={'text-white-100'}
          height="h-10"
          placeholder={'Search created jobs'}
          isRecruit
        />
      </div>

      <div className=" bg-white-100 rounded-md p-4 sm:px-10 space-y-6">
        {/* Jobs Created This Week */}
        <div className="space-y-4">
          <h6 className="text-black-100">This Week</h6>
          <div className="flex justify-start gap-4 items-center flex-wrap">
            {thisWeekJobs.length > 0 ? (
              thisWeekJobs.map((job) => (
                <ApplicationCards
                  key={job.vacancyId}
                  role={job.jobTitle}
                  company="Revohub Technologies" // Assuming this is the company name or you can use job.companyId if available
                  location={'Lagos'} // You can add job location if provided in the data
                  status={jobType}
                  dept={job.department}
                  vacancyId={job.id}
                  //link={JOB_DETAILS}
                />
              ))
            ) : (
              <p>No jobs posted this week.</p>
            )}
          </div>
        </div>

        {/* Jobs Created This Month */}
        <div className="space-y-4">
          <h6 className="text-black-100">This Month</h6>
          <div className="flex justify-start gap-4 items-center flex-wrap">
            {thisMonthJobs.length > 0 ? (
              thisMonthJobs.map((job) => (
                <ApplicationCards
                  key={job.vacancyId}
                  role={job.jobTitle}
                  company="Revohub Technologies"
                  location={'Lagos'} // Assuming Lagos, but this can be dynamic based on your data
                  status={jobType}
                  dept={job.department}
                  vacancyId={job.id}
                  //link={JOB_DETAILS}
                />
              ))
            ) : (
              <p>No jobs posted this month.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourceLayout;
