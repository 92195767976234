import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StatusCard from '../../../components/cards/StatusCard';
import { getLeaveType } from '../../../helpers/functions';
import Pagination from '../../../components/general/pagination';

const Pending = ({
  pendingLeaveRequests = [],
  showRejectModal,
  showApproveModal,
  setActiveLeaveRequest,
  itemsPerPage = 20
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const validPendingLeaveRequests = Array.isArray(pendingLeaveRequests) ? pendingLeaveRequests : [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = validPendingLeaveRequests.slice(indexOfFirstItem);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {currentItems.length === 0 ? (
        <tr className='cursor-pointer  mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'>
          <td className=''>No Leave Requests To Show</td>
        </tr>
      ) : (
        currentItems.map((leaveRequest) => {
          return (
            <tr
              className='border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize '
              key={leaveRequest?.id}>
              <td
                className='p-3 px-6 underline text-blue-100 cursor-pointer'
                onClick={() => navigate(`/manage-absence/${leaveRequest?.leaveId}`)}>
                VA-{leaveRequest?.employeeId}
              </td>
              <td className='p-3 px-6 '>{leaveRequest?.name}</td>
              <td className='p-3 px-6'>{leaveRequest?.department}</td>
              <td className='p-3 px-6'>{getLeaveType(leaveRequest?.leaveType)}</td>
              <td className='p-3 px-6 lowercase'>{leaveRequest?.duration} days</td>
              <td className='p-3 px-6 '>
                <StatusCard
                  pendingApproval
                  reject
                  onClick={() => {
                    showRejectModal();
                    setActiveLeaveRequest(leaveRequest);
                  }}
                />
              </td>
              <td className='p-3 px-6 '>
                <StatusCard
                  pendingApproval
                  approve
                  onClick={() => {
                    showApproveModal();
                    setActiveLeaveRequest(leaveRequest);
                  }}
                />
              </td>
            </tr>
          );
        })
      )}
      <div className='w-full flex justify-end'>
        <Pagination
          totalPages={Math.ceil(validPendingLeaveRequests.length / itemsPerPage)}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
};

export default Pending;
