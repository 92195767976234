import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FiCopy, FiShare2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/button';
import { apiService } from '../../../../../helpers/api';
import BackWithText from '../../../../../components/general/BackWithText';
import SearchWithFilter from '../../../../../components/general/SearchWithFilter';

const JobCategories = () => {
  const [loading, setLoading] = useState(false);
  const [vacancyDetails, setVacancyDetails] = useState([]); // Initialize as an empty array
  const { id } = useParams();
  
  const fetchVacancyDetails = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        `JobVacancy/FetchAllJobVacancy`,
        { vacancyId: id },
        true
      );
      const response = res?.data?.data?.data;
      setVacancyDetails(response || []); // Ensure response is an array or empty
    } catch (err) {
      console.log('Error in fetching vacancy details: ', err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVacancyDetails();
  }, []);

  return (
    <div className="space-y-10">
      <div className=" bg-pink-100 py-4 flex rounded-xl items-center justify-between px-4 ">
        <BackWithText text={'Create Job Opening'} goBack />
        <SearchWithFilter
          className={'text-white-100'}
          height="h-10"
          placeholder={'Search created jobs'}
          isRecruit
        />
      </div>

      <div className=" bg-white-100 rounded-md p-4 sm:px-10  space-y-6">
        <div className="flex items-center flex-wrap justify-between">
          <h4 className="text-black-100">All Posted Jobs</h4>

          <p className="text-gray-100 rounded-[5px] p-2 text-sm  border border-gray-200">
            View Most Recent{' '}
          </p>
          <div className="flex gap-2 flex-wrap">
            <Button isGray text={'Print'} />
            <Button isGray text={'Publish'} />
          </div>
        </div>
        <div className="w-full overflow-scroll whitespace-nowrap no-scroll-bar ">
          <table className="w-full  border-spacing-2  table-auto">
            <thead className="bg-pink-40 border-collapse border-red-500  ">
              <tr className="">
                <th className="p-3 ">S/N</th>
                <th className="p-3 ">Company Hiring</th>
                <th className="p-3 ">Position Title</th>
                <th className="p-3 ">Link to Published Jobs</th>
                <th className="p-3 ">Copy</th>
                <th className="p-3 ">Share</th>
              </tr>
            </thead>

            <tbody className="bg-pink-40 bg-opacity-40 font-medium">
             {vacancyDetails.length > 0 ? (
                vacancyDetails.map((jobVacancy, i) => (
                  <tr className="text-center"
                   key={jobVacancy?.vacancyId}>
                    <td className="p-3">{i + 1}.</td>
                    <td className="p-3">The Lucranium Ltd.</td>
                    <td className="p-3">{jobVacancy?.jobTitle}</td>
                    <td className="p-3 text-pink-100 cursor-pointer">
                      <Link to="/source-and-attract/job-categories/:id">
                        https://linkstojobcareerpages{' '}
                      </Link>
                    </td>
                    <td className="p-3">
                      <FiCopy className="mx-auto text-gray-100" />{' '}
                    </td>
                    <td className="p-3">
                      <FiShare2 className="mx-auto text-gray-100" />{' '}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='6' className='p-3 text-center'>
                    No vacancies found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default JobCategories;
