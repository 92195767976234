import React from 'react';
import { BiCircle } from 'react-icons/bi';
const Button = ({
  withIcon,
  icon,
  icons,
  text,
  onClick,
  isPink,
  isGreen,
  isPurple,
  isYellow,
  className,
  loading,
  type = 'button',
  disabled,
  isTransparent,
  isFaded,
  isGray,
  isOrange,
  isRecruit
}) => {
  return (
    <button
      className={`w-fit px-8 py-2 h-11 rounded-[5px]  flex gap-2 justify-center items-center text-white-100 focus:outline-none font-bold text-2xs  tracking-[3px] uppercase transition-all duration-200 cursor-pointer disabled:bg-opacity-50 disabled:cursor-not-allowed ${
        isPink
          ? 'hover:bg-opacity-80  focus:bg-opacity-80 bg-pink-100 '
          : isPurple
          ? 'hover:bg-opacity-80 bg-purple-100 focus:bg-opacity-80'
          : isYellow
          ? 'hover:bg-opacity-80 focus:bg-opacity-80 bg-yellow-200 text-blue-200'
          : isGreen
          ? 'hover:bg-opacity-90 focus:bg-opacity-90 bg-green-100 '
          : isTransparent
          ? `bg-none  hover:bg-pink-100 hover:text-white-100 focus:border-opacity-80 ${
              isRecruit ? 'border border-gray-20 text-white-100' : 'text-blue-100'
            } `
          : isFaded
          ? 'bg-white-30 border-[1px] border-opacity-70  text-white-100 hover:bg-pink-100 hover:shadow-lg hover:text-white-100 focus:border-opacity-80'
          : isOrange
          ? 'bg-orange-100  text-white-100 hover:bg-opacity-60 hover:shadow-lg hover:text-black-100 focus:border-opacity-80'
          : isGray
          ? 'bg-gray-50  border-opacity-70  text-black-100 hover:bg-opacity-60 hover:shadow-lg hover:text-black-100 focus:border-opacity-80'
          : 'bg-white-100 text-blue-100 hover:bg-purple-30 hover:bg-opacity-10    focus:bg-opacity-80 '
      } ${className}`}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}>
      {withIcon && <span className='mr_10'>{icon}</span>}
      {loading && (
        <span className=''>
          <BiCircle size={16} className='mx-auto animate-spin' />
        </span>
      )}
      <div className='ml_10'>{text}</div>
      {withIcon && <span className='mr_10'>{icons}</span>}
    </button>
  );
};

export default Button;
