import React from 'react';
import { useState } from 'react';
import RecruitReportLeft from './RecruitReportLeft';
import RecruitReportRight from './RecruitReportRight';
import RecruitReportJobs from './RecruitReportJobs';
import RecruitReportClosed from './RecruitReportClosed';
import RecruitReportSuccess from './RecruitReportSuccess';
import RecruitReportApp from './RecruitReportApp';

const RecruitReportLayout = () => {
  const [active, setActive] = useState('report');
  function onJobsClick() {
    setActive('jobs');
  }

  function onAppliClick() {
    setActive('app');
  }

  function onClosedClick() {
    setActive('closed');
  }

  function onSuccessClick() {
    setActive('success');
  }
  return (
    <div className="space-y-5">
      <div className=" bg-pink-100 py-4 flex rounded-xl items-center justify-between px-4 text-white-100">
        <p className="font-medium">Report</p>
      </div>
      <div className=" flex justify-between flex-wrap gap-4 my-2 bg-white-100 rounded-md p-4 sm:px-10  ">
        <div className=" px-4 sm:px-6 py-4   space-y-6  w-full mdLg:w-[72%] ">
          {active === 'report' && (
            <RecruitReportLeft
              onJobsClick={onJobsClick}
              onAppliClick={onAppliClick}
              onClosedClick={onClosedClick}
              onSuccessClick={onSuccessClick}
            />
          )}
          {active === 'jobs' && (
            <RecruitReportJobs
              onJobsClick={onJobsClick}
              onAppliClick={onAppliClick}
              onClosedClick={onClosedClick}
              onSuccessClick={onSuccessClick}
            />
          )}
          {active === 'closed' && (
            <RecruitReportClosed
              onJobsClick={onJobsClick}
              onAppliClick={onAppliClick}
              onClosedClick={onClosedClick}
              onSuccessClick={onSuccessClick}
            />
          )}
          {active === 'success' && (
            <RecruitReportSuccess
              onJobsClick={onJobsClick}
              onAppliClick={onAppliClick}
              onClosedClick={onClosedClick}
              onSuccessClick={onSuccessClick}
            />
          )}

          {active === 'app' && (
            <RecruitReportApp
              onJobsClick={onJobsClick}
              onAppliClick={onAppliClick}
              onClosedClick={onClosedClick}
              onSuccessClick={onSuccessClick}
            />
          )}
        </div>

        <div className=" px-2 py-4  space-y-6  w-full mdLg:w-[26%]">
          <RecruitReportRight />
        </div>
      </div>
    </div>
  );
};

export default RecruitReportLayout;
