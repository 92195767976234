import React from 'react';
import { BiSearch } from 'react-icons/bi';
import Input from '../input';
import Filter from './filter';

const SearchWithFilter = ({
  isPink,
  placeholder,
  className,
  height,
  isRecruit,
  onChange,
  value,
  name,
  menuList
}) => {
  return (
    <div
      className={`flex justify-center md:justify-between items-center flex-wrap gap-4 ${className}`}
    >
      <Input
        isRounded
        icon={<BiSearch size={20} color='#FF365E' />}
        placeholder={placeholder}
        containerClass={`${
          isRecruit ? 'w-full sm:w-[50%] md:w-[60%]' : 'w-full sm:w-[60%] md:w-[40%] '
        }`}
        pl='pl-[3rem]'
        isPink={isPink}
        withIcon
        height={height}
        onChange={onChange}
        value={value}
        name={name}
      />
      <Filter isRecruit={isRecruit} menuList={menuList} />
    </div>
  );
};

export default SearchWithFilter;
