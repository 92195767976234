import React, { useState } from 'react';
import SettingsSideBar from './settings-side-bar';
import { MdTimeToLeave, MdOutlinePayment } from 'react-icons/md';
import { GrInsecure, GrGroup } from 'react-icons/gr';
import { CgPerformance, CgBulb, CgArrowTopRightR } from 'react-icons/cg';
import PerformanceSettings from './performance-settings';
import PayrollSettings from './payroll-settings';
import PrivacySettings from './privacy-settings';
import LeaveSettings from './leave-settings';
import ObjectivesSettings from './set-objectives';
import SetObjectives from './set-objectives/Objectives';
import Competencies from './competency-settings';
import EmployeeGroupSettings from './employee-group-settings';

const SettingsLayout = () => {
  const [active, setActive] = useState('leave');
  const SETTINGS_ARRAY = [
    {
      name: 'Absence Settings',
      icon: <MdTimeToLeave />,
      onClick: () => setActive('leave')
    },
    {
      name: 'Employee Group Settings',
      icon: <GrGroup />,
      onClick: () => setActive('group')
    },
    {
      name: 'Performance Settings',
      icon: <CgPerformance />,
      onClick: () => setActive('performance')
    },
    {
      name: 'Compensation Settings',
      icon: <MdOutlinePayment />,
      onClick: () => setActive('payroll')
    },
    {
      name: 'Corporate Objectives Settings',
      icon: <CgArrowTopRightR />,
      onClick: () => setActive('objectives')
    },
    {
      name: 'Competency Settings',
      icon: <CgBulb />,
      onClick: () => setActive('competencies')
    },
    {
      name: 'Privacy & Security',
      icon: <GrInsecure />,
      onClick: () => setActive('privacy')
    }
  ];
  return (
    <div className='flex gap-5 justify-between'>
      <SettingsSideBar SETTINGS_ARRAY={SETTINGS_ARRAY} />
      <div className='bg-white-100 rounded-lg w-[75%] p-8'>
        {active === 'performance' ? (
          <PerformanceSettings />
        ) : active === 'payroll' ? (
          <PayrollSettings />
        ) : active === 'privacy' ? (
          <PrivacySettings />
        ) : (
          active === 'leave' && <LeaveSettings />
        )}
        {active === 'group' && <EmployeeGroupSettings />}
        {active === 'objectives' && <ObjectivesSettings />}
        {active === 'competencies' && <Competencies />}
      </div>
    </div>
  );
};

export default SettingsLayout;
