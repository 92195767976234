import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { apiService } from '../../../helpers/api';
import Pagination from '../../../components/general/pagination';
import StatusCard from '../../../components/cards/StatusCard';
import Display from '../../../assets/img/user.png';

const Requests = () => {
  const { id } = useParams();
  const [request, setRequest] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  const formattedStartDate = `${day}-${month}-${year}`;
  const formattedEndDate = `31-12-${year}`;

  const fetchAllRequests = async (SearchParam, StartDate, Enddate) => {
    try {
      const res = await apiService.get(
        'Request/Request/Search',
        {
          SearchParam: '',
          StartDate: formattedStartDate,
          Enddate: formattedEndDate
        },
        true
      );
      const pendingRequests = res?.data?.data || []; // Ensure the response is an array
      console.log('Pending Requests: ', pendingRequests);
      setRequest(pendingRequests);
    } catch (err) {
      console.log('Error in fetching pending requests: ', err)
    }
  };

  useEffect(() => {
    fetchAllRequests();
  }, []);

  const totalItems = request.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div className='pink-bg mx-6 my-6 space-y-3'>
      {Array.isArray(request) && request.length === 0 ? (
        <div className="text-center">There are no requests to show.</div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
            {Array.isArray(request) && request.length > 0 &&
              request.slice(startIndex, endIndex).map((item, index) => (
                <div
                  key={index}
                  //onClick={() => navigate(`/manage-absence/${item?.leaveId}`)}
                  className="flex flex-col bg-white-100 w-90 h-40 rounded-lg overflow-hidden space-y-5 space-x-5"
                >
                  <div className='flex flex-col space-y-2 mr-3.5'>
                    <div className='flex flex-wrap items-center place-content-between'>
                      <h3 className='text-purple-100 text-sm font-bold ml-3.5 mr-2.5 mt-3 mb-2 capitalize'>{item.requestType} Request</h3>
                      <StatusCard isOpen />
                    </div>
                    <div className='flex flex-wrap gap-1'>
                      <p className="text-purple-900 text-xs ml-3.5 mr-2.5 mx-px my-px">
                        {item.description}
                      </p>
                    </div>
                  </div>
                  <div className="w-90 flex flex-row gap-3 items-center">
                    <img
                      src={Display}
                      alt="Card Image"
                      className="object-cover h-9 w-9 rounded-full"
                    />
                    <p className="text-gray-700 text-xs font-bold capitalize">{item.name}</p>
                  </div>
                </div>
              ))}
          </div>
          <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
        </>
      )}
    </div>
  );
};

export default Requests;
