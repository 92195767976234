import React from 'react';
import { IoBriefcaseOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/button';

const JobCategoryDetailsLeft = ({ onClick }) => {
  return (
    <div className=" space-y-4">
      <div className=" w-full  bg-white-100">
        <div className="bg-white-100 border-b border-gray-20">
          <div className="flex items-center gap-4 text-white-100  w-full py-4 px-6  ">
            <IoBriefcaseOutline className="p-3 w-[50px] h-[50px] bg-gray-600 rounded-full " />

            <div className="flex flex-col text-gray-200 font-medium">
              <p className="text-black-100  text-sm md:text-base">Product Manager</p>
              <p>Revohub Technologies</p>
              <p> Lagos | Full Time | 150,000 </p>
              <p>Department: Information Technology</p>
            </div>
          </div>
        </div>
        <p className="ml-auto flex justify-end p-2">2 weeks ago</p>
      </div>
      <div className=" space-y-[1px]">
        <div className=" bg-white-100 p-4 sm:px-10  space-y-6 text-gray-100 font-semibold">
          Job Description
        </div>
        <div className=" bg-white-100 p-4 sm:px-10  space-y-6 text-gray-100 ">
          <p> The Product Manager is expected to :</p>

          <ul className="list-disc">
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>

            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>

            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
          </ul>
        </div>
      </div>

      <div className=" space-y-[1px]">
        <div className=" bg-white-100 p-4 sm:px-10  space-y-6 text-gray-100 font-semibold">
          Requirements
        </div>
        <div className=" bg-white-100 p-4 sm:px-10  space-y-6 text-gray-100 ">
          <ul className="list-disc">
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>

            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>

            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
          </ul>
        </div>
      </div>

      <div className=" space-y-[1px]">
        <div className=" bg-white-100 p-4 sm:px-10  space-y-6 text-gray-100 font-semibold">
          Benefits
        </div>
        <div className=" bg-white-100 p-4 sm:px-10  space-y-6 text-gray-100 ">
          <ul className="list-disc">
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>

            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
            <li>
              Develop all customer wireframes and user cases and ensure an optimal level of customer
              service{' '}
            </li>
          </ul>
        </div>
        <Link to="/source-and-attract/application-form" className="pt-2">
          <Button isPink text="Apply" className={'mt-1 w-full rounded-none '} onClick={onClick} />{' '}
        </Link>
      </div>
    </div>
  );
};

export default JobCategoryDetailsLeft;
