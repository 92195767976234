import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import { BiChevronDown } from 'react-icons/bi';
import ObjectivesForm from './ObjectivesForm';
import BehaviouralForm from './BehaviouralForm';
import Button from '../../../../components/button';
import { STORAGE_KEYS } from '../../../../helpers/constant';
import { API } from '../../../../utils/apis';
import { getEmployeesOption } from '../../../../helpers/functions';
import PerformanceGoals from './edit-performance/performance-goals';
import PerformanceCompetencyGoals from './edit-performance/performance-goals/performance-competency';
import OkrGoals from './edit-okr/okr-goals';
import OkrCompetencyGoals from './edit-okr/okr-goals/okr-competency';
import BscGoals from './bsc-goal';


const EditGoals = ({formData}) => {
  const [option, setOption] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesArray, setEmployeesArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const loadEmployees = async () => {
    setLoading(true);
    const res = await API.fetchAllEmployeesById();
    if (res) setEmployees(res);
    setLoading(false);
  };

  useEffect(() => {
    loadEmployees();
  }, []);

  useEffect(() => {
    setEmployeesArray(getEmployeesOption(employees));
  }, [employees]);

  const submitData = async (e) => {
    setLoading(true);
    console.log(formData);
    await API.submitData(formData, navigate);
    setLoading(false);
    e.preventDefault();
  };

  useEffect(() => {
    const option = localStorage.getItem(STORAGE_KEYS.PERFORMANCE_OPTION);
    setOption(parseInt(option));
  }, []);

  let termToDisplay;
  switch (option) {
    case 0:
      termToDisplay = <PerformanceGoals formData={formData} onSubmit={submitData}/>
      break;
    case 1:
      termToDisplay = <PerformanceCompetencyGoals onSubmit={submitData}/>
      break;
    case 2:
      termToDisplay = <OkrGoals onSubmit={submitData}/>
      break;
    case 3:
      termToDisplay = <OkrCompetencyGoals onSubmit={submitData}/>
      break;
    case 4:
      termToDisplay = <BscGoals onSubmit={submitData}/>
      break;
    default:
      termToDisplay = <PerformanceGoals onSubmit={submitData}/>
  }

  return (
    <div>
      {termToDisplay}
    </div>
    
  );
};

export default EditGoals;
