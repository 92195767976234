import React, { useState, useEffect } from 'react';
import BaseModal from '../../../../components/base/modal';
import Button from '../../../../components/button';
import { apiService } from '../../../../helpers/api';
import { API } from '../../../../utils/apis';

const DeleteDepartmentModal = ({ show, onClose, setDepartments, editDepartmentInfo }) => {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState('');
  const [, setAllDepartments] = useState([]);

  useEffect(() => {
    const fetchAllDepartments = async () => {
      const response = await API.fetchAllDepartment();
      setAllDepartments(response);
    };

    fetchAllDepartments();
  }, []);
  
  const deleteDepartment = async () => {
    setLoading(true);
    try {
      const res = await apiService.put(
        `Department/DeleteDepartment`,
        {
          departmentId: editDepartmentInfo?.id
        }, true
      );
      if (res) {
        const response = await API.fetchAllDepartment();
        setDepartments(response);
        onClose();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      title={'Are you sure you want to '}
      coloredText='Delete? '
      show={show}
      onClose={() => { setError(''); onClose(); }}>
      <div className='flex gap-4 flex-wrap mt-6 justify-end'>
        <Button className={''} text='Go back' onClick={onClose} isGray />
        <Button
          text='Delete'
          isPink
          className='bg-opacity-80'
          loading={loading}
          onClick={deleteDepartment}
        />
      </div>
    </BaseModal>
  );
};

export default DeleteDepartmentModal;

