import React, { useState, useEffect } from 'react';
import BaseModal from '../../../../../components/base/modal';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import Select from '../../../../../components/input/Select';
import { COMPONENT_TYPE, RATE_TYPE } from '../../../../../helpers/constant';
import { API } from '../../../../../utils/apis';

const EditBasicAllowancesModal = ({ show, closeModal, editBasicAllowancesInfo }) => {
  const DEPENDENT_TYPE = [
    {
      label: 'None',
      value: 1
    },
    {
      label: 'Gross',
      value: 2
    },
    {
      label: 'Other Components',
      value: 3,
      children: [
        { label: 'Basic', value: '31' },
        { label: 'Training', value: '32' },
        { label: 'Housing', value: '33' },
        { label: 'Transport', value: '34' }
      ]
    }
  ];

  const [modalData, setModalData] = useState({
    basicAllowance: [
      {
        componentId: 0,
        name: '',
        value: 0,
        dependantComponentType: 1,
        dependant: '',
        dependantComponent: '',
        duration: 1,
        componentType: 0,
        withinGross: true,
        isCompulsory: true,
        maxAmount: 0,
        minAmount: 0,
        order: 0,
        withinTaxRelief: true
      }
    ],
    deductions: [],
    taxRelief: []
  });

  useEffect(() => {
    if (editBasicAllowancesInfo) {
      setModalData({
        basicAllowance: [
          {
            ...modalData.basicAllowance[0],
            componentId: editBasicAllowancesInfo.componentId,
            name: editBasicAllowancesInfo.name,
            value: editBasicAllowancesInfo.value,
            dependantComponentType: editBasicAllowancesInfo.dependantComponentType,
            dependant: editBasicAllowancesInfo.dependant,
            dependantComponent: editBasicAllowancesInfo.dependantComponent,
            duration: editBasicAllowancesInfo.duration,
            componentType: editBasicAllowancesInfo.componentType,
            withinGross: editBasicAllowancesInfo.withinGross,
            isCompulsory: editBasicAllowancesInfo.isCompulsory,
            maxAmount: editBasicAllowancesInfo.maxAmount,
            minAmount: editBasicAllowancesInfo.minAmount,
            order: editBasicAllowancesInfo.order,
            withinTaxRelief: editBasicAllowancesInfo.withinTaxRelief,
          }
        ],
        deductions: [],
        taxRelief: []
      });
    }
  }, [editBasicAllowancesInfo]);

  const [loading, setLoading] = useState(false);

  const handleChange = (index, field, value) => {
    setModalData((prevData) => {
      const updatedBasicAllowance = prevData.basicAllowance.map((item, idx) => {
        if (idx === index) {
          return { ...item, [field]: value };
        }
        return item;
      });
      return { ...prevData, basicAllowance: updatedBasicAllowance };
    });
  };

  const submitData = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(modalData);
    await API.createSalaryComponent(modalData);
    closeModal();
    setLoading(false);
    window.location.reload();
  };

  const renderDropdownOptions = (options) => {
    return options.map((option) => {
      if (option.children) {
        return (
          <Select.OptionGroup key={option.value} label={option.label}>
            {renderDropdownOptions(option.children)}
          </Select.OptionGroup>
        );
      } else {
        return (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        );
      }
    });
  };

  return (
    <BaseModal title={'Edit Component'} show={show} onClose={closeModal}>
      <div className='space-y-10 rounded-lg w-full sm:w-[400px] overflow-hidden'>
        {modalData.basicAllowance.map((data, index) => (
          <React.Fragment key={index}>
            <Input
              withLabel
              value={data.name}
              placeholder={editBasicAllowancesInfo?.name}
              label='Name'
              containerClass='w-full sm:w-auto'
              name='name'
              onChange={(e) => handleChange(index, 'name', e.target.value)}
            />

            <div className='flex flex-col gap-2 w-full'>
              <h6 className='text-xs'>RATE TYPE</h6>
              <Select
                selectedOption={data.dependant}
                placeholder={editBasicAllowancesInfo?.dependant}
                className='w-full sm:w-auto'
                options={RATE_TYPE}
                onChange={(e) => handleChange(index, 'dependant', e?.value)}
              />
            </div>

            <Input
              withLabel
              value={data.value}
              placeholder={editBasicAllowancesInfo?.value}
              label={data.dependant === 1 ? 'Value' : 'Amount'}
              containerClass='w-full sm:w-auto'
              name='value'
              onChange={(e) => handleChange(index, 'value', e.target.value)}
            />

            <Input
              withLabel
              value={data.duration}
              placeholder={editBasicAllowancesInfo?.duration}
              label='Duration'
              containerClass='w-full sm:w-auto'
              name='duration'
              onChange={(e) => handleChange(index, 'duration', e.target.value)}
            />
          </React.Fragment>
        ))}
        
        <div className='flex gap-4 flex-wrap justify-end'>
          <Button
            className='border border-pink-100'
            text='Cancel'
            onClick={closeModal}
            isTransparent
          />
          <Button text='Submit' type='submit' onClick={submitData} isPurple />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditBasicAllowancesModal;
