import React, { useState, useEffect } from 'react';
import { API } from '../../../../../utils/apis';
import GoalsLayout from '../goals-layout';
import BscGoal from './BscGoal';
import CustomerGoal from './customer';
import FinancialGoal from './financial';
import InternalGoal from './internal';
import LearningGoal from './learning';
import { getCompetencyOption } from '../../../../../helpers/functions';
import { STORAGE_KEYS } from '../../../../../helpers/constant';
import { useNavigate } from 'react-router';

const BscGoals = ({ employees }) => {
  const staffId = localStorage.getItem(STORAGE_KEYS?.EMPLOYEE_ID);
  const emptyForm = {
    performanceId: 0,
    employeeId: staffId,
    departmentId: 0,
    employeeGroupId: 0,
    performanceTemplate: 0,
    approverId: 0,
    isDraft: true,
    goal: [
      {
        objectiveId: 0,
        description: '',
        goalType: 1,
        targets: [
          {
            target: '',
            metrics: 1,
            value: '',
            dueDate: '',
            weight: 0,
          },
        ],
        performanceGoalCategory: 1,
      },
      {
        objectiveId: 0,
        description: '',
        goalType: 1,
        targets: [
          {
            target: '',
            metrics: 1,
            value: '',
            dueDate: '',
            weight: 0,
          },
        ],
        performanceGoalCategory: 2,
      },
      {
        objectiveId: 0,
        description: '',
        goalType: 1,
        targets: [
          {
            target: '',
            metrics: 1,
            value: '',
            dueDate: '',
            weight: 0,
          },
        ],
        performanceGoalCategory: 3,
      },
      {
        objectiveId: 0,
        description: '',
        goalType: 1,
        targets: [
          {
            target: '',
            metrics: 1,
            value: '',
            dueDate: '',
            weight: 0,
          },
        ],
        performanceGoalCategory: 4,
      },
    ],
    behavioural: [],
    technical: [],
    groupType: 1,
  };
  const [formData, setFormData] = useState({
    performanceId: 0,
    employeeId: staffId,
    departmentId: 0,
    employeeGroupId: 0,
    performanceTemplate: 0,
    approverId: 0,
    isDraft: true,
    goal: [
      {
        objectiveId: 0,
        description: '',
        goalType: 1,
        targets: [
          {
            target: '',
            metrics: 1,
            value: '',
            dueDate: '',
            weight: 0,
          },
        ],
        performanceGoalCategory: 1,
      },
      {
        objectiveId: 0,
        description: '',
        goalType: 1,
        targets: [
          {
            target: '',
            metrics: 1,
            value: '',
            dueDate: '',
            weight: 0,
          },
        ],
        performanceGoalCategory: 2,
      },
      {
        objectiveId: 0,
        description: '',
        goalType: 1,
        targets: [
          {
            target: '',
            metrics: 1,
            value: '',
            dueDate: '',
            weight: 0,
          },
        ],
        performanceGoalCategory: 3,
      },
      {
        objectiveId: 0,
        description: '',
        goalType: 1,
        targets: [
          {
            target: '',
            metrics: 1,
            value: '',
            dueDate: '',
            weight: 0,
          },
        ],
        performanceGoalCategory: 4,
      },
    ],
    behavioural: [],
    technical: [],
    groupType: 1,
  });
  const [learning, setLearning] = useState(emptyForm);
  const [internal, setInternal] = useState(emptyForm);
  const [customer, setCustomer] = useState(emptyForm);
  const [financial, setFinancial] = useState(emptyForm);
  const [loading, setLoading] = useState(false);
  const [competencyOption, setCompetencyOption] = useState([]);
  const navigate = useNavigate();

  const loadCompetencyOptions = async () => {
    const res = await API.getBehaviouralCompetency();
    setCompetencyOption(getCompetencyOption(res));
  };

  useEffect(() => {
    loadCompetencyOptions();
  }, []);

  const submitData = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData);
    await API.submitData(formData, navigate);
    setLoading(false);
  };

  return (
    <GoalsLayout
      employees={employees}
      title='BSC Goals'
      submitData={submitData}
    >
      <BscGoal
        formData={formData}
        setFormData={setFormData}
        competencyOption={competencyOption}
        submitData={submitData}
      />
      
    </GoalsLayout>
  );
};

export default BscGoals;
