import React from 'react';
import RequestCard from '../../../../components/cards/RequestCard';
import { getLeaveType } from '../../../../helpers/functions';

const RightEmployeeDetails = ({ leaveRequest }) => {
  return (
    <div className='pt-16 space-y-16 mx-auto lg:mx-0 '>
      {leaveRequest.length !== 0 && (
        <div className='bg-purple-100 p-4 py-8 sm:p-8 rounded-sm space-y-6'>
          <div className='flex justify-between '>
            <h6 className='text-white-100'>requests</h6>
            <span className='px-3 py-[1px] bg-yellow-100 text-white-100 text-xs rounded-[8px] font-semibold'>
              {leaveRequest.length}
            </span>
          </div>
          <div className='space-y-6'>
            {leaveRequest.map((req, i) => (
              <RequestCard requestType={getLeaveType(req?.leaveType) + ' LEAVE'} key={i} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RightEmployeeDetails;
