import React from 'react';
import { Link } from 'react-router-dom';
import StatusCard from './StatusCard';

const AuditCard = ({ className, icon, date, time, isApproved, isRejected }) => {
  return (
    <Link
      to={''}
      className={`bg-white-100 p-4 flex  items-center gap-x-10 justify-between  font-semibold rounded-[5px] w-full ${className}`}>
      <p>{icon}</p>
      <p>{date}</p>
      <p>{time}</p>
      <StatusCard isApproved={isApproved} isRejected={isRejected} />
    </Link>
  );
};

export default AuditCard;
