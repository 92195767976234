import React, { useState, useEffect } from 'react';
import Button from '../../button';
import Input from '../../input';
import Select from '../../input/Select';
import Modal from '../../modal';
import { API } from '../../../utils/apis';
import { EVENT_CATEGORY } from '../../../helpers/constant';
import { useNavigate } from 'react-router';
import { DURATION_TYPE, DURATION_PERIOD, STORAGE_KEYS } from '../../../helpers/constant';
//import { MANAGE_EVENTS } from '../../../../router/router';

const SelectedDate = ({ show, closeModal, selectedDate, selectedEmployee }) => {
  const companyId = localStorage.getItem(STORAGE_KEYS?.COMPANY_ID);
  const dateSelected = selectedDate ? new Date(selectedDate.getTime() + (60 * 60 * 1000)).toISOString().split('T')[0] : '';
  const [modalData, setModalData] = useState({
    activityId: 0,
    time: '',
    employeeId: selectedEmployee,
    duration: 0,
    durationPeriod: 0,
    durationPeriodName: '',
    date: '' || dateSelected,
    title: '',
    link: '',
    companyId: companyId
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (field, value) => {
    if (field === 'cateory') {
      setModalData((prevData) => ({
        ...prevData,
        cateory: value,
        type: value,
      }));
    } else {
      setModalData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  const handleChangePeriod = (field, selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';
    setModalData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  }; 

  const submitData = async (e) => {
    setLoading(true);
    console.log(modalData);
    await API.createActivity(modalData, navigate);
    setLoading(false);
    e.preventDefault();
  };

  useEffect(() => {
    setModalData(prevData => ({
      ...prevData,
      employeeId: selectedEmployee
    }));
  }, [selectedEmployee]);

  useEffect(() => {
    setModalData((prevData) => ({
      ...prevData,
      date: dateSelected || '',
    }));
  }, [dateSelected]);
  
  return (
    <Modal
      title={'Add Employee Activity'}
      showModal={show}
      closeModal={closeModal}
      content={
        <div className=' space-y-10 mt-4 rounded-lg w-[400px]'>
          <Input
            withLabel
            onChange={(e) => handleChange('title', e.target.value)}
            value={modalData.title}
            label='event name *'
            containerClass=' w-full'
            name={'title'}
          />
          
          <Input
            withLabel
            onChange={(e) => handleChange('date', e.target.value)}
            value={modalData.date}
            label='event date *'
            type='date'
            containerClass=' w-full'
            name={'date'}
          />

         <Input
            withLabel
            onChange={(e) => handleChange('time', e.target.value)}
            value={modalData.time}
            label='event time *'
            type='time'
            containerClass=' w-full'
            name={'time'}
          /> 

         <Select
            withLabel
            label='DURATION'
            containerClass=''
            selectedOption={modalData.duration}
            options={DURATION_TYPE}
            name='duration'
            onChange={(e) => handleChangePeriod('duration', e)}
          />

          <Select
            withLabel
            label='DURATION PERIOD'
            containerClass=''
            selectedOption={modalData.durationPeriod}
            options={DURATION_PERIOD}
            name='durationPeriod'
            onChange={(e) => handleChangePeriod('durationPeriod', e)}
          />

          <Input
            withLabel
            onChange={(e) => handleChange('durationPeriodName', e.target.value)}
            value={modalData.durationPeriodName}
            label='duration period name *'
            containerClass=' w-full'
            name={'durationPeriodName'}
          />

          <div className='flex gap-4 flex-wrap justify-end'>
            <Button
              className={'border border-pink-100'}
              text='cancel'
              onClick={closeModal}
              isTransparent
            />
            <Button 
              text='submit' 
              isPurple
              onClick={submitData}
              loading={loading}
            />
          </div>
        </div>
      }
    />
  );
};

export default SelectedDate;
