import React, { useState, useEffect } from 'react';
import { MdOutlineArrowBack, MdOutlineArrowForward } from 'react-icons/md';
import BirthdayCard from '../../../../components/cards/BirthdayCard';
import { apiService } from '../../../../helpers/api';

const UpcomingBirthdays = () => {
  const [loading, setLoading] = useState(false);
  const [birthdays, setBirthdays] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [birthdaysPerPage] = useState(4);

  const fetchEmployees = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        'Employee/FetchAllEmployeeByCompanyId',
        {
          pageNumber: 1,
          pageSize: 20
        },
        true
      );
      const response = res?.data?.data?.data;
      setBirthdays(response.map((employee) => ({
        ...employee,
        dob: formatDOB(employee.dob),
      })));
    } catch (err) {
      console.log('Error in fetching employee details: ', err)
    } finally {
      setLoading(false);
    }
  };

  const formatDOB = (dob) => {
    const date = new Date(dob);
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    let suffix = 'th';

    if (day === 1 || day === 21 || day === 31) {
      suffix = 'st';
    } else if (day === 2 || day === 22) {
      suffix = 'nd';
    } else if (day === 3 || day === 23) {
      suffix = 'rd';
    }

    return `${month} ${day}${suffix}`;
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const indexOfLastBirthday = currentPage * birthdaysPerPage;
  const indexOfFirstBirthday = indexOfLastBirthday - birthdaysPerPage;
  const currentBirthdays = birthdays.slice(indexOfFirstBirthday, indexOfLastBirthday);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="w-full md:w-[48%] space-y-3">
      <p className="font-bold text-blue-100">Upcoming Birthdays</p>
      <div className="bg-pink-30 space-y-3 rounded-sm p-4 py-6">
        <h6>team birthdays</h6>
        <div className="space-y-3">
          {currentBirthdays.map((birthday, index) => (
            <BirthdayCard
              key={index}
              name={birthday.firstName + ' ' + birthday.lastName}
              birthDate={birthday.dob}
            />
          ))}
        </div>
        <div className="pagination-controls flex justify-end">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <MdOutlineArrowBack />
          </button>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastBirthday >= birthdays.length}
          >
            <MdOutlineArrowForward />
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpcomingBirthdays;
