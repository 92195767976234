import { Toast } from '../../../components/toast/toast';
import { apiService } from '../../../helpers/api';
import { MANAGE_PERFORMANCE, SETTINGS } from '../../../router/router';

const base = 'Performance/';

export const addCompetency = async (Name, Description, category) => {
  try {
    const res = await apiService.post(
      `Meta/Competence/Create?Name=${Name}&Description=${Description}&category=${category}`,
      {},
      true
    );

    if (res)
      Toast({
        type: 'success',
        message: 'Competency Added Successfully'
      });

    return res;
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  }
};

export const addBehaviouralCompetency = async (name) => {
  try {
    const res = await apiService.post(
      `Meta/Competence/Create?category=1`,
      {
        name
      },
      true
    );

    if (res)
      Toast({
        type: 'success',
        message: 'Behavioural Competency Added Successfully'
      });

    return res;
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  }
};

export const addTechnicalCompetency = async (name) => {
  try {
    const res = await apiService.post(
      `Meta/Competence/Create?category=2`,
      {
        name
      },
      true
    );

    if (res)
      Toast({
        type: 'success',
        message: 'Technical Competency Added Successfully'
      });

    return res;
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  }
};

export const getCompetency = async () => {
  try {
    const res = await apiService.get(`Meta/Competence/List`, {}, true);
    const response = res?.data?.data;
    return response;
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  }
};

export const getBehaviouralCompetency = async () => {
  try {
    const res = await apiService.get(`Meta/Competence/List?Category=1`, {}, true);
    const response = res?.data?.data;
    return response;
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  }
};

export const getTechnicalCompetency = async () => {
  try {
    const res = await apiService.get(`Meta/Competence/List?Category=2`, {}, true);
    const response = res?.data?.data;
    return response;
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  }
};

export const performanceSettings = async () => {
  try {
    const res = await apiService.get(
      `${base}Setting`,
      {
        
      },
      true
    );
    const response = res?.data?.data;

    if (response)
      Toast({
        type: 'success',
        message: 'Performance Settings Fetched Successfully'
      });

    return response;
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  }
};

export const saveObjective = async (data, navigate) => {
  try {
    const res = await apiService.post(`${base}Objective/Create`, data, true);
    if (res)
      Toast({
        type: 'success',
        message: 'Objective Created Successfully'
      });
    navigate(SETTINGS);
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  }
};

export const getObjective = async () => {
  try {
    const res = await apiService.get(`${base}Objective/List`, {}, true);
    const response = res?.data?.data;
    return response;
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  }
};

export const addAdminGoal = async (data, navigate) => {
  try {
    const res = await apiService.post(`${base}Admin/Goal/Create`, data, true);
    if (res)
    Toast({
      type: 'success',
      message: 'Performance Goal Created Successfully'
     });
    navigate(MANAGE_PERFORMANCE);
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  } 
};

/*export const addGoal = async (e) => {
  e.preventDefault();
  setLoading(true);
  try {
    const res = await apiService.post('Performance/Goal/Create', formData, true);
    if (res) navigate(MANAGE_PERFORMANCE);
    Toast({
      type: 'success',
      message: 'Performance Goal Created Successfully'
     });
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  } finally {
    setLoading(false);
  }
};*/

export const fetchAllPerformances = async () => {
  try {
    const res = await apiService.get(
      `${base}/Search`,
      {
        status: 0
      },
      true
    );
    const response = res?.data?.data;

    if (response)
    return response;
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  }
};

export const fetchAllBehaviouralObjs = async () => {
  try {
    const res = await apiService.get(
      `${base}/Search`,
      {
        status: 1
      },
      true
    );
    const response = res?.data?.data;

    if (response)
    return response;
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  }
};

export const fetchAllTechnicalObjs = async () => {
  try {
    const res = await apiService.get(
      `${base}/Search`,
      {
        status: 2
      },
      true
    );
    const response = res?.data?.data;

    if (response)
    return response;
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  }
};

export const getPerformanceResults = async (val) => {
  try {
    const res = await apiService.get(
      `${base}/Search`,
      {
        status: 0,
        searchParam: val
      },
      true
    );
    return res;
  } catch (err) {
    Toast({
      type: 'error',
      message: err.message
    });
  }
};
