import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../../assets/img/logo.svg';
import authService from '../../../../services/auth.service';
import PasswordReset from './PasswordReset';

const ResetPassword = () => {
  const emptyForm = {
    email: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(emptyForm);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setError('');
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const passwordReset = async (e) => {
    setLoading(true);
    e.preventDefault();
    await authService.resetPassword(formData, navigate);
    setLoading(false);
  };

  return (
    <div className=' grid md:h-[100vh] py-8'>
      <div className='flex flex-col items-center justify-center w-full md:w-[50%] md:mx-auto'>
        <Logo className='' />

        <PasswordReset
          formData={formData}
          handleChange={handleChange}
          loading={loading}
          passwordReset={passwordReset}
          error={error}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
