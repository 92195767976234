import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { TextArea } from '../../../../components/input';
import Button from '../../../../components/button';
import { API } from '../../../../utils/apis';
import { MANAGE_LEARNING } from '../../../../router/router';
import { STORAGE_KEYS } from '../../../../helpers/constant';

const FeedbackPage = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [ratings, setRatings] = useState({
    'Rate the event coordination': 0,
    'Rate the facilitators': 0,
    'Training is valuable to my needs/career/role': 0,
    'I will apply training to my work': 0,
    'Content was well organized': 0,
    'Training was engaging': 0,
    'Training has required experience': 0,
    'Training has well delivered': 0,
    'Training met my expectations': 0,
  });
  const [feedback, setFeedback] = useState('');

  const ratingMappings = {
    'Rate the event coordination': 'coordinationRating',
    'Rate the facilitators': 'facilitatorRating',
    'Training is valuable to my needs/career/role': 'valuableRating',
    'I will apply training to my work': 'applicationRating',
    'Content was well organized': 'organisationRating',
    'Training was engaging': 'engagingRating',
    'Training has required experience': 'expirienceRating',
    'Training has well delivered': 'deliveryRating',
    'Training met my expectations': 'expectationRating',
  };

  const handleRatingChange = (category, value) => {
    setRatings(prevRatings => ({
      ...prevRatings,
      [category]: value,
    }));
  };

  const submitFeedback = async (e) => {
    e.preventDefault();
    setLoading(true);

    const transformedRatings = Object.keys(ratings).reduce((acc, category) => {
      acc[ratingMappings[category]] = ratings[category];
      return acc;
    }, {});

    const formData = {
      eventId: eventId,
      ...transformedRatings,
      feedback: feedback,
      employeeId: localStorage.getItem(STORAGE_KEYS?.EMPLOYEE_ID) || 0,
    };

    console.log(formData);
    await API.learningEventFeedback(formData, navigate);
    setLoading(false);
  };

  return (
    <div className='white-bg'>
      <div className=' xs:w-[80%] sm:w-[70%] mdLg:w-full mdLg:mx-0 mx-auto'>
        <div className='purple-bg rounded-[2px] space-y-8 mt-8 bg-pink-30 shadow-xl rounded-3xl p-4 md:p-10'>
          <h1 className="text-2xl font-bold mb-4">Provide Feedback</h1>
          {Object.keys(ratings).map(category => (
            <div key={category} className='flex items-center space-x-2 mb-3 font-semibold text-purple-300'>
              <span className='flex-grow'>{category}</span>
              <div className='flex space-x-1'>
                {[1, 2, 3, 4, 5].map(starValue => (
                  <button
                    key={starValue}
                    className={`w-6 h-6 ${
                      starValue <= ratings[category] ? 'text-yellow-400' : 'text-gray-400'
                    }`}
                    onClick={() => handleRatingChange(category, starValue)}
                  >
                    ★
                  </button>
                ))}
              </div>
           </div>
          ))}
          <div className='mdLg:w-[100%]'>
            <span>
              <TextArea
                withLabel
                label='PROVIDE FEEDBACK'
                className='h-32'
                containerClass=''
                value={feedback}
                name='feedback'
                onChange={(e) => setFeedback(e.target.value)}
              />
            </span>
          </div>
          <div className='flex gap-4 flex-wrap justify-end'>
                <Button
                  className='border border-pink-100 text-pink-100'
                  text='Cancel'
                  onClick={() => navigate(MANAGE_LEARNING)}
                  isTransparent
                />
                <Button
                  text='Submit'
                  type='submit'
                  onClick={submitFeedback}
                  isOrange
                  disabled={loading}
                />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackPage;
