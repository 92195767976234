import React from 'react';
import Input from '../../../../../components/input';
import Select from '../../../../../components/input/Select';
import { CYCLE } from '../../../../../helpers/constant.js';

const formatNumberWithCommas = (number) => {
  if (!number) return '';
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const PayrollForm = ({ employees, formData, setFormData, updateGrossData }) => {
  const handleGrossCompensationChange = (e) => {
    const amount = e?.target?.value.replace(/,/g, ''); // Remove existing commas
    const formattedAmount = formatNumberWithCommas(amount);

    setFormData({
      ...formData,
      gross: formattedAmount,
    });

    // Update grossAmount and duration in grossData
    updateGrossData(amount, formData.duration);
  };

  const handleDurationChange = (e) => {
    const duration = e?.value;

    setFormData({
      ...formData,
      duration
    });

    // Update grossAmount and duration in grossData
    updateGrossData(formData.gross.replace(/,/g, ''), duration);
  };

  return (
    <div className='space-y-8'>
      <div className='flex flex-col gap-2'>
        <h6 className='text-xs'>Employee</h6>
        <Select
          options={employees}
          onChange={(e) =>
            setFormData({
              ...formData,
              employeeId: e?.value,
            })
          }
        />
      </div>
      <div className='grid md:grid-cols-2 gap-6'>
        <Input
          withLabel
          label='GROSS COMPENSATION (₦)'
          containerClass=''
          onChange={handleGrossCompensationChange}
          value={formData?.gross}
        />
        <div className='flex flex-col gap-2'>
          <h6 className='text-xs'>Duration</h6>
          <Select
            options={CYCLE}
            onChange={handleDurationChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PayrollForm;
