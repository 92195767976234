import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

interface FilterDropDownProps {
  filterMenu: boolean;
  parentRef: React.RefObject<HTMLDivElement>;
  menuList: { name: string; route: string }[];
}

const FilterDropDown: React.FC<FilterDropDownProps> = ({ filterMenu, parentRef, menuList }) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const calculatePosition = () => {
      if (parentRef.current && dropdownRef.current) {
        const parentRect = parentRef.current.getBoundingClientRect();
        const scrollY = window.scrollY || window.pageYOffset;

        dropdownRef.current.style.top = `${scrollY + parentRect.bottom}px`;
        dropdownRef.current.style.left = `${parentRect.left}px`;
      }
    };

    if (filterMenu) {
      calculatePosition();
      window.addEventListener('resize', calculatePosition);
      window.addEventListener('scroll', calculatePosition);
    }

    return () => {
      window.removeEventListener('resize', calculatePosition);
      window.removeEventListener('scroll', calculatePosition);
    };
  }, [filterMenu, parentRef]);

  return (
    <>
      {filterMenu && (
        <div
          ref={dropdownRef}
          className={`bg-white-100 drop-shadow-2xl rounded-sm p-5 pr-9 absolute space-y-4 font-medium`}
        >
          {menuList.map(({ name, route }, i) => (
            <div
              className={`text-sm hover:font-bold cursor-pointer transition-all duration-100  ${
                menuList.length - 1 !== i ? 'text-black-100' : 'text-red-700'
              }`}
              key={i}
            >
              {route ? (
                <Link to={route} className='flex items-center gap-2'>
                  {name}
                </Link>
              ) : (
                <p className='flex items-center gap-2'>
                  {name}
                </p>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default FilterDropDown;
