import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import LeftAbsenceEmployeeInfo from './LeftAbsenceEmployeeInfo';
import RightAbsenceEmployeeInfo from './RightAbsenceEmployeeInfo';
import { apiService } from '../../../../helpers/api';
import { Toast } from '../../../../components/toast/toast';
import { useEffect } from 'react';
import { BiLoaderCircle } from 'react-icons/bi';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';

const AbsenceEmployeeInfo = () => {
  const [loading, setLoading] = useState(false);
  const [leaveDetail, setLeaveDetail] = useState([]);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const fetchLeaveDetail = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(`Leave/Request/${id}`);
      const response = res?.data?.data;
      setLeaveDetail(response);
      console.log(leaveDetail);
    } catch (err) {
      console.log('Error in fetching leave details: ', err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeaveDetail();
  }, []);

  const rejectLeaveRequest = async () => {
    setRequestLoading(true);
    try {
      const res = await apiService.post(`Leave/Request/Approve?leaveRequestId=${id}&approve=false`);
      if (res) {
        await fetchLeaveDetail();
        setShowRejectModal(!showRejectModal);
        Toast({
          type: 'success',
          message: 'Leave Request Rejected'
        });
      }
    } catch (err) {
      console.log('Error in rejecting leave request: ', err)
    } finally {
      setRequestLoading(false);
    }
  };

  const approveLeaveRequest = async () => {
    setRequestLoading(true);
    try {
      const res = await apiService.post(`Leave/Request/Approve?leaveRequestId=${id}&approve=true`);
      if (res) {
        await fetchLeaveDetail();
        setShowApproveModal(!showApproveModal);
        Toast({
          type: 'success',
          message: 'Leave Request Approved'
        });
      }
    } catch (err) {
      console.log('Error in approving leave request: ', err)
    } finally {
      setRequestLoading(false);
    }
  };

  return (
    <div className="mx-auto lg:mx-0 space-y-6">
      <div
        className="uppercase text-xs flex items-center justify-start space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer"
        onClick={() => navigate(-1)}>
        <MdOutlineArrowBack /> <span className="text-blue-100"> BACK</span>
      </div>

      <div className="space-y-6 flex flex-wrap justify-between items-start">
        {loading ? (
          <BiLoaderCircle size={16} className="mx-auto animate-spin-slow" />
        ) : (
          <>
            <LeftAbsenceEmployeeInfo leaveDetail={leaveDetail} />
            <RightAbsenceEmployeeInfo
              leaveDetail={leaveDetail}
              showRejectionModal={() => setShowRejectModal(!showRejectModal)}
              showApprovalModal={() => setShowApproveModal(!showApproveModal)}
            />
          </>
        )}
      </div>

      <Modal
        title={'Are you sure you want to '}
        coloredTitle="Approve? "
        showModal={showApproveModal}
        closeModal={() => setShowApproveModal(!showApproveModal)}
        content={
          <div className="flex gap-4 flex-wrap">
            <Button
              className={''}
              text="Go back"
              onClick={() => setShowApproveModal(!showApproveModal)}
              isGray
            />
            <Button
              text="Approve"
              isGreen
              className="bg-opacity-80"
              loading={requestLoading}
              onClick={approveLeaveRequest}
            />
          </div>
        }
      />
      <Modal
        title={'Are you sure you want to '}
        coloredTitle="Reject? "
        showModal={showRejectModal}
        closeModal={() => setShowRejectModal(!showRejectModal)}
        content={
          <div className="flex gap-4 flex-wrap">
            <Button
              className={''}
              text="Go back"
              onClick={() => setShowRejectModal(!showRejectModal)}
              isGray
            />
            <Button
              text="Reject"
              isPink
              className="bg-opacity-80"
              loading={requestLoading}
              onClick={rejectLeaveRequest}
            />
          </div>
        }
      />
    </div>
  );
};

export default AbsenceEmployeeInfo;
