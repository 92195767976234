import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Button from '../../components/button';
import { HeaderCard } from '../../components/cards/Cards';
import LayoutWithSideBar from '../../components/layout/LayoutWithSideBar';
import { EDIT_GOALS } from '../../router/router';

const Settings = () => {
  const { pathname } = useLocation();
  return (
    <LayoutWithSideBar>
      <HeaderCard text={'Settings'} titleClassName={'font-bold'} className={''} />
      <Outlet />
    </LayoutWithSideBar>
  );
};

export default Settings;
