import React from 'react';
import SearchWithFilter from '../../../components/general/SearchWithFilter';
import LeftAuditContent from './LeftAuditContent';
import LeftAuditHeader from './LeftAuditHeader';

const LeftAudit = () => {
  return (
    <div className="space-y-10">
      <LeftAuditHeader />
      <SearchWithFilter />
      <LeftAuditContent />
    </div>
  );
};

export default LeftAudit;
