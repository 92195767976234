import React, { useEffect, useState } from 'react';
import { BiLoaderAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import StatusCard from '../../../../components/cards/StatusCard';
import Pagination from '../../../../components/general/pagination';
import { getEmployeeType, getJobType } from '../../../../helpers/functions';

const VacancyContent = ({ jobVacancies, loading, searchInput }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const totalPages = Math.ceil(jobVacancies.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  const filteredJobVacancies = jobVacancies.filter((jobVacancy) =>
    jobVacancy.jobTitle.toLowerCase().includes(searchInput.toLowerCase())
  );

  const currentJobVacancies = searchInput
    ? filteredJobVacancies.slice(startIndex, endIndex)
    : jobVacancies.slice(startIndex, endIndex)
  ;

  const handleClick = (item) => {
    navigate(`/manage-hiring/vacancy/${item?.vacancyId}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className='rounded-[20px] bg-purple-30 p-10 my-6'>
      <div>
        <div className='whitespace-nowrap overflow-x-scroll w-[100%] '>
          <table className='  w-full '>
            <thead className=' border-collapse border-red-500  '>
              <tr className='w-[100%] text-left uppercase tracking-[3px] text-blue-100 font-semibold text-2xs pl-28 mb-4 '>
                <th></th>
                <th className='p-6  '>VACANCY ID</th>
                <th className='p-6  '>JOB TITLE</th>
                <th className='p-6  '> DEPARTMENT</th>
                <th className='p-6  '>EMPLOYMENT TYPE</th>
                <th className='p-6  '>STATUS</th>
              </tr>
            </thead>
            <tbody className='rounded-sm'>
              {currentJobVacancies.length > 0 ? (
                currentJobVacancies.map((jobVacancy, i) => (
                  <tr
                    className='cursor-pointer border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                    key={jobVacancy?.vacancyId}
                    onClick={() => handleClick(jobVacancy)}>
                    <td className='p-3 px-6 '>{i + 1}.</td>
                    <td className='p-3 px-6'>VC-{jobVacancy?.vacancyId}</td>
                    <td className='p-3 px-6'>{jobVacancy?.jobTitle}</td>
                    <td className='p-3 px-6'>{jobVacancy?.department}</td>
                    <td className='p-3 px-6'>
                      {getJobType(jobVacancy?.jobType)
                        ? getEmployeeType(jobVacancy?.jobType)
                        : jobVacancy?.jobType}
                    </td>
                    <td className='p-3 px-6'>
                      <StatusCard
                        isClosed={jobVacancy?.jobStatus === 2}
                        isOpen={jobVacancy?.jobStatus === 1}
                        isDraft={jobVacancy?.jobStatus === 3}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='6' className='p-3 text-center'>
                    No matching vacancies found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className='pb-10 flex items-center'>
            {loading && (
              <BiLoaderAlt
                className='animate-spin my-10 flex text-center text-blue-100 justify-center mx-auto'
                size={30}
              />
            )}
            {!loading && jobVacancies.length === 0 && (
              <span className=' p-3'>There are currently no vacancies to show</span>
            )}
          </div>
        </div>
        <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
      </div>
    </div>
  );
};

export default VacancyContent;
