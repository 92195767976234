import React, { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import Loader from '../../../../components/base/loader';
import Button from '../../../../components/button';
import BackWithText from '../../../../components/general/BackWithText';
import { API } from '../../../../utils/apis';
import AddDepartmentModal from './add-department-modal';
import EditDepartmentModal from './edit-department-modal';
import DeleteDepartmentModal from './delete-department-modal';
import Pagination from '../../../../components/general/pagination';

const ITEMS_PER_PAGE = 20;

const Department = () => {
  const [showAddDepartmentModal, setShowAddDepartmentModal] = useState(false);
  const [showEditDepartmentModal, setShowEditDepartmentModal] = useState(false);
  const [showDeleteDepartmentModal, setShowDeleteDepartmentModal] = useState(false);
  const [department, setDepartment] = useState([]);
  const [editDepartmentInfo, setEditDepartmentInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const toggleAddDepartment = () => {
    setShowAddDepartmentModal(!showAddDepartmentModal);
  };

  const getDepartments = async () => {
    setLoading(true);
    const res = await API.fetchAllDepartment();
    if (res) setDepartment(res);
    setLoading(false);
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = department.slice(startIndex, endIndex);

  return (
    <div className='bg-pink-30 rounded-sm pt-4 pb-14 px-12 space-y-8'>
      <BackWithText />
      <Button
        isPink
        text={'Add Department'}
        onClick={() => setShowAddDepartmentModal(true)}
        className='ml-auto'
      />

      {loading ? (
        <Loader />
      ) : !loading && currentItems.length !== 0 ? (
        <div className='bg-white-100 rounded-[20px] p-10 overflow-x-scroll w-[100%] whitespace-nowrap no-scroll-bar'>
          <div className='w-full p-18 bg-purple-30 rounded-[20px]'>
            <table className='w-full'>
              <thead className=' border-collapse border-red-500'>
                <tr className='w-[100%] text-left uppercase tracking-[3px] text-blue-100 font-semibold text-2xs pl-28 mb-4 '>
                  <th className='p-6'>#</th>
                  <th className='p-6'>Department Name </th>
                  <th className='p-6'>Action</th>
                </tr>
              </thead>
              <tbody className='  rounded-sm'>
                {currentItems?.map((d, i) => (
                  <tr key={d?.id}>
                    <td className='p-6'>{startIndex + i + 1}.</td>
                    <td className='p-6 capitalize'>{d?.name}</td>
                    <td className='p-6 flex text-blue-100 gap-4 text-3xl'>
                      <FiEdit
                        className='cursor-pointer hover:bg-pink-30 p-[5px] transition-all'
                        onClick={() => {
                          setShowEditDepartmentModal(true);
                          setEditDepartmentInfo(d);
                        }}
                      />
                      <RiDeleteBinLine 
                        className='cursor-pointer hover:bg-pink-30 p-[5px] transition-all' 
                        onClick ={() => {
                          setShowDeleteDepartmentModal(true);
                          setEditDepartmentInfo(d);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            totalPages={Math.ceil(department.length / ITEMS_PER_PAGE)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      ) : (
        !loading && currentItems.length === 0 && <h4>There are no more departments to show</h4>
      )}

      <AddDepartmentModal
        show={showAddDepartmentModal}
        onClose={toggleAddDepartment}
        setDepartments={setDepartment}
      />

      <EditDepartmentModal
        show={showEditDepartmentModal}
        onClose={() => {
          setShowEditDepartmentModal(false);
          setEditDepartmentInfo(null);
        }}
        setDepartments={setDepartment}
        editDepartmentInfo={editDepartmentInfo}
      />

     <DeleteDepartmentModal
        show={showDeleteDepartmentModal}
        onClose={() => {
          setShowDeleteDepartmentModal(false);
          setEditDepartmentInfo(null);
        }}
        setDepartments={setDepartment}
        editDepartmentInfo={editDepartmentInfo}
      />
    </div>
  );
};

export default Department;
