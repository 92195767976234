import { Toast } from '../../../components/toast/toast';
import { apiService } from '../../../helpers/api';
import { MANAGE_HIRING } from '../../../router/router';

export const addVacancy = async (data, navigate) => {
  try {
    const res = await apiService.post('JobVacancy/CreateJobVacancy', data, true);
    if (res)
      Toast({
        type: 'success',
        message: 'Job Vacancy Created Successfully'
      });
    navigate(MANAGE_HIRING);
  } catch (err) {
    console.log('Error in creating job vacancy: ', err)
  }
};

export const updateVacancy = async (data, navigate) => {
  try {
    const res = await apiService.put('JobVacancy/UpdateJobVacancyDetails', data, true);
    if (res)
      Toast({
        type: 'success',
        message: 'Vacancy Details Updated Successfully'
      });
    navigate(MANAGE_HIRING);
  } catch (err) {
    console.log('Error in creating job vacancy: ', err)
  }
};
