import React, { useState, useRef, useEffect } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { ReactComponent as Grid } from '../../assets/img/grid.svg';
import { ReactComponent as Burger } from '../../assets/img/burger.svg';
import FilterDropDown from './filter-dropdown';
import SortDropDown from './sort-dropdown';

const Filter = ({ isRecruit }) => {
  const [filterMenu, setFilterMenu] = useState(false);
  const [sortMenu, setSortMenu] = useState(false);
  const filterRef = useRef(null);
  const sortRef = useRef(null);

  const handleFilterClick = () => {
    setFilterMenu(!filterMenu);
    setSortMenu(false);
  };

  const handleSortClick = () => {
    setSortMenu(!sortMenu);
    setFilterMenu(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setFilterMenu(false);
      }
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setSortMenu(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const menuListFilter = [
    {
      name: 'Department',
      route: ''
    },
    {
      name: 'Gender',
      route: ''
    }
  ];

  const menuListSort = [
    {
      name: 'Ascending',
      route: ''
    },
    {
      name: 'Descending',
      route: ''
    }
  ];

  return (
    <div className={`justify-center sm:justify-between flex flex-wrap items-center gap-y-2 gap-x-6 w-fit font-bold text-sm ${isRecruit ? 'text-white-100' : 'text-blue-100'}`}>
      <div className="flex gap-y-2 gap-x-6 items-center">
        <div className="flex items-center gap-1 cursor-pointer" ref={filterRef}>
          Filter by
          <FiChevronDown
            size={18}
            className='hover:bg-white-20 cursor-pointer text-pink-100'
            onClick={handleFilterClick}
          />
          <FilterDropDown filterMenu={filterMenu} parentRef={filterRef} menuList={menuListFilter} />
        </div>
        <div className="flex items-center gap-1 cursor-pointer" ref={sortRef}>
          Sort by
          <FiChevronDown
            size={18}
            className='hover:bg-white-20 cursor-pointer text-pink-100'
            onClick={handleSortClick}
          />
          <FilterDropDown filterMenu={sortMenu} parentRef={sortRef} menuList={menuListSort} />
        </div>
      </div>
      {!isRecruit && (
        <div className="flex items-center gap-3 py-2 px-4 bg-purple-50 rounded-[5px]">
          <div className="bg-purple-100 p-2 rounded-[5px] cursor-pointer">
            <Grid />
          </div>
          <Burger className="cursor-pointer" />
        </div>
      )}
    </div>
  );
};

export default Filter;
