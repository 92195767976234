import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { STORAGE_KEYS } from '../../../../helpers/constant';
import { apiService } from '../../../../helpers/api';
import { ReactComponent as DesktopIcon } from '../../../../assets/icons/airplay.svg';
import RadioGroup from '../../../../components/input/RadioGroup';

const UpcomingMeetings = () => {
  const [a, setA] = useState('');
  const { id } = useParams();
  const staffId = localStorage.getItem(STORAGE_KEYS?.EMPLOYEE_ID);
  const [loading, setLoading] = useState(false);
  const [outings, setOutings] = useState([]);

  const fetchOutings = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        'Event/Upcoming/Single',
        {
          employeeId: staffId,
          type: 2
        },
        true
      );
      const response = res?.data?.data;
      // Ensure outings is an array
      setOutings(Array.isArray(response) ? response : [response]);
    } catch (err) {
      console.log('Error in fetching outings: ', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOutings();
  }, []);

  const latestOuting = outings[0] || {};
  const nextOutings = outings.slice(1, 5).map((outing, index) => ({
    title: outing.title || `Outing ${index + 1}`,
    value: outing.eventId || `outing-${index + 1}`
  }));

  return (
    <div className='space-y-3 w-full md:w-[38%] '>
      <p className='font-bold '>Upcoming Meetings</p>

      <div className='bg-pink-100 p-4 rounded-sm space-y-4 '>
        <div className=' flex gap-3 items-center'>
          <DesktopIcon className=' w-9 h-9 p-1 rounded-[5px] bg-white-100' />
          <div>
            {outings.length === 0 && (
              <h4 className='text-sm text-white-100'>No Upcoming Meetings</h4>
            )}
            {outings.length === 1 && (
              <div>
                <h6 className=' text-xs text-white-100'>{latestOuting.title}</h6>
              </div>
            )}
            {outings.length > 1 && (
              <div>
                <h6 className=' text-xs text-white-100'>{latestOuting.title}</h6>
    
                <RadioGroup
                  name='upcomingMeetings'
                  selectedOption={a}
                  options={nextOutings}
                  onChange={(e) => setA(e.target.value)}
                  orientation={'vertical'}
                  isWhite
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingMeetings;
