import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Button from '../../components/button';
import { HeaderCard } from '../../components/cards/Cards';
import LayoutWithSideBar from '../../components/layout/LayoutWithSideBar';
import { EDIT_GOALS, MANAGE_EMPLOYEES } from '../../router/router';

const Performance = () => {
  const { pathname } = useLocation();
  return (
    <LayoutWithSideBar>
      <HeaderCard
        text={'Manage Performance'}
        withButton
        titleClassName={'font-bold'}
        button={<Button text={'EDIT GOALS INPUT'} isPink />}
        className={''}
        link={EDIT_GOALS}
        linkClassName={`${pathname.includes(EDIT_GOALS) && 'hidden'}`}>
        {/* <Link to={`${MANAGE_EMPLOYEES}`}>
          <Button text={'Employee Tracker'} isPink />
        </Link> */}
      </HeaderCard>
      <Outlet />
    </LayoutWithSideBar>
  );
};

export default Performance;
