import React, { useState } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../components/button';
import Modal from '../../../../../components/modal';
import CreateJobFirstPage from './CreateJobFirstPage';
import CreateJobSecondPage from './CreateJobSecondPage';

const CreateJob = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [modalStep, setModalStep] = useState(0);
  const [publishModal, setPublishModal] = useState(false);
  return (
    <div>
      <div className="space-y-10 relative">
        <div className=" bg-pink-100 py-4 flex rounded-xl items-center justify-between px-4 flex-wrap gap-4 ">
          <div className="text-white-100 flex gap-2 items-center">
            <MdOutlineArrowBack
              className=" cursor-pointer bg-white-30 w-6 h-6 p-1 rounded-full"
              onClick={() => navigate(-1)}
            />
            <p className="font-medium">Create Job Opening</p>
          </div>
          <div className="flex gap-4 flex-wrap items-center">
            <Button text={'Cancel'} isFaded className={''} onClick={() => navigate(-1)} />
            <Button isRecruit text={'Preview'} className={'text-white-100'} isTransparent />

            <Button
              text={'Publish'}
              className={''}
              onClick={() => {
                step === 1 && setPublishModal(!publishModal);
              }}
            />
          </div>
        </div>

        <div className="flex flex-wrap gap-6 justify-between items-start bg-white-100 rounded-md p-4">
          <CreateJobFirstPage step={step} setStep={setStep} />
          <CreateJobSecondPage step={step} setStep={setStep} />
        </div>
      </div>

      <Modal
        title={step === 0 && 'Are you sure to '}
        coloredTitle={step === 0 ? 'Publish Job?' : 'Direct Link of Published Job  '}
        showModal={publishModal}
        closeModal={() => setPublishModal(!publishModal)}
        content={
          <div className=" space-y-8">
            {modalStep === 0 && (
              <>
                <p className="text-gray-200 w-full sm:w-[70%] ">
                  Please preview or scan through job description before publishing it.
                </p>
                <div className="flex gap-4 flex-wrap">
                  <Button
                    className={''}
                    text="Go back"
                    onClick={() => setPublishModal(!publishModal)}
                    isGray
                  />
                  <Button
                    text="Publish Job"
                    isPink
                    className="bg-opacity-80"
                    onClick={() => setModalStep(modalStep + 1)}
                  />
                </div>
              </>
            )}

            {modalStep === 1 && (
              <>
                <p className="text-gray-200 w-full sm:w-[70%] ">
                  This link is securely published and ready to use at this address
                </p>
                <div className="flex gap-4 flex-wrap">
                  <Button className={''} text="All Links" isGray />
                  <Button text=" Copy Link" isPink className="bg-opacity-80" />
                </div>
              </>
            )}
          </div>
        }
      />
    </div>
  );
};

export default CreateJob;
