import React from 'react';
import Button from '../../../components/button';
import { AiOutlineArrowRight } from 'react-icons/ai';

const HelpDeskTopics = () => {
  return (
    <div className="w-full md:w-[50%] space-y-6">
      <div className="flex xs:justify-between gap-2 items-center xs:items-end flex-wrap justify-center flex-col xs:flex-row">
        <h4 className="text-lg">Popular Topics</h4>
        <p className="font-bold text-2xs underline text-pink-100"> See All</p>
      </div>

      <div className="flex flex-col items-center xl:flex-row xl:flex-wrap gap-3">
        <Button
          text="getting started"
          className={
            'flex items-center text-left justify-between tracking-normal text-sm capitalize flex-row-reverse bg-purple-100 bg-opacity-80 border border-purple-100 text-purple-100 px-4 w-[90%] xs:w-[50%] md:w-full  mdLg:w-[70%] xl:w-[43%]'
          }
          icon={<AiOutlineArrowRight size={15} className="" />}
          withIcon
        />
        <Button
          text="Accounts & Profiles"
          className={
            'flex items-center text-left justify-between tracking-normal text-sm capitalize flex-row-reverse bg-white-100 bg-opacity-60 border border-yellow-100 text-yellow-100 px-4 w-[90%] xs:w-[50%] md:w-full  mdLg:w-[70%] xl:w-[43%]'
          }
          icon={<AiOutlineArrowRight size={15} className="" />}
          withIcon
        />
        <Button
          text="Getting More out of PeopleCenta"
          className={
            'flex items-center text-left justify-between tracking-normal text-sm capitalize flex-row-reverse bg-pink-100 bg-opacity-80 border border-pink-100 text-pink-100 px-4 w-[90%] xs:w-[50%] md:w-full  mdLg:w-[70%] xl:w-[45%]'
          }
          icon={<AiOutlineArrowRight size={15} className="" />}
          withIcon
        />
        <Button
          text="Security"
          className={
            'flex items-center text-left justify-between tracking-normal text-sm capitalize flex-row-reverse bg-green-100 bg-opacity-80 border border-green-100 text-green-100 px-4 w-[90%] xs:w-[50%] md:w-full  mdLg:w-[70%] xl:w-[45%]'
          }
          icon={<AiOutlineArrowRight size={15} className="" />}
          withIcon
        />
      </div>
    </div>
  );
};

export default HelpDeskTopics;
