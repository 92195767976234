import React from 'react';
import { BiHelpCircle, BiLogOut } from 'react-icons/bi';
import { FiSettings } from 'react-icons/fi';
import { RiProfileFill } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { AUTH_LOGIN, HELPDESK, SETTINGS } from '../../router/router';
import { Toast } from '../toast/toast';

const UserDropDown = ({ showMenu }: { showMenu: boolean }) => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate(AUTH_LOGIN);
    Toast({
      type: 'success',
      message: 'Logged out Successfully'
    });
  };

  const menuList = [
    {
      name: 'Profile',
      icon: <RiProfileFill />,
      route: ''
    },
    {
      name: 'Settings',
      icon: <FiSettings />,
      route: SETTINGS
    },
    {
      name: 'Help Center',
      icon: <BiHelpCircle />,
      route: HELPDESK
    },
    {
      name: 'Logout',
      icon: <BiLogOut />,
      onClick: logout
    }
  ];
  return (
    <>
      {showMenu && (
        <div
          className={` bg-white-100 drop-shadow-2xl rounded-sm p-5 pr-9 absolute right-0 top-[5.5rem] space-y-4 font-medium`}>
          {menuList.map(({ name, icon, route, onClick }, i) => (
            <div
              className={`text-sm hover:font-bold cursor-pointer transition-all duration-100  ${
                menuList.length - 1 !== i ? 'text-black-100' : 'text-red-700'
              }`}
              key={i}>
              {route ? (
                <Link to={route} className='flex items-center gap-2'>
                  {icon} {name}
                </Link>
              ) : (
                <p className=' flex  items-center gap-2' onClick={onClick}>
                  {icon} {name}
                </p>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default UserDropDown;
