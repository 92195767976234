import React from 'react';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';

const PayrollCard = ({
  key,
  className,
  employeeNumber,
  employeeId,
  componentId,
  dept,
  name,
  rate,
  email,
  role,
  salaryAmount,
  editIcon,
  deleteIcon,
  onEditClick // Add this prop
}) => {
  return (
    <Link
      key={key}
      to={``}
      className={`bg-white-100 p-4 flex items-start justify-between font-semibold rounded-[5px] ${className}`}>
      <td>{name}</td>
      <td>{rate}</td>
      <td className=''>{email}</td>
      <td className='place-items-right'>{salaryAmount}</td>
      <td className='mr-4 place-items-right flex flex-row gap-4'>
        {editIcon}
        {deleteIcon}
        <FiEdit onClick={onEditClick} /> {/* Attach the onClick handler */}
      </td>
    </Link>
  );
};

export default PayrollCard;
