import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddEmployeeHeader from './AddEmployeeHeader';
import AddEmployeeForm from './AddEmployeeForm';
import { API } from '../../../../utils/apis';

const AddEmployee = () => {
  const navigate = useNavigate();
  const emptyForm = {
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    email: '',
    dob: '',
    homeAddress: '',
    otherAddress: '',
    phoneNumber: '',
    alternatePhoneNumber: '',
    startDate: '',
    employeeNumber: '',
    employeeType: 1,
    employmentRole: '',
    status: 1,
    designation: '',
    departmentId: 0,
    grade: '',
    gradeGroup: ''
  };
  
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(emptyForm);
  const [employees, setEmployees] = useState([]);
  const [employeeGrade, setEmployeeGrade] = useState([]);
  const [employeeGroup, setEmployeeGroup] = useState([]);
  const [employeeGroupOption, setEmployeeGroupOption] = useState([]);
  const [employeeGradeOption, setEmployeeGradeOption] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [departmentsOption, setDepartmentOption] = useState([]);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleImageUpload = async (file) => {
    if (!file || !formData.email) {
      setError('Please select an image and provide an email address.');
      return;
    }

    const imageData = new FormData();
    imageData.append('image', file);
    imageData.append('email', formData.email);

    try {
      console.log("Image Data: ", imageData);
      //await API.addEmployeeAvi(imageData);
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Failed to upload image.');
    }
  };

  const submitData = async (imageData) => {
    if (!formData.email) {
      setError('Email is required');
      return;
    }

    const fullName = `${formData.firstName} ${formData.lastName}`;
    const employeeExists = employees.some(employee => employee.fullName === fullName);
    const emailExists = employees.some(employee => employee.officeEmail === formData.email);

    if (employeeExists || emailExists) {
      let errorMessage = '';
      if (employeeExists) {
        errorMessage += 'Employee already exists';
      }
      if (employeeExists && emailExists) {
        errorMessage += ' or ';
      }
      if (emailExists) {
        errorMessage += 'Email already exists';
      }
      setError(errorMessage);
      return;
    }

    try {
      setLoading(true);
      await API.addEmployee(formData);

      //If there is an image, upload it
      if (imageData) {
        await handleImageUpload(imageData);
      }

      // Navigate or handle success
      /*navigate('/employees');*/
    } catch (error) {
      console.error('Error adding employee:', error);
      setError('Failed to add employee.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDepartments(departments);
    getEmployeeGroup(employeeGrade, employeeGroup);
  }, [departments, employeeGroup, employeeGrade]);

  const getDepartments = (department) => {
    let DEPARTMENT_ARRAY = [];
    for (let i = 0; i < department.length; i++) {
      DEPARTMENT_ARRAY.push({ label: department[i]?.name, value: department[i]?.id });
    }
    setDepartmentOption(DEPARTMENT_ARRAY);
  };

  const getEmployeeGroup = (group) => {
    let EMPLOYEE_GROUP_ARRAY = [], EMPLOYEE_GRADE_ARRAY = [];
    for (let i = 0; i < group.length; i++) {
      EMPLOYEE_GROUP_ARRAY.push({ label: group[i]?.gradeGroupName, value: group[i]?.id });
      EMPLOYEE_GRADE_ARRAY.push({ label: group[i]?.gradeName, value: group[i]?.id });
    }
    setEmployeeGroupOption(EMPLOYEE_GROUP_ARRAY);
    setEmployeeGradeOption(EMPLOYEE_GRADE_ARRAY);
  };

  const loadDepartmentData = async () => {
    setLoading(true);
    const departmentRes = await API.fetchAllDepartment();
    if (departmentRes) setDepartments(departmentRes);
    setLoading(false);
  };

  const loadEmployeeGradeGroupData = async () => {
    setLoading(true);
    const EmployeeGroupRes = await API.fetchAllGradeGroupById();
    const EmployeeGradeRes = await API.fetchAllGradeById();
    if (EmployeeGroupRes) setEmployeeGroup(EmployeeGroupRes);
    if (EmployeeGradeRes) setEmployeeGrade(EmployeeGradeRes)
    setLoading(false);
  };

  useEffect(() => {
    loadDepartmentData();
    loadEmployeeGradeGroupData();
  }, []);


  return (
    <div>
      <AddEmployeeHeader handleAddEmployee={submitData} loading={loading} />
      <AddEmployeeForm
        employees={employees}
        employeeGroup={employeeGroup}
        employeeGrade={employeeGrade}
        departments={departments}
        departmentsOption={departmentsOption}
        employeeGroupOption={employeeGroupOption}
        employeeGradeOption={employeeGradeOption}
        setFormData={setFormData}
        submitData={submitData}
        handleChange={handleChange}
        formData={formData}
        error={error}
      />
    </div>
  );
};

export default AddEmployee;
