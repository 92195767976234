import React, { useState } from 'react';

const StatusTabs = ({ small, list, className = 'bg-purple-30', activeClassName, onClick }) => {
  const [active, setActive] = useState(list[0]);

  const styles = {
    active: `text-white-100 bg-purple-100 rounded-[12.63px] ${
      small ? 'px-4 py-1' : 'px-6 py-[6px]'
    }  ${activeClassName} `
  };
  return (
    <div
      className={`font-semibold tracking-tight text-yellow-100 uppercase text-2xs  w-full rounded-[22px]  gap-6 flex flex-wrap items-center justify-center text-center 
    ${small ? 'px-1 py-1' : 'px-5 py-3'} ${className}
    `}>
      {list?.map((item) => (
        <span
          key={item}
          className={`${
            active === item && styles.active
          } cursor-pointer  transition-all duration-700 text-center`}
          onClick={() => {
            setActive(item);
            onClick(item);
          }}>
          {item}
        </span>
      ))}
    </div>
  );
};

export default StatusTabs;
