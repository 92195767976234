// LearningOverview.js
import React, { useState, useEffect } from 'react';
import { BiSearch } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/button';
import { LEARNING_EVENT, LEARNING_REQUEST } from '../../../router/router';
import Input from '../../../components/input';
import SearchWithFilter from '../../../components/general/SearchWithFilter';
import LearningContent from './LearningContent';
import PaginationBar from './PaginationBar';
import { apiService } from '../../../helpers/api';

const LearningOverview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [upcoming, setUpcoming] = useState([]);
  const [previous, setPrevious] = useState([]);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const resUpcoming = await apiService.get(
          'Event/Upcoming',
          {
            startDate: '2022-10-26',
            endDate: '2023-12-31',
            employeeId: id // Replace with the actual employee ID
          },
          true
        );

        const upcomingEvents = resUpcoming?.data?.data || [];
        const totalItems = upcomingEvents.length;
        const calculatedTotalPages = Math.ceil(totalItems / 6); // 6 items per page

        setUpcoming(upcomingEvents);
        setTotalPages(calculatedTotalPages);
        setCurrentPage(1);
      } catch (err) {
        console.log('Error in fetching upcoming learning events: ', err)
      }
    };

    const fetchPreviousData = async () => {
      try {
        const resPrevious = await apiService.get(
          'Event/Previous',
          {
            employeeId: id
          },
          true
        );

        const previousEvents = resPrevious?.data?.data || [];

        setPrevious(previousEvents);
      } catch (err) {
        console.log('Error in fetching previous learning events: ', err)
      }
    };

    fetchEventData();
    fetchPreviousData();
  }, []);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className='white-bg'>
      <div className="flex flex-col gap-4 justify-between">
        <div className='flex flex-wrap gap-4 justify-end'>
          <Button
            isOrange
            text={'CREATE LEARNING EVENT'}
            onClick={() => navigate(LEARNING_EVENT)}
          />
          <Button
            isPurple
            text={'CREATE LEARNING REQUEST'}
            onClick={() => navigate(LEARNING_REQUEST)}
          />
        </div>
        <div className='bg-purple-30 rounded-3xl px-10 py-7 space-y-5'>
          <Input 
            isRounded
            withIcon
            icon={<BiSearch size={20} color='#FF365E' />}
            className='' 
            placeholder={'Search upcoming trainings'} 
          />
          <LearningContent currentPage={currentPage} totalPages={totalPages} upcoming={upcoming} previous={previous} />
          <PaginationBar
            currentPage={currentPage}
            totalPages={totalPages}
            onNextPage={handleNextPage}
            onPreviousPage={handlePreviousPage}
          />
        </div>
      </div>
    </div>
  );
};

export default LearningOverview;
