import React from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import Button from '../../../components/button';
import LandingFooter from '../../../components/landing-page/LandingFooter';
import LandingPageHeader from '../../../components/landing-page/LandingPageHeader';

const Pricing = () => {
  return (
    <div className="bg-blue-100 text-white-100">
      <LandingPageHeader />

      <div className="py-32 space-y-10">
        <div className="space-y-6 font-bold text-center w-[55%] mx-auto h-[80vh] relative">
          <h2 className="text-4xl">Manage your work effectively.</h2>
          <p className="text-base">
            Create and post job openings; track, screen, hire and onboard new employees with aid of
            visual dashboard, Create and post job openings
          </p>
          <p className="text-orange-100 text-base">
            Choose a plan that is right for your company below.
          </p>
        </div>

        <div className="rounded-2xl border-2 border-white-100 p-6 bg-purple-400 bg-opacity-50 mx-auto w-full md:w-[55%] space-y-6 mt-32 absolute left-1/2 -translate-x-1/2 top-[25rem] ">
          <div className="flex justify-between items-center pb-4 border-b-2">
            <div className="flex items-center">
              <p className="text-base md:text-2xl font-bold">$500</p>
              <p className="text-sm font-normal">/month</p>
            </div>
            <p className="text-base md:text-2xl font-bold">Startup Plan</p>
          </div>

          <ul className="flex justify-between">
            <div className="space-y-4">
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
            </div>
            <div className="space-y-4">
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
            </div>
            <div className="space-y-4">
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
              <li className="flex gap-2 items-center text-white-100">
                <BsCheckCircleFill />
                All limited links
              </li>
            </div>
          </ul>

          <span className="flex justify-center pt-4 pb-24">
            <Button text={'Make Payment'} className="mx-auto" />
          </span>
        </div>
        <div className="bg-white-100  py-32"></div>
      </div>
      <LandingFooter />
    </div>
  );
};

export default Pricing;
