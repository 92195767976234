import React, { useState } from 'react';
import moment from 'moment/moment';
import TerminateEmployeeModal from './terminate-modal';
import { getEmployeeType } from '../../../../helpers/functions';

const EmploymentInfo = ({ employee }) => {
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);

  const closeModal = () => {
    setShowEmployeeModal(false);
  };

  const employmentId = employee?.employeeNextofKins[0];

  return (
    <div className='space-y-4'>
      <h6 className=''>EMPLOYMENT STATUS</h6>
      <div className='font-semibold space-y-6'>
        <div className='flex justify-between gap-4 flex-wrap '>
          <div className='flex flex-col justify-start gap-1'>
            <span className='capitalize text-gray-100 text-sm'> Start Date</span>
            <span className='text-black-100 text-base'>
              {' '}
              {moment(employee?.startDate).format('Do, MMM YYYY')}
            </span>
          </div>

          <div className='flex flex-col justify-start gap-1'>
            <span className='capitalize text-gray-100 text-sm'> Status</span>
            <span className='text-green-100 text-base capitalize'> Active</span>
          </div>

          <div className='flex flex-col justify-start gap-1'>
            <span className='capitalize text-gray-100 text-sm'> Employee ID</span>
            <span className='text-black-100 text-base'> JKI-{employee?.id}</span>
          </div>
        </div>
        <div className='flex flex-col justify-start gap-1'>
          <span className='capitalize text-gray-100 text-sm'>Employment Type</span>
          <span className='text-black-100 text-base'> {getEmployeeType(employee?.employeeType)}</span>
        </div>

        <div className='flex flex-wrap justify-between items-start gap-6'>
          <div className=' space-y-6'>
            <h6 className=''>work type</h6>

            <div className='space-y-6'>
              <div className='flex flex-col justify-start gap-1'>
                <span className='capitalize text-gray-100 text-sm'>Department</span>
                <span className='text-black-100 text-base'> {employee?.department?.name}</span>
              </div>
              <div className='flex flex-col justify-start gap-1'>
                <span className='capitalize text-gray-100 text-sm'>Designation</span>
                <span className='text-black-100 text-base capitalize'> {employee?.designation}</span>
              </div>
            </div>
          </div>{' '}
          <div className='flex flex-col justify-start gap-1'>
            <span className='capitalize text-gray-100 text-sm'>
              Employee Termination / Activiation
            </span>
            <button
              onClick={() => setShowEmployeeModal(true)}
              className='rounded-[50px] bg-peach-100 px-6 font-semibold cursor-pointer py-2 text-center'>
              Terminate Employment
            </button>
          </div>
        </div>
      </div>

      <TerminateEmployeeModal show={showEmployeeModal} closeModal={closeModal} />
    </div>
  );
};

export default EmploymentInfo;
