import React, { useState, useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiLoaderAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { fetchAllBehaviouralObjs } from '../../../../utils/apis/api-modules/performance';
import Pagination from '../../../../components/general/pagination';

const PerformanceReport = () => {
  const [loading, setLoading] = useState(false);
  const [performanceReports, setPerformanceReports] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPerformanceReports = performanceReports.slice(indexOfFirstItem, indexOfLastItem);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchPerformanceReports = async () => {
    setLoading(true);
    const res = await fetchAllBehaviouralObjs();
    if (res) setPerformanceReports(res);
    setLoading(false);
  };

  useEffect(() => {
    fetchPerformanceReports();
  }, []);

  return (
    <div className='rounded-[20px] bg-purple-30 p-10 my-6'>
      <div>
        <div className='whitespace-nowrap overflow-x-scroll'>
          <table className='w-full'>
            <thead className='border-collapse border-red-500'>
              <tr className='w-[100%] text-left uppercase tracking-[3px] text-blue-100 font-semibold text-2xs pl-28 mb-4'>
                <th className='p-6 text-center'>PERFORMANCE ID</th>
                <th className='p-6 text-center'>EMPLOYEE NAME</th>
                <th className='p-6 text-center'>JOB ROLE</th>
                <th className='p-6 text-center'>GOAL</th>
                <th className='p-6 text-center'>TARGETS</th>
                <th className='p-6 text-center'>DUE DATE</th>
                <th className='p-3 px-6 text-center'>ACTION</th>
              </tr>
            </thead>
            <tbody className='rounded-[20px]'>
              {currentPerformanceReports.map((performanceReport, i) => (
                <tr
                  className='cursor-pointer border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                  key={performanceReport?.id}
                  onClick={() => navigate(``)}
                >
                  <td className='p-3 px-6 text-center'>
                    {performanceReport.performanceId}
                  </td>
                  <td className='p-3 px-6 text-center'>{performanceReport.name}</td>
                  <td className='p-3 px-6 text-center'>{performanceReport.jobRole}</td>
                  <td className='p-3 px-6 text-center'>{performanceReport.goal ? performanceReport.goal: 'nil'}</td>
                  <td className='p-3 px-6 text-center'>{performanceReport.targets}</td>
                  <td className='p-3 px-6 text-center'>{new Date(performanceReport.dueDate).toLocaleDateString()}</td>
                  <td className='p-3 px-6 grid place-items-center'><RiDeleteBinLine /></td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='pb-10 flex flex-col items-center'>
            {loading && (
              <BiLoaderAlt
                className='animate-spin my-10 flex text-center text-blue-100 justify-center mx-auto'
                size={30}
              />
            )}
            {!loading && performanceReports.length === 0 && (
              <span className='p-3'>There are currently no leave settings to show</span>
            )}
          </div>
        </div>
      </div>
      <Pagination
        totalPages={Math.ceil(performanceReports.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default PerformanceReport;
