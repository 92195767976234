import React from 'react';
import { GoCalendar } from 'react-icons/go';
import { Link } from 'react-router-dom';
import Button from '../../../../components/button';
import ApplicationCards from '../../../../components/cards/people_recruit/ApplicationCards';
import Filter from '../../../../components/general/filter';
import { LEADERBOARD, NEW_INTERVIEW, SCHEDULE_DETAILS } from '../../../../router/router';

const ScheduleLayout = () => {
  return (
    <div className="space-y-5">
      <div className=" bg-pink-100 py-4 flex flex-wrap gap-2 rounded-xl items-center justify-between px-4 text-white-100">
        <div className="flex items-center gap-2 flex-wrap">
          <Link to={LEADERBOARD} className="flex justify-end">
            <Button text={'Today'} isFaded className={'flex gap-2 text-white-100 items-center'} />
          </Link>
          <p className="font-medium">135 interviews</p>
        </div>
        <div className="flex items-center gap-2 flex-wrap">
          <Link to={''} className="flex justify-end">
            <Button
              text={'View Calendar'}
              icon={<GoCalendar size={12} />}
              withIcon
              isFaded
              className={'flex gap-2 text-white-100 items-center'}
            />
          </Link>
          <Link to={NEW_INTERVIEW} className="flex justify-end">
            <Button text={'+ New Interview'} isFaded />
          </Link>
          <Filter isRecruit />
        </div>
      </div>

      <div className=" bg-white-100 rounded-md p-4 sm:px-10  space-y-6">
        <div className="space-y-4">
          <h6 className="text-black-100">this week</h6>
          <div className="flex justify-start gap-4 items-center flex-wrap">
            <ApplicationCards
              role="Product Manager"
              company="Revohub Technologies"
              location={'Lagos'}
              status="Full Time"
              dept={'Information Technology'}
              link={SCHEDULE_DETAILS}
            />
            <ApplicationCards
              role="Product Manager"
              company="Revohub Technologies"
              location={'Lagos'}
              status="Full Time"
              dept={'Information Technology'}
            />
            <ApplicationCards
              role="Product Manager"
              company="Revohub Technologies"
              x
              location={'Lagos'}
              status="Full Time"
              dept={'Information Technology'}
            />
          </div>
        </div>
        <div className="space-y-4">
          <h6 className="text-black-100">this month</h6>
          <div className="flex justify-start gap-4 items-center flex-wrap">
            <ApplicationCards
              role="Product Manager"
              company="Revohub Technologies"
              location={'Lagos'}
              status="Full Time"
              dept={'Information Technology'}
            />
            <ApplicationCards
              role="Product Manager"
              company="Revohub Technologies"
              location={'Lagos'}
              status="Full Time"
              dept={'Information Technology'}
            />
            <ApplicationCards
              role="Product Manager"
              company="Revohub Technologies"
              location={'Lagos'}
              status="Full Time"
              dept={'Information Technology'}
            />
            <ApplicationCards
              role="Product Manager"
              company="Revohub Technologies"
              location={'Lagos'}
              status="Full Time"
              dept={'Information Technology'}
            />
            <ApplicationCards
              role="Product Manager"
              company="Revohub Technologies"
              location={'Lagos'}
              status="Full Time"
              dept={'Information Technology'}
            />
            <ApplicationCards
              role="Product Manager"
              company="Revohub Technologies"
              location={'Lagos'}
              status="Full Time"
              dept={'Information Technology'}
            />
            <ApplicationCards
              role="Product Manager"
              company="Revohub Technologies"
              location={'Lagos'}
              status="Full Time"
              dept={'Information Technology'}
            />
            <ApplicationCards
              role="Product Manager"
              company="Revohub Technologies"
              location={'Lagos'}
              status="Full Time"
              dept={'Information Technology'}
            />
            <ApplicationCards
              role="Product Manager"
              company="Revohub Technologies"
              location={'Lagos'}
              status="Full Time"
              dept={'Information Technology'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleLayout;
