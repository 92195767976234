import React from 'react';
import { DateRangePicker } from 'react-date-range';
import { format } from 'date-fns'; // Import date-fns for date formatting
import './styles.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DatePicker = ({ selectionRange, handleSelect }) => {

  // Handle the date selection
  const handleSelectWithFormatting = (ranges) => {
    const { startDate, endDate } = ranges.selection;

    // Format dates to 'yyyy-mm-dd'
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    // Call the original handleSelect with formatted dates
    handleSelect({
      ...ranges,
      selection: {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        key: 'selection'
      }
    });
  };

  return (
    <div className="react-calendar">
      <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleSelectWithFormatting}
        moveRangeOnFirstSelection={false}
        editableDateInputs={true}
        color={'#000000'}
      />
    </div>
  );
};

export default DatePicker;
