import axios from 'axios';
import { STORAGE_KEYS } from './constant';

// eslint-disable-next-line no-undef
const BASE_API_URL = process.env.REACT_APP_API_URL;
const getToken = () => {
  const token = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN);
  return token;
};
// prettier --write ./**/*.{js,ts,tsx,css,md,json} --config ./.prettierrc

const makeRequest = (urlPath, options, useToken) => {
  const headers = options?.headers || {};
  if (useToken) headers['Authorization'] = 'Bearer ' + getToken();
  const fullUrl = BASE_API_URL + urlPath;
  return axios({ ...options, url: fullUrl, headers: headers });
};

export const apiService = {
  makeRequest: makeRequest,
  get: (urlPath, params, useToken) =>
    makeRequest(urlPath, { method: 'get', params: params }, useToken),
  patch: (urlPath, data, useToken) =>
    makeRequest(urlPath, { method: 'patch', data: data }, useToken),
  post: (urlPath, data, useToken) => makeRequest(urlPath, { method: 'post', data: data }, useToken),
  put: (urlPath, data, useToken) => makeRequest(urlPath, { method: 'put', data: data }, useToken),
  delete: (urlPath, useToken) => makeRequest(urlPath, { method: 'delete' }, useToken)
};
