import React from 'react';
import EditCard from '../../../../../components/cards/people_recruit/EditCard';

const JobDetailsLeft = ({ job = [] }) => {
  
  return (
    <div className=" w-full md:w-[45%] space-y-10">
      <EditCard title={'Overview'} className="w-full">
        <div>
          <div className="space-y-6">
            <div className="flex flex-wrap gap-4">
              <div>
                <h4 className="text-black-100">{job?.jobTitle || 'Product Manager'}</h4>
                <p className=" text-gray-200">{job?.department}</p>
              </div>
              <div>
                <h4 className="text-pink-100">Revohub Technologies</h4>
                <p className="text-yellow-100">Lagos | Full Time</p>
              </div>
            </div>

            <div className="flex flex-wrap gap-4">
              <div>
                <p className="text-gray-200">Application closes on</p>
                <p className="text-blue-100">16/04/2022</p>
              </div>
              <div>
                <p className="text-gray-200">Interview Date</p>
                <p className="text-blue-100"> N/A</p>
              </div>
            </div>
          </div>
        </div>{' '}
      </EditCard>

      <EditCard title={'Job Description'} className="w-full">
        {' '}
        <div>
          <p>{job?.keyResponsibility || 'No job description provided.'}</p>
        </div>{' '}
      </EditCard>

      <EditCard title={'Key Requirements'} className="w-full">
        {' '}
        <div>
          <p>{job?.keyRequirement || 'No key requirements provided.'}</p>
        </div>{' '}
      </EditCard>

      <EditCard title={'Skills'} className="w-full">
        <div>
          <p>{job?.skillRequirement || 'No skill requirements provided.'}</p>
        </div>
      </EditCard>
    </div>
  );
};

export default JobDetailsLeft;
