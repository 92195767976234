import React from 'react';
import './styles.scss';
export default function RadioGroup({
  className,
  name,
  options,
  onChange,
  orientation,
  selectedOption,
  required,
  isWhite
}) {
  return (
    <div
      className={`flex justify-between RadioGroup ${className} ${
        orientation === 'vertical' ? 'flex-col space-y-2' : ''
      }`}>
      {options?.length > 0 &&
        options.map(({ value, title }, idx) => (
          <div key={idx} className={`radio-label text-sm mr-2 w-full `}>
            <label htmlFor={`${name}-${title}-${idx}`} className=''>
              <input
                data-testid={name}
                id={`${name}-${title}-${idx}`}
                name={name}
                className={`radio mr-2 leading-tight ${isWhite && 'radio--white'}`}
                onChange={onChange}
                type='radio'
                value={value}
                checked={selectedOption === value}
                required={required}
              />
              <span className='text-sm text-primary-dark'>{title}</span>
              <span className='bg-current checkmark'></span>
            </label>
          </div>
        ))}
    </div>
  );
}
