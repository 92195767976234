import React from 'react';
import { PinkHeaderWithFilter } from '../../../../../components/cards/Cards';
import { candidates } from '../../../../../utils/peopleRecruitArrays';

const LeaderBoard = () => {
  return (
    <div className='space-y-6'>
      <PinkHeaderWithFilter goBack text={'Leaderboard'} />
      <div className=' bg-white-100 rounded-md p-4 sm:px-10  space-y-6'>
        <div className='w-full overflow-scroll whitespace-nowrap '>
          <table className='w-full  border-spacing-2  table-auto'>
            <thead className=' border-collapse border-red-500  '>
              <tr className='text-left text-blue-100'>
                <th></th>
                <th className='p-3 '>Name</th>
                <th className='p-3 '> Position</th>
                <th className='p-3 '> Qualification</th>
                <th className='p-3 '>Email Address</th>
                <th className='p-3 '>Score</th>
                <th></th>
              </tr>
            </thead>

            <tbody className='text-gray-100'>
              {candidates.map((candidate, i) => {
                return (
                  <tr className='' key={i}>
                    <td className='p-3'>{i + 1}.</td>
                    <td className='p-3'>{candidate.name}</td>
                    <td className='p-3'>{candidate.role}</td>
                    <td className='p-3'>{candidate.qualification}</td>
                    <td className='p-3'>{candidate.email}</td>
                    <td className='p-3 capitalize'>{candidate.score}</td>
                    <td className='p-3 text-pink-100 italic'>
                      {candidate.score === 'passed' && <span>Schedule date</span>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;
