import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import Modal from '../../../../components/modal';
import { API } from '../../../../utils/apis';
import { apiService } from '../../../../helpers/api';
import { MANAGE_EVENTS } from '../../../../router/router';

const AddAnnouncement = ({ show, closeModal }) => {
  const [modalData, setModalData] = useState({
    date: '',
    title: '',
    description: ''
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const submitData = async (e) => {
    e.preventDefault();
    setLoading(true);
    await API.addAnnouncement(modalData, navigate);
    closeModal();
    setLoading(false);
    window.location.reload();
  };

  /*const submitData = async (e) => {
    setLoading(true);
    console.log(modalData);
    await API.addAnnouncement(modalData, navigate);
    setLoading(false);
    e.preventDefault();
  };*/

  return (
    <Modal
      title={'Add Announcement'}
      showModal={show}
      closeModal={closeModal}
      content={
        <div className='space-y-10 mt-4 rounded-lg w-[400px]'>
          <Input
            withLabel
            onChange={handleChange}
            value={modalData.title}
            label='Title'
            containerClass='w-full'
            name='title'
          />
          <Input
            withLabel
            onChange={handleChange}
            value={modalData.date}
            label='Date'
            type='date'
            containerClass='w-full'
            name='date'
          />
          <Input
            withLabel
            onChange={handleChange}
            value={modalData.description}
            label='Description'
            containerClass='w-full'
            name='description'
          />
          <div className='flex gap-4 flex-wrap justify-end'>
            <Button
              className='border border-pink-100'
              text='cancel'
              onClick={closeModal}
              isTransparent
            />
            <Button text='submit' onClick={submitData} isPurple />
          </div>
        </div>
      }
    />
  );
};

export default AddAnnouncement;
