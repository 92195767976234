import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdUpload, MdOutlineArrowBack } from 'react-icons/md';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import Select from '../../../../components/input/Select';
import { TextArea } from '../../../../components/input';
import { COST_TYPE } from '../../../../helpers/constant';
import { MANAGE_LEARNING } from '../../../../router/router';
import { API } from '../../../../utils/apis';

const LearningRequest = () => {
  const emptyForm = {
    title: '',
    date: '',
    time: '',
    description: '',
    type: 1,
    location: '',
    targetedCompetencies: '',
    objectives: '',
    amount: 0,
    pricing: 1
  };

  const [formData, setFormData] = useState(emptyForm);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const submitData = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData);
    //await API.createLearningRequest(formData, navigate);
    setLoading(false);
  };

  return (
    <div className="white-bg xs:w-[80%] sm:w-[70%] mdLg:w-full mdLg:mx-0 mx-auto">
      <div
        className="uppercase text-xs flex items-center justify-start space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer"
        onClick={() => navigate(-1)}>
        <MdOutlineArrowBack /> <span className="text-blue-100"> BACK</span>
      </div>
      <form onSubmit={submitData} className="space-y-10 mt-4">
        <div className="space-y-8 bg-pink-30 shadow-xl rounded-3xl p-4 md:p-10">
          <h4 className="text-center mdLg:text-left mb-4">Create Learning Request</h4>
          <div className='flex flex-col w-full'>
           <Input
              withLabel
              label="EVENT TITLE"
              containerClass=""
              value={formData.title}
              name="title"
              onChange={(e) => handleChange(e, 'title')}
            />
          </div>
          <div className='w-full mdLg:flex mdLg:space-x-5'>
            <div className='flex-1 mdLg:w-1/2 mb-2 mdLg:mb-0'>
              <Input
                withLabel
                label='DATE'
                type='date'
                onChange={(e) => setFormData({
                  ...formData,
                  date: e?.target?.value
                })}
              />
            </div>
            <div className='flex-1 mdLg:w-1/2 mb-2 mdLg:mb-0'>
              <Input
                withLabel
                label='TIME'
                type='time'
                onChange={(e) => setFormData({
                  ...formData,
                  time: e?.target?.value
                })}
              />
            </div>
          </div>

          <div className='flex flex-col w-full'>
            <Input
              withLabel
              label='LOCATION'
              containerClass=''
              className=''
              value={formData.location}
              name='location'
              onChange={(e) => setFormData({
                ...formData,
                location: e?.target?.value
              })}
            />
          </div>

          <div className='w-full mdLg:flex mdLg:space-x-5'>
            <div className='flex-1 mdLg:w-1/2 mb-2 mdLg:mb-0'>
              <Select
                withLabel
                label='COST'
                containerClass=''
                className=''
                selectedOption={formData.pricing}
                options={COST_TYPE}
                name='pricing'
                onChange={(e) => setFormData({
                  ...formData,
                  pricing: e?.value
                })}
              />
            </div>
            <div className='flex-1 mdLg:w-1/2 mb-2 mdLg:mb-0'>
              <Input
                withLabel
                label='AMOUNT (₦)'
                containerClass=''
                className=''
                value={formData.amount.toLocaleString()}
                name='amount'
                onChange={(e) => setFormData({
                  ...formData,
                  amount: e?.target?.value
                })}
              />
            </div>
          </div>

          <div className="w-full">
            <TextArea
              withLabel
              label="DESCRIPTION"
              containerClass=""
              className='h-32'
              value={formData.description}
              name='Description'
              onChange={(e) => handleChange(e, 'description')}
            />
          </div>
  
          <div className="w-full">
            <TextArea
              withLabel
              label="TARGETED COMPETENCY"
              containerClass=""
              className='h-32'
              value={formData.targetedCompetencies}
              name="Targeted Competency"
              onChange={(e) => handleChange(e, 'targetedCompetencies')}
            />
          </div>

          <div className="w-full">
            <TextArea
              withLabel
              label="TRAINING OBJECTIVES"
              containerClass=""
              className='h-32'
              value={formData.objectives}
              name='Objectives'
              onChange={(e) => handleChange(e, 'objectives')}
            />
          </div>

          <div className="flex gap-4 flex-wrap justify-end">
            <Button
              className="border border-pink-100 text-pink-100"
              text="Cancel"
              onClick={() => navigate(MANAGE_LEARNING)}
              isTransparent
            />
            <Button 
             text="Send For Approval" 
             type="submit" 
             isOrange 
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default LearningRequest;
