import React from 'react';

const DashWithNumbers = ({ no, isBlue }) => {
  return (
    <div
      className={`sm:flex hidden text-white-100 gap-2  flex-col items-center justify-center absolute left-12 top-[50%] -translate-y-[50%] ${
        isBlue && 'text-blue-100'
      }`}>
      <div className="text-center">{no}</div>
      <div className={`w-[1px] h-[100px]  bg-white-100 ${isBlue && 'bg-blue-100'}`}></div>
      <div className="text-center">06</div>
    </div>
  );
};

export default DashWithNumbers;
