import React from 'react';
// import { IoBriefcaseOutline } from 'react-icons/io5';
import Button from '../../../../../components/button';
// import Input from '../../../../../components/input';
// import CheckBox from '../../../../../components/input/CheckBox';
import { ReactComponent as Desktop } from '../../../../../assets/img/apply.svg';
import DP from '../../../../../assets/img/DP.png';
import { BsBriefcase } from 'react-icons/bs';

const ApplicationFormRight = () => {
  return (
    <div className='  w-full space-y-[1px]'>
      <div className=' w-full  bg-white-100 text-gray-100 py-10'>
        <div className='w-[85%] mx-auto flex flex-col gap-4 justify-center items-center'>
          <img src={DP} alt='' />
          <p className=' text-gray-100'>Priscilla Holmes</p>
        </div>
      </div>

      <div className=' w-full  bg-white-100 text-gray-100 py-10'>
        <div className='w-[85%] mx-auto flex flex-col gap-4 justify-center items-left'>
          <p className='flex gap-3 flex-wrap items-center text-xs text-pink-100'>
            <BsBriefcase />
            <span className=' text-gray-100 font-semibold'> 26 Applications this month</span>
          </p>
          <p className='flex gap-3 flex-wrap items-center text-xs text-pink-100'>
            <BsBriefcase />
            <span className=' text-gray-100 font-semibold'> Last Profile update 2 weeks ago</span>
          </p>
        </div>
      </div>

      <div className='bg-blue-100 mt-3'>
        <div className='flex flex-col items-center'>
          <Desktop className=' scale-75 ' />
          <div className='pb-4'>
            <Button isPink className={' w-full rounded-none '} text='POST A JOB' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationFormRight;
