import React, { useState } from 'react';
import BaseModal from '../../../../components/base/modal';
import Button from '../../../../components/button';
import { Toast } from '../../../../components/toast/toast';
import { apiService } from '../../../../helpers/api';

const ApproveLeaveModal = ({
  showApproveModal,
  toggleModal,
  pendingLeaveRequests,
  activeLeaveRequest,
  setPendingLeaveRequests,
  setApprovedLeaveRequests,
  approvedLeaveRequests
}) => {
  const [loading, setLoading] = useState(false);

  const approveLeaveRequest = async () => {
    setLoading(true);
    try {
      const res = await apiService.post(
        `Leave/Request/Approve?leaveRequestId=${activeLeaveRequest?.leaveId}&approve=true`
      );
      const filteredRequests = pendingLeaveRequests.filter(
        ({ leaveId }) => leaveId != activeLeaveRequest?.leaveId
      );

      if (res) {
        setPendingLeaveRequests(filteredRequests);
        setApprovedLeaveRequests([...approvedLeaveRequests, activeLeaveRequest]);
        toggleModal();
        Toast({
          type: 'success',
          message: 'Leave Request Aproved'
        });
      }
    } catch (err) {
      console.log('Error in approving leave request: ', err)
    } finally {
      setLoading(false);
    }
  };
  return (
    <BaseModal
      title={'Are you sure you want to '}
      coloredText='Approve? '
      show={showApproveModal}
      onClose={toggleModal}>
      <div className='flex gap-4 flex-wrap mt-6 justify-end'>
        <Button className={''} text='Go back' onClick={toggleModal} isGray />
        <Button
          text='Approve'
          isGreen
          className='bg-opacity-80'
          loading={loading}
          onClick={approveLeaveRequest}
        />
      </div>
    </BaseModal>
  );
};

export default ApproveLeaveModal;
