import React from 'react';
import { Outlet } from 'react-router-dom';
// import Button from '../../../../../components/button';
// import { PinkHeaderWithFilter } from '../../../../../components/cards/Cards';
// import { SCREENING_SCORES } from '../../../../../router/router';
// import { candidates } from '../../../../../utils/peopleRecruitArrays';

const JobApplicantsLayout = () => {
  return <Outlet />;
};

export default JobApplicantsLayout;
