import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { AUTH_LOGIN } from '../../../router/router';
import authService from '../../../services/auth.service';
import { ReactComponent as Logo } from '../../../assets/img/logo.svg';
import { BiMailSend } from 'react-icons/bi';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const passwordReset = async (e) => {
    setLoading(true);
    e.preventDefault();
    const res = await authService.forgotPassword(email);
    if (res) setShow(true);
    setLoading(false);
  };
  
  return (
    <div className=' grid md:h-[100vh] py-8'>
      <div className='flex flex-col items-center justify-center w-full md:w-[90%] md:mx-auto'>
        <Logo className='' />
        <form
          onSubmit={passwordReset}
          className=' flex flex-col gap-8 items-start w-[90%] md:w-[75%] my-8'>
          <Input
            withLabel
            onChange={(e) => setEmail(e.target.value)}
            label='email'
            className=''
            containerClass='w-full md:w-[60%] mx-auto'
            name={'email'}
            value={email}
            disabled={show}
          />

          {!show ? (
            <Button
              disabled={loading}
              loading={loading}
              text={' Confirm'}
              className={'w-auto mx-auto md:px-16'}
              isPurple
              type='submit'
            />
          ) : (
            <p className=' text-black-200 text-center text-sm md:text-xl font-medium flex gap-1 items-center mx-auto'>
              <BiMailSend /> <span>Please Check Your Email For A Password Reset Link!</span>
            </p>
          )}

          <p className=' text-center mx-auto mt-6'>
            Back To{' '}
            <span
              onClick={() => navigate(AUTH_LOGIN)}
              className=' font-semibold text-black-100 underline cursor-pointer'>
              Login
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
