import React from 'react';
import EditCard from '../../../../../components/cards/people_recruit/EditCard';

const JobDetailsRight = () => {
  const applicants = [
    {
      name: 'Adaezinne Sinminsola ',
      email: 'sinmin4you@gmail.com'
    },
    {
      name: 'Adaezinne Sinminsola ',
      email: 'sinmin4you@gmail.com'
    },
    {
      name: 'Adaezinne Sinminsola ',
      email: 'sinmin4you@gmail.com'
    },
    {
      name: 'Adaezinne Sinminsola ',
      email: 'sinmin4you@gmail.com'
    },
    {
      name: 'Adaezinne Sinminsola ',
      email: 'sinmin4you@gmail.com'
    },
    {
      name: 'Adaezinne Sinminsola ',
      email: 'sinmin4you@gmail.com'
    }
  ];
  return (
    <div className=" w-full md:w-[50%] space-y-10">
      <EditCard title={'Applicants (78)'} className="w-full">
        <ol className="list list-decimal ">
          {applicants.map((item, idx) => (
            <li
              className=" flex flex-wrap justify-between gap-2 border-b border-b-gray-20 py-4"
              key={idx}>
              <p>
                {idx + 1}. {'  '} {item?.name}
              </p>
              <p className="text-blue-light">{item?.email}</p>
              <p className="text-pink-100 italic">view details</p>
            </li>
          ))}
        </ol>
      </EditCard>
    </div>
  );
};

export default JobDetailsRight;
