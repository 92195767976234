import React from 'react';
import { ReactComponent as Absence } from '../../../assets/img/landing/absence.svg';
import { ReactComponent as Compensation } from '../../../assets/img/landing/compensation.svg';
import { ReactComponent as Hiring } from '../../../assets/img/landing/hiring.svg';
import { ReactComponent as Performance } from '../../../assets/img/landing/performance.svg';
import { ReactComponent as Time } from '../../../assets/img/landing/time.svg';
import { ReactComponent as Jobs } from '../../../assets/img/landing/jobs.svg';
import MiniCard from '../../../components/landing-page/MiniCard';

const ResourceTab = () => {
  return (
    <div className="border border-yellow-100 flex flex-col items-center justify-center py-4 w-full xl:w-[95%] rounded-md gap-y-10">
      <div className="space-y-3 -mt-9">
        <div className="flex md:flex-row flex-col gap-4">
          <MiniCard title={'hiring'} icon={<Hiring />} active />
          <MiniCard title={'time'} icon={<Time />} />
        </div>
        <div className="flex md:flex-row flex-col gap-4">
          <MiniCard title={'compensation'} icon={<Compensation />} />
          <MiniCard title={'absence'} icon={<Absence />} />
        </div>
        <div className="flex md:flex-row flex-col gap-4">
          <MiniCard title={'jobs'} icon={<Jobs />} />
          <MiniCard title={'performance'} icon={<Performance />} />
        </div>
      </div>

      <div className="h-[2px] w-full md:w-[80%] rounded-md bg-yellow-100"></div>

      <div className="flex flex-col w-[80%] mx-auto gap-y-3 ">
        <p className="text-yellow-100 text-lg md:text-xl uppercase font-bold">Manage Jobs</p>
        <p className="text-blue-100 w-full font-medium leading-7 ">
          Create and post job openings; track, screen, hire and onboard new employees with aid of
          visual dashboard
        </p>
      </div>
    </div>
  );
};

export default ResourceTab;
