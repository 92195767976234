import React from 'react';
import Header from '../header';
import SideBar from '../sidebar';

const LayoutWithSideBar = ({ children, helpdesk = false }) => {
  return (
    <div className='min-h-screen'>
      <Header helpdesk={helpdesk} isPeopleCenta />
      <div className='flex gap-4'>
        <SideBar isPeopleCenta />
        <div className='z-1 mx-auto w-[90%] md:mx-0 md:w-[87%]  space-y-6 pb-10 '>{children}</div>
      </div>
    </div>
  );
};

export default LayoutWithSideBar;
