import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Button from '../../components/button';
import { HeaderCard } from '../../components/cards/Cards';
import LayoutWithSideBar from '../../components/layout/LayoutWithSideBar';
import { ASSIGN_LEAVE, PERFORMANCE_REPORT } from '../../router/router';
import AbsenceHeader from './features/AbsenceHeader';

const Absence = () => {
  const { pathname } = useLocation();
  return (
    <LayoutWithSideBar>
      <HeaderCard
        text={'Manage Absence'}
        titleClassName={'font-bold'}
        className={''}
        button={<Button text={'LEAVE REQUEST'} isPink />}
        link={ASSIGN_LEAVE}
        linkClassName={`${pathname.includes(ASSIGN_LEAVE) && 'hidden'}`}
        withButton
      />
      <div className='space-y-3'>
        <AbsenceHeader />
        <Outlet />
      </div>
    </LayoutWithSideBar>
  );
};

export default Absence;
