const User = {
  displayPicture: '../assets/img/user.png',
  name: 'Augustus Lanre',
  dept: 'IT Department ',
  role: 'Web Developer',
  email: 'augustuslanre@gmail.com',
  dob: '19th March 1945',
  password: '......',
  phoneNo: ['+234 819 000 9999', '+234 819 000 9999'],
  address: '200 Kano way, Yaba, Lagos state.',
  nextOfKin: {
    name: 'Augustus Dappo ',
    email: 'lanreau@gmail.com',
    phoneNo: '+234 819 201 9090',
    relationship: 'Brother',
    address: '200 Kano way, Yaba, Lagos state.'
  }
};

export { User };
