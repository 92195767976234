import { Toast } from '../../../components/toast/toast';
import { apiService } from '../../../helpers/api';

const base = 'Leave/';

export const fetchPendingLeaveRequests = async () => {
  try {
    const res = await apiService.get(
      `${base}Request/Search`,
      {
        approvalStatus: 0
      },
      true
    );
    const response = res?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching pending leave requests: ', err)
  }
};

export const fetchSingleUserLeaveRequests = async (id) => {
  try {
    const res = await apiService.get(
      'Leave/Request/Search',
      {
        employeeId: id
      },
      true
    );
    const response = res?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching leave requests: ', err)
  }
};

export const fetchAllLeaveSettings = async () => {
  try {
    const res = await apiService.get(
      'Leave/Setting',
      {},
      true
    );
    const response = res?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching leave settings: ', err)
  }
};

export const createLeaveSettings = async (payload) => {
  try {
    const res = await apiService.post(`${base}Setting/Create`, payload, true);
    console.log('res', res);
    if (res)
      Toast({
        type: 'success',
        message: 'Leave Settings Created Successfully'
      });
  } catch (err) {
    console.log('Error in creating leave setting: ', err)
  }
};

export const updateLeaveSettings = async (payload) => {
  try {
    const res = await apiService.post(`${base}Setting/Update`, payload, true);
    console.log('res', res);
  } catch (err) {
    console.log('Error in updating leave setting: ', err)
  }
};
