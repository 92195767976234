import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const EmployeesRoleCard = ({ name, displayPicture, email, className, id }) => {
  return (
    <Link
      to={`/manage-employees/user/${id}`}
      className={`bg-white-100 p-3 flex items-center justify-between font-semibold rounded-[5px] ${className}`}>
      <div className='flex items-center gap-x-2'>
        {displayPicture}

        <div className='flex flex-col gap-1'>
          <p className='text-xs'>{name}</p>
          <h6 className='text-gray-100'>{email}</h6>
        </div>
      </div>

      <AiOutlineArrowRight size={17} className='' />
    </Link>
  );
};

export default EmployeesRoleCard;
