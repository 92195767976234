import React from 'react';

export default function CheckBox({
  name,
  id,
  checked,
  hideTitle,
  value,
  title,
  onChange,
  className,
  required,
  round,
  ...otherProps
}) {
  return (
    <div
      className={`CheckBox flex flex-col font-semibold text-sm ${className} ${
        round ? 'round' : ''
      }`}>
      <label className=" flex items-center">
        <input
          data-testid={name}
          id={id || name}
          name={name}
          title={title}
          className="mr-1 leading-tight "
          onChange={onChange}
          type="checkbox"
          value={value}
          checked={checked}
          required={required}
          {...otherProps}
        />
        {title && (
          <span className={`text-sm mt-px inline-block ${hideTitle ? 'hidden' : ''}`}>{title}</span>
        )}
        <span className="bg-current checkmark" />
      </label>
    </div>
  );
}
