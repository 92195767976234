import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Button from '../../components/button';
import { HeaderCard } from '../../components/cards/Cards';
import LayoutWithSideBar from '../../components/layout/LayoutWithSideBar';
import { MANAGE_LEARNING } from '../../router/router';
import UploadButton from './features/learning-event/UploadButton';

const Learning = () => {
  const { pathname } = useLocation();
  return (
    <LayoutWithSideBar>
      <HeaderCard
        text={'Manage Learning'}
        withButton
        titleClassName={'font-bold'}
        button={<Button text={'EDIT GOALS INPUT'} isPink />}
        className={''}
        link={MANAGE_LEARNING}
        linkClassName={`${pathname.includes(MANAGE_LEARNING) && 'hidden'}`}>
        {/* <Link to={`${MANAGE_EMPLOYEES}`}>
          <Button text={'Employee Tracker'} isPink />
        </Link> */}
      </HeaderCard>
      <Outlet />
    </LayoutWithSideBar>
  );
};

export default Learning;
