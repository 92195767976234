import React from 'react';
import { Link } from 'react-router-dom';
import { BiRightArrowAlt } from 'react-icons/bi';
import { IoIosDesktop } from 'react-icons/io';

const RequestCard = ({ requestType, name, className, leaveId }) => {
  return (
   <Link
     to={`/manage-absence/${leaveId}`}>
     <div>
       <div
         className={`bg-white-30 rounded-[5px] flex items-center space-x-1 sm:space-x-4 p-2 sm:p-4  ${className}`}>
         <div className="flex gap-4 items-center">
           <IoIosDesktop color="#4E0083" size={30} className="p-2 bg-white-100 rounded-[50%] " />

           {name && (
             <div className="flex flex-col gap-1 justify-start">
               <p className="capitalize font-bold text-xs text-blue-100">{name}</p>
               <h6 className="text-white-100 bg-purple-15 px-1 sm:px-4 py-1 rounded-[5px]">
                 {' '}
                 {requestType} leave
               </h6>
             </div>
            )}
           {!name && (
             <h6 className="text-white-100 bg-purple-15 px-1 sm:px-4 py-1 rounded-[5px]">
               {' '}
               {requestType}
             </h6>
            )}
         </div>

         <BiRightArrowAlt size={18} color="#ffffff" className="ml-0 sm:ml-3 " />
       </div>
     </div>
   </Link>
  );
};

export default RequestCard;
