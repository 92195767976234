import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import StatsCard from '../../../../../components/cards/StatsCard';
import TitleCard from '../../../../../components/cards/TitleCard';
import { apiService } from '../../../../../helpers/api';
import { EDIT_GOALS } from '../../../../../router/router';

const PerformanceHeader = () => {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [objectivesCount, setObjectivesCount] = useState(0);
  const [behaviouralCount, setBehaviouralCount] = useState(0);
  const [technicalCount, setTechnicalCount] = useState(0);

  const fetchObjectivesCount = async () => {
      const res = await apiService.get(
        'Performance/Search', {}, true
      );
      const response = res?.data?.data;
      if (response)
      setObjectivesCount(response.length);  
  };

  const fetchBehaviouralCount = async () => {
    const res = await apiService.get(
      'Meta/Competence/List?Category=1', {}, true
    );
    const response = res?.data?.data;
    if (response)
    setBehaviouralCount(response.length);  
};

const fetchTechnicalCount = async () => {
  const res = await apiService.get(
    'Meta/Competence/List?Category=2', {}, true
  );
  const response = res?.data?.data;
  if (response)
  setTechnicalCount(response.length);  
};

  useEffect(() => {
    fetchObjectivesCount();
  }, []);

  useEffect(() => {
    fetchBehaviouralCount();
  }, []);

  useEffect(() => {
    fetchTechnicalCount();
  }, []);
  
  return (
    <div
      className={`flex flex-wrap gap-4 mx-auto xs:w-[80%] sm:w-[60%] md:w-full md:mx-0 md:flex-wrap md:flex-row ${
        pathname.includes(EDIT_GOALS) && 'hidden'
      }`}
    >
      <TitleCard
        text='Manage your employees performance goals.'
        className='w-full md:w-[25%] xl:w-[20%] '
      />
      <StatsCard
        isBlue
        title='TOTAL NUMBER OF OBJECTIVES'
        value={`${objectivesCount}`}
        //incrementArrow
        //percent='2.5'
        isPerformance
        className='w-full md:w-[40%] mdLg:w-[30%] xl:w-[25%]'
      />
      <StatsCard
        title='TOTAL NUMBER OF BEHIAVOURAL COMPETENCIES'
        value={`${behaviouralCount}`}
        //decrementArrow
        //percent='2.5'
        className='w-full md:w-[40%] mdLg:w-[30%] xl:w-[25%]'
        isPerformance
      />
      <StatsCard
        isYellow
        title='TOTAL NUMBER OF TECHNICAL COMPETENCIES'
        value={`${technicalCount}`}
        //decrementArrow
        //percent='2.5'
        className='w-full md:w-[40%] mdLg:w-[30%] xl:w-[25%]'
        isPerformance
      />
    </div>
  );
};

export default PerformanceHeader;
