import React from 'react';
import LandingPageHeader from '../../../components/landing-page/LandingPageHeader';
import HeaderContent from './HeaderContent';
import HumanResource from './HumanResource';
import Services from './Services';
import WhyPeopleCenta from './WhyPeopleCenta';
import WorkForceContent from './WorkForceContent';

const HomePage = () => {
  return (
    <div className="bg-blue-100">
      <LandingPageHeader />
      <div>
        <HeaderContent />
        <WorkForceContent />
        <HumanResource />
        <Services />
        <WhyPeopleCenta />
      </div>
    </div>
  );
};

export default HomePage;

// <LandingFaqs />
// <WorkExperience />
// <LandingFooter />
