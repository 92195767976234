import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import { BiLoaderCircle, BiUserCircle } from 'react-icons/bi';
import { API } from '../../../../utils/apis';
import EmployeeHeader from '../user/EmployeeHeader';
import { FaThumbsUp } from 'react-icons/fa';
import { TbDotsVertical } from 'react-icons/tb';
import AddLogs from './add-logs';

const EmployeeTracker = () => {
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchEmployee = async () => {
    setLoading(true);
    const res = await API.fetchSingleEmployee(id);
    setEmployee(res);
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  return (
    <>
      {loading ? (
        <span className=''>
          <BiLoaderCircle
            size={16}
            className='h-[200px] flex items-center mx-auto animate-spin-slow'
          />
        </span>
      ) : (
        <>
          <div className='bg-pink-30 rounded-sm pt-4 pb-14 px-2 sm:px-12'>
            <div
              className='uppercase text-xs flex items-center justify-start space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer'
              onClick={() => navigate(-1)}>
              <MdOutlineArrowBack /> <span className='text-blue-100'> BACK</span>
            </div>
            <div className='mt-4 '>
              <EmployeeHeader employee={employee} setModal={setShowModal} />
              <div className='bg-white-100 rounded-sm py-5 px-4 sm:px-12 mt-12 space-y-8'>
                <h4>Tracker Logs</h4>

                <div className='flex items-start gap-4 text-[#444444]'>
                  <BiUserCircle className='w-9 h-9 ' />
                  <div className='w-full space-y-6'>
                    <h4>{employee?.fullName}</h4>
                    <div className=' bg-pink-30 p-5 rounded-lg space-y-3'>
                      <div className=' flex justify-between items-center'>
                        <p className=' flex gap-2 items-center text-xl font-semibold'>
                          Test Tracker
                          <span>
                            <FaThumbsUp className='text-green-100' />
                          </span>
                        </p>

                        <TbDotsVertical size={20} />
                      </div>

                      <p>
                        Personnel tracking, also called employee tracking or personnel management,
                        is any system used to keep track of time card and office location
                        information of employees. These systems may help managers to locate
                        employees and keep track of working hours. Scheduling information and time
                        off requests are often considered part of personnel tracking, as this
                        information lets managers know when employees are expected to actually be in
                        the office or other work areas.
                      </p>
                    </div>
                    <p> 09:26am 26/06/2022</p>
                  </div>
                </div>
              </div>
            </div>
            <AddLogs show={showModal} onClose={() => setShowModal(false)} />
          </div>
        </>
      )}
    </>
  );
};

export default EmployeeTracker;
