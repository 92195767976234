import React, { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { FaUserCircle } from 'react-icons/fa';
import Pagination from '../../../components/general/pagination';
import { BiLoaderAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import CheckBox from '../../../components/input/CheckBox';
import Button from '../../../components/button';

const TimesheetContent = ({ searchValue, employeesSearch, timesheet, loading }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const searchResults = searchValue.length >= 2 ? employeesSearch : [];
  const totalItems = searchResults.length !== 0 ? searchResults.length : timesheet.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = searchValue.length >= 2 ? searchResults.slice(startIndex, endIndex) : timesheet.slice(startIndex, endIndex);

  return (
    <div className='rounded-[20px] bg-purple-30 p-10 my-6'>
      <div>
        <div className='whitespace-nowrap overflow-x-scroll'>
          <table className='w-full'>
            <thead className='border-collapse border-red-500'>
              <tr className='w-[100%] text-left uppercase tracking-[3px] text-blue-100 font-semibold text-2xs pl-28 mb-4'>
                <th></th>
                <th></th>
                <th className='p-3 text-center'>EMPLOYEE NAME</th>
                <th className='p-3'></th>
                <th className='p-6 text-center'>PROJECTS</th>
                <th className='p-6 text-center'>DATE</th>
                <th className='p-6 text-center'>ASSIGNED HOURS</th>
                <th className='p-6 text-center'>HOURS</th>
                <th className='p-6 text-center'>DETAILS</th>
              </tr>
            </thead>
            <tbody className='rounded-sm'>
              {searchValue.length >= 2 && searchResults.length !== 0 ? (
                <>
                  <tr className='text-blue-100 font-bold text-lg mb-6 border-b-[15px] border-pink-30 '>
                    Search result for {searchValue}
                  </tr>
                  {displayedData.map((employee, i) => (
                    <tr
                      className='cursor-pointer border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                      key={employee?.id}
                      onClick={() => navigate(`user/${employee?.id}`)}>
                      <td className='p-3 px-6 '>{i + 1}.</td>
                      <td className='p-3 px-6'>
                        <FaUserCircle size={30} color='#4E008330' />
                      </td>
                      <td className='p-3 px-6 text-center'>{employee?.firstName + ' ' + employee?.lastName}</td>
                      <td className='p-3 px-6 text-center'>{employee?.id !== null ? employee?.id : 'nil'}</td>
                      <td className='p-3 px-6 text-center'>
                        {employee?.personalEmail !== null ? employee?.personalEmail : 'nil'}
                      </td>
                      <td className='p-3 px-6 text-center'>
                        {employee?.department?.name !== null ? employee?.department?.name : 'nil'}
                      </td>
                      <td className='p-3 px-6 text-center'>{employee?.jobRole !== null ? employee?.jobRole : 'nil'}</td>
                    </tr>
                  ))}
                </>
              ) : (
                displayedData.map((timesheet, i) => (
                  <tr
                    className='cursor-pointer border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                    key={timesheet?.employeeId}
                    onClick={() => navigate(`user/${timesheet?.id}`)}>
                    <td className='p-3 px-6 '><CheckBox /></td>
                    <td className='p-3 px-6'>
                      <FaUserCircle size={30} color='#4E008330' />
                    </td>
                    <td className='p-3 px-6 text-center'>{timesheet?.periodName}</td>
                    <td className='p-3 px-6 text-center'>{timesheet?.id !== null ? timesheet?.id : 'nil'}</td>
                    {timesheet?.projects[0] ? (
                      timesheet.projects.map((project, projectIndex) => (
                        <React.Fragment key={projectIndex}>
                          <td className='p-3 px-6 text-center'>{project.project}</td>
                          {project.periods.map((period, periodIndex) => (
                            <React.Fragment key={periodIndex}>
                              <td className='p-3 px-6 text-center'>{period.date.split(' ')[0]}</td>
                              <td className='p-3 px-6 text-center'>{calculateDuration(period.startTime, period.endTime)}</td>
                              <td className='p-3 px-6 text-center'>{period.duration}</td>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))
                    ) : (
                      <>
                        <td className='p-3 px-6 text-center' colSpan="4">nil</td>
                      </>
                    )}
                    <td className='p-3 px-6'>
                      <Button
                       isPurple
                       text={'MORE DETAILS'}
                       withIcon
                       icon={<AiOutlineArrowRight size={15} className="" />}
                       className={
                         'flex items-center text-left justify-between flex-row-reverse'
                        }
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div className='pb-10 flex items-center'>
            {loading && (
              <BiLoaderAlt
                className='animate-spin my-10 flex text-center text-blue-100 justify-center mx-auto'
                size={30}
              />
            )}
            {!loading && totalItems === 0 && (
              <span className='p-3'>There are currently no timesheets to show</span>
            )}
          </div>
        </div>
      </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </div>
  );
};

const calculateDuration = (startTime, endTime) => {
  const start = new Date(`2022-01-01T${startTime}`);
  const end = new Date(`2022-01-01T${endTime}`);
  const durationInHours = (end - start) / (1000 * 3600);
  return Math.round(durationInHours);
};

export default TimesheetContent;
