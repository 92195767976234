import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import './styles.scss';
import { BiLoaderCircle } from 'react-icons/bi';
const Input = ({
  withLabel,
  onClick,
  withIcon,
  withIcons,
  icon,
  icons,
  ispassword,
  label,
  name,
  className,
  type = 'text',
  placeholder,
  value,
  onChange,
  disabled,
  required,
  max,
  min,
  containerClass,
  maxLength,
  step,
  labelClassName,
  currency,
  days,
  inButton,
  btn_text,
  onClickBtn,
  loading,
  isRounded,
  isPink,
  isWhiteLabel,
  height,
  isHorizontal,
  position,
  normalLabel,
  isBlack,
  pl,
  isPurple
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const passwordType = showPassword ? 'text' : 'password';

  return (
    <div
      className={`${containerClass} flex ${
        isHorizontal ? ' flex-col gap-2  sm:flex-row ' : 'flex-col  sm:flex-col gap-1'
      }  `}
      onClick={onClick}>
      {withLabel && (
        <label
          className={`${!normalLabel && 'tracking-tight uppercase'} font-semibold mr-1 mb-1 ${
            isWhiteLabel
              ? 'text-white-100 text-base'
              : isBlack
              ? 'text-black-200'
              : 'text-blue-100 text-xs'
          }  ${labelClassName}`}>
          {label} {required && <span className='text-pink-100 '>*</span>}
        </label>
      )}
      <div className='relative w-full '>
        <input
          max={max}
          min={min}
          maxLength={maxLength}
          name={name}
          className={` w-full ${pl ? pl : 'pl-[1rem]'} ${
            height ? height : 'h-12'
          } h-16 border rounded px-7 bg-white-100  text-blue-100 text-sm  placeholder:text-sm md:text-base md:placeholder:text-base  font-medium placeholder-opacity-50 placeholder-purple-300 hover:border-blue-100 disabled:opacity-60 focus:border-[1px] focus:border-purple-100  focus:outline-none ${
            withIcon && 'pl-16'
          } ${
            isPink &&
            'bg-purple-40 placeholder:text-purple-200 placeholder:capitalize placeholder:font-semibold text-blue-100'
          } ${isRounded && 'rounded-[27.5px]'} ${
            isPurple &&
            'bg-blue-100 bg-opacity-10 border-0 placeholder:capitalize placeholder:font-semibold text-blue-100 focus:border focus:border-blue-100'
          }  ${className}`}
          type={ispassword ? passwordType : type}
          placeholder={placeholder}
          value={value}
          autoComplete='on'
          disabled={disabled}
          onChange={onChange}
          required={required}
          step={step}
        />
        {ispassword && (
          <button
            type='button'
            onClick={togglePassword}
            className='absolute inset-y-0 right-0 flex items-center px-4 font-semibold text-gray-100 text-xs rounded-r-lg hover:text-blue-100'>
            <h6>{showPassword ? 'hide' : 'show'} </h6>
          </button>
        )}
        {inButton && (
          <button
            type='button'
            onClick={onClickBtn}
            className='absolute inset-y-0 right-0 flex items-center px- font-medium text-white-100 text-base rounded-r-lg bg-blue-100 focus:bg-opacity-80'>
            {loading ? (
              <span className=''>
                <BiLoaderCircle size={32} className='mx-auto animate-spin-slow' />
              </span>
            ) : !loading ? (
              <div className='ml_10'>{btn_text}</div>
            ) : null}
          </button>
        )}
        {currency && (
          <p className='absolute inset-y-0 left-0 flex items-center pl-6 font-semibold text-black-100 text-base rounded-l-lg'>
            ₦
          </p>
        )}
        {days && (
          <p className='absolute inset-y-0 right-0 flex items-center pr-6 font-semibold text-black-100  text-base rounded-r-lg'>
            Days
          </p>
        )}
        {withIcon && (
          <div
            className={`absolute ${position ? position : 'top-[50%] translate-y-[-50%] left-5'}  `}>
            <div className='flex flex-wrap justify-between'>
             {icon}
             {icons}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const TextAreaWithFormatting = ({
  withLabel,
  labelClassName,
  label,
  className,
  normalLabel,
  onEditorStateChange,
  editorState
}) => {
  return (
    <div className={`input_container flex flex-col gap-3  text-area-with-format  ${className}`}>
      {withLabel && (
        <label
          className={`text-sm font-semibold text-blue-100 ${
            !normalLabel && 'tracking-tight uppercase text-xs'
          } ${labelClassName}`}>
          {label}
        </label>
      )}

      <Editor
        wrapperClassName='wrapper'
        editorClassName='editor'
        toolbarClassName='toolbar'
        onEditorStateChange={onEditorStateChange}
        editorState={editorState}
      />
    </div>
  );
};

export const TextArea = ({
  label,
  className,
  normalLabel,
  isWhiteLabel,
  isPurple,
  required,
  labelClassName,
  containerClass,
  onChange,
  ...props
}) => {
  return (
    <div className={`flex flex-col ${containerClass}`}>
      {label && (
        <label
          className={`${!normalLabel && 'tracking-tight uppercase'} font-semibold mr-1 mb-1  ${
            isWhiteLabel ? 'text-white-100 text-base' : 'text-blue-100 text-xs'
          }  ${labelClassName}`}>
          {label} {required && <span className='text-pink-100 '>*</span>}
        </label>
      )}
      <textarea
        onChange={onChange}
        className={`  rounded px-3 pt-2 ${
          !isPurple ? 'bg-white-100 border' : 'bg-purple-30 border-none'
        }  text-blue-100 text-sm  placeholder:text-sm md:text-base md:placeholder:text-base  font-medium placeholder-opacity-50 placeholder-purple-300 hover:border-blue-100 disabled:opacity-60 focus:border-blue-100  focus:outline-none resize-none ${className}`}
        {...props}
      />
    </div>
  );
};

export default Input;
// {!inButton && <br />}
