import React, { useState } from 'react';

import Profile from './Profile';
import EmploymentInfo from './EmploymentInfo';
import Skills from './Skills';
import StatusTabs from '../../../../components/base/status-tabs';

const LeftEmployeeDetails = ({ employee }) => {
  const [active, setActive] = useState('profile');

  return (
    <div className='mx-auto lg:mx-0'>
      <div className='py-6'>
        <StatusTabs list={['profile', 'employment', 'skills']} onClick={setActive} />
      </div>

      <div>
        {active === 'profile' && <Profile employee={employee} />}
        {active === 'employment' && <EmploymentInfo employee={employee} />}
        {active === 'skills' && <Skills employee={employee} />}
      </div>
    </div>
  );
};

export default LeftEmployeeDetails;
