import React, { useState, useRef } from 'react';
import ImgUploader from '../../../../components/base/image-uploader';
import Button from '../../../../components/button';

const AddEmployeeHeader = ({ handleAddEmployee, loading }) => {
  const [file, setFile] = useState(null); // Initialize as null
  const fileInputRef = useRef(null);

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile); // Update file state
  };

  const handleImportList = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  return (
    <div className='font-bold flex my-16 items-center flex-wrap gap-6 justify-center md:justify-between'>
      <ImgUploader file={file} onFileChange={handleFileSelect} />
      <div className='flex gap-x-10 gap-y-6 items-center flex-wrap justify-center md:justify-between cursor-pointer'>
        <span className='text-pink-100 text-xs tracking-tight' onClick={handleImportList}>ADD VIA LIST</span>
        <Button
          isPink
          text='ADD EMPLOYEE'
          onClick={() => handleAddEmployee(file)} // Pass the file when the button is clicked
          loading={loading}
          disabled={loading}
        />
        <input
          ref={fileInputRef}
          type="file"
          accept=".csv, .jpg, .jpeg, .png"
          style={{ display: 'none' }}
          onChange={handleFileSelect}
        />
      </div>
    </div>
  );
};

export default AddEmployeeHeader;
