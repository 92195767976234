import React, { useState } from 'react';
import BaseModal from '../../../../components/base/modal';
import Button from '../../../../components/button';
import { Toast } from '../../../../components/toast/toast';
import { apiService } from '../../../../helpers/api';

const RejectLeaveModal = ({
  showRejectModal,
  toggleModal,
  pendingLeaveRequests,
  activeLeaveRequest,
  setPendingLeaveRequests,
  setRejectedLeaveRequests,
  rejectedLeaveRequests
}) => {
  const [loading, setLoading] = useState(false);

  const rejectLeaveRequest = async () => {
    setLoading(true);
    try {
      const res = await apiService.post(
        `Leave/Request/Approve?leaveRequestId=${activeLeaveRequest?.leaveId}&approve=false`
      );
      const filteredRequests = pendingLeaveRequests.filter(
        ({ leaveId }) => leaveId != activeLeaveRequest?.leaveId
      );
      if (res) {
        setPendingLeaveRequests(filteredRequests);
        setRejectedLeaveRequests([...rejectedLeaveRequests, activeLeaveRequest]);
        toggleModal();
        Toast({
          type: 'success',
          message: 'Leave Request Rejected'
        });
      }
    } catch (err) {
      console.log('Error in rejecting leave request: ', err)
    } finally {
      setLoading(false);
    }
  };
  return (
    <BaseModal
      title={'Are you sure you want to '}
      coloredText='Reject? '
      show={showRejectModal}
      onClose={toggleModal}>
      <div className='flex gap-4 flex-wrap mt-6 justify-end'>
        <Button className={''} text='Go back' onClick={toggleModal} isGray />
        <Button
          text='Reject'
          isPink
          className='bg-opacity-80'
          loading={loading}
          onClick={rejectLeaveRequest}
        />
      </div>
    </BaseModal>
  );
};

export default RejectLeaveModal;
