import * as activity from './api-modules/activity';
import * as event from './api-modules/event';
import * as employee from './api-modules/employee';
import * as vacancy from './api-modules/vacancy';
import * as leave from './api-modules/leave';
import * as performance from './api-modules/performance';
import * as compensation from './api-modules/compensation';
import * as overtime from './api-modules/overtime';
import * as learning from './api-modules/learning';
import * as timesheet from './api-modules/timesheet';
import * as announcement from './api-modules/announcement';

export const API = {
  ...activity,
  ...event,
  ...employee,
  ...vacancy,
  ...leave,
  ...performance,
  ...compensation,
  ...overtime,
  ...learning,
  ...timesheet,
  ...announcement 
};
