import React from 'react';
import { useEffect, useState } from 'react';
import { apiService } from '../../../helpers/api';
import { LEAVE_STATUS } from '../../../helpers/constant';
import Approved from './Approved';
import ApproveLeaveModal from './leave-modals/approve';
import RejectLeaveModal from './leave-modals/reject';
import Pending from './Pending';
import Rejected from './Rejected';

const AbsenceContent = () => {
  const [active, setActive] = useState(LEAVE_STATUS.PENDING);
  const [pendingLeaveRequests, setPendingLeaveRequests] = useState([]);
  const [approvedLeaveRequests, setApprovedLeaveRequests] = useState([]);
  const [rejectedLeaveRequests, setRejectedLeaveRequests] = useState([]);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeLeaveRequest, setActiveLeaveRequest] = useState({});

  const fetchPendingLeaveRequests = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        'Leave/Request/Search',
        {
          approvalStatus: 0
        },
        true
      );
      const response = res?.data?.data;
      setPendingLeaveRequests(response);
    } catch (err) {
      console.log('Error in pending leave requests: ', err)
    } finally {
      setLoading(false);
    }
  };

  const fetchApprovedLeaveRequests = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        'Leave/Request/Search',
        {
          approvalStatus: 1
        },
        true
      );
      const response = res?.data?.data;
      setApprovedLeaveRequests(response);
    } catch (err) {
      console.log('Error in approved leave requests: ', err)
    } finally {
      setLoading(false);
    }
  };

  const fetchRejectedLeaveRequests = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        'Leave/Request/Search',
        {
          approvalStatus: 2
        },
        true
      );
      const response = res?.data?.data;
      setRejectedLeaveRequests(response);
    } catch (err) {
      console.log('Error in rejected leave requests: ', err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingLeaveRequests();
    fetchApprovedLeaveRequests();
    fetchRejectedLeaveRequests();
  }, []);
  function handleSetPending() {
    setActive(LEAVE_STATUS.PENDING);
  }

  function handleSetApproved() {
    setActive(LEAVE_STATUS.APPROVED);
  }

  function handleSetRejected() {
    setActive(LEAVE_STATUS.REJECTED);
  }

  return (
    <div>
      {/* prettier-ignore */}
      <div className="py-6 space-y-6 ">
        <div className="font-semibold tracking-tight text-blue-100 uppercase text-2xs bg-white-100 rounded-[22px] px-5 py-3 gap-6 flex flex-wrap items-center justify-center text-center md:w-[40%] lg:w-[45%] mx-auto">
          <span
            className={`${active === LEAVE_STATUS.PENDING && 'text-white-100 bg-pink-100 rounded-[12.63px] px-6 py-[6px] '
              } cursor-pointer  transition-all duration-700 text-center`}
            onClick={handleSetPending}>
            pending
          </span>
          <span
            className={`${active === LEAVE_STATUS.APPROVED && 'text-white-100 bg-pink-100 rounded-[12.63px] px-6 py-[6px] '
              } cursor-pointer transition-all duration-700 text-center`}
            onClick={handleSetApproved}>
            approved
          </span>
          <span
            className={`${active === LEAVE_STATUS.REJECTED && ' text-white-100 bg-pink-100 rounded-[12.63px] px-6 py-[6px] '
              } cursor-pointer transition-all duration-700 text-center`}
            onClick={handleSetRejected}>
            rejected
          </span>
        </div>

        <div className="whitespace-nowrap overflow-x-scroll my-6 pb-8 no-scroll-bar">
          <div className="space-y-6">
            <table className="w-full">
              <thead className=" border-collapse border-red-500">
                <tr className="w-[100%] text-left uppercase tracking-[3px] text-blue-100 font-semibold text-2xs pl-28 mb-4 ">
                  <th className=" ">EMPLOYEE ID</th>
                  <th className="p-3 px-6 ">NAME</th>
                  <th className="p-3 px-6 ">DEPARTMENT</th>
                  <th className="p-3 px-6 ">LEAVE TYPE</th>
                  <th className="p-3 px-6 ">LEAVE DURATION</th>
                  <th className="p-3 px-6 ">STATUS</th>
                </tr>
              </thead>
              <tbody className="rounded-sm">
                <tr className="text-purple-100 font-semibold text-xs " > <td className='pb-3'>  This week </td>
                </tr>
                {active === LEAVE_STATUS.PENDING && <Pending
                  pendingLeaveRequests={pendingLeaveRequests}
                  showRejectModal={() => setShowRejectModal(!showRejectModal)} setActiveLeaveRequest={setActiveLeaveRequest}
                  showApproveModal={() => setShowApproveModal(!showApproveModal)} />}
                {active === LEAVE_STATUS.APPROVED && <Approved approvedLeaveRequests={approvedLeaveRequests} />}
                {active === LEAVE_STATUS.REJECTED && <Rejected rejectedLeaveRequests={rejectedLeaveRequests} />}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ApproveLeaveModal
        showApproveModal={showApproveModal}
        toggleModal={() => setShowApproveModal(!showApproveModal)}
        pendingLeaveRequests={pendingLeaveRequests}
        activeLeaveRequest={activeLeaveRequest}
        setPendingLeaveRequests={setPendingLeaveRequests}
        setApprovedLeaveRequests={setApprovedLeaveRequests}
        approvedLeaveRequests={approvedLeaveRequests}
      />
      <RejectLeaveModal
        showRejectModal={showRejectModal}
        toggleModal={() => setShowRejectModal(!showRejectModal)}
        pendingLeaveRequests={pendingLeaveRequests}
        activeLeaveRequest={activeLeaveRequest}
        setPendingLeaveRequests={setPendingLeaveRequests}
        setRejectedLeaveRequests={setRejectedLeaveRequests}
        rejectedLeaveRequests={rejectedLeaveRequests}
      />
    </div>
  );
};

export default AbsenceContent;
