import React, { useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import CompensationContent from './CompensationContent';
import Input from '../../../components/input';
import { apiService } from '../../../helpers/api';
import SearchWithFilter from '../../../components/general/SearchWithFilter';

const CompensationLayout = () => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [compensations, setCompensations] = useState([]);  
  const [filteredCompensations, setFilteredCompensations] = useState([]);  

  const fetchAllCompensation = async () => {
    setLoading(true);
    try {
      const res = await apiService.get('Compensation', {}, true);
      const response = res?.data?.data || [];  
      setCompensations(response);
      setFilteredCompensations(response); 
    } catch (err) {
      console.error('Error in fetching compensations:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCompensation();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);

    if (value) {
      const results = compensations.filter(compensation => 
        compensation.employeeNumber.toLowerCase().includes(value) ||
        compensation.department.toLowerCase().includes(value)
      );
      setFilteredCompensations(results);
    } else {
      setFilteredCompensations(compensations); 
    }
  };

  return (
    <div className='white-bg'>
      <div className='p-6'>
        <SearchWithFilter
          placeholder={'Search by employee ID, department'}
          isPink
          onChange={handleSearch}
          value={searchValue}
        />
      </div>

      <CompensationContent
        compensations={compensations}
        filteredCompensations={filteredCompensations}
        loading={loading}
      />
    </div>
  );
};

export default CompensationLayout;
