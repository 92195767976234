import React, { useState } from 'react';
import Button from '../../../../components/button';
import BackWithText from '../../../../components/general/BackWithText';
import Filter from '../../../../components/general/filter';
import Input, { TextArea } from '../../../../components/input';
import Select from '../../../../components/input/Select';
import Modal from '../../../../components/modal';
import ScheduleLeft from './ScheduleLeft';
import ScheduleRight from './ScheduleRight';

const ScheduleLayoutDetails = () => {
  const [interviewModal, setInterviewModal] = useState(false);
  const interviewerOptions = [
    {
      label: 'James Scott',
      value: 'James'
    },
    {
      label: 'Bernard Fredd',
      value: 'Bernard'
    },
    {
      label: 'Tadascha Scott',
      value: 'Tadasha'
    }
  ];

  return (
    <div className="space-y-5">
      <div className=" bg-pink-100 py-4 flex flex-wrap gap-2 rounded-xl items-center justify-between px-4 text-white-100">
        <div className="flex items-center gap-2 flex-wrap">
          <BackWithText text={'Product Manager(135 interviews)'} goBack />
          {/*	<Button
						text={"Today"}
						isFaded
						className={"flex gap-2 text-white-100 items-center"}
	/>*/}
          <p className="font-medium"></p>
        </div>
        <div className="flex items-center gap-2 flex-wrap">
          {/*<Link to={""} className="flex justify-end">
			<Button
				text={"View Calendar"}
				icon={<GoCalendar size={12} />}
				withIcon
				isFaded
				className={"flex gap-2 text-white-100 items-center"}
			/>
		</Link><Link to={NEW_INTERVIEW} className="flex justify-end">
			<Button text={"+ New Interview"} isFaded />
</Link>*/}
          <Filter isRecruit />
          <Button
            text={'+ New Interview'}
            isFaded
            onClick={() => setInterviewModal(!interviewModal)}
          />
        </div>
      </div>

      <div className="flex justify-between flex-wrap gap-6 my-2">
        <div className=" bg-white-100 px-4 sm:px-6 rounded-md py-4   space-y-6  w-full mdLg:w-[72%] overflow-scroll whitespace-nowrap">
          <ScheduleLeft />
        </div>
        <div className=" bg-white-100 px-4 sm:px-6 rounded-md py-4  space-y-6  w-full mdLg:w-[25%]">
          <ScheduleRight />
        </div>
      </div>

      <Modal
        showModal={interviewModal}
        closeModal={() => setInterviewModal(!interviewModal)}
        content={
          <div className=" no-scroll-bar overflow-scroll h-[80vh] space-y-6 pr-10">
            <h4> Schedule Interview </h4>
            <div className=" space-y-3 pb-2">
              <Input height={'h-9'} label={'Subject'} withLabel normalLabel />

              <Input height={'h-9'} label={'Language'} withLabel normalLabel />

              <div>
                <label className=" text-blue-100 text-xs font-semibold mr-1 mb-1">
                  Select Interviewer
                </label>
                <Select
                  className="w-full md:w-[240px] "
                  options={interviewerOptions}
                  onChange={(e) => console.log(e)}
                  isMulti
                />
              </div>

              <TextArea label={'Details'} className="h-32 capitalize" normalLabel />
            </div>
            <Button text={'Send'} isPurple />
          </div>
        }
      />
    </div>
  );
};

export default ScheduleLayoutDetails;
