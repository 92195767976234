const DASHBOARD = '/dashboard';
const MANAGE_PERFORMANCE = '/manage-performance';
const MANAGE_COMPENSATION = '/manage-compensation';
const MANAGE_HIRING = '/manage-hiring';
const MANAGE_ABSENCE = '/manage-absence';
const ASSIGN_LEAVE = 'assign-leave';
const MANAGE_EVENTS = '/manage-events';
const MANAGE_LEARNING = '/manage-learning';
const REQUESTS = '/manage-requests';
const OVERVIEW = '/manage-overview';
const AUDITS = '/audits';
const TIMESHEET = '/timesheet';
const REPORTS = '/reports';
const HELPDESK = '/helpdesk';
const EMPLOYEE_DETAILS = 'user/:id';
const EDIT_GOALS = 'edit-goals';
const ADD_VACANCY = 'add-vacancy';
const VACANCY = 'vacancy/:id';
const APPLICANTS = 'applicants';
const ADD_PAYROLL = 'add-payroll';
const SALARY_COMPONENTS = 'salary-components';
const ABSENCE_DETAILS = ':id';
const PAYROLL_DETAILS = ':id';
const PERFORMANCE_DETAILS = ':id';
const TRAINING_DETAILS = ':id';
const LEARNING_EVENT = 'create-event';
const LEARNING_REQUEST = 'create-request';
const CALENDAR_DETAILS = 'calendar-details';
const SETTINGS = '/settings';

// manage-employees
const MANAGE_EMPLOYEES = '/manage-employees';
const ADD_PEOPLE = 'add-employees';
export const DEPARTMENT = 'department';

// manage-reports
const LEAVE_REPORT = 'leave-report';
const TRAINING_REPORT = 'training-report';
const HR_ADMIN_REPORT = 'hr-admin-report';
const RECRUITMENT_REPORT = 'recruitment-report';
const COMPENSATION_REPORT = 'compensation-report';
const PERFORMANCE_REPORT = 'performance-report';
const EMPLOYEE_REPORT = 'employee-report';
const ONBOARDING_OFFBOARDING_REPORT = 'onboarding-offboarding-report';

// manage-learning
//const TRAINING_DETAILS = 'manage-performance/training-details/:id';
const FEEDBACK_DETAILS = 'training-feedback';
const TRAINING_REVIEW = 'training-review';

// performance
const EMPLOYEE_TRACKER = 'tracker/:id';

// people recruit
const PEOPLE_RECRUIT = '/people-recruit/dashboard';
const SOURCE_AND_ATTRACT = '/source-and-attract';
const SHORTLIST = '/shortlist';
const SCHEDULE = '/schedule';
const SCHEDULE_DETAILS = ':id';
const RECRUITER = '/recruiter';
const JOB_DETAILS = 'job-details/:id';
const CREATE_JOB = 'create-job';

const JOB_CATEGORIES = 'job-categories';
const JOB_CATEGORY_DETAILS = 'job-categories/:id';
const APPLICATION_FORM = 'application-form';

// shortlist
const LEADERBOARD = 'leaderboard';
const JOB_APPLICANTS = 'job-applicants';
const SCREENING_SCORES = 'screening-scores';
const CANDIDATE_DETAILS = 'candidate-details';
const CANDIDATE_SCORE = 'candidate-score';
const ASSESSMENT_QUESTIONS = 'assessment-questions';

// schedule and engage
const SCHEDULE_AND_ENGAGE = '/schedule_and_engage';
const NEW_INTERVIEW = 'new-interview';
const EVENTS = 'events';

// REPORT
const PEOPLE_RECRUIT_REPORT = '/report';

// AUTH
const AUTH_LOGIN = '/auth/login';
const USER_EMAIL_VERIFICATION = `/email-verification/email=:email&token=:token`;
const EMAIL_VERIFICATION = `/email-verification`;
const RESET_PASSWORD = `/reset-password`;
const RESET_USER_PASSWORD = `/reset-password/email=:email&token=:token`;
const FORGOT_PASSWORD = `/forgot-password`;

const SIGN_UP = '/auth/signup';

// PROFILE
const PROFILE = '/profile';

export {
  DASHBOARD,
  MANAGE_EMPLOYEES,
  MANAGE_PERFORMANCE,
  MANAGE_COMPENSATION,
  MANAGE_HIRING,
  MANAGE_ABSENCE,
  MANAGE_EVENTS,
  MANAGE_LEARNING,
  REQUESTS,
  OVERVIEW,
  AUDITS,
  TIMESHEET,
  REPORTS,
  HELPDESK,
  ADD_PEOPLE,
  EMPLOYEE_DETAILS,
  EDIT_GOALS,
  ADD_VACANCY,
  VACANCY,
  APPLICANTS,
  ADD_PAYROLL,
  SALARY_COMPONENTS,
  PAYROLL_DETAILS,
  ABSENCE_DETAILS,
  PERFORMANCE_DETAILS,
  PEOPLE_RECRUIT,
  SOURCE_AND_ATTRACT,
  PEOPLE_RECRUIT_REPORT,
  SHORTLIST,
  SCHEDULE,
  RECRUITER,
  JOB_DETAILS,
  CREATE_JOB,
  JOB_CATEGORIES,
  JOB_CATEGORY_DETAILS,
  LEADERBOARD,
  JOB_APPLICANTS,
  SCREENING_SCORES,
  CANDIDATE_DETAILS,
  CANDIDATE_SCORE,
  ASSESSMENT_QUESTIONS,
  SCHEDULE_AND_ENGAGE,
  SCHEDULE_DETAILS,
  NEW_INTERVIEW,
  EVENTS,
  APPLICATION_FORM,
  AUTH_LOGIN,
  SIGN_UP,
  PROFILE,
  EMAIL_VERIFICATION,
  USER_EMAIL_VERIFICATION,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  RESET_USER_PASSWORD,
  ASSIGN_LEAVE,
  SETTINGS,
  EMPLOYEE_TRACKER,
  LEARNING_EVENT,
  LEARNING_REQUEST,
  PERFORMANCE_REPORT,
  LEAVE_REPORT,
  TRAINING_REPORT,
  HR_ADMIN_REPORT,
  COMPENSATION_REPORT,
  EMPLOYEE_REPORT,
  ONBOARDING_OFFBOARDING_REPORT,
  RECRUITMENT_REPORT,
  CALENDAR_DETAILS,
  TRAINING_DETAILS,
  TRAINING_REVIEW,
  FEEDBACK_DETAILS
};
