import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/img/logo.svg';
import SignupImg from '../../../assets/img/sign-up.png';
import Button from '../../../components/button';
import { AUTH_LOGIN } from '../../../router/router';
import authService from '../../../services/auth.service';
import './styles.scss';
import CompanyInfoForm from './CompanyInfoForm';
import CompanyRepInfo from './CompanyRepInfo';
import { MdOutlineArrowBack } from 'react-icons/md';
import CheckBox from '../../../components/input/CheckBox';

const AuthSignUp = () => {
  const emptyForm = {
    companyName: '',
    companyEmail: '',
    companyPhoneNumber: '',
    fieldIndustry: '',
    typeOfEmployer: 1,
    numberOfEmployees: '',
    companyWebsite: '',
    companyAddress: '',
    whereDidYouHearAboutUs: '',
    primaryContactFirstName: '',
    primaryContactLastName: '',
    primaryContactEmail: '',
    primaryContactCompanyPosition: '',
    primaryContactPhoneNumber: '',
    createdBy: 0,
    isActive: true
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(emptyForm);
  const [step, setStep] = useState(1);
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCheckBoxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const signUp = async (e) => {
    e.preventDefault();
    if (!isChecked) {
      // If checkbox is not checked, do not submit the form
      alert('Please accept the Terms of Service to create an account.');
      return;
    }

    setLoading(true);
    const payload = {
      ...formData,
      typeOfEmployer: formData.typeOfEmployer.value,
      fieldIndustry: formData.fieldIndustry.value,
      whereDidYouHearAboutUs: formData.whereDidYouHearAboutUs.value,
      numberOfEmployees: formData.numberOfEmployees.value,
      isActive: formData.isActive.value,
      primaryContactCompanyPosition: formData.primaryContactCompanyPosition.value
    };
    // console.log(payload);
    await authService.signup(payload, navigate);
    setLoading(false);
  };

  const firstStepIsDisabled = () => {
    return (
      !formData.companyName ||
      !formData.companyEmail ||
      !formData.fieldIndustry ||
      !formData.typeOfEmployer ||
      !formData?.companyPhoneNumber ||
      !formData?.companyWebsite ||
      !formData?.companyAddress ||
      !formData.numberOfEmployees ||
      !formData.whereDidYouHearAboutUs
    );
  };

  const secondStepIsDisabled = () => {
    return (
      !formData.primaryContactFirstName ||
      !formData.primaryContactLastName ||
      !formData.primaryContactEmail ||
      !formData.primaryContactCompanyPosition ||
      !formData?.primaryContactPhoneNumber
    );
  };

  return (
    <div className='md:h-[100vh] py-10'>
      <Logo className='mx-auto' />
      <div className='grid md:grid-cols-2 items-center justify-between'>
        <div className='px-6 md:px-14 py-10 overflow-scroll no-scroll-bar h-[80vh]'>
          <div className='space-y-5'>
            {step === 2 && (
              <div
                className='text-blue-100 cursor-pointer flex gap-2 items-center'
                onClick={() => setStep(1)}>
                <MdOutlineArrowBack />
                <p className='font-medium'>Back</p>
              </div>
            )}
            <h4 className='normal-case'>Signup as an Employer</h4>
            <p className='text-black-300 font-bold'>
              Step {step} of 2
              <span className='ml-2 bg-peach-100 text-black-200 font-medium px-3 py-1'>
                {step === 1 ? 'Company Information' : 'Company Representative '}
              </span>
            </p>
            <div className='flex flex-wrap gap-2'>
              <p className='text-pink-100 font-bold'>*</p>
              <p className='text-black-100 font-semibold'>All fields are required</p>
            </div>
            <p className='text-sm text-black-200'>
              {step === 1
                ? 'This information pertains to your company'
                : 'This is information pertaining to you as a representative of the company.'}
            </p>
            <form onSubmit={signUp} className='flex flex-col gap-6 items-start my-8 pb-10'>
              {step === 1 && (
                <CompanyInfoForm
                  handleChange={handleChange}
                  formData={formData}
                  setFormData={setFormData}
                />
              )}
              {step === 2 && (
                <CompanyRepInfo
                  handleChange={handleChange}
                  formData={formData}
                  setFormData={setFormData}
                />
              )}

              {step === 1 && (
                <Button
                  disabled={firstStepIsDisabled()}
                  loading={loading}
                  text={'Next'}
                  className={'w-full mt-6'}
                  isPink
                  type={'button'}
                  onClick={() => setStep(step + 1)}
                />
              )}

              {step === 2 && (
                <Button
                  disabled={secondStepIsDisabled() || loading}
                  loading={loading}
                  text={'Create Account'}
                  className={'w-full mt-6'}
                  isPink
                  type={'submit'}
                />
              )}

              {step === 2 && (
                <div className='flex flex-row items-start'>
                  <CheckBox onChange={handleCheckBoxChange} />
                  <p className='font-semibold text-pink-100 ml-2'>
                    By Clicking Create Account, you accept our Terms of Service
                  </p>
                </div>
              )}
            </form>
            {step === 1 && (
              <p className='text-center font-semibold text-pink-100 mx-auto'>
                Already have an account?{' '}
                <span>
                  <Link to={AUTH_LOGIN} className='font-semibold underline'>
                    Login
                  </Link>
                </span>
              </p>
            )}
          </div>
        </div>
        <div className='flex justify-end'>
          <img src={SignupImg} alt='Sign up' className='w-[70%]' />
        </div>
      </div>
    </div>
  );
};

export default AuthSignUp;
