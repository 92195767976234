import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import BaseModal from '../../../../../components/base/modal';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import { STORAGE_KEYS } from '../../../../../helpers/constant';
import { API } from '../../../../../utils/apis';

const EditEmployeeGroupModal = ({ show, closeModal, editEmployeeGroupInfo }) => {
  const staffId = localStorage.getItem(STORAGE_KEYS?.USER_ID);
  const [modalData, setModalData] = useState(editEmployeeGroupInfo || {});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (editEmployeeGroupInfo) {
      setModalData({
        ...editEmployeeGroupInfo,
        modifiedBy: staffId
      });
    }
  }, [editEmployeeGroupInfo, staffId]);

  const handleChange = (field, value) => {
    setModalData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  const submitData = async (e) => {
    e.preventDefault();
    setLoading(true);
    const updatedModalData = { ...modalData };
    delete updatedModalData.createdBy;
    console.log(updatedModalData);
    await API.updateEmployeeGroup(updatedModalData);
    setLoading(false);
    closeModal();
    window.location.reload();
  };
  

  return (
    <BaseModal title={'Edit Employee Grade'} show={show} onClose={closeModal}>
      <div className='space-y-10 rounded-lg w-full sm:w-[400px] overflow-hidden'>    
        <Input
          withLabel
          value={modalData.gradeGroupName}
          label='Grade Group Name'
          containerClass='w-full sm:w-auto'
          name='gradeGroupName'
          onChange={(e) => handleChange('gradeGroupName', e.target.value)}
        />

        <div className='flex gap-4 flex-wrap justify-end'>
          <Button
            className='border border-pink-100'
            text='Cancel'
            onClick={closeModal}
            isTransparent
          />
          <Button
            text='Submit'
            type='submit'
            onClick={submitData}
            isPurple
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditEmployeeGroupModal;
