import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button';
import EmploymentInfoForm from './employment-info-form';
import NextOfKinForm from './next-of-kin-form';
import PersonalInfoForm from './personal-info-form';
import ProfileHeader from './profile-header';
import ExperienceEducationForm from './experience-education';
import { API } from '../../../../utils/apis';
import { getEmployeesOption } from '../../../../helpers/functions';
import { fetchAllEmployeesById } from '../../../../utils/apis/api-modules/employee';
import { STORAGE_KEYS } from '../../../../helpers/constant';

const UserProfileLayout = ({ employee }) => {
  const staffId = localStorage.getItem(STORAGE_KEYS?.USER_ID);
  const personalInfoForm = {
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    email: '',
    dob: '',
    homeAddress: '',
    otherAddress: '',
    phoneNumber: '',
    alternatePhoneNumber: '',
    startDate: '',
    employeeNumber: '',
    employeeType: 1,
    employmentRole: '',
    status: 1,
    designation: '',
    departmentId: 0,
    grade: '',
    gradeGroup: '',
    id: 0,
    modifiedBy: staffId || 0,
    supervisorName: '',
    bankName: '',
    accountNumber: '',
    sortCode: ''
  };

  const otherInfoForm = {
    employeeId: employee.id || 0,
    email: employee.officeEmail || '',
    employeeNextofKins: [
      {
        nextofKinFirstName: '',
        nextofKinLastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        relationship: ''
      }
    ],
    employeeWorkExperiences: [
      {
        companyName: '',
        jobTitle: '',
        startDate: '',
        endDate: ''
      }
    ],
    employeeEducationHistories: [
      {
        institutionName: '',
        course: '',
        startDate: '',
        endDate: ''
      }
    ],
    employeeCertifications: [
      {
        certificationProgram: '',
        expirationDate: ''
      }
    ]
  };

  const [file, setFile] = useState('');
  const [formData, setFormData] = useState(personalInfoForm);
  const [otherFormData, setOtherFormData] = useState(otherInfoForm);
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employeesArray, setEmployeesArray] = useState([]);
  const [employeeGrade, setEmployeeGrade] = useState([]);
  const [employeeGroup, setEmployeeGroup] = useState([]);
  const [employeeGroupArray, setEmployeeGroupArray] = useState([]);
  const [employeeGradeArray, setEmployeeGradeArray] = useState([]);
  const [showExperienceEducationForm, setShowExperienceEducationForm] = useState(false);
  const navigate = useNavigate();

  const onFileChange = (e) => {
    const { files } = e.target;
    if (files) setFile(URL.createObjectURL(files[0]));
  };

  useEffect(() => {
    getEmployeeGroup(employeeGrade, employeeGroup);
  }, [employeeGroup, employeeGrade]);

  const getEmployeeGroup = (group) => {
    let EMPLOYEE_GROUP_ARRAY = [], EMPLOYEE_GRADE_ARRAY = [];
    for (let i = 0; i < group.length; i++) {
      EMPLOYEE_GROUP_ARRAY.push({ label: group[i]?.gradeGroupName, value: group[i]?.id });
      EMPLOYEE_GRADE_ARRAY.push({ label: group[i]?.gradeName, value: group[i]?.id });
    }
    setEmployeeGroupArray(EMPLOYEE_GROUP_ARRAY);
    setEmployeeGradeArray(EMPLOYEE_GRADE_ARRAY);
  };

  const loadEmployeeGradeGroupData = async () => {
    setLoading(true);
    const EmployeeGroupRes = await API.fetchAllGradeGroupById();
    const EmployeeGradeRes = await API.fetchAllGradeById();
    if (EmployeeGroupRes) setEmployeeGroup(EmployeeGroupRes);
    if (EmployeeGradeRes) setEmployeeGrade(EmployeeGradeRes)
    setLoading(false);
  };

  useEffect(() => {
    loadEmployeeGradeGroupData();
  }, []);


  const loadEmployeesData = async () => {
    setLoading(true);
    const res = await fetchAllEmployeesById();
    if (res) setEmployees(res);
    setLoading(false);
  };

  useEffect(() => {
    loadEmployeesData();
  }, []);

  useEffect(() => {
    setEmployeesArray(getEmployeesOption(employees));
  }, [employees]);

  useEffect(() => {
    if (employee) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...employee,
      }));
    }
  }, [employee]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInfoChange = (e) => {
    const { name, value } = e.target;

    const updateNestedArray = (arrayName, index, fieldName) => {
      setOtherFormData((prevFormData) => ({
        ...prevFormData,
        [arrayName]: prevFormData[arrayName].map((item, i) =>
          i === index ? { ...item, [fieldName]: value } : item
        ),
      }));
    };

    if (name.startsWith('employeeNextofKins')) {
      const kinIndex = parseInt(name.match(/\d+/)[0]);
      const fieldName = name.split('.').pop();
      updateNestedArray('employeeNextofKins', kinIndex, fieldName);
    } else if (name.startsWith('employeeWorkExperiences')) {
      const experienceIndex = parseInt(name.match(/\d+/)[0]);
      const fieldName = name.split('.').pop();
      updateNestedArray('employeeWorkExperiences', experienceIndex, fieldName);
    } else if (name.startsWith('employeeEducationHistories')) {
      const educationIndex = parseInt(name.match(/\d+/)[0]);
      const fieldName = name.split('.').pop();
      updateNestedArray('employeeEducationHistories', educationIndex, fieldName);
    } else if (name.startsWith('employeeCertifications')) {
      const certificationIndex = parseInt(name.match(/\d+/)[0]);
      const fieldName = name.split('.').pop();
      updateNestedArray('employeeCertifications', certificationIndex, fieldName);
    } else {
      setOtherFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };    

  const submitData = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData);
    console.log(otherFormData);
    console.log("It's all in order");
    await API.updateEmployee(formData);
    await API.updateEmployeeExpEd(otherFormData, navigate);
    setLoading(false);
  };

  const toggleExperienceEducationForm = () => {
    setShowExperienceEducationForm(!showExperienceEducationForm);
  };

  return (
    <div className='space-y-10 p-6 md:py-4 md:px-10 bg-pink-30 rounded-sm'>
      <form action='' className='space-y-10' onSubmit={submitData}>
        <ProfileHeader onFileChange={onFileChange} file={employee?.user?.profileImageURL} employee={employee} />
        <PersonalInfoForm
          formData={formData}
          employee={employee}
          handleChange={handleChange}
          setFormData={setFormData}
        />
        
        <EmploymentInfoForm 
          formData={formData}
          setFormData={setFormData}
          employee={employee} 
          employeesArray={employeesArray}
          employeeGradeArray={employeeGradeArray}
          employeeGroupArray={employeeGroupArray}
          handleChange={handleChange} 
        />

        <NextOfKinForm 
          otherFormData={otherFormData} 
          employee={employee} 
          handleInfoChange={handleInfoChange} 
        />

        <ExperienceEducationForm 
          otherFormData={otherFormData}
          setOtherFormData={setOtherFormData}
          employee={employee} 
          handleInfoChange={handleInfoChange} 
        />

        <div className='flex justify-end mt-8 gap-8'>
          <Button
            text={'Cancel'}
            className={''}
            isTransparent
            type={'button'}
            onClick={() => navigate(-1)}
          />
          <Button
            disabled={loading}
            loading={loading}
            text={'Save'}
            className={''}
            isPurple
            type={'submit'}
          />
        </div>
      </form>
    </div>
  );
};

export default UserProfileLayout;
