import React from 'react';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';

const SalaryComponentCard = ({
  key,
  className,
  name,
  duration,
  grossAmount,
  amount,
  editIcon,
  deleteIcon
}) => {
  return (
    <Link
      key={key}
      to={''}
      className={`bg-white-100 p-4 flex items-start justify-between font-semibold rounded-[5px] ${className}`}>
      <td>{name}</td>
      <td>{duration}</td>
      <td className='place-items-center w-[18%]'>{grossAmount}</td>
      <td className='place-items-center'>{amount}</td>
      <td className='place-items-right flex flex-row gap-4'>{editIcon}{deleteIcon}</td>
    </Link>
  );
};

export default SalaryComponentCard;
