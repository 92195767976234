import React from 'react';
import { AiOutlineRight } from 'react-icons/ai';

const MiniCard = ({ title, icon, active }) => {
  return (
    <div
      className={` w-[230px] py-5  rounded-md cursor-pointer ${
        active
          ? 'bg-yellow-100 text-white-100 font-semibold '
          : 'bg-white-100 text-blue-100 font-medium'
      }`}>
      <div
        className={`flex w-[90%] mx-auto items-center justify-between gap-6  text-sm hover:bg-opacity-50  ${
          active ? ' text-white-100' : 'text-purple-100'
        }`}>
        <div className="flex items-center uppercase  gap-2">
          {icon}
          {title}
        </div>
        <AiOutlineRight size={20} />
      </div>
    </div>
  );
};

export default MiniCard;
