import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { BiChevronDown } from 'react-icons/bi';
import { FaUserCircle } from 'react-icons/fa';
import { MdOutlineArrowBack } from 'react-icons/md';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Button from '../../button';
import AddCalendarEvent from './CalendarModal';
import SelectedDate from './SelectedDateModal';
import { fetchAllEmployeesById, fetchActivitiesByEmployeeAndDate } from '../../../utils/apis/api-modules/employee';

const CalendarDetails = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showSelectedDateModal, setShowSelectedDateModal] = useState(false);
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const res = await fetchAllEmployeesById();
      setEmployees(res || []);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const fetchEvents = async (employeeId, date) => {
    try {
      const res = await fetchActivitiesByEmployeeAndDate(employeeId, date);
      setEvents(res);
    } catch (error) {
      console.error('Error fetching events:', error);
      setEvents([]);
    }
  };

  const handleEmployeeSelect = (employeeId) => {
    setSelectedEmployee(employeeId);
    const date = new Date().toISOString().split('T')[0];
    fetchEvents(employeeId, date);
    setIsDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelect = (info) => {
    const { start } = info;
    setShowSelectedDateModal(true);
    setSelectedDate(start);
  };

  const EventItem = ({ info }) => {
    const { event } = info;
    return (
      <div>
        <p>{event.title}</p>
      </div>
    );
  };
  console.log(events);

  return (
    <div className="white-bg w-full flex flex-col space-y-5 relative">
      <div
        className='uppercase text-xs flex items-center justify-start space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer'
        onClick={() => navigate(-1)}
      >
        <MdOutlineArrowBack /> <span className='text-blue-100'> BACK</span>
      </div>
      <div className='flex flex-wrap justify-between z-10 relative'>
        <div className="relative z-10">
          <Button
            className='flex text-white-100 text-semibold capitalize flex-row-reverse'
            isPurple
            withIcon
            text={selectedEmployee ? employees.find(emp => emp.id === selectedEmployee)?.fullName : 'Select Employee'}
            icon={<BiChevronDown />}
            icons={<FaUserCircle size={25} color="#FFFFFF" />}
            onClick={handleDropdownToggle}
          />
          {employees && employees.length > 0 && isDropdownOpen && (
            <div className="absolute bg-purple-100 mt-1 py-2 w-full rounded shadow-lg z-20">
              {employees.map(employee => (
                <div 
                  key={employee.id} 
                  onClick={() => handleEmployeeSelect(employee.id)} 
                  className="bg-purple-100 text-white-100 text-semibold px-4 py-2 
                  cursor-pointer hover:bg-white-100 hover:text-purple-100"
                >
                  {employee.fullName}
                </div>
              ))}
            </div>
          )}
        </div>
        <Button
          isPurple
          withIcon
          icon={'+'}
          text={'ADD EVENT'}
          onClick={() => setShowAddEventModal(true)}
        />
      </div>
      <FullCalendar
        eventBackgroundColor='#FFFFFF'
        eventColor='#3c87ee'
        editable={true}
        selectable={true}
        select={handleSelect}
        headerToolbar={{
          start: "prev,today,next",
          center: 'title',
          end: "dayGridMonth,dayGridWeek,dayGridDay",
        }}
        eventContent={(info) => <EventItem info={info} />}
        plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
        views={{
          //hourGrid: { buttonText: 'Hourly Grid' },
          dayGridMonth: { buttonText: 'Month' },
          dayGridWeek: { buttonText: 'Week' },
          dayGridDay: { buttonText: 'Day' },
          //listMonth: { buttonText: 'List' },
        }}
        initialView="dayGridMonth"
        style={{ background: 'white' }}
        /*events={events.map(event => ({
          title: event.title,
          start: event.startTime,
          end: event.endTime
        }))}*/
      />
      <AddCalendarEvent
        show={showAddEventModal}
        closeModal={() => setShowAddEventModal(false)}
        selectedEmployee={selectedEmployee}
        handleEmployeeSelect={handleEmployeeSelect}
      />
      <SelectedDate
        show={showSelectedDateModal}
        closeModal={() => setShowSelectedDateModal(false)}
        selectedDate={selectedDate}
        selectedEmployee={selectedEmployee}
      />
    </div>
  );
};

export default CalendarDetails;
