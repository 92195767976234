import React from 'react';
import JobCategoryDetailsLeft from './JobCategoryDetailsLeft';
import JobCategoryDetailsRight from './JobCategoryDetailsRight';

const JobCategoryDetails = () => {
  return (
    <div>
      <div className=" bg-white-100 rounded-md p-4 sm:px-10  space-y-6 text-gray-100 font-semibold">
        Job Details
      </div>

      <div className="flex justify-between flex-wrap gap-6 my-2">
        <div className="  rounded-md py-4   space-y-6 text-gray-100  w-full md:w-[72%]">
          <JobCategoryDetailsLeft />
        </div>
        <div className="  rounded-md py-4  space-y-6 text-gray-100  w-full md:w-[25%]">
          <JobCategoryDetailsRight />
        </div>
      </div>
    </div>
  );
};

export default JobCategoryDetails;
