import React from 'react';
import { useState } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/button';
import Modal from '../../../../../components/modal';
import ApplicationFormLeft from './ApplicationFormLeft';
import ApplicationFormRight from './ApplicationFormRight';

const ApplicationForm = () => {
  const [successModal, showSuccessModal] = useState(false);
  return (
    <div>
      <Modal
        showModal={successModal}
        closeModal={() => showSuccessModal(!successModal)}
        content={
          <div className=" space-y-4 text-green-100 text-center flex flex-col items-center justify-center">
            <BsCheckCircle size={20} />{' '}
            <p className=" font-semibold text-lg text-green-100 w-full sm:w-[90%] ">
              Application Succesful
            </p>{' '}
            <Link to="/" className="flex gap-4 flex-wrap">
              <Button text="Proceed to Dashboard" isPink className={' w-full'} />
            </Link>
          </div>
        }
      />
      <div>
        <div className=" bg-white-100 rounded-md p-4 sm:px-10  space-y-6 text-gray-100 font-semibold">
          Submit your application
        </div>

        <div className="flex justify-between flex-wrap gap-6 my-2">
          <div className="  rounded-md py-4   space-y-6 text-gray-100  w-full md:w-[72%]">
            <ApplicationFormLeft onClick={() => showSuccessModal(!successModal)} />
          </div>
          <div className="  rounded-md py-4  space-y-6 text-gray-100  w-full md:w-[25%]">
            <ApplicationFormRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationForm;
