import React, { useState } from 'react';
import { BsClipboard } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/button';
import { PinkHeaderWithFilter } from '../../../../../components/cards/Cards';
import {
  ASSESSMENT_QUESTIONS,
  JOB_APPLICANTS,
  NEW_INTERVIEW,
  SCHEDULE,
  SHORTLIST
} from '../../../../../router/router';
import CreateAssessment from './CreateAssessment';
import SavedTemplates from './SavedTemplates';

const AssesmentQuestions = () => {
  const [question, setQuestion] = useState('');
  const [active, setActive] = useState('create');

  const [suggestedField, setSuggestedField] = useState([
    {
      question: 'I almost always complete what I started',
      response: 'Stronly Agree'
    },

    {
      question: ' I am punctual',
      response: 'Strongly Agree'
    },
    {
      question: 'I almost always complete what I started',
      response: 'Stronly Agree'
    },

    {
      question: ' I am punctual',
      response: 'Strongly Agree'
    }
  ]);

  // const [templates, setTemplates] = useState('');

  const addQuestions = () => {
    setSuggestedField([...suggestedField, { question: question }]);
    setQuestion('');
  };

  return (
    <div className="space-y-6">
      <div>
        <div className="flex gap-2 flex-wrap">
          <Link to={`${SHORTLIST}/${JOB_APPLICANTS}/${ASSESSMENT_QUESTIONS}`}>
            <Button
              icon={<BsClipboard />}
              withIcon
              text={'Assessment Questions'}
              className="flex items-center gap-2"
              isPink
            />
          </Link>
          <Link to={`${SCHEDULE}/${NEW_INTERVIEW}`}>
            <Button
              icon={<BsClipboard />}
              withIcon
              text={'Schedule Interview'}
              className="flex items-center gap-2"
              isPink
            />
          </Link>
        </div>
      </div>
      <PinkHeaderWithFilter goBack text={'1024 candidates screening'} />
      <div className=" bg-white-100 rounded-md p-4 sm:px-10  space-y-6">
        <div className=" justify-center flex flex-wrap gap-3">
          <h6
            onClick={() => setActive('create')}
            className={`flex justify-center cursor-pointer transition-all duration-1000 origin-center items-center ${
              active === 'creat'
                ? 'bg-purple-100 text-white-100 p-2 rounded-2xl px-4'
                : 'bg-white-100 text-blue-200 rounded-none'
            }`}>
            Create Template
          </h6>
          <h6
            onClick={() => {
              setActive('edit');
              addQuestions;
            }}
            className={`flex justify-center cursor-pointer transition-all duration-1000 origin-center items-center ${
              active === 'edit'
                ? 'bg-purple-100 text-white-100 p-2 rounded-2xl px-4'
                : 'bg-white-100 text-blue-200 rounded-none'
            }`}>
            Existing Templates
          </h6>
        </div>

        {active === 'create' ? <CreateAssessment /> : <SavedTemplates />}
      </div>
    </div>
  );
};

export default AssesmentQuestions;
