import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { FORGOT_PASSWORD, SIGN_UP } from '../../../router/router';

const Login = ({ step, handleChange, login, loading, error, loginDetailsError, showValidationMessage }) => {
  return (
    <>
      {step === 0 && (
        <form onSubmit={login} className=' flex flex-col gap-6 items-start w-[60%] my-8'>
          <div className='text-center w-full'>
            <h4 className=' text-lg sm:text-xl md:text-3xl'> Welcome Back</h4>
            <p>Login your details to get started</p>
          </div>

          <Input
            withLabel
            onChange={handleChange}
            label='email'
            placeholder={'Enter your email address'}
            className=''
            containerClass='w-full'
            name={'username'}
          />
          <Input
            withLabel
            onChange={handleChange}
            label='Password'
            placeholder={'Enter your password'}
            className=''
            containerClass='w-full'
            name={'password'}
            ispassword
          />
          <div className=' flex justify-between flex-wrap gap-2 w-full items-center'>
            <Link
              to={FORGOT_PASSWORD}
              className=' underline font-semibold text-purple-100 cursor-pointer'>
              Forgot Password ?
            </Link>
          </div>
          
          {showValidationMessage && !loginDetailsError && (
            <p className="text-red-500">Email and password are required.</p>
          )}

          {showValidationMessage && loginDetailsError && (
            <p className="text-red-500">{loginDetailsError}</p>
          )}

          <Button
            disabled={loading}
            loading={loading}
            text='Login'
            className={'w-full mt-6'}
            isPurple
            type='submit'
          />
          <p className=' text-center mx-auto mt-6'>
            Don't have an account?
            <span>
              <Link to={SIGN_UP} className=' font-semibold text-black-100 underline '>
                Sign Up
              </Link>
            </span>
          </p>
        </form>
      )}
    </>
  );
};

export default Login;
