import React from 'react';
import { ReactComponent as CloseIcon } from './close.svg';
import styles from './toast.module.scss';

export const DEFAULT_TOAST_OPTIONS = {
  autoClose: 5000,
  closeButton: false,
  icon: false,
  hideProgressBar: true,
  toastClassName: 'toastify__wrapper',
  bodyClassName: 'toastify__body'
};

// export type TostType = 'warn' | 'success' | 'error';
const ToastMessage = ({ onClose, message, type, isNetworkError }) => {
  const bgColor = `toastify__${type}`;
  return (
    <div className={`${styles['toastify__content']} ${styles[bgColor]}`}>
      <span className={`${styles['toastify__alert']}`}>
        {type === 'success' ? 'ALERT 🎉' : 'OOPS 😓'}
      </span>
      <span className={styles['toastify__text']}>
        {isNetworkError ? 'Network Error, Kindly Refresh' : message}
      </span>
      <button onClick={onClose} className={`${styles['toastify__close']}`}>
        <CloseIcon />
      </button>
    </div>
  );
};

export { ToastMessage };
