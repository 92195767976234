import React from 'react';
import Display from '../../assets/img/user.png';

const BirthdayCard = ({ name, birthDate }) => {
  return (
    <div className="w-full text-purple-100 flex items-center gap-2 font-semibold">
      <img
        src={Display}
        alt="user"
        className="border border-purple-100 rounded-[50%] p-[2px] w-12 h-12 "
      />
      <p className="capitalize grow text-sm"> {name}</p>
      <p className="text-xs"> {birthDate}</p>
    </div>
  );
};

export default BirthdayCard;
