import React, { useState } from 'react';
import Input from '../../../components/input';
import Select from '../../../components/input/Select';
import {
  FIELD,
  HEAR_ABOUT_US,
  NUMBER_OF_EMPLOYEES
} from '../../../helpers/constant';

const CompanyInfoForm = ({ handleChange, formData, setFormData }) => {
  const [emailError, setEmailError] = useState('');
  
  const validateEmail = (companyEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(companyEmail);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setFormData({ ...formData, companyEmail: emailValue });

    if (!validateEmail(emailValue)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };
  
  return (
    <>
      <div className='w-full flex items-end justify-between flex-wrap gap-6'>
        <Input
          withLabel
          placeholder={'Enter the company name'}
          onChange={handleChange}
          value={formData.companyName}
          label='Company Name'
          containerClass='w-full md:w-[45%]'
          name={'companyName'}
        />

        <div className='w-full md:w-[45%]'>
          <Input
            withLabel
            placeholder={'Enter the email of the company'}
            onChange={handleEmailChange}
            value={formData.companyEmail}
            label='Company Email'
            className=''
            containerClass=''
            name={'companyEmail'}
          />
          {emailError && <p className="text-red-500 mt-1">{emailError}</p>}
        </div>
      </div>

      <div className='w-full flex items-end justify-between flex-wrap gap-6'>
        <Input
          withLabel
          placeholder={'Enter the phone number of the company'}
          onChange={handleChange}
          value={formData.companyPhoneNumber}
          label='Company Phone Number'
          className=''
          containerClass='w-full md:w-[45%]'
          name={'companyPhoneNumber'}
          type='number'
        />
        <div className='flex flex-col gap-2 w-full md:w-[45%]'>
          <h6 className='text-xs'>Number of Employees</h6>
          <Select
            selectedOption={formData.numberOfEmployees}
            placeholder={'Number of employees'}
            className='w-full'
            options={NUMBER_OF_EMPLOYEES}
            onChange={(e) =>
              setFormData({
                ...formData,
                numberOfEmployees: e
              })
            }
          />
        </div>
      </div>

      <div className='w-full flex items-end justify-between flex-wrap gap-6'>
        <div className='flex flex-col gap-2 w-full md:w-[100%]'>
          <h6 className='text-xs'>Field/Industry</h6>
          <Select
            selectedOption={formData?.fieldIndustry}
            placeholder={'Field/industry of the company'}
            className='w-full'
            options={FIELD}
            onChange={(e) =>
              setFormData({
                ...formData,
                fieldIndustry: e
              })
            }
          />
        </div>
      </div>

      <div className='w-full flex items-end justify-between flex-wrap gap-6'>
        <Input
          withLabel
          placeholder={'Enter the address of the company'}
          onChange={handleChange}
          value={formData.companyAddress}
          label='Company Address'
          className=''
          containerClass='w-full md:w-[45%]'
          name={'companyAddress'}
        />

        <div className='flex flex-col gap-1 w-full md:w-[45%]'>
          <h6 className='text-xs'>Where did you hear about us?</h6>
          <Select
            selectedOption={formData?.whereDidYouHearAboutUs}
            placeholder={'How you found out about us'}
            className='w-full'
            options={HEAR_ABOUT_US}
            onChange={(e) =>
              setFormData({
                ...formData,
                whereDidYouHearAboutUs: e
              })
            }
          />
        </div>
        <Input
          withLabel
          placeholder={'Enter the website of the company'}
          onChange={handleChange}
          value={formData.companyWebsite}
          label='Website'
          className=''
          containerClass='w-full'
          name={'companyWebsite'}
          type='url'
        />
      </div>
    </>
  );
};

export default CompanyInfoForm;
