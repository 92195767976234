import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import LeftPayroll from './left-payroll/index';
import RightPayroll from './right-payroll/RightPayroll';
import { API } from '../../../../utils/apis';
import { apiService } from '../../../../helpers/api';

const list = ['upload payroll', 'salary components'];
const AddPayroll = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [active, setActive] = useState(list[0]);
  const [employees, setEmployees] = useState([]);
  const [salaryComponent, setSalaryComponent] = useState([]);
  const [showLeftTable, setShowLeftTable] = useState(true);
  const [error, setError] = useState('');
  //
  const emptyForm = {
    employeeId: 0,
    duration: 1,
    gross: ''
  };
  const [formData, setFormData] = useState(emptyForm);

  const loadEmployees = async () => {
    setLoading(true);
    const res = await API.fetchAllEmployeesById();
    if (res) {
      let allEmployees = [];
      res?.map((item) => {
        const response = {
          label: item?.fullName,
          value: item?.id,
          data: item
        };
        allEmployees.push(response);
      });
      setEmployees(allEmployees);
    }
    setLoading(false);
  };

  const fetchSalaryComponent = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(`SalaryComponent/List`, {}, true);
      const response = res?.data?.data;
      setSalaryComponent(response);
    } catch (err) {
      console.log('error in compensations', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadEmployees();
    fetchSalaryComponent();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('name and value', name, value);
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const submitData = async (e) => {
    setAddLoading(true);
    try {
      const existingCompensations = await API.fetchAllCompensations();
      const existingCompensation = existingCompensations.find(
        compensation => compensation.employeeId === formData.employeeId
      );
      if (existingCompensation) {
        setError('Compensation Already Exists');
        console.log('Compensation Already Exists');
      } else {
        await API.createCompensation(formData);
        navigate(-1);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    } finally {
      setAddLoading(false);
    }
    e.preventDefault();
  };

  const handleRightTableButtonClick = () => {
    setShowLeftTable(false);
  };

  return (
    <div className='pink-bg space-y-6 '>
      <div
        className='uppercase text-xs flex flex-wrap items-center justify-between space-x-80 text-pink-100 tracking-[3px] font-bold cursor-pointer'
        onClick={() => navigate(-1)}>
        <div className='flex flex-row gap-2'>
         <MdOutlineArrowBack /> <span className='text-blue-100'> BACK</span>
        </div>
        <div>
         <RightPayroll loading={loading} submitData={submitData} onRightTableButtonClick={handleRightTableButtonClick} />
        </div>
      </div>
      <div className='flex flex-wrap space-y-6 items-baseline justify-between'>
        <div><h4>Add New Payroll</h4></div>
      </div>
      <div className='pl-3 items-start justify-between space-y-8 my-6 flex gap-6 flex-wrap'>
       {showLeftTable && (
          <LeftPayroll
            active={active}
            list={list}
            setActive={setActive}
            employees={employees}
            salaryComponent={salaryComponent}
            loading={loading}
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
            error={error}
          />
        )}
      </div>
    </div>
  );
};

export default AddPayroll;

