import React from 'react';
import { FiEdit2 } from 'react-icons/fi';

const EditCard = ({ title, children, className }) => {
  return (
    <div className={`rounded-xl border-[2px] border-gray-light ${className}`}>
      <div className="bg-gray-light flex justify-between p-2 sm:p-4  gap-4">
        <p className="text-gray-600 font-medium text-sm">{title}</p>
        <FiEdit2 className="text-white-100 bg-gray-20 w-6 h-6 p-1 rounded-full cursor-pointer " />
      </div>

      <div className="bg-white-100 p-2 sm:p-4 sm:py-8 text-gray-200">{children}</div>
    </div>
  );
};

export default EditCard;
