import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import { TextArea } from '../../../../../components/input';
import Select from '../../../../../components/input/Select';
import { fetchAllEmployeesById } from '../../../../../utils/apis/api-modules/employee';
import { getEmployeesOption } from '../../../../../helpers/functions';
import { GROUP_TYPE, METRIC_TYPE } from '../../../../../helpers/constant';
import { API } from '../../../../../utils/apis';
import { useNavigate } from 'react-router';

const BscGoal = ({ formData, setFormData, emptyForm, objectiveOption, submitData }) => {
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employeesArray, setEmployeesArray] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [departmentsOption, setDepartmentOption] = useState([]);
  const navigate = useNavigate();

  const addTargets = (goalIndex) => {
    const updatedGoals = [...formData.goal];
    updatedGoals[goalIndex].targets.push(emptyForm.goal[goalIndex].targets[0]);
    setFormData({ ...formData, goal: updatedGoals });
  };

  const deleteKeyResults = (goalIndex, targetIndex) => {
    const updatedGoals = [...formData.goal];
    updatedGoals[goalIndex].targets = updatedGoals[goalIndex].targets.filter((_, ind) => ind !== targetIndex);
    setFormData({ ...formData, goal: updatedGoals });
  };

  const addCompetency = () => {
    setFormData({ ...formData, goal: [...formData.goal, emptyForm.goal[0]] });
  };

  const deleteObj = (goalIndex) => {
    setFormData({ ...formData, goal: formData.goal.filter((_, ind) => ind !== goalIndex) });
  };

  const handleChangeFormData = (e, goalIndex, isObj) => {
    const updatedGoals = [...formData.goal];
    if (isObj) {
      updatedGoals[goalIndex].objectiveId = e?.value;
    } else {
      const { name, value } = e.target;
      updatedGoals[goalIndex] = { ...updatedGoals[goalIndex], [name]: value };
    }
    setFormData({ ...formData, goal: updatedGoals });
  };

  const handleChangeKeyResults = (goalIndex, targetIndex, e) => {
    const { name, value } = e.target || e;
    if (name === 'weight' && value > 100) {
      alert('Weight cannot exceed 100%');
      return;
    }
    const updatedGoals = [...formData.goal];
    updatedGoals[goalIndex].targets[targetIndex] = { ...updatedGoals[goalIndex].targets[targetIndex], [name]: value };
    setFormData({ ...formData, goal: updatedGoals });
  };

  const handleChangePerformanceGoal = (goalIndex, description) => {
    const updatedGoals = [...formData.goal];
    updatedGoals[goalIndex].description = description;
    setFormData({ ...formData, goal: updatedGoals });
  };

  const handleChangeGroupData = (name, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleGroupTypeChange = (e) => {
    const selectedGroupType = e.value;
    handleChangeGroupData('groupType', selectedGroupType);

    handleChangeGroupData('employeeId', 0);
    handleChangeGroupData('departmentId', 0);
  };

  const getGroupTypeLabel = (groupType) => {
    const selectedOption = GROUP_TYPE.find((option) => option.value === groupType);
    return selectedOption ? selectedOption.label : '';
  };

  const handleChangeMetrics = (goalIndex, targetIndex, selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';
    const updatedGoals = [...formData.goal];
    updatedGoals[goalIndex].targets[targetIndex] = { ...updatedGoals[goalIndex].targets[targetIndex], metrics: value };
    setFormData({ ...formData, goal: updatedGoals });
  };

  const loadEmployeesData = async () => {
    setLoading(true);
    const res = await fetchAllEmployeesById();
    if (res) setEmployees(res);
    setLoading(false);
  };

  const loadDepartmentData = async () => {
    setLoading(true);
    const departmentRes = await API.fetchAllDepartment();
    if (departmentRes) setDepartments(departmentRes);
    setLoading(false);
  };

  useEffect(() => {
    loadEmployeesData();
    loadDepartmentData();
  }, []);

  useEffect(() => {
    if (employees.length > 0) {
      setEmployeesArray(getEmployeesOption(employees));
    }
  }, [employees]);

  useEffect(() => {
    const departmentOptions = departments.map((department) => ({
      label: department.name,
      value: department.id,
    }));
    setDepartmentOption(departmentOptions);
  }, [departments]);

  return (
    <div className='xs:w-[100%] sm:w-[100%] mdLg:w-full mdLg:mx-0 mx-auto'>
      <form onSubmit={submitData} className='space-y-10 mt-4'>
       <div className='border-2 border-dashed space-y-6 rounded-lg p-4'>
         <div className='w-[95%] flex justify-end items-start'>
            <Select
              options={employeesArray} 
              selectedOption={formData.approverId}
              placeholder={'Select employee'}
              label={'To be approved by'}
              name={`approverId`}
              onChange={(e) => handleChangeGroupData('approverId', e.value)}
            />
          </div>
          <div className='flex flex-row w-[95%] justify-between items-start gap-1'>
            <div className='flex gap-2 items-start w-full mdLg:w-[65%]'>
              <Select
                withLabel
                label='GROUP TYPE'
                containerClass='w-full'
                className='mdLg:w-[90%]'
                selectedOption={formData.groupType}
                options={GROUP_TYPE}
                name={`groupType`}
                onChange={handleGroupTypeChange}
              />
            </div>

            <div className='flex gap-5 items-start w-full mdLg:w-[55%]'>
              <div className='flex flex-col gap-2 w-full'>
              <h6 className='text-xs'>CHOOSE {getGroupTypeLabel(formData.groupType)}</h6>
              {formData.groupType === 1 ? (
                <Select
                  selectedOption={formData.employeeId}
                  placeholder={'Select employee'}
                  options={employeesArray}
                  name='employeeId'
                  className='capitalize'
                  onChange={(e) => handleChangeGroupData('employeeId', e.value)}
                />
              ) : formData.groupType === 2 ? (
                <Select
                  selectedOption={formData.departmentId}
                  placeholder={'Select department'}
                  options={departmentsOption}
                  name='departmentId'
                  className='capitalize'
                  onChange={(e) => handleChangeGroupData('departmentId', e.value)}
                />
              ) : null}
            </div>
            </div>
          </div>
        </div>
        {Array.isArray(formData.goal) && formData.goal.map((goal, goalIndex) => (
          <div key={goalIndex} className='space-y-9 gap-2 bg-pink-30 shadow-xl rounded-3xl p-4 md:p-10'>
            <div className='w-full flex justify-between items-start'>
              <h3 className='text-xs justify-between gap-10 font-bold'>
                {goal.performanceGoalCategory === 1 ? 'FINANCIAL' :
                 goal.performanceGoalCategory === 2 ? 'CUSTOMER' :
                 goal.performanceGoalCategory === 3 ? 'INTERNAL' :
                 'LEARNING'}
              </h3>
            </div>
            <div className='flex flex-col justify-between items-end gap-5'>
              <div className='flex gap-5 items-start w-full mdLg:w-[100%]'>
                <div className='flex flex-col gap-2 w-full'>
                  <h6 className='text-xs'>CORPORATE OBJECTIVES</h6>
                  <Select
                    placeholder={''}
                    selectedOption={{ value: goal.objectiveId, label: objectiveOption.find(option => option.value === goal.objectiveId)?.label || '' }}
                    options={objectiveOption}
                    onChange={(e) => handleChangeFormData(e, goalIndex, true)}
                    name='objectiveId'
                  />
                </div>
                {/*goalIndex > 0 && formData.goal.length > 1 && (
                  <MdDelete onClick={() => deleteObj(goalIndex)} className='cursor-pointer' />
                )*/}
              </div>

              <div className='flex gap-5 items-start w-full mdLg:w-[100%]'>
                <div className='flex gap-5 items-start w-full'>
                  <div className='flex flex-col gap-2 space-y-1 w-full'>
                    <h6 className='text-xs'>
                      {goal.performanceGoalCategory === 1 ? 'FINANCIAL GOAL' :
                       goal.performanceGoalCategory === 2 ? 'CUSTOMER GOAL' :
                       goal.performanceGoalCategory === 3 ? 'INTERNAL GOAL' :
                       'LEARNING GOAL'}
                    </h6>
                    <TextArea
                      placeholder={''}
                      value={goal.description}
                      onChange={(e) => handleChangePerformanceGoal(goalIndex, e.target.value)}
                      name='description'
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='space-y-5'>
              {goal.targets.map((target, targetIndex) => (
                <div key={targetIndex} className='space-y-6 mdLg:space-y-0 mdLg:flex mdLg:flex-wrap items-start gap-3 gap-y-6'>
                  <div className='flex flex-wrap items-end gap-3'>
                    <span className='flex-1 w-full md:w-1/4 mb-4 md:mb-0 md:mr-4'>
                      <Select
                        withLabel
                        placeholder={'Select metric type'}
                        label={'METRIC TYPE'}
                        containerClass=''
                        selectedOption={METRIC_TYPE.find(option => option.value === target.metrics) || {}}
                        options={METRIC_TYPE}
                        name='metrics'
                        onChange={(e) => handleChangeMetrics(goalIndex, targetIndex, e)}
                      />
                    </span>
                    <span className='flex-1 w-full md:w-1/4 mb-4 md:mb-0 md:mr-4'>
                      <Input
                        withLabel
                        label={'WEIGHT (%)'}
                        value={target.weight}
                        name='weight'
                        onChange={(e) => handleChangeKeyResults(goalIndex, targetIndex, e)}
                        type='number'
                      />
                    </span>
                    <span className='flex-1 w-full md:w-1/4 mb-4 md:mb-0 md:mr-4'>
                      <Input
                        withLabel
                        label={'TARGET VALUE'}
                        value={target.value}
                        name='value'
                        onChange={(e) => handleChangeKeyResults(goalIndex, targetIndex, e)}
                        type='number'
                      />
                    </span>
                    <span className='flex-1 w-full md:w-1/3 mb-4 md:mb-0 md:mr-4'>
                      <Input
                        withLabel
                        label={'DUE DATE'}
                        value={target.dueDate}
                        name='dueDate'
                        onChange={(e) => handleChangeKeyResults(goalIndex, targetIndex, e)}
                        type='date'
                      />
                    </span>

                    {targetIndex > 0 && (
                      <MdDelete
                        className={`ml-[4rem] self-center  cursor-pointer `}
                        onClick={() => deleteKeyResults(goalIndex, targetIndex)}
                      />
                    )}
                  </div>
                </div>
              ))}{' '}
            </div>
          </div>
        ))}
        <div className='mx-auto flex justify-center mdLg:justify-start'>
          <Button
            text={'+ADD MORE'}
            className='bg-transparent border-purple-100 border hover:border-none '
            onClick={addCompetency}
          />
        </div>
      </form>
    </div>
  );
};

export default BscGoal;
