import React, { useState, useRef } from 'react';
import { TbDownload } from 'react-icons/tb';
import Button from '../../../../../components/button';

const RightPayroll = ({ loading, handleAddList, submitData }) => {
  const [file, setFile] = useState('');
  const fileInputRef = useRef(null);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    handleAddList(file);
  };

  const handleImportClick = (e) => {
    e.preventDefault();  // Prevent the default form submission behavior
    fileInputRef.current.click();
  };

  return (
    <div className='flex items-center gap-3 justify-end flex-wrap'>
      <Button
        icon={<TbDownload size={15} />}
        withIcon
        text={'IMPORT PAYROLL'}
        className='flex justify-between items-center space-x-1'
        isTransparent
        file={file}
        onClick={handleImportClick}
      />
      <Button
        isPurple
        text={'SAVE PAYROLL'}
        loading={loading}
        onClick={(e) => {
          e.preventDefault();  // Optional: if the submitData function handles form submission.
          submitData(e);
        }}
      />
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        onChange={handleFileSelect}
      />
    </div>
  );
};

export default RightPayroll;
