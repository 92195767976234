import React from 'react';
import CalendarTab from './CalendarTab';
import EmployeesTab from './EmployeesTab';
import RequestsTab from './RequestsTab';

const OverviewContent = ({ employees, pendingLeaveRequests }) => {
  return (
    <div className='flex justify-between text-blue-100 flex-col mdLg:flex-row gap-6 md:h-[80vh] overflow-hidden no-scroll-bar'>
      <EmployeesTab employees={employees} />
      <RequestsTab pendingLeaveRequests={pendingLeaveRequests} />
      <CalendarTab />
    </div>
  );
};

export default OverviewContent;
