import React from 'react';
import { HeaderCard } from '../cards/Cards';
import Header from '../header';
import SideBar from '../sidebar';

const RecruitLayoutWithSideBar = ({ hText, children }) => {
  return (
    <div>
      <Header helpdesk link="/" isRecruit />
      <div className="space-y-10">
        <HeaderCard text={hText} isRecruit className={'rounded-none'} />
        <div className="flex gap-4">
          <SideBar isRecruit />
          <div className="z-1 mx-auto w-[90%] md:mx-0 md:w-[87%]  space-y-6 pb-10 rounded-xl bg-pink-30  p-6">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitLayoutWithSideBar;
// <VerticalAlignCard
// 				imgOnly
// 				text={"filjewfi"}
// 				icon="hjfeh"
// 				className="bg-pink-100"
// 			/>
