import React from 'react';

const StatusCard = ({
  isNo,
  isYes,
  isOpen,
  isClosed,
  isDraft,
  isApproved,
  isRejected,
  pendingApproval,
  onClick,
  reject,
  approve
}) => {
  return (
    <div
      onClick={onClick}
      className={`uppercase text-2xs font-bold tracking-tight w-fit px-2 py-[4px] rounded-[3px] hover:scale-[.85] transition-all duration-300 cursor-pointer ${
        isDraft
          ? 'bg-yellow-100 bg-opacity-10 text-yellow-100'
          : isClosed || isRejected
          ? 'bg-pink-100 text-pink-100 bg-opacity-10'
          : pendingApproval && reject
          ? 'bg-none text-pink-100 hover:bg-pink-30'
          : pendingApproval && approve
          ? 'bg-opacity-10 bg-green-100 text-green-100'
          : isOpen || isApproved
          ? 'bg-opacity-10 bg-green-100 text-green-100'
          : isNo
          ? 'bg-pink-100 text-pink-100 bg-opacity-10'
          : isYes
          ? 'bg-opacity-10 bg-green-100 text-green-100'
          : ''
      }`}>
      {isNo && 'no'}
      {isYes && 'yes'}
      {isOpen && 'open'}
      {isClosed && 'closed'}
      {isDraft && 'draft'}
      {pendingApproval && reject && 'reject'}
      {pendingApproval && approve && 'approve'}
      {isRejected && 'rejected'}
      {isApproved && 'approved'}
    </div>
  );
};

export default StatusCard;
