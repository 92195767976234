import React from 'react';
import { BsFillBriefcaseFill } from 'react-icons/bs';

const RecruitReportRight = () => {
  return (
    <div className="space-y-6 ">
      <div className="flex items-center gap-1">
        <h4 className="text-base w-[200px]">Quick Links</h4>{' '}
        <p className=" w-[100%] h-[2px]  bg-pink-100"> </p>
      </div>
      <div className=" bg-white-20 bg-opacity-25 rounded-md p-3 space-y-6">
        <div className="flex w-full bg-white-100 p-4 items-center rounded-sm text-blue-100 font-medium gap-3">
          <BsFillBriefcaseFill />
          <p>Add Jobs</p>
        </div>
        <div className="flex w-full bg-white-100 p-4 items-center rounded-sm text-blue-100 font-medium gap-3">
          <BsFillBriefcaseFill />
          <p>Add Jobs</p>
        </div>
        <div className="flex w-full bg-white-100 p-4 items-center rounded-sm text-blue-100 font-medium gap-3">
          <BsFillBriefcaseFill />
          <p>Add Jobs</p>
        </div>
      </div>
      <div className="flex items-center gap-1">
        <h4 className="text-base w-[200px]">Booked Meetings</h4>{' '}
        <p className=" w-[100%] h-[2px]  bg-pink-100"> </p>
      </div>

      <div className=" bg-white-20 bg-opacity-25 rounded-md p-3 space-y-6">
        <div className="flex w-full bg-white-100 p-4 items-center rounded-sm text-gray-200 font-medium gap-3">
          <div className="flex flex-col items-center text-xs bg-gray-200 text-white-100 p-1 rounded-2xl px-3">
            <p className="text-white-100">24</p> <p className="font-bold">Mon</p>{' '}
          </div>
          <div>
            {' '}
            <p className="text-sm font-bold">Add Jobs</p>
            <p className=" text-2xs text-gray-200">10:00am - 12:00 am</p>
          </div>
        </div>
        <div className="flex w-full bg-white-100 p-4 items-center rounded-sm text-gray-200 font-medium gap-3">
          <div className="flex flex-col items-center text-xs bg-gray-200 text-white-100 p-1 rounded-2xl px-3">
            <p className="text-white-100">24</p> <p className="font-bold">Mon</p>{' '}
          </div>
          <div>
            {' '}
            <p className="text-sm font-bold">Add Jobs</p>
            <p className=" text-2xs text-gray-200">10:00am - 12:00 am</p>
          </div>
        </div>
        <div className="flex w-full bg-white-100 p-4 items-center rounded-sm text-gray-200 font-medium gap-3">
          <div className="flex flex-col items-center text-xs bg-gray-200 text-white-100 p-1 rounded-2xl px-3">
            <p className="text-white-100">24</p> <p className="font-bold">Mon</p>{' '}
          </div>
          <div>
            {' '}
            <p className="text-sm font-bold">Add Jobs</p>
            <p className=" text-2xs text-gray-200">10:00am - 12:00 am</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitReportRight;
