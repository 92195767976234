import React, { useState } from 'react';
import { ReactComponent as Logo } from '../../../assets/img/logo.svg';
import Button from '../../../components/button';
import { STORAGE_KEYS } from '../../../helpers/constant';
import authService from '../../../services/auth.service';

const VerifyEmail = () => {
  const [loading, setLoading] = useState(false);

  const email = localStorage.getItem(STORAGE_KEYS.EMAIL);
  const resendVerificationEmail = async () => {
    setLoading(true);
    await authService.resendVerificationEmail(email);
    setLoading(false);
  };
  return (
    <div className='h-[100vh] flex flex-col items-center pt-32 space-y-16 px-6'>
      <Logo />
      <div className='flex flex-col gap-2 justify-center text-center'>
        <h4 className=' text-xl md:text-3xl'>Please Verify Your Email</h4>
        <p className=' text-black-200 font-medium'>
          We have sent an email to {email} for verification
        </p>
      </div>
      <Button
        text={'Send Again'}
        isPink
        className={'h-14 w-full sm:w-[300px]'}
        onClick={resendVerificationEmail}
        disabled={loading}
        loading={loading}
      />
    </div>
  );
};

export default VerifyEmail;
