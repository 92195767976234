import React, { useState } from 'react';
import SearchWithFilter from '../../../components/general/SearchWithFilter';
import AbsenceContent from './AbsenceContent';

const AbsenceLayout = () => {
  const [loading, setLoading] = useState(false);

  // const fetchLeaveRequests = async (e) => {
  //   e.preventDefault();
  //   let payload = {};
  //   setLoading(true);
  //   // await apiService.get
  //   setLoading(false);
  // };

  return (
    <div className='space-y-5 white-bg'>
      <SearchWithFilter placeholder={'Search by employee ID, department'} isPink />
      <div className='pink-bg'>
        <AbsenceContent />
      </div>
    </div>
  );
};

export default AbsenceLayout;
