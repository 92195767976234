import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import Button from '../../../../../../components/button';
import Input, { TextArea } from '../../../../../../components/input';
import Select from '../../../../../../components/input/Select';
import { fetchAllEmployeesById } from '../../../../../../utils/apis/api-modules/employee';
import { getEmployeesOption } from '../../../../../../helpers/functions';
import { GROUP_TYPE, METRIC_TYPE } from '../../../../../../helpers/constant';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { API } from '../../../../../../utils/apis';

const OkrOnly = ({ formData, setFormData, employeeId, objectiveOption, submitData }) => {
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employeesArray, setEmployeesArray] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [departmentsOption, setDepartmentOption] = useState([]);

  const addKeyResults = () => {
    const updatedTargets = [...formData.goal[0].targets, { target: '', metrics: 1, value: '', dueDate: '', weight: 0 }];
    setFormData(prevFormData => ({
      ...prevFormData,
      goal: [{ ...prevFormData.goal[0], targets: updatedTargets }]
    }));
  };

  const deleteKeyResults = (ind) => {
    const updatedTargets = formData.goal[0].targets.filter((_, targetIndex) => targetIndex !== ind);
    setFormData(prevFormData => ({
      ...prevFormData,
      goal: [{ ...prevFormData.goal[0], targets: updatedTargets }]
    }));
  };

  const handleChangeFormData = (e, isObj) => {
    setFormData(prevFormData => {
      const updatedForm = { ...prevFormData };
      if (isObj) {
        updatedForm.goal[0].objectiveId = e?.value;
      } else {
        const { name, value } = e.value;
        updatedForm[name] = value;
      }
      return updatedForm;
    });
  };

  const handleChangePerformanceGoal = (description) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      goal: [{ ...prevFormData.goal[0], description }]
    }));
  };

  const handleChangeGroupData = (name, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleChangeMetrics = (ind, selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';
    const updatedTargets = formData.goal[0].targets.map((target, targetIndex) => {
      if (targetIndex === ind) {
        return { ...target, metrics: value };
      }
      return target;
    });
    setFormData(prevFormData => ({
      ...prevFormData,
      goal: [{ ...prevFormData.goal[0], targets: updatedTargets }]
    }));
  };

  const handleGroupTypeChange = (e) => {
    const selectedGroupType = e.value;
    handleChangeGroupData('groupType', selectedGroupType);
  
    // Reset employeeId and departmentId when groupType changes
    handleChangeGroupData('employeeId', 0);
    handleChangeGroupData('departmentId', 0);
  };
  

  const getGroupTypeLabel = (groupType) => {
    const selectedOption = GROUP_TYPE.find((option) => option.value === groupType);
    return selectedOption ? selectedOption.label : '';
  };

  const handleChangeKeyResults = (ind, e) => {
    const { name, value } = e.target || e;
    if (name === 'weight' && value > 100) {
      alert('Weight cannot exceed 100%');
      return;
    }
    const updatedTargets = formData.goal[0].targets.map((target, targetIndex) => {
      if (targetIndex === ind) {
        return { ...target, [name]: value };
      }
      return target;
    });
    setFormData(prevFormData => ({
      ...prevFormData,
      goal: [{ ...prevFormData.goal[0], targets: updatedTargets }]
    }));
  };

  useEffect(() => {
    setEmployeesArray(getEmployeesOption(employees));
  }, [employees]);

  useEffect(() => {
    getDepartments(departments);
  }, [departments]);

  const getDepartments = (department) => {
    let DEPARTMENT_ARRAY = [];
    for (let i = 0; i < department.length; i++) {
      DEPARTMENT_ARRAY.push({ label: department[i]?.name, value: department[i]?.id });
    }
    setDepartmentOption(DEPARTMENT_ARRAY);
  };

  const loadEmployeesData = async () => {
    setLoading(true);
    const res = await fetchAllEmployeesById();
    if (res) setEmployees(res);
    setLoading(false);
  };

  const loadDepartmentData = async () => {
    setLoading(true);
    const departmentRes = await API.fetchAllDepartment();
    if (departmentRes) setDepartments(departmentRes);
    setLoading(false);
  };

  useEffect(() => {
    loadEmployeesData();
    loadDepartmentData();
  }, []);

  return (
    <div className='xs:w-[80%] sm:w-[70%] mdLg:w-full mdLg:mx-0 mx-auto'>
      <form onSubmit={submitData} className='space-y-10 mt-4'>
        <div className='space-y-8 bg-pink-30 shadow-xl rounded-3xl p-4 md:p-10'>
          <div className='flex flex-wrap justify-between gap-5'>
            <div className='flex flex-col w-full'>
              <h6 className='text-xs'>CORPORATE OBJECTIVES</h6>
              <Select
                selectedOption={{ value: formData.goal[0].objectiveId, label: formData.goal[0].objectiveId?.label }}
                placeholder={'Select corporate objective'}
                options={objectiveOption}
                name='objectiveId'
                onChange={(e) => handleChangeFormData(e, true)}
              />
            </div>
            <div className='flex gap-5 items-start w-full mdLg:w-[100%]'>
                <div className='flex flex-col gap-2 w-full'>
                  <h6 className='text-xs'>GROUP TYPE</h6>
                  <Select
                    selectedOption={formData.groupType}
                    placeholder={'Select group type'}
                    options={GROUP_TYPE}
                    name='groupType'
                    onChange={handleGroupTypeChange}
                  />
                </div>
            </div>
            <div className='flex flex-row justify-between items-center gap-5 w-full'>
                <div className='flex gap-5 items-start w-full mdLg:w-[100%]'>
                  <div className='flex flex-col gap-2 w-full'>
                    <h6 className='text-xs'>CHOOSE {getGroupTypeLabel(formData.groupType)}</h6>
                    {formData.groupType === 1 ? (
                      <Select
                        selectedOption={formData.employeeId}
                        placeholder={'Select employee'}
                        options={employeesArray}
                        name='employeeId'
                        className='capitalize'
                        onChange={(e) => handleChangeGroupData('employeeId', e.value)}
                      />
                    ) : formData.groupType === 2 ? (
                      <Select
                        selectedOption={formData.departmentId}
                        placeholder={'Select department'}
                        options={departmentsOption}
                        name='departmentId'
                        className='capitalize'
                        onChange={(e) => handleChangeGroupData('departmentId', e.value)}
                      />
                    ) : null}
                  </div>
                </div>
                <div className='flex gap-5 items-start w-full mdLg:w-[100%] '>
                  <div className=' flex flex-col gap-2 w-full'>
                    <h6 className=' text-xs'>TO BE APPROVED BY</h6>
                    <Select
                      selectedOption={formData.approverId}
                      placeholder={'Select employee'}
                      options={employeesArray}
                      className='capitalize'
                      onChange={(e) => handleChangeGroupData('approverId', e.value)}
                    />
                  </div>
                </div>
            </div>
          </div>
          <div className='flex flex-col w-full'>
            <h6 className='text-xs'>INPUT PERFORMANCE GOAL</h6>
            <TextArea
              withLabel
              containerClass=''
              value={formData.goal[0].description}
              placeholder={'Enter performance goal of employee or department'}
              name='description'
              onChange={(e) => handleChangePerformanceGoal(e.target.value)}
            />
          </div>
          {Array.isArray(formData.goal[0].targets) &&
            formData.goal[0].targets.map((result, ind) => (
              <div key={ind}>
                <div className='space-y-6 mdLg:space-y-0 mdLg:flex mdLg:flex-wrap items-start gap-3 gap-y-6'>
                  <div className='mdLg:w-[100%]'>
                    <span>
                      <TextArea
                        withLabel
                        label='KEY TARGET'
                        placeholder={'Enter key targets of employee or department'}
                        className=''
                        value={result.target}
                        name='target'
                        onChange={(e) => handleChangeKeyResults(ind, e)}
                      />
                    </span>
                  </div>
                  <div className='flex flex-wrap items-end gap-2'>
                      <span className='flex-1 w-full md:w-1/4 mb-4 md:mb-0 md:mr-4'>
                        <Select
                          withLabel
                          label='METRIC TYPE'
                          containerClass=''
                          selectedOption={result.metrics}
                          options={METRIC_TYPE}
                          name='metrics'
                          onChange={(e) => handleChangeMetrics(ind, e)}
                        />
                      </span>
                      <span className='flex-1 w-full md:w-1/4 mb-4 md:mb-0 md:mr-4'>
                        <Input
                          withLabel
                          label='TARGET VALUE'
                          value={result.value}
                          name='value'
                          onChange={(e) => handleChangeKeyResults(ind, e)}
                        />
                      </span>
                      <span className='flex-1 w-full md:w-1/4 mb-4 md:mb-0 md:mr-4'>
                        <Input
                          withLabel
                          label='WEIGHT (%)'
                          value={result.weight}
                          name='weight'
                          onChange={(e) => handleChangeKeyResults(ind, e)}
                        />
                      </span>
                      <span className='flex-1 w-full md:w-1/4 mb-4 md:mb-0 md:mr-4'>
                        <Input
                          withLabel
                          label='DUE DATE'
                          value={result.dueDate}
                          name='dueDate'
                          onChange={(e) => handleChangeKeyResults(ind, e)}
                          type='date'
                        />
                      </span>
                      {ind > 0 && (
                      <MdDelete
                        className='ml-[4rem] self-center cursor-pointer'
                        onClick={() => deleteKeyResults(ind)}
                      />
                    )}
                  </div>
                  <div className='flex flex-wrap place-items-end'>
                    <div></div>
                    <div className="ml-auto flex justify-end">
                      <span className='flex justify-end'>
                        <h6
                          className={`underline flex justify-end cursor-pointer ${
                            ind === formData.goal[0].targets.length - 1 ? 'flex' : 'hidden'
                          }`}
                          onClick={() => addKeyResults(ind)}
                        >
                          +ADD MORE KEY TARGETS
                        </h6>
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            ))}
        </div>
      </form>
    </div>
  );
};

export default OkrOnly;
