import React from 'react';
import { BiSearch, BiCalendarAlt } from 'react-icons/bi';
import Input from '../input';

const Search = ({
  placeholder,
  className,
  height,
  isRecruit,
  onChange,
}) => {
  return (
    <div className={`flex items-center gap-4 ${className}`}>
      <Input
        isRounded
        placeholder={placeholder}
        icon={<BiSearch size={20} />}
        icons={<BiCalendarAlt />}
        containerClass={`${isRecruit ? 'w-full' : 'w-full'}`}
        pl='pl-[3rem]'
        withIcon
        withIcons
        height={height}
        onChange={onChange}
        className='relative w-full flex items-center'
      />
    </div>
  );
};

export default Search;
