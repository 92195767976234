import React from 'react';
import EventsDetails from '../../../../components/base/calendar-tab';

const CalendarTab = () => {
  return (
    <div className="space-y-3  h-[100%] overflow-y-scroll no-scroll-bar w-[360px]">
      <p className="font-medium">Calendar</p>
      <EventsDetails />
    </div>
  );
};

export default CalendarTab;
