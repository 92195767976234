import React from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const ReportsLayout = () => {
  const navigate = useNavigate();

  return (
    <div className='uppercase text-xs flex items-center justify-start space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer'
      onClick={() => navigate(-1)}>
      <MdOutlineArrowBack /> <span className='text-blue-100'> BACK</span>
    </div>
  );
};

export default ReportsLayout;
