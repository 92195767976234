import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import PayrollForm from './PayrollForm';
import Button from '../../../../../components/button';
import { API } from '../../../../../utils/apis';

const LeftPayroll = ({
  loading,
  error,
  employees,
  salaryComponent,
  handleChange,
  formData,
  setFormData
}) => {
  const [grossData, setGrossData] = useState({
    grossAmount: 0,
    duration: 1
  });
  const [addLoading, setAddLoading] = useState(false);
  const [isTableVisible, setTableVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  const updateGrossData = (grossAmount, duration) => {
    setGrossData({
      grossAmount,
      duration
    });
  };

  const previewData = async (e) => {
    setAddLoading(true);
  
    try {
      const response = await API.previewCompensation(grossData);
      console.log(grossData);
  
      if (Array.isArray(response)) {
        const formattedData = response.map((item) => ({
          salaryComponent: item.salaryComponent,
          amount: item.amount
        }));
        setTableData(formattedData);
        setTableVisible(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setAddLoading(false);
    }
  
    e.preventDefault();
  };

  return (
    <div className='space-y-6'>
      <div className='py-6 space-y-6'>
        <div>
          <PayrollForm
            employees={employees}
            salaryComponent={salaryComponent}
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
            updateGrossData={updateGrossData}
          />
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <div className='flex items-center gap-3 justify-center flex-wrap'>
          <Button
            isPink
            text={'PREVIEW'}
            loading={loading}
            onClick={previewData}
          />
        </div>
      </div>

      {isTableVisible && (
        <div className='rounded-[20px] bg-blue-100 p-10 space-y-6'>
          <div className='flex justify-end'>
            <button
              className='text-gray-500 hover-text-gray-700'
              onClick={() => setTableVisible(false)}
            >
              x
            </button>
          </div>
          <table className='w-full whitespace-nowrap space-y-4 rounded-[5px]'>
            <thead className='uppercase tracking-[3px] text-white-100 font-bold text-2xs mb-4 w-fit xl:w-full'>
              <tr>
                <th className='text-left ml-2.5'>Component</th>
                <th className='text-right'>Amount (₦)</th>
              </tr>
            </thead>
            <tbody className='w-[138%] bg-white-100 flex flex-col space-y-8 rounded-[10px]'>
              {tableData.map((item, index) => (
                <tr key={index} className='space-y-6 w-full mt-2 mb-2'>
                  <div className='flex flex-wrap place-content-between w-full'>
                    <td className='text-left font-bold text-blue-100 ml-2.5'>{item.salaryComponent}</td>
                    <td className='text-right font-bold text-blue-100 mr-2.5'>
                      {item.amount.toLocaleString()}
                    </td>
                  </div>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default LeftPayroll;
