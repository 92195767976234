import React from 'react';
import LeftAudit from './LeftAudit';
import RightAudit from './RightAudit';

const AuditLayout = () => {
  return (
    <div className="flex">
      <LeftAudit />
      <RightAudit />
    </div>
  );
};

export default AuditLayout;
