import React, { useState } from 'react';
import BaseModal from '../../../../../components/base/modal';
import Button from '../../../../../components/button';
import { apiService } from '../../../../../helpers/api';

const DeleteEmployeeGradeModal = ({
  showDeleteGradeModal,
  deleteEmployeeGradeInfo,
  closeModal
}) => {
  const [loading, setLoading] = useState(false);

  const deleteEmployeeGrade = async () => {
    setLoading(true);
    try {
      const res = await apiService.put(
        `EmployeeGrade/DeleteGrade`,
        { gradeId: deleteEmployeeGradeInfo },
        true
      );
      if (res) {
        closeModal();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      title={'Are you sure you want to '}
      coloredText='Delete? '
      show={showDeleteGradeModal}
      onClose={closeModal}>
      <div className='flex gap-4 flex-wrap mt-6 justify-end'>
        <Button className={''} text='Go back' onClick={closeModal} isGray />
        <Button
          text='Delete'
          isPink
          className='bg-opacity-80'
          loading={loading}
          onClick={deleteEmployeeGrade}
        />
      </div>
    </BaseModal>
  );
};

export default DeleteEmployeeGradeModal;
