import React from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Accordion = ({ question, answer, open, index, onClick }) => {
  return (
    <div
      className={`border border-purple-100 rounded-md py-6 px-6 flex items-center  space-y-6 faqs h-14 overflow-hidden ${
        open === index && ' h-[220px]'
      }`}>
      <div className="w-full space-y-6">
        <div className=" text-purple-100 font-bold text-base md:text-lg items-center flex justify-between gap-2 transition-all duration-1000">
          {question}
          {open === index ? (
            <FaChevronUp className="cursor-pointer" onClick={onClick} />
          ) : (
            <FaChevronDown className="cursor-pointer" onClick={onClick} />
          )}
        </div>

        {open === index && (
          <div
            className={` text-sm md:text-base rounded-md md:px-16 leading-8 font-medium transition-all duration-300 = ${
              open === index && 'bg-white-100 h-auto py-4 flex items-center'
            }`}>
            {answer}
          </div>
        )}
      </div>
    </div>
  );
};

export default Accordion;
