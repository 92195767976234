import React from 'react';
import AnnouncementCard from '../../../../components/cards/AnnouncementCard';

const UpcomingAnnouncements = () => {
  return (
    <div className="space-y-3">
      <p className="font-bold">Announcements</p>
      <div>
        <AnnouncementCard time={'12:00pm'} title="New Client Signing" />
      </div>
    </div>
  );
};

export default UpcomingAnnouncements;
