import React, { useState } from 'react';

const SettingsSideBar = ({ SETTINGS_ARRAY }) => {
  const [active, setActive] = useState(SETTINGS_ARRAY[0].name);
  const styles = {
    active: 'bg-pink-30 font-bold',
    inactive: 'font-medium '
  };
  return (
    <div className='h-[100vh] bg-white-100 rounded-lg w-[25%] pt-8'>
      {SETTINGS_ARRAY.map(({ name, icon, onClick }, i) => (
        <div
          key={i}
          className={`text-black-100 flex gap-3 cursor-pointer p-2 pl-6 items-center ${
            active === name ? styles.active : styles.inactive
          }`}
          onClick={() => {
            setActive(name);
            onClick();
          }}>
          {icon}
          {name}
        </div>
      ))}
    </div>
  );
};

export default SettingsSideBar;
