import React from 'react';
import DashWithNumbers from '../../../components/landing-page/DashWithNumbers';
import One from '../../../assets/img/landing/1.png';
import Two from '../../../assets/img/landing/2.png';
import Three from '../../../assets/img/landing/3.png';
import Four from '../../../assets/img/landing/4.png';
import Five from '../../../assets/img/landing/5.png';
import Six from '../../../assets/img/landing/6.png';
import ResourceTab from './ResourceTab';

const HumanResource = () => {
  return (
    <div className="bg-cream-100 relative">
      <DashWithNumbers no={'03'} isBlue />
      <div className="flex flex-col pt-24 lg:pt-0 lg:flex-row px-5 sm:px-20 items-center gap-10">
        <div className="">
          <div className="flex flex-col md:pl-14 space-y-1 ">
            <span className="text-yellow-100  font-semibold text-sm md:text-base">
              We are dedicated to
            </span>
            <span className=" font-bold text-blue-100 text-lg sm:text-3xl w-full md:w-[70%]">
              Simplifying human resource management for you
            </span>
            <div className="pt-10">
              <ResourceTab />
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-x-8 w-[80%] sm:w-[60%] md:w-[45%] xl:w-auto  ">
          <div className="flex flex-col justify-between gap-y-10 pt-8">
            <img src={One} alt="" className="hr-one " />
            <img src={Two} alt="" className="hr-two" />
          </div>

          <div className="flex flex-col gap-y-10 justify-between">
            <img src={Three} alt="" className="hr-three" />
            <img src={Four} alt="" className="hr-four" />
          </div>

          <div className="flex flex-col justify-between gap-y-10 pt-8">
            <img src={Five} alt="" className="hr-five " />
            <img src={Six} alt="" className="hr-six" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HumanResource;
