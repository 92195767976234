import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import Button from '../../../../components/button';
import VacancyForm from './VacancyForm';
import { API } from '../../../../utils/apis';
import { STORAGE_KEYS } from '../../../../helpers/constant';

const AddVacancy = () => {
  const staffId = localStorage.getItem(STORAGE_KEYS?.EMPLOYEE_ID);

  const emptyForm = {
    vacancyId: '',
    jobTitle: '',
    jobType: ' ',
    jobStatus: 1,
    department: '',
    keyResponsibility: '',
    keyRequirement: '',
    skillRequirement: '',
    benefitsAndCompensation: '',
    createdBy: staffId,
    isActive: true
  };
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(emptyForm);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const submitData = async (e) => {
    setLoading(true);
    console.log(formData);
    await API.addVacancy(formData, navigate);
    setLoading(false);
    e.preventDefault();
  };

  const [departments, setDepartments] = useState([]);
  const [departmentsOption, setDepartmentOption] = useState([]);
  const loadData = async () => {
    const departmentRes = await API.fetchAllDepartment();
    if (departmentRes) setDepartments(departmentRes);
  };

  const getDepartments = (department) => {
    let DEPARTMENT_ARRAY = [];
    for (let i = 0; i < department.length; i++) {
      DEPARTMENT_ARRAY.push({ label: department[i]?.name, value: department[i]?.id });
    }
    setDepartmentOption(DEPARTMENT_ARRAY);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    getDepartments(departments);
  }, [departments]);
  
  return (
    <div className='bg-pink-30 rounded-sm pt-4 pb-14 px-12'>
      <div
        className='uppercase text-xs flex items-center justify-start space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer'
        onClick={() => navigate(-1)}>
        <MdOutlineArrowBack /> <span className='text-blue-100'> BACK</span>
      </div>

      <div className='pl-5 space-y-6 md:space-y-10'>
        <div className='mt-6 flex justify-between flex-wrap items-center gap-3'>
          <h4>Create New Vacancy</h4>
          <div className='flex gap-3 items-center flex-wrap'>
            <Button text={'save as draft'} isTransparent loading={loading} />
            <Button
              text={'create vacancy'}
              isPink
              loading={loading}
              onClick={submitData}
              type='submit'
            />
          </div>
        </div>
        <VacancyForm
          handleChange={handleChange}
          submitData={submitData}
          formData={formData}
          setFormData={setFormData}
          department={departmentsOption}
        />
      </div>
    </div>
  );
};

export default AddVacancy;
