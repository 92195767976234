import { apiService } from '../../../helpers/api';
import { MANAGE_EVENTS } from '../../../router/router';

const base = 'Event/';

export const createEvent = async (data, navigate) => {
  try {
    const res = await apiService.post(`${base}Create`, data, true);
    if (res) {
      navigate(MANAGE_EVENTS);
    }
  } catch (err) {
    console.log('Error in creating event: ', err)
  }
};
