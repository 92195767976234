import React, { Fragment, useState, useEffect } from 'react';
import Loader from '../../../components/base/loader';
import { API } from '../../../utils/apis';
import OverviewContent from './overview-content/';
import OverviewHeader from './OverviewHeader';

const OverviewLayout = () => {
  const [requestCount, setRequestCount] = useState({ leaveRequests: 0, employeesCount: 0 });

  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [pendingLeaveRequests, setPendingLeaveRequests] = useState([]);

  const getOverviewData = async () => {
    setLoading(true);

    await API.getRequestCount(requestCount, setRequestCount);
    const employees = await API.fetchAllEmployeesById();

    const request = await API.fetchPendingLeaveRequests();

    if (employees) setEmployees(employees);
    if (request) setPendingLeaveRequests(request);
    setLoading(false);
  };

  useEffect(() => {
    getOverviewData();
  }, []);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <OverviewHeader requestCount={requestCount} />
          <OverviewContent employees={employees} pendingLeaveRequests={pendingLeaveRequests} />
        </>
      )}
    </Fragment>
  );
};

export default OverviewLayout;
