import { apiService } from '../helpers/api';
import { STORAGE_KEYS } from '../helpers/constant';
import { Toast } from '../components/toast/toast';
import { DASHBOARD, EMAIL_VERIFICATION } from '../router/router';

const LOGIN_URL = 'UserAccess/Login';
const SIGNUP_URL = 'Company/CreateCompany';
const FORGOT_PASSWORD_URL = 'UserAccess/ForgetPassword';
const RESET_PASSWORD_URL = 'UserAccess/ResetPassword';

class AuthService {
  /** *********************************Login********************* */
  login = async (data, navigate) => {
    try {
      const response = await apiService.post(LOGIN_URL, data, false);
      if (response.data) {
        const res = response?.data?.data?.data;
        localStorage.setItem(STORAGE_KEYS.AUTH_TOKEN, res.token);
        localStorage.setItem(STORAGE_KEYS.USER_ID, res.userId);
        localStorage.setItem(STORAGE_KEYS.COMPANY_ID, res.companyId);
        localStorage.setItem(STORAGE_KEYS.EMAIL, res.email);
        localStorage.setItem(STORAGE_KEYS.FULL_NAME, res.fullName);
        localStorage.setItem(STORAGE_KEYS.ROLE, res.role);
        localStorage.setItem(STORAGE_KEYS.EMPLOYEE_ID, res.employeeId);
        Toast({
          type: 'success',
          message: 'Logged In Successfully'
        });
        navigate(DASHBOARD);
      }
      return response;
    } catch (err) {
      Toast({
        type: 'error',
        message:
          err?.message === 'Network Error'
            ? 'Network Error'
            : err?.response.status === 400
            ? 'Incorrect Email Or Password'
            : err?.message
      });
      
    }
  };

  forgotPassword = async (email) => {
    try {
      const response = await apiService.post(FORGOT_PASSWORD_URL, { email }, false);
      if (response.data) {
        Toast({
          type: 'success',
          message: 'Password Sent To Email'
        });
      }
      return response;
    } catch (err) {
      Toast({
        type: 'error',
        message: err.response.status === 401 ? 'Incorrect Email' : err.message
      });
    }
  };

  verifyEmail = async (email, token) => {
    try {
      const res = await apiService.post(
        'UserManagement/VerifyEmailToken',

        {
          email: email,
          token: token
        },
        false
      );
      if (res) localStorage.clear();
      Toast({
        type: 'success',
        message: 'Email Verified Successfully'
      });
      return res?.data?.data;
    } catch (err) {
      Toast({
        type: 'error',
        message: err.message
      });
    }
  };

  validateResetToken = async (email, token) => {
    try {
      const res = await apiService.post(
        'UserAccess/ValidateResetToken',
        {
          email: email,
          token: token
        },
        false
      );
      return res;
    } catch (err) {
      Toast({
        type: 'error',
        message: err.message
      });
    }
  };

  resendVerificationEmail = async (email) => {
    try {
      const res = await apiService.post(
        'UserManagement/ReSendVerificationEmail',
        {
          email: email
        },
        false
      );
      if (res)
        Toast({
          type: 'success',
          message: 'Please check your email'
        });
      return res?.data?.data;
    } catch (err) {
      Toast({
        type: 'error',
        message: 'There seems to be a network issue. Please try again'
      });
    }
  };

  resetPassword = async (data, navigate) => {
    try {
      const response = await apiService.post(RESET_PASSWORD_URL, data, false);
      if (response.data) {
        localStorage.clear();
        Toast({
          type: 'success',
          message: 'PasswordReset Successfully'
        });
        navigate('/auth/login');
      }
      return response;
    } catch (err) {
      console.log(err);
      Toast({
        type: 'error',
        message:
          err.response.status === 400
            ? 'Password must contain at least 7 letters, 1 symbol and a number'
            : err.message
      });
    }
  };

  signup = async (data, navigate) => {
    try {
      const response = await apiService.post(SIGNUP_URL, data, true);
      if (response.data) {
        const res = response?.data?.data?.data;
        localStorage.setItem(STORAGE_KEYS.AUTH_TOKEN, res.token);
        localStorage.setItem(STORAGE_KEYS.USER_ID, res.userId);
        localStorage.setItem(STORAGE_KEYS.COMPANY_ID, res.companyId);
        localStorage.setItem(STORAGE_KEYS.EMAIL, res.companyEmail);
        localStorage.setItem(STORAGE_KEYS.FIRST_NAME, res.first_name);
        localStorage.setItem(STORAGE_KEYS.LAST_NAME, res.last_name);
        localStorage.setItem(STORAGE_KEYS.ROLE, res.role);
        localStorage.setItem(STORAGE_KEYS.EMPLOYEE_ID, res.employeeId);
        Toast({
          type: 'success',
          message: 'Company Created Successfully'
        });
        navigate(EMAIL_VERIFICATION);
      }
      console.log(response);
      return response;
    } catch (err) {
      Toast({
        type: 'error',
        message:
          err.response.status === 401
            ? 'Incorrect Email Or Password'
            : err.response.status === 417
            ? 'User creation failed! Please check user details and try again.'
            : err.message
      });
    }
  };
}

export default new AuthService();
