import React from 'react';
import { Outlet } from 'react-router-dom';
import { HeaderCard } from '../../components/cards/Cards';
import LayoutWithSideBar from '../../components/layout/LayoutWithSideBar';
const Overview = () => {
  return (
    <LayoutWithSideBar>
      <HeaderCard text={'Overview'} titleClassName={'font-bold'} className={''} />
      <Outlet />
    </LayoutWithSideBar>
  );
};

export default Overview;
