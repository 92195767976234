import React from 'react';
import { Link } from 'react-router-dom';
import { AssesmentIcon } from '../../../../../assets/icons';
import Button from '../../../../../components/button';
import BackWithText from '../../../../../components/general/BackWithText';

const NewInterview = () => {
  return (
    <div className="space-y-5">
      <div className=" bg-pink-100 py-4 flex flex-wrap gap-4 rounded-xl items-center justify-between px-4 text-white-100">
        <BackWithText text={'Create Interview'} goBack />
        <div className="flex gap-2 flex-wrap">
          <Link to={``}>
            <Button text={' Cancel'} className="flex items-center gap-2" isFaded />
          </Link>
          <Link to={''}>
            <Button text={'Save'} className="flex items-center gap-2" />
          </Link>
        </div>
      </div>
      <div className=" bg-white-100 rounded-md p-4 md:p-9 sm:px-10  space-y-6 ">
        <div className="flex flex-wrap gap-8 justify-center py-4 md:py-24">
          <div className="flex flex-col bg-white-100 drop-shadow shadow-sm w-fit items-center text-center gap-2 py-6 px-4">
            <AssesmentIcon />
            <h4>Zoom</h4>
            <p className=" font-semibold text-black-100 text-base">
              Interview Shortlisted Applicants
            </p>
            <p className=" text-gray-600 text-sm w-full md:w-[70%]">
              Schedule meetings Register applicants for meetings and webinars
            </p>
          </div>
          <div className="flex flex-col bg-white-100 drop-shadow shadow-sm w-fit items-center text-center gap-2 py-6 px-4">
            <AssesmentIcon />
            <h4>Google Meet</h4>
            <p className=" font-semibold text-black-100 text-base">
              Interview Shortlisted Applicants
            </p>
            <p className=" text-gray-600 text-sm w-full md:w-[70%]">
              Schedule meetings Register applicants for meetings and webinars
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewInterview;
