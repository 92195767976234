import React from 'react';
import Button from '../button';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <div className="w-full mt-6">
      <div className="flex justify-end">
        <div className="flex gap-2">
          {pageNumbers.map((pageNumber) => (
            <span
              key={pageNumber}
              className={`${
                pageNumber === currentPage
                  ? 'bg-blue-100 text-white-100'
                  : 'text-blue-100 hover:text-white-100 hover:bg-blue-100'
              } p-3 px-5 rounded-[5px] cursor-pointer transition-all duration-200`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </span>
          ))}
          {/* Assuming you have a function handleNextPage for handling next page */}
          <Button isPink text="NEXT" onClick={() => onPageChange(currentPage + 1)} />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
