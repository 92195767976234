import { Toast } from '../../../components/toast/toast';
import { apiService } from '../../../helpers/api';
import { MANAGE_LEARNING } from '../../../router/router';

const base = 'Event/';

export const fetchUpcomingLearningEvents = async (id) => {
  try {
    const res = await apiService.get(
      `${base}Upcoming`,
      {
        employeeId: id
      },
      true
    );
    const response = res?.data?.data;

    if (response){
      return response;
    }
  } catch (err) {
    console.log('Error in fetching upcoming learning events: ', err)
  }
};

export const fetchPreviousLearningEvents = async (id) => {
  try {
    const res = await apiService.get(
      'Leave/Previous',
      {
        employeeId: id
      },
      true
    );
    const response = res?.data?.data;
    return response;
  } catch (err) {
    console.log('Error in fetching previous learning events: ', err)
  }
};

export const createLearningEvent = async (data, navigate) => {
  try {
    const res = await apiService.post(`${base}Create`, data, true);
    if (res)
    Toast({
      type: 'success',
      message: 'Training Event Created Successfully'
     });
    navigate(MANAGE_LEARNING);
  } catch (err) {
    console.log('Error in creating learning event: ', err)
  } 
};

export const createLearningRequest = async (data, navigate) => {
  try {
    const res = await apiService.post(`${base}Request`, data, true);
    if (res)
    Toast({
      type: 'success',
      message: 'Training Request Created Successfully'
     });
    navigate(MANAGE_LEARNING);
  } catch (err) {
    console.log('Error in creating learning request: ', err)
  } 
};

export const registerLearningEvent = async (data, navigate) => {
  try {
    const res = await apiService.post(`${base}Register`, data, true);
    if (res)
    Toast({
      type: 'success',
      message: 'Training Event Registered Successfully'
     });
    navigate(MANAGE_LEARNING);
  } catch (err) {
    console.log('Error in registering for learning event: ', err)
  } 
};

export const learningEventFeedback = async (data, navigate) => {
  try {
    const res = await apiService.post(`${base}Feedback`, data, true);
    if (res)
    Toast({
      type: 'success',
      message: 'Feedback Submitted Successfully'
     });
    navigate(MANAGE_LEARNING);
  } catch (err) {
    console.log('Error in submitting feedback: ', err)
  } 
};
