import React, { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import Input from '../../../components/input';
import Accordion from '../../../components/landing-page/Accordion';
import LandingFooter from '../../../components/landing-page/LandingFooter';
import LandingPageHeader from '../../../components/landing-page/LandingPageHeader';
import FaqNavbar from './FaqNavbar';

const FAQs = () => {
  const [open, setOpen] = useState(0);

  const questions = [
    {
      questions: 'How does it work?',
      answers: 'In blandit ultrices enim.'
    },
    {
      questions: 'When does it start',
      answers:
        'Nulla mollis molestie lorem. Quisque ut erat. Curabitur gravida nisi at nibh. In hac habitasse platea dictumst. Aliquam augue quam, sollicitudin vitae, consectetuer eget, rutrum at, lorem.'
    },
    {
      questions: 'Are subscriptions Free?',
      answers: 'Duis at velit eu est congue elementum.'
    },
    {
      questions: 'How can I trust you?',
      answers:
        'Sed vel enim sit amet nunc viverra dapibus. Nulla suscipit ligula in lacus. Curabitur at ipsum ac tellus semper interdum. Mauris ullamcorper purus sit amet nulla. Quisque arcu libero, rutrum ac, lobortis vel, dapibus at, diam. Nam tristique tortor eu pede.'
    }
  ];

  function handleAccordion(index) {
    if (open === index) {
      setOpen(null);
    } else {
      setOpen(index);
    }
  }
  return (
    <div className=" bg-purple-5  ">
      <LandingPageHeader />
      <div className="text-purple-100 px-4 md:px-16 pb-20 pt-10 space-y-24">
        <div className="flex items-center justify-between flex-wrap">
          <div className="font-bold text-lg md:text-3xl w-full md:w-[35%] capitalize">
            <span className="font-bold text-lg md:text-3xl  capitalize">
              Here are some Frequently asked
            </span>
            <span className="text-pink-100"> questions</span>
          </div>

          <Input
            icon={<BiSearch size={20} color="#FF365E" />}
            placeholder={'Type your question here.....'}
            containerClass=" flex items-center  -mt-6 w-full sm:w-[60%] md:w-[40%]"
            className=" h-12 border-purple-100"
            isPink
            withIcon
          />
        </div>
        <div className="flex justify-between ">
          <FaqNavbar />
          <div className="space-y-4  w-full md:w-[60%]">
            {questions.map((question, index) => {
              return (
                <Accordion
                  question={question.questions}
                  answer={question.answers}
                  open={open}
                  index={index}
                  onClick={() => handleAccordion(index)}
                  key={index}
                />
              );
            })}
          </div>
        </div>
      </div>

      <LandingFooter />
    </div>
  );
};

export default FAQs;
