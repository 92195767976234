import React from 'react';

const FaqNavbar = () => {
  return (
    <div>
      <p className="p-2 px-5 bg-red-300 cursor-pointer text-pink-100 hover:bg-pink-30 rounded-[40px] font-bold">
        General
      </p>
      <p className="p-2 px-5  cursor-pointer text-blue-100 hover:bg-pink-30 rounded-[40px] ">
        Audit
      </p>
      <p className="p-2 px-5  cursor-pointer text-blue-100 hover:bg-pink-30 rounded-[40px] ">
        Payment
      </p>
      <p className="p-2 px-5  cursor-pointer text-blue-100 hover:bg-pink-30 rounded-[40px] ">
        Recruiting
      </p>
      <p className="p-2 px-5  cursor-pointer text-blue-100 hover:bg-pink-30 rounded-[40px] ">
        Onboarding
      </p>
      <p className="p-2 px-5  cursor-pointer text-blue-100 hover:bg-pink-30 rounded-[40px] ">
        Return Policy
      </p>
    </div>
  );
};

export default FaqNavbar;
