import React from 'react';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';

const OvertimeCard = ({
  key,
  className,
  name,
  durationType,
  rateType,
  value,
  editIcon,
  deleteIcon
}) => {
  return (
    <Link
      key={key}
      to={''}
      className={`bg-white-100 p-4 flex items-start gap-3 justify-between font-semibold rounded-[5px] ${className}`}>
      <td>{name}</td>
      <td>{durationType}</td>
      <td>{rateType}</td>
      <td>{value}</td>
      <td className='flex flex-row gap-4'>{editIcon}{deleteIcon}</td>
    </Link>
  );
};

export default OvertimeCard;
