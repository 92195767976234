import moment from 'moment/moment';
import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import { getApprovalStatus, getLeaveType } from '../../../../helpers/functions';
import { User } from '../../../../utils/user';
import { API } from '../../../../utils/apis';

const LeftAbsenceEmployeeInfo = ({ leaveDetail }) => {
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [employeeId, setEmployeeId] = useState(leaveDetail?.employeeId);

  const fetchEmployee = async () => {
    setLoading(true);
    const res = await API.fetchSingleEmployee(employeeId);
    setEmployee(res);
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployee();
  }, []);


  return (
    <div className=" w-[80%] md:w-[70%]  space-y-10">
      <div className="flex flex-wrap items-center gap-8  w-full  ">
        <img src={employee?.user?.profileImageURL} 
         height='92' width='92' 
         alt="user" className="border border-yellow-100 rounded-[50%] p-2" 
        />
        <div className="flex flex-col gap-2 items-start">
          <p className="font-bold capitalize text-lg sm:text-xl md:text-2xl text-purple-300">
            {leaveDetail?.name}
          </p>
          <div className="flex flex-wrap gap-5 items-center text-white-100">
            <p className="font-semibold text-purple-300 text-sm sm:text-base md:text-xl">
              {User.role}
            </p>
            <span className="font-medium text-sm sm:text-base px-4  py-1 border bg-yellow-100 rounded-[21px] border-yellow-100 ">
              {leaveDetail?.department}
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-col  justify-between gap-4 space-y-10">
        <div className='flex flex-wrap justify-between space-x-9'>
          <div>
            <h6>EMPLOYEE ID</h6>
            <p className="text-black-100 font-semibold">ID-{leaveDetail?.employeeId}</p>
          </div>
          <div>
            <h6>START DATE</h6>
            <p className="text-black-100 font-semibold">
              {moment(leaveDetail?.startDate).format('MMM Do')}
            </p>
          </div>
          <div>
            <h6>END DATE</h6>
            <p className="text-black-100 font-semibold">
              {moment(leaveDetail?.endDate).format('MMM Do')}
            </p>
          </div>
          <div>
            <h6>LEAVE TYPE</h6>
            <p className="text-black-100 font-semibold capitalize">
              {getLeaveType(leaveDetail?.leaveType)}
            </p>
          </div>
          <div>
            <h6>LEAVE DURATION</h6>
            <p className="text-black-100 font-semibold">{leaveDetail.duration} days</p>
          </div>
        </div>
        <div className='flex flex-wrap justify-between gap-4'>
         <div>
            <h6>ENTITLEMENT</h6>
            <p className="text-black-100 font-semibold">{leaveDetail.entitled} days</p>
          </div>
          <div>
            <h6>LEAVE TAKEN</h6>
            <p className="text-black-100 font-semibold">{leaveDetail.taken} days</p>
          </div>
          <div>
            <h6>LEAVE REMAINING</h6>
            <p className="text-black-100 font-semibold">{leaveDetail.remaining} days</p>
          </div>
        </div>
        <div>
          <h6>STATUS</h6>
          <p
            className={`${leaveDetail?.approvalStatus === 0
              ? 'text-yellow-100'
              : leaveDetail?.approvalStatus === 1
                ? 'text-green-800'
                : 'text-red-700'
              } font-semibold uppercase text-sm tracking-[2.5px]`}>
            {getApprovalStatus(leaveDetail?.approvalStatus)}
          </p>
        </div>
      </div>

      <div>
        <h6>REASON FOR REQUEST</h6>
        <p className="text-black-100 font-semibold">{leaveDetail?.description}</p>
      </div>
    </div>
  );
};

export default LeftAbsenceEmployeeInfo;
