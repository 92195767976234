import React from 'react';
export const ZoomIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="25" height="25" rx="10" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"></pattern>
      </defs>
    </svg>
  );
};

export const AssesmentIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6675 3.17091C13.6675 3.79806 13.487 4.41112 13.1489 4.93258C12.8108 5.45403 12.3303 5.86046 11.7681 6.10046C11.2058 6.34046 10.5872 6.40325 9.99027 6.2809C9.39344 6.15855 8.84519 5.85655 8.41488 5.41309C7.98456 4.96963 7.69152 4.40462 7.57279 3.78953C7.45407 3.17443 7.51501 2.53687 7.74789 1.95746C7.98077 1.37805 8.37515 0.882819 8.88114 0.534395C9.3871 0.185971 9.98202 0 10.5906 0C11.4066 0 12.1892 0.334077 12.7663 0.928742C13.3433 1.5234 13.6675 2.32994 13.6675 3.17091Z"
        fill="#FF9800"
      />
      <path
        d="M13.6675 18.714C13.6675 19.341 13.487 19.9541 13.1489 20.4756C12.8108 20.997 12.3303 21.4035 11.7681 21.6434C11.2058 21.8835 10.5872 21.9463 9.99027 21.8239C9.39344 21.7016 8.84519 21.3995 8.41488 20.9561C7.98456 20.5126 7.69152 19.9477 7.57279 19.3325C7.45407 18.7174 7.51501 18.0799 7.74789 17.5005C7.98077 16.9211 8.37515 16.4259 8.88114 16.0774C9.3871 15.729 9.98202 15.543 10.5906 15.543C11.4066 15.543 12.1892 15.8771 12.7663 16.4717C13.3433 17.0664 13.6675 17.873 13.6675 18.714Z"
        fill="#FF9800"
      />
      <path
        d="M18.1599 14.0859C17.5514 14.0859 16.9565 13.9 16.4505 13.5516C15.9445 13.2031 15.5501 12.7079 15.3172 12.1285C15.0844 11.5491 15.0234 10.9115 15.1421 10.2965C15.2609 9.68131 15.5539 9.11634 15.9843 8.67288C16.4146 8.22942 16.9628 7.92742 17.5596 7.80507C18.1566 7.68272 18.7752 7.74551 19.3374 7.98551C19.8996 8.22551 20.3802 8.63194 20.7183 9.15339C21.0564 9.6748 21.2369 10.2879 21.2369 10.915C21.2369 11.756 20.9126 12.5626 20.3357 13.1573C19.7586 13.7519 18.976 14.0859 18.1599 14.0859Z"
        fill="#FF9800"
      />
      <path
        d="M3.07691 14.0859C2.46835 14.0859 1.87346 13.9 1.36747 13.5516C0.861475 13.2031 0.467101 12.7079 0.234216 12.1285C0.00133317 11.5491 -0.0595998 10.9115 0.0591233 10.2965C0.177846 9.68131 0.470893 9.11634 0.901205 8.67288C1.33152 8.22942 1.87977 7.92742 2.47663 7.80507C3.07349 7.68272 3.69216 7.74551 4.25438 7.98551C4.81662 8.22551 5.29717 8.63194 5.63526 9.15339C5.97335 9.6748 6.15381 10.2879 6.15381 10.915C6.15381 11.756 5.82964 12.5626 5.2526 13.1573C4.67558 13.7519 3.89295 14.0859 3.07691 14.0859Z"
        fill="#FF9800"
      />
      <path
        d="M6.11073 18.7144C6.11073 19.4275 5.83583 20.1115 5.34651 20.6157C4.85718 21.12 4.19352 21.4033 3.50152 21.4033H0V16.0254H3.50152C4.19352 16.0254 4.85718 16.3087 5.34651 16.813C5.83583 17.3172 6.11073 18.0012 6.11073 18.7144Z"
        fill="#FF365F"
      />
      <path
        d="M15.1075 3.17086C15.1043 2.81591 15.1694 2.46383 15.2991 2.13502C15.4289 1.8062 15.6207 1.50718 15.8633 1.2553C16.106 1.00342 16.3948 0.803686 16.7129 0.667665C17.0311 0.531644 17.3723 0.462044 17.7167 0.462898H21.2182V5.84077H17.7167C17.0279 5.84079 16.367 5.5601 15.8782 5.05994C15.3894 4.55977 15.1123 3.88071 15.1075 3.17086Z"
        fill="#FF365F"
      />
      <path
        d="M3.0769 6.29743C2.73248 6.30079 2.39083 6.23367 2.07176 6.09997C1.7527 5.96626 1.46255 5.76863 1.21813 5.51852C0.973724 5.26841 0.77991 4.97079 0.647921 4.64293C0.515933 4.31507 0.448396 3.96346 0.449226 3.6085V0H5.66766V3.6085C5.66767 4.31837 5.3953 4.99944 4.90998 5.50317C4.42464 6.0069 3.7657 6.29241 3.0769 6.29743Z"
        fill="#FF365F"
      />
      <path
        d="M18.1599 15.5684C18.852 15.5684 19.5157 15.8517 20.0049 16.3559C20.4943 16.8602 20.7692 17.5441 20.7692 18.2573V21.8658H15.5508V18.2573C15.5508 17.5441 15.8257 16.8602 16.3149 16.3559C16.8043 15.8517 17.468 15.5684 18.1599 15.5684Z"
        fill="#FF365F"
      />
    </svg>
  );
};
