import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StatusCard from '../../../components/cards/StatusCard';
import Pagination from '../../../components/general/pagination';
import { getLeaveType } from '../../../helpers/functions';

const Approved = ({ approvedLeaveRequests }) => {
  const navigate = useNavigate();
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);

  const validApprovedLeaveRequests = Array.isArray(approvedLeaveRequests) ? approvedLeaveRequests : [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = validApprovedLeaveRequests.slice(indexOfFirstItem, indexOfLastItem);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {currentItems.length === 0 ? (
        <tr className='cursor-pointer mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'>
          <td className=''>No Leave Requests To Show</td>
        </tr>
      ) : (
        currentItems.map((leaveRequest) => (
          <tr
            className='border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
            key={leaveRequest?.id}>
            <td
              className='p-3 px-6 underline text-blue-100 cursor-pointer'
              onClick={() => navigate(`/manage-absence/${leaveRequest?.leaveId}`)}>
              VA-{leaveRequest?.employeeId}
            </td>
            <td className='p-3 px-6 '>{leaveRequest?.name}</td>
            <td className='p-3 px-6'>{leaveRequest?.department}</td>
            <td className='p-3 px-6'>{getLeaveType(leaveRequest?.leaveType)}</td>
            <td className='p-3 px-6 lowercase'>{leaveRequest?.duration} days</td>
            <td className='p-3 px-6 '>
              <StatusCard isApproved />
            </td>
          </tr>
        ))
      )}
      <div className='w-full flex gap-4 justify-end'>
        <Pagination
          totalPages={Math.ceil(validApprovedLeaveRequests.length / itemsPerPage)}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
};

export default Approved;
