import React, { useState } from 'react';
import BaseModal from '../../../../../components/base/modal';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import Select from '../../../../../components/input/Select';
import { RATE_TYPE, DURATION_TYPE } from '../../../../../helpers/constant';
import { API } from '../../../../../utils/apis';

const AddOvertimeModal = ({ show, closeModal }) => {
  const [modalData, setModalData] = useState({
    name: '',
    durationType: 1,
    rateType: 1,
    value: 0
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setModalData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  const submitData = async (e) => {
    setLoading(true);
    console.log(modalData);
    await API.createOvertime(modalData);
    setLoading(false);
    e.preventDefault();
  };

  return (
    <BaseModal title={'Add Overtime'} show={show} onClose={closeModal}>
      <div className='space-y-10 rounded-lg w-full sm:w-[400px] overflow-hidden'>
        <Input
          withLabel
          value={modalData.name}
          label='Name'
          containerClass='w-full sm:w-auto'
          name='name'
          onChange={(e) => handleChange('name', e.target.value)}
        />
        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>DURATION TYPE</h6>
          <Select
            selectedOption={modalData.durationType}
            className='w-full sm:w-auto'
            options={DURATION_TYPE}
            onChange={(e) => handleChange('durationType', e?.value)}
          />
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>RATE TYPE</h6>
          <Select
            selectedOption={modalData.rateType}
            className='w-full sm:w-auto'
            options={RATE_TYPE}
            onChange={(e) => handleChange('rateType', e?.value)}
          />
        </div>
        <Input
          withLabel
          value={modalData.value}
          label='Value'
          containerClass='w-full sm:w-auto'
          name='value'
          onChange={(e) => handleChange('value', e.target.value)}
        />
        <div className='flex gap-4 flex-wrap justify-end'>
          <Button
            className='border border-pink-100'
            text='Cancel'
            onClick={closeModal}
            isTransparent
          />
          <Button
            text='Submit'
            type='submit'
            onClick={submitData}
            isPurple
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default AddOvertimeModal;
