import React from 'react';
import { ReactComponent as PinkPerformance } from '../../assets/img/pink-performance.svg';
import { ReactComponent as WhitePerformance } from '../../assets/img/white-performance.svg';
import { ReactComponent as PurplePerformance } from '../../assets/img/purple-performance.svg';

const ReportCard = ({ text, className, isPerformance, isBlue, isYellow, value, icon }) => {
  return (
    <div
      className={`mini-card-bg space-y-2 p-6 text-white-100 font-semibold rounded-sm text-sm h-[114px] grid place-content-center  ${className}`}>
      <span className="">{text}</span>
      <div className="flex gap-6 items-center">
          <div
            className={`p-1 w-11 h-11 flex justify-center items-center rounded-circle bg-purple-100 ${(isBlue || isYellow) && 'bg-white-100'
              }  `}>
            {isPerformance && isBlue ? (
              <PinkPerformance />
            ) : isPerformance && isYellow ? (
              <PurplePerformance />
            ) : (
              isPerformance && <WhitePerformance />
            )}
            {
              icon
            }
          </div>
          <span className="text-lg sm:text-xl md:text-4xl">{value}</span>
        </div>
    </div>
  );
};

export default ReportCard;
