import React, { useState } from 'react';
import Input from '../../../../components/input';
import { ReactComponent as Email } from '../../../../assets/img/mail.svg';
import { ReactComponent as User } from '../../../../assets/img/user.svg';
import { BiHome, BiPhone } from 'react-icons/bi';
import { JOB_TYPE, GENDER } from '../../../../helpers/constant';
import Select from '../../../../components/input/Select';

const AddEmployeeForm = ({ employees, employeeGroupOption, employeeGradeOption, departmentsOption, setFormData, submitData, handleChange, formData, error }) => {
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [alternatePhoneError, setAlternatePhoneError] = useState('');
  const [employeeExistsError, setEmployeeExistsError] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateNumber = (number) => {
    const numberRegex = /^\d+$/;
    return numberRegex.test(number);
  };

  const checkEmployeeExists = () => {
    const fullName = `${formData.firstName} ${formData.lastName}`;
    const employeeExists = employees.some(employee => employee.fullName === fullName);
    if (employeeExists) {
      console.log('Fuck off');
      setEmployeeExistsError('Employee already exists');
      return true;
    }
    return false;
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setFormData({ ...formData, email: emailValue });

    if (!validateEmail(emailValue)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };

  const handlePhoneChange = (e) => {
    const phoneValue = e.target.value;
    setFormData({ ...formData, phoneNumber: phoneValue });

    if (!validateNumber(phoneValue)) {
      setPhoneError('Invalid phone number');
    } else {
      setPhoneError('');
    }
  };

  const handleAlternatePhoneChange = (e) => {
    const alternatePhoneValue = e.target.value;
    setFormData({ ...formData, alternatePhoneNumber: alternatePhoneValue });

    if (!validateNumber(alternatePhoneValue)) {
      setAlternatePhoneError('Invalid alternate phone number');
    } else {
      setAlternatePhoneError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkEmployeeExists()) return; 
    await submitData(e);
  };

  return (
    <form 
      onSubmit={submitData} 
      className='flex flex-col space-y-7'>
      <div className='flex flex-wrap gap-2'>
        {/*<p className=' text-pink-100 font-bold '>
          *
       </p>*/}
        <p className=' text-pink-100 font-bold '>
          All fields are required
        </p>
      </div>
      <div className='flex flex-wrap gap-6 justify-between space-y-5 md:space-y-0 text-purple-100'>
        <Input
          onChange={handleChange}
          placeholder={'Enter your first name'}
          name='firstName'
          value={formData.firstName}
          label='first name'
          withLabel
          containerClass={'w-full md:w-[45%] id'}
          withIcon
          icon={<User />}
        />
        <Input
          onChange={handleChange}
          placeholder={'Enter your last name'}
          name='lastName'
          value={formData.lastName}
          label='last name'
          withLabel
          containerClass={'w-full md:w-[45%] id'}
          withIcon
          icon={<User />}
        />
        <Input
          onChange={handleChange}
          placeholder={'Enter your middle name'}
          name='middleName'
          value={formData.middleName}
          label='middle name'
          withLabel
          containerClass={'w-full md:w-[45%] id'}
          withIcon
          icon={<User />}
        />
        <Select
          options={GENDER}
          placeholder={'Select your gender'}
          onChange={(e) =>
            setFormData({
              ...formData,
              gender: e?.value
            })
          }
          label='Gender'
          withLabel
          className='w-full md:w-[45%]'
        />
        <div  className='flex flex-col w-[45%]'>
          <Input
            onChange={handleEmailChange}
            placeholder={'Enter your email address'}
            name='email'
            value={formData.email}
            label='Email'
            withLabel
            containerClass={'w-full email'}
            withIcon
            icon={<Email />}
            error={emailError}
            type='email'
          />
          {emailError && <p className="text-red-500">{emailError}</p>}
        </div>
        <Input
          onChange={handleChange}
          placeholder={'Enter your employee number'}
          name='employeeNumber'
          value={formData.employeeNumber}
          label='EMPLOYEE NUMBER'
          withLabel
          containerClass={'w-full md:w-[45%] id'}
          withIcon
          icon={<User />}
        />
        <Input
          onChange={handlePhoneChange}
          placeholder={'Enter your phone number'}
          name='phoneNumber'
          value={formData.phoneNumber}
          label='Phone Number'
          withLabel
          containerClass={'w-full md:w-[45%] id'}
          withIcon
          icon={<BiPhone size={18} />}
          error={phoneError}
          type='number'
        />
        <Input
          onChange={handleAlternatePhoneChange}
          placeholder={'Enter your alternate phone number'}
          name='alternatePhoneNumber'
          value={formData.alternatePhoneNumber}
          label='Alternate Phone Number'
          withLabel
          containerClass={'w-full md:w-[45%] id'}
          withIcon
          icon={<BiPhone size={18} />}
          error={alternatePhoneError}
          type='number'
        />
        <Input
          onChange={handleChange}
          placeholder={'Enter your home address'}
          name='homeAddress'
          value={formData.homeAddress}
          label='Home Address'
          withLabel
          containerClass={'w-full md:w-[45%] id'}
          withIcon
          icon={<BiHome size={18} />}
        />
        <Input
          onChange={handleChange}
          placeholder={'Enter your alternate address'}
          name='otherAddress'
          value={formData.otherAddress}
          label='Other Address'
          withLabel
          containerClass={'w-full md:w-[45%] id'}
          withIcon
          icon={<BiHome size={18} />}
        />
        <Input
          onChange={handleChange}
          name='dob'
          value={formData.dob}
          label='date of birth'
          withLabel
          containerClass={'w-full md:w-[45%] start-date'}
          type='date'
        />
        <Input
          onChange={handleChange}
          name='startDate'
          value={formData.startDate}
          label='START DATE'
          withLabel
          containerClass={'w-full md:w-[45%] start-date'}
          type='date'
        />
        <Input
          onChange={handleChange}
          placeholder={'e.g. Officer, Manager, General Manager, etc.'}
          name='designation'
          value={formData.designation}
          label='Designation'
          withLabel
          containerClass={'w-full md:w-[45%] position'}
        />
        <Input
          onChange={handleChange}
          placeholder={'e.g. Sales Representative, Customer Care Officer, etc.'}
          name='employmentRole'
          value={formData.employmentRole}
          label='Role Title'
          withLabel
          containerClass={'w-full md:w-[45%] position'}
        />
        <div className=' flex flex-col gap-2 w-full md:w-[45%]'>
          <h6 className=' text-xs'>department</h6>
          <Select
            placeholder={'Select your department in the company'}
            className=' w-full'
            options={departmentsOption}
            onChange={(e) =>
              setFormData({
                ...formData,
                departmentId: e?.value
              })
            }
          />
        </div>
        <Select
          options={JOB_TYPE}
          placeholder={'Select your employment type'}
          onChange={(e) =>
            setFormData({
              ...formData,
              employeeType: e?.value
            })
          }
          label='Employment Type'
          withLabel
          className='w-full md:w-[45%] h-[100px] '
        />
        
        <div className=' flex flex-col gap-2 w-full md:w-[45%]'>
          <h6 className=' text-xs'>Employee Group</h6>
          <Select
            placeholder={'Select your group in the company'}
            className=' w-full'
            options={employeeGroupOption}
            onChange={(e) =>
              setFormData({
                ...formData,
                gradeGroup: e?.label
              })
            }
          />
        </div>

        <div className=' flex flex-col gap-2 w-full md:w-[45%]'>
          <h6 className=' text-xs'>Employee Grade</h6>
          <Select
            placeholder={'Select your group in the company'}
            className=' w-full'
            options={employeeGradeOption}
            onChange={(e) =>
              setFormData({
                ...formData,
                grade: e?.label
              })
            }
          />
        </div>
      </div>
      {error && <p className="text-red-500">{error}</p>}
    </form>
  );
};

export default AddEmployeeForm;
