import React, {useState, useEffect} from 'react';
import SearchWithFilter from '../../../components/general/SearchWithFilter';
import CalendarTab from '../../overview/features/overview-content/CalendarTab';
import EmployeesTab from '../../overview/features/overview-content/EmployeesTab';
import RequestsTab from '../../overview/features/overview-content/RequestsTab';
import PerformancesTab from './PerformanceTabs';
import Requests from './Requests';
import { API } from '../../../utils/apis';

const RequestContent = () => {
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [performances, setPerformances] = useState([]);
  const [pendingLeaveRequests, setPendingLeaveRequests] = useState([]);

  const getRequestsData = async () => {
    setLoading(true);

    const employees = await API.fetchAllEmployeesById();

    const performances = await API.fetchAllPerformances();

    const request = await API.fetchPendingLeaveRequests();

    if (employees) setEmployees(employees);
    if (performances) setPerformances(performances);
    if (request) setPendingLeaveRequests(request);
    setLoading(false);
  };

  useEffect(() => {
    getRequestsData();
  }, []);

  return (
    <div className='bg-white-100 rounded-sm py-5 px-4 sm:px-12'>
      <SearchWithFilter placeholder='Search requests' isPink />
      <Requests />
      
    </div>
  );
};

export default RequestContent;
