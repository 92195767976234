import React, { useState } from 'react';
import { MdDelete } from 'react-icons/md';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import { TextArea } from '../../../../../components/input';
import Select from '../../../../../components/input/Select';
import { METRIC_TYPE } from '../../../../../helpers/constant';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { emptyBehavioralCompetency, emptyBehaviouralForm } from '../../../../../helpers/constant';

const BehaviouralCompetency = ({ competencyOption, formData, setFormData }) => {
  
  const addTargets = (index) => {
    const updatedFormData = { ...formData };
    updatedFormData.behavioural[index].targets.push({ ...emptyBehavioralCompetency });
    setFormData(updatedFormData);
  };

  const deleteKeyResults = (index, i) => {
    const updatedFormData = { ...formData };
    updatedFormData.behavioural[index].targets.splice(i, 1);
    setFormData(updatedFormData);
  };

  const addCompetency = () => {
    const updatedFormData = { ...formData };
    updatedFormData.behavioural.push(emptyBehaviouralForm);
    setFormData(updatedFormData);
  };

  const deleteObj = (ind) => {
    const updatedFormData = { ...formData };
    updatedFormData.behavioural.splice(ind, 1);
    setFormData(updatedFormData);
  };
  
  const handleChangeFormData = (i, e, name, isObj) => {
    const updatedFormData = { ...formData };
    if (isObj) {
      updatedFormData.behavioural[i] = { ...updatedFormData.behavioural[i], [name]: e };
    } else {
      const { name, value } = e.target;
      updatedFormData.behavioural[i] = { ...updatedFormData.behavioural[i], [name]: value };
    }
    setFormData(updatedFormData);
  };

  const handleChangeKeyResults = (i, ind, e) => {
    const updatedFormData = { ...formData };
  
    if (e.target) {
      const { name, value } = e.target;

      // Validate weight does not exceed 100%
      if (name === 'weight' && value > 100) {
        alert('Weight cannot exceed 100%');
        return;
      }

      updatedFormData.behavioural[i].targets[ind] = { ...updatedFormData.behavioural[i].targets[ind], [name]: value };
    } else {
      updatedFormData.behavioural[i].targets[ind][e.name] = e.value;
    }
  
    setFormData(updatedFormData);
  };  

  return (
    <div className='xs:w-[80%] sm:w-[70%] mdLg:w-full mdLg:mx-0 mx-auto'>
      <form className='space-y-10 mt-16'>
        <Button
          text={'BEHAVIOURAL COMPETENCY'}
          className=''
          isPurple
        />
        {formData.behavioural.map((item, i) => (
          <div key={i} className='space-y-8 bg-pink-30 shadow-xl rounded-3xl p-4 md:p-10'>
            <div className='flex flex-wrap justify-between gap-5'>
              <div className='flex gap-5 items-start w-full'>
                {formData.behavioural.length > 1 && <h6>{i + 1}.</h6>}
                <div className='flex flex-col mdLg:w-[100%] space-y-3'>
                  <h6 className='text-xs'>Behavioural Competency</h6>
                  <TextArea
                    className={`mdLg:w-[100%]`}
                    placeholder={''}
                    options={competencyOption}
                    onChange={({ label }) => handleChangeFormData(i, label, 'competencyId', true)}
                    value={item.competencyId}
                    name='competencyId'
                  />
                </div>
                {i > 0 && formData.behavioural.length > 1 && (
                  <RiDeleteBin6Line onClick={() => deleteObj(i)} className='cursor-pointer' />
                )}
              </div>
            </div>
            {item.targets.map((result, ind) => (
              <div key={ind} className='space-y-6'>
                <div className='space-y-6 mdLg:space-y-0 mdLg:flex mdLg:flex-wrap items-start gap-3 gap-y-6'>
                  <div className='w-full mdLg:w-[100%]'>
                    <Input
                      withLabel
                      label={'SET CAPABILITY/ACTIVITY TARGET'}
                      containerClass=''
                      value={result.target}
                      name='target'
                      onChange={(e) => handleChangeKeyResults(i, ind, e)}
                    />
                  </div>
                  <div className='flex flex-wrap items-end gap-2'>
                    <span className='flex-1 w-full md:w-1/4 mb-4 md:mb-0 md:mr-4'>
                      <div className='flex flex-col'>
                        <label className='text-blue-100 font-semibold text-xs tracking-tight uppercase mr-1 mb-1'>METRIC TYPE</label>
                        <Select
                          containerClass='mt-1'
                          selectedOption={result.metrics}
                          options={METRIC_TYPE}
                          name='metrics'
                          onChange={(selectedOption) => handleChangeKeyResults(i, ind, { name: 'metrics', value: selectedOption.value })}
                        />
                      </div>
                    </span>
                    <span className='flex-1 w-full md:w-1/4 mb-4 md:mb-0 md:mr-4'>
                      <div className='flex flex-col'>
                        <label className='text-blue-100 font-semibold text-xs tracking-tight uppercase mr-1 mb-1'>TARGET LEVEL</label>
                        <Input
                          containerClass='mt-1'
                          value={result.value}
                          name='value'
                          onChange={(e) => handleChangeKeyResults(i, ind, e)}
                        />
                      </div>
                    </span>
                    <span className='flex-1 w-full md:w-1/4 mb-4 md:mb-0 md:mr-4'>
                      <div className='flex flex-col'>
                        <label className='text-blue-100 font-semibold text-xs tracking-tight uppercase mr-1 mb-1'>SET WEIGHT (%)</label>
                        <Input
                          containerClass='mt-1'
                          value={result.weight}
                          name='weight'
                          onChange={(e) => handleChangeKeyResults(i, ind, e)}
                        />
                      </div>
                    </span>
                    <span className='flex-1 w-full md:w-1/3 mb-4 md:mb-0 md:mr-4'>
                      <div className='flex flex-col'>
                        <label className='text-blue-100 font-semibold text-xs tracking-tight uppercase mr-1 mb-1'>DUE DATE</label>
                        <Input
                          containerClass='mt-1'
                          value={result.dueDate}
                          name='dueDate'
                          onChange={(e) => handleChangeKeyResults(i, ind, e)}
                          type='date'
                        />
                        <h6
                          className={`underline text-xs justify-end space-x-2 cursor-pointer ${
                            ind === item.targets.length - 1 ? 'flex' : 'hidden'
                          }`}
                          onClick={() => addTargets(i)}>
                          +ADD MORE TARGETS
                        </h6>
                      </div>
                    </span>
                    {ind > 0 && (
                      <MdDelete
                        className={`ml-[4rem] self-center cursor-pointer`}
                        onClick={() => deleteKeyResults(i, ind)}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
        <div className='mx-auto flex justify-center mdLg:justify-start'>
          <Button
            text={'+ADD BEHAVIOURAL COMPETENCY'}
            className='bg-transparent border-purple-100 border hover:border-none'
            onClick={addCompetency}
          />
        </div>
      </form>
    </div>
  );
};

export default BehaviouralCompetency;
