import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { GiSpeaker } from 'react-icons/gi'

const AnnouncementCard = ({ isBlue, title, date, isDraft }) => {
  return (
    <div
      className={`border border-white-100 flex w-full rounded-sm justify-between bg-white-30 p-4 items-center ${isBlue ? 'text-blue-100' : isDraft ? 'text-orange-100' : 'text-white-100'
        } `}>

      <div className=' flex gap-3 items-center font-semibold'>
        <GiSpeaker className=' w-9 h-9 bg-white-100 rounded-full text-pink-100 p-2' />
        <div>
          <p className=' text-sm'>{title} {isDraft && ' (Drafts) '}</p>
          {date && <p className='text-xs'>{date}</p>}
        </div>
      </div>
      <AiOutlineArrowRight size={18} />
    </div>
  );
};

export default AnnouncementCard;
