import React, { useState } from 'react';
import BaseModal from '../../../../../components/base/modal';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import { getCompetencyOption } from '../../../../../helpers/functions';
import { API } from '../../../../../utils/apis';

const AddCompetencyModal = ({ show, onClose, setOptions }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  const addCompetency = async () => {
    setLoading(true);
    const res = await API.addCompetency(name);
    if (res) {
      const response = await API.getCompetency();
      setOptions(getCompetencyOption(response));
      onClose();
      setName('');
    }
    setLoading(false);
  };
  return (
    <BaseModal show={show} onClose={onClose} title='Add' coloredText='competency'>
      <div className='space-y-12'>
        <Input
          name={'name'}
          label='competency name'
          onChange={(e) => setName(e.target.value)}
          withLabel
          isPurple
        />
        <Button
          text='add competency'
          isPurple
          className='bg-opacity-80 ml-auto'
          loading={loading}
          onClick={addCompetency}
        />
      </div>
    </BaseModal>
  );
};

export default AddCompetencyModal;
