import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import AuditCard from '../../../components/cards/AuditCard';

const LeftAuditContent = () => {
  return (
    <div className="space-y-10">
      <div className="whitespace-nowrap no-scroll-bar overflow-x-scroll space-y-10 bg-pink-30 p-6 rounded-sm ">
        <div className="uppercase tracking-[3px] text-blue-100 font-semibold text-2xs flex justify-start  mb-4 w-fit ">
          <p className="w-[160px]">ADMIN</p>
          <p className="w-[250px]">DATE</p>
          <p className="w-[150px]"> TIME</p>
          <p className="">ACTION</p>
        </div>
        <div className=" w-ful flex flex-col gap-4 ">
          <AuditCard
            date={'June 12th'}
            time={'11:59am'}
            isApproved
            icon={<FaUserCircle size={30} color="#4E008330" />}
            dept="IT Department"
            email={'augustuslanre@gmail.com'}
            role="Web Developer"
            name={'Augustus Lanre'}
          />
          <AuditCard
            date={'June 12th'}
            time={'11:59am'}
            isApproved
            icon={<FaUserCircle size={30} color="#4E008330" />}
            dept="IT Department"
            email={'augustuslanre@gmail.com'}
            role="Web Developer"
            name={'Augustus Lanre'}
          />
          <AuditCard
            date={'June 12th'}
            time={'11:59am'}
            isApproved
            icon={<FaUserCircle size={30} color="#4E008330" />}
            dept="IT Department"
            email={'augustuslanre@gmail.com'}
            role="Web Developer"
            name={'Augustus Lanre'}
          />
          <AuditCard
            date={'June 12th'}
            time={'11:59am'}
            isRejected
            icon={<FaUserCircle size={30} color="#4E008330" />}
            dept="IT Department"
            email={'augustuslanre@gmail.com'}
            role="Web Developer"
            name={'Augustus Lanre'}
          />
          <AuditCard
            date={'June 12th'}
            time={'11:59am'}
            isRejected
            icon={<FaUserCircle size={30} color="#4E008330" />}
            dept="IT Department"
            email={'augustuslanre@gmail.com'}
            role="Web Developer"
            name={'Augustus Lanre'}
          />
        </div>
      </div>
    </div>
  );
};

export default LeftAuditContent;
