import React, { Fragment } from 'react';
import './App.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Routes, Route } from 'react-router-dom';
import {
  ADD_PEOPLE,
  ADD_VACANCY,
  AUDITS,
  EDIT_GOALS,
  EMPLOYEE_DETAILS,
  HELPDESK,
  DASHBOARD,
  MANAGE_ABSENCE,
  MANAGE_COMPENSATION,
  MANAGE_EMPLOYEES,
  MANAGE_EVENTS,
  MANAGE_HIRING,
  MANAGE_LEARNING,
  MANAGE_PERFORMANCE,
  TIMESHEET,
  OVERVIEW,
  REPORTS,
  REQUESTS,
  VACANCY,
  APPLICANTS,
  ADD_PAYROLL,
  SALARY_COMPONENTS,
  ABSENCE_DETAILS,
  CALENDAR_DETAILS,
  PAYROLL_DETAILS,
  PERFORMANCE_DETAILS,
  SOURCE_AND_ATTRACT,
  JOB_DETAILS,
  CREATE_JOB,
  JOB_CATEGORIES,
  JOB_CATEGORY_DETAILS,
  SHORTLIST,
  LEADERBOARD,
  JOB_APPLICANTS,
  SCREENING_SCORES,
  CANDIDATE_DETAILS,
  CANDIDATE_SCORE,
  ASSESSMENT_QUESTIONS,
  SCHEDULE,
  NEW_INTERVIEW,
  PEOPLE_RECRUIT_REPORT,
  APPLICATION_FORM,
  EVENTS,
  SCHEDULE_DETAILS,
  SIGN_UP,
  AUTH_LOGIN,
  PROFILE,
  PEOPLE_RECRUIT,
  EMAIL_VERIFICATION,
  USER_EMAIL_VERIFICATION,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  RESET_USER_PASSWORD,
  ASSIGN_LEAVE,
  DEPARTMENT,
  SETTINGS,
  EMPLOYEE_TRACKER,
  LEARNING_EVENT,
  LEARNING_REQUEST,
  PERFORMANCE_REPORT,
  HR_ADMIN_REPORT,
  COMPENSATION_REPORT,
  RECRUITMENT_REPORT,
  ONBOARDING_OFFBOARDING_REPORT,
  EMPLOYEE_REPORT,
  TRAINING_DETAILS,
  FEEDBACK_DETAILS,
  LEAVE_REPORT,
  TRAINING_REVIEW
} from './router/router';
import Home from './pages/home';
import Performance from './pages/performance';
import Employees from './pages/employees';
import Overview from './pages/overview';
import Hiring from './pages/hiring';
import Absence from './pages/absence';
import Compensation from './pages/compensation';
import Events from './pages/events';
import Learning from './pages/learning';
import Requests from './pages/requests';
import Audits from './pages/audits';
import Timesheet from './pages/timesheet';
import HelpDesk from './pages/helpdesk';
import Reports from './pages/reports';
import ScrollToTop from './utils/helperFunctions';
import NotFound from './pages/notfound/NotFound';
import EmployeesLayout from './pages/employees/features';
import AddEmployee from './pages/employees/features/add-employee';
import EmployeeDetails from './pages/employees/features/user';
import PerformanceLayout from './pages/performance/features';
import EditGoals from './pages/performance/features/edit-goals/index';
import HiringLayout from './pages/hiring/features';
import AddVacancy from './pages/hiring/features/add-vacancy';
import VacancyDetails from './pages/hiring/features/vacancy/VacancyDetails';
import Applicants from './pages/hiring/features/vacancy/applicants';
import VacancyDetailsLayout from './pages/hiring/features/vacancy';
import CompensationLayout from './pages/compensation/features';
import CompensationInfo from './pages/compensation/features/compensation-info';
import AddPayroll from './pages/compensation/features/add-payroll';
import SalaryComponents from './pages/compensation/features/add-payroll/left-payroll/SalaryComponents';
import AbsenceLayout from './pages/absence/features';
import AbsenceEmployeeInfo from './pages/absence/features/absence-employee-info/index';
import HelpDeskLayout from './pages/helpdesk/features';
import OverviewLayout from './pages/overview/features';
import AuditLayout from './pages/audits/features';
import { CONTACT, FAQs, HOME, LOGIN, PRICING } from './router/landingPageRoutes';
import Login from './pages/landing/login';
import Contact from './pages/landing/contact';
import Faqs from './pages/landing/faq';
import Pricing from './pages/landing/pricing';
import { PeopleRecruitHome } from './pages/people_recruit/home';
import SourceAndAttract from './pages/people_recruit/source-and-attract';
import SourceLayout from './pages/people_recruit/source-and-attract/features';
import JobDetails from './pages/people_recruit/source-and-attract/features/job-details/JobDetails';
import CreateJob from './pages/people_recruit/source-and-attract/features/create-job';
import JobCategories from './pages/people_recruit/source-and-attract/features/job-categories';
import JobCategoryDetails from './pages/people_recruit/source-and-attract/features/job-categories/JobCategoryDetails';
import ApplicationForm from './pages/people_recruit/source-and-attract/features/job-categories/ApplicationForm';
import Shortlist from './pages/people_recruit/shortlist-and-screen';
import ShortlistLayout from './pages/people_recruit/shortlist-and-screen/features';
import LeaderBoard from './pages/people_recruit/shortlist-and-screen/features/leaderboard';
import ScreeningScores from './pages/people_recruit/shortlist-and-screen/features/screening-scores';
import JobApplicantsLayout from './pages/people_recruit/shortlist-and-screen/features/applicants';
import JobApplicants from './pages/people_recruit/shortlist-and-screen/features/applicants/JobApplicants';
import CandidateDetails from './pages/people_recruit/shortlist-and-screen/features/candidate-details';
import CandidateScore from './pages/people_recruit/shortlist-and-screen/features/candidate-details/CandidateScore';
import AssesmentQuestions from './pages/people_recruit/shortlist-and-screen/features/applicants/AssesmentQuestions';
import ScheduleLayout from './pages/people_recruit/schedule-and-engage/features';
import Schedule from './pages/people_recruit/schedule-and-engage';
import NewInterview from './pages/people_recruit/schedule-and-engage/features/new-interview';
import RecruitReport from './pages/people_recruit/report';
import RecruitReportLayout from './pages/people_recruit/report/features';
import PeopleRecruitEvents from './pages/people_recruit/shortlist-and-screen/features/events';
import ScheduleLayoutDetails from './pages/people_recruit/schedule-and-engage/features/ScheduleLayoutDetails';
import AuthLogin from './pages/auth/login';
import AuthSignUp from './pages/auth/signup';
import { ToastContainer } from 'react-toastify';
import { DEFAULT_TOAST_OPTIONS } from './components/toast';
import UnAuthRoute from './router/UnAuthRoute';
import AuthRoute from './router/AuthRoute';
import EventsOverview from './pages/events/features';
import LearningOverview from './pages/learning/features';
import UserProfile from './pages/people_centa/profile';
import UserProfileLayout from './pages/people_centa/profile/features';
import LandingPage from './pages/landing';
import VerifyUserEmail from './pages/auth/login/VerifyUserEmail';
import VerifyEmail from './pages/auth/login/VerifyEmail';
import ResetPassword from './pages/auth/login/reset-password';
import ForgotPassword from './pages/auth/login/ForgotPassword';
import ForgotUserPassword from './pages/auth/forgot-password';
import AssignLeave from './pages/absence/features/assign-leave';
import Department from './pages/employees/features/department';
import Settings from './pages/settings';
import SettingsLayout from './pages/settings/features';
import EmployeeTracker from './pages/employees/features/employee-tracker';
import LearningEvent from './pages/learning/features/learning-event';
import LearningRequest from './pages/learning/features/learning-request';
import PerformanceInfo from './pages/performance/features/edit-goals/performance-info';
import AllReports from './pages/reports/features';
import LeaveReport from './pages/reports/features/leave';
import EmployeeReport from './pages/reports/features/employment-info';
import PerformanceMgmtReport from './pages/reports/features/performance-mgt';
import TrainingReport from './pages/reports/features/training';
import RecruitmentReport from './pages/reports/features/recruitment';
import CompensationReport from './pages/reports/features/compensation';
import OnboardingOffboardingReport from './pages/reports/features/onboading-offboarding';
import HRAdminReport from './pages/reports/features/hr-administration';
import LearningDetails from './pages/learning/features/learning-details';
import CalendarDetails from './components/base/calendar-tab/CalendarDetails';
import TrainingReview from './pages/learning/features/feedback-details/TrainingReview';
import FeedbackPage from './pages/learning/features/feedback-details/FeedbackDetails';

function App() {
  return (
    <Fragment>
      <ScrollToTop />
      <ToastContainer {...DEFAULT_TOAST_OPTIONS} />
      <Routes>
        {/* LANDING PAGE  */}
        <Route
          path={HOME}
          element={
            <UnAuthRoute>
              <LandingPage />
            </UnAuthRoute>
          }
        />

        <Route
          path={PRICING}
          element={
            <UnAuthRoute>
              <Pricing />
            </UnAuthRoute>
          }
        />
        <Route
          path={FAQs}
          element={
            <UnAuthRoute>
              <Faqs />
            </UnAuthRoute>
          }
        />
        <Route
          path={CONTACT}
          element={
            <UnAuthRoute>
              <Contact />
            </UnAuthRoute>
          }
        />
        <Route
          path={LOGIN}
          element={
            <UnAuthRoute>
              <Login />
            </UnAuthRoute>
          }
        />

        {/* AUTH */}
        <Route
          path={AUTH_LOGIN}
          element={
            <UnAuthRoute>
              <AuthLogin />
            </UnAuthRoute>
          }
        />
        <Route
          path={SIGN_UP}
          element={
            <UnAuthRoute>
              <AuthSignUp />
            </UnAuthRoute>
          }
        />
        <Route path={RESET_PASSWORD} element={<ResetPassword />} />
        <Route
          path={RESET_USER_PASSWORD}
          element={
            <UnAuthRoute>
              <ForgotUserPassword />
            </UnAuthRoute>
          }
        />
        <Route
          path={FORGOT_PASSWORD}
          element={
            <UnAuthRoute>
              <ForgotPassword />
            </UnAuthRoute>
          }
        />
        <Route path={USER_EMAIL_VERIFICATION} element={<VerifyUserEmail />} />

        <Route path={EMAIL_VERIFICATION} element={<VerifyEmail />} />
        {/* DASHBOARD PEOPLE_CENTA STARTS*/}
        <Route
          path={DASHBOARD}
          element={
            <AuthRoute>
              <Home />
            </AuthRoute>
          }
        />

        {/*PROFILE */}

        <Route
          path={PROFILE}
          element={
            <AuthRoute>
              <UserProfile />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <UserProfileLayout />
              </AuthRoute>
            }
          />
        </Route>

        {/*OVERVIEW */}

        <Route
          path={OVERVIEW}
          element={
            <AuthRoute>
              <Overview />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <OverviewLayout />
              </AuthRoute>
            }
          />
        </Route>

        {/*SETTINGS */}

        <Route
          path={SETTINGS}
          element={
            <AuthRoute>
              <Settings />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <SettingsLayout />
              </AuthRoute>
            }
          />
        </Route>

        {/*MANAGE PERFORMANCE */}

        <Route
          path={MANAGE_PERFORMANCE}
          element={
            <AuthRoute>
              <Performance />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <PerformanceLayout />
              </AuthRoute>
            }
          />
          <Route
            path={PERFORMANCE_DETAILS}
            element={
              <AuthRoute>
                <PerformanceInfo />
              </AuthRoute>
            }
          />
          <Route
            path={EDIT_GOALS}
            element={
              <AuthRoute>
                <EditGoals />
              </AuthRoute>
            }
          />
        </Route>

        {/*MANAGE HIRING */}

        <Route
          path={MANAGE_HIRING}
          element={
            <AuthRoute>
              <Hiring />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <HiringLayout />
              </AuthRoute>
            }
          />
          <Route
            path={ADD_VACANCY}
            element={
              <AuthRoute>
                <AddVacancy />
              </AuthRoute>
            }
          />
          <Route
            path={VACANCY}
            element={
              <AuthRoute>
                <VacancyDetailsLayout />
              </AuthRoute>
            }>
            <Route
              index
              element={
                <AuthRoute>
                  <VacancyDetails />
                </AuthRoute>
              }
            />
            <Route
              path={APPLICANTS}
              element={
                <AuthRoute>
                  <Applicants />
                </AuthRoute>
              }
            />
          </Route>
        </Route>

        {/*MANAGE EMPLOYEES */}

        <Route
          path={MANAGE_EMPLOYEES}
          element={
            <AuthRoute>
              <Employees />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <EmployeesLayout />
              </AuthRoute>
            }
          />
          <Route
            path={ADD_PEOPLE}
            element={
              <AuthRoute>
                <AddEmployee />
              </AuthRoute>
            }
          />
          <Route
            path={DEPARTMENT}
            element={
              <AuthRoute>
                <Department />
              </AuthRoute>
            }
          />
          <Route
            path={EMPLOYEE_DETAILS}
            element={
              <AuthRoute>
                <EmployeeDetails />
              </AuthRoute>
            }
          />
          <Route
            path={EMPLOYEE_TRACKER}
            element={
              <AuthRoute>
                <EmployeeTracker />
              </AuthRoute>
            }
          />
        </Route>

        {/*MANAGE ABSENCE */}

        <Route
          path={MANAGE_ABSENCE}
          element={
            <AuthRoute>
              <Absence />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <AbsenceLayout />
              </AuthRoute>
            }
          />
          <Route
            path={ABSENCE_DETAILS}
            element={
              <AuthRoute>
                <AbsenceEmployeeInfo />
              </AuthRoute>
            }
          />
          <Route
            path={ASSIGN_LEAVE}
            element={
              <AuthRoute>
                <AssignLeave />
              </AuthRoute>
            }
          />
        </Route>

        {/*MANAGE COMPENSATION */}
        <Route
          path={MANAGE_COMPENSATION}
          element={
            <AuthRoute>
              <Compensation />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <CompensationLayout />
              </AuthRoute>
            }
          />
          <Route
            path={PAYROLL_DETAILS}
            element={
              <AuthRoute>
                <CompensationInfo />
              </AuthRoute>
            }
          />
          <Route
            index
            path={SALARY_COMPONENTS}
            element={
              <AuthRoute>
                <SalaryComponents />
              </AuthRoute>
            }
          />
          <Route
            path={ADD_PAYROLL}
            element={
              <AuthRoute>
                <AddPayroll />
              </AuthRoute>
            }
          />
        </Route>

        {/*MANAGE EVENTS */}

        <Route
          path={MANAGE_EVENTS}
          element={
            <AuthRoute>
              <Events />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <EventsOverview />
              </AuthRoute>
            }
          />
          <Route
            path={CALENDAR_DETAILS}
            element={
              <AuthRoute>
                <CalendarDetails />
              </AuthRoute>
            }
          />
        </Route>

        {/*MANAGE LEARNING */}

        <Route
          path={MANAGE_LEARNING}
          element={
            <AuthRoute>
              <Learning />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <LearningOverview />
              </AuthRoute>
            }
          />
          <Route
            path={LEARNING_EVENT}
            element={
              <AuthRoute>
                <LearningEvent />
              </AuthRoute>
            }
          />
          <Route
            path={LEARNING_REQUEST}
            element={
              <AuthRoute>
                <LearningRequest />
              </AuthRoute>
            }
          />
          <Route
            path={TRAINING_DETAILS}
            element={
              <AuthRoute>
                <LearningDetails />
              </AuthRoute>
            }
          />
          <Route
            path={`${TRAINING_REVIEW}/:eventId`}
            element={
              <AuthRoute>
                <TrainingReview />
              </AuthRoute>
            }
          />
          <Route
            path={`${FEEDBACK_DETAILS}/:eventId`}
            element={
              <AuthRoute>
                <FeedbackPage />
              </AuthRoute>
            }
          />
        </Route>

        {/*MANAGE REQUESTS */}

        <Route
          path={REQUESTS}
          element={
            <AuthRoute>
              <Requests />
            </AuthRoute>
          }
        />

        {/*AUDITS */}

        <Route
          path={AUDITS}
          element={
            <AuthRoute>
              <Audits />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <AuditLayout />
              </AuthRoute>
            }
          />
        </Route>

        {/* TIMESHEET */}

        <Route
          path={TIMESHEET}
          element={
            <AuthRoute>
              <Timesheet />
            </AuthRoute>
          }
        />

        {/*HELPDESK */}

        <Route
          path={HELPDESK}
          element={
            <AuthRoute>
              <HelpDesk />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <HelpDeskLayout />
              </AuthRoute>
            }
          />
        </Route>

        {/*REPORTS */}

        <Route
          path={REPORTS}
          element={
            <AuthRoute>
              <Reports />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <AllReports />
              </AuthRoute>
            }
          />
          <Route
            path={PERFORMANCE_REPORT}
            element={
              <AuthRoute>
                <PerformanceMgmtReport />
              </AuthRoute>
            }
          />
          <Route
            path={LEAVE_REPORT}
            element={
              <AuthRoute>
                <LeaveReport />
              </AuthRoute>
            }
          />
          <Route
            path={EMPLOYEE_REPORT}
            element={
              <AuthRoute>
                <EmployeeReport />
              </AuthRoute>
            }
          />
          <Route
            path={COMPENSATION_REPORT}
            element={
              <AuthRoute>
                <CompensationReport />
              </AuthRoute>
            }
          />
          <Route
            path={RECRUITMENT_REPORT}
            element={
              <AuthRoute>
                <RecruitmentReport />
              </AuthRoute>
            }
          />
        </Route>

        {/* DASHBOARD PEOPLE_CENTA ENDS*/}

        {/* DASHBOARD PEOPLE_RECRUIT STARTS */}

        <Route
          path={PEOPLE_RECRUIT}
          element={
            <AuthRoute>
              <PeopleRecruitHome />
            </AuthRoute>
          }
        />

        {/*SOURCE AND ATTRACT STARTS*/}

        <Route
          path={SOURCE_AND_ATTRACT}
          element={
            <AuthRoute>
              <SourceAndAttract />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <SourceLayout />
              </AuthRoute>
            }
          />
          <Route
            path={JOB_DETAILS}
            element={
              <AuthRoute>
                <JobDetails />
              </AuthRoute>
            }
          />
          <Route
            path={CREATE_JOB}
            element={
              <AuthRoute>
                <CreateJob />
              </AuthRoute>
            }
          />
          <Route
            path={JOB_CATEGORIES}
            element={
              <AuthRoute>
                <JobCategories />
              </AuthRoute>
            }
          />
          <Route
            path={JOB_CATEGORY_DETAILS}
            element={
              <AuthRoute>
                <JobCategoryDetails />
              </AuthRoute>
            }
          />
          <Route
            path={APPLICATION_FORM}
            element={
              <AuthRoute>
                <ApplicationForm />
              </AuthRoute>
            }
          />
        </Route>

        {/*SOURCE AND ATTRACT ENDS*/}
        <Route
          path={SHORTLIST}
          element={
            <AuthRoute>
              <Shortlist />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <ShortlistLayout />
              </AuthRoute>
            }
          />
          <Route
            path={LEADERBOARD}
            element={
              <AuthRoute>
                <LeaderBoard />
              </AuthRoute>
            }
          />
          <Route
            path={JOB_APPLICANTS}
            element={
              <AuthRoute>
                <JobApplicantsLayout />
              </AuthRoute>
            }>
            <Route
              index
              element={
                <AuthRoute>
                  <JobApplicants />
                </AuthRoute>
              }
            />
            <Route
              path={SCREENING_SCORES}
              element={
                <AuthRoute>
                  <ScreeningScores />
                </AuthRoute>
              }
            />
            <Route
              path={CANDIDATE_DETAILS}
              element={
                <AuthRoute>
                  <CandidateDetails />
                </AuthRoute>
              }
            />
            <Route
              path={CANDIDATE_SCORE}
              element={
                <AuthRoute>
                  <CandidateScore />
                </AuthRoute>
              }
            />
            <Route
              path={ASSESSMENT_QUESTIONS}
              element={
                <AuthRoute>
                  <AssesmentQuestions />
                </AuthRoute>
              }
            />
          </Route>
        </Route>
        {/*SHORTLIST STARTS */}
        <Route
          path={SCHEDULE}
          element={
            <AuthRoute>
              <Schedule />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <ScheduleLayout />
              </AuthRoute>
            }
          />
          <Route
            path={SCHEDULE_DETAILS}
            element={
              <AuthRoute>
                <ScheduleLayoutDetails />
              </AuthRoute>
            }
          />
          <Route
            path={NEW_INTERVIEW}
            element={
              <AuthRoute>
                <NewInterview />
              </AuthRoute>
            }
          />
          <Route
            path={EVENTS}
            element={
              <AuthRoute>
                <PeopleRecruitEvents />
              </AuthRoute>
            }
          />
        </Route>
        {/*SHORTLIST ENDS */}

        {/*REPORT STARTS */}

        <Route
          path={PEOPLE_RECRUIT_REPORT}
          element={
            <AuthRoute>
              <RecruitReport />
            </AuthRoute>
          }>
          <Route
            index
            element={
              <AuthRoute>
                <RecruitReportLayout />
              </AuthRoute>
            }
          />
        </Route>
        {/*REPORT ENDS */}

        {/* DASHBOARD PEOPLE_RECRUIT ENDS */}

        {/*NOT FOUND*/}

        <Route
          path='*'
          element={
            <AuthRoute>
              <NotFound />
            </AuthRoute>
          }
        />
      </Routes>
    </Fragment>
  );
}

export default App;
