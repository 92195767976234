import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/img/logo.svg';
import Loader from '../../../components/base/loader';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { AUTH_LOGIN } from '../../../router/router';
import authService from '../../../services/auth.service';

const ForgotUserPassword = () => {
  const { email, token } = useParams();
  const emptyForm = {
    email: email,
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(emptyForm);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const passwordReset = async (e) => {
    setLoading(true);
    e.preventDefault();
    await authService.resetPassword(formData, navigate);
    setLoading(false);
  };

  const validateResetToken = async () => {
    setLoading(true);
    const res = await authService.validateResetToken(email, token);
    if (res) {
      setStep(1);
    } else {
      setStep(2);
    }
    setLoading(false);
  };

  useEffect(() => {
    validateResetToken();
  }, []);
  return (
    <div className=' grid md:h-[100vh] py-8'>
      <div className='flex flex-col items-center justify-center w-full md:w-[50%] md:mx-auto'>
        <Logo className='' />

        {step === 1 ? (
          <form
            onSubmit={passwordReset}
            className=' flex flex-col gap-8 items-start w-[90%] md:w-[75%] my-8'>
            <Input
              withLabel
              onChange={handleChange}
              label='email'
              className=''
              containerClass='w-full'
              name={'email'}
              value={formData?.email}
              disabled={step === 1}
            />
            <Input
              withLabel
              onChange={handleChange}
              label='New Password'
              className=''
              containerClass='w-full'
              name={'newPassword'}
              ispassword
              value={formData?.newPassword}
            />

            <Input
              withLabel
              onChange={handleChange}
              label='Confirm Password'
              className=''
              containerClass='w-full'
              name={'confirmPassword'}
              ispassword
              value={formData?.confirmPassword}
            />

            <Button
              disabled={loading}
              loading={loading}
              text={'reset Password'}
              className={'w-full mt-6'}
              isPurple
              type='submit'
            />
          </form>
        ) : step === 2 ? (
          <div className=' space-y-8 pt-9'>
            <p className=' text-red-700 font-semibold text-xl md:text-2xl'>
              Authentication failed!!
            </p>
            <Link to={AUTH_LOGIN}>
              <Button text={'Back To Login'} className={'w-full mt-6'} isPurple />
            </Link>
          </div>
        ) : (
          <Loader size={28} className='mt-7' />
        )}
      </div>
    </div>
  );
};

export default ForgotUserPassword;
