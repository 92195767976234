import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import CompensationDetails from './CompensationDetails';
import RightSalary from '../add-payroll/right-payroll/RightSalary';
import { apiService } from '../../../../helpers/api';
import { BiLoaderCircle } from 'react-icons/bi';

const CompensationInfo = ({ id, compensationDetail, salaryComponent }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showComponentModal, setShowComponentModal] = useState(false);
  const [showOvertimeModal, setShowOvertimeModal] = useState(false);
  const [showSalaryComponentContent, setShowSalaryComponentContent] = useState(false);

  const closeModal = () => {
    setShowComponentModal(false);
    setShowOvertimeModal(false);
  };

  return (
    <div className="mx-auto lg:mx-0 space-y-6">
      <div
        className="uppercase text-xs flex items-center justify-between space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer">
        <div className='flex flex-row gap-2' onClick={() => navigate(-1)}>
          <MdOutlineArrowBack /> <span className="text-blue-100"> BACK</span>
        </div>
        <div>
          <RightSalary 
            loading={loading} 
            salaryComponent={salaryComponent}
            id={id} 
          />
        </div>
      </div>

      <div className="space-y-6 flex flex-wrap justify-between items-start">
        {loading ? (
          <BiLoaderCircle size={16} className="mx-auto animate-spin-slow" />
        ) : (
          <>
            <CompensationDetails compensationDetail={compensationDetail} />
          </>
        )}
      </div>

      
      
    </div>
  );
};

export default CompensationInfo;
