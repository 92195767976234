import React from 'react';
import { Link } from 'react-router-dom';
import StatusCard from './StatusCard';

const ApplicantCard = ({
  name,
  displayPicture,
  email,
  applicantId,
  className,
  withAttachments,
  CV,
  coverLetter,
  statusClassName,
  isApproved,
  isRejected,
  pendingApproval
}) => {
  return (
    <Link
      to={''}
      className={`bg-white-100 p-4 flex  items-start gap-x-10 justify-between font-semibold rounded-[5px] w-[100%]   ${
        withAttachments && 'flex-col gap-y-5'
      } ${className}`}>
      <div className="w-full gap-x-10  flex justify-between items-center">
        <div className="flex items-center gap-x-5">
          {displayPicture}
          <p>{name}</p>
        </div>
        <p className="uppercase">{applicantId}</p>
        <p>{email}</p>

        {pendingApproval ? (
          <div className="flex items-center gap-3">
            <StatusCard className={statusClassName} pendingApproval reject />
            <StatusCard className={statusClassName} pendingApproval approve />
          </div>
        ) : (
          <StatusCard className={statusClassName} isApproved={isApproved} isRejected={isRejected} />
        )}
      </div>
      {withAttachments && (
        <div className="flex items-center gap-3 text-xs font-semibold w-full">
          <span className="text-gray-100">View links to attachments : </span>
          {CV && <span className="underline text-purple-100 ">CV</span>}
          {coverLetter && <span className="underline text-purple-100 ">Cover Letter</span>}
        </div>
      )}
    </Link>
  );
};

export default ApplicantCard;
