import React, { useState } from 'react';
import BaseModal from '../../../../../components/base/modal';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import Select from '../../../../../components/input/Select';
import CheckBox from '../../../../../components/input/CheckBox';
import { RATE_TYPE, DURATION_TYPE, LEAVE_TYPE_OPTIONS, STORAGE_KEYS, EMPLOYEE_GROUP_ID } from '../../../../../helpers/constant';
import { API } from '../../../../../utils/apis';

const AddLeaveTypeModal = ({ show, closeModal }) => {
  const companyId = localStorage.getItem(STORAGE_KEYS?.COMPANY_ID);
  const [modalData, setModalData] = useState({
    id: 0,
    leaveType: 1,
    entitlement: 0,
    startMonth: '',
    endMonth: '',
    endsNextYear: true,
    allowCarryOver: true,
    companyId: companyId,
    employeeGroupId: 0
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setModalData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  const submitData = async (e) => {
    setLoading(true);
    console.log(modalData);
    console.log(companyId);
    await API.createLeaveSettings(modalData);
    setLoading(false);
    e.preventDefault();
  };

  return (
    <BaseModal title={'Add Leave Type'} show={show} onClose={closeModal}>
      <div className='space-y-10 rounded-lg w-full sm:w-[400px] overflow-hidden'>
        <div className='flex flex-col gap-2 w-full'>
          <Select
            withLabel
            selectedOption={modalData.employeeGroupId}
            options={EMPLOYEE_GROUP_ID}
            label='Employee Group Id*'
            containerClass='w-full sm:w-auto'
            name='employeeGroupId'
            onChange={(e) => handleChange('employeeGroupId', e?.value)}
          />
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>LEAVE TYPE *</h6>
          <Select
            selectedOption={modalData.leaveType}
            className='w-full sm:w-auto'
            options={LEAVE_TYPE_OPTIONS}
            onChange={(e) => handleChange('leaveType', e?.value)}
          />
        </div>
        <Input
          withLabel
          value={modalData.entitlement}
          label='Leave Days'
          containerClass='w-full sm:w-auto'
          name='leaveDays'
          onChange={(e) => handleChange('entitlement', e.target.value)}
        />
        <div className='flex flex-wrap gap-7 w-full'>
          <Input
            withLabel
            value={modalData.startMonth}
            label='Start Month *'
            containerClass='w-full sm:w-auto'
            name='startMonth'
            type='date'
            onChange={(e) => handleChange('startMonth', e.target.value)}
          />
          <Input
            withLabel
            value={modalData.endMonth}
            label='End Month *'
            containerClass='w-full sm:w-auto'
            name='endMonth'
            type='date'
            onChange={(e) => handleChange('endMonth', e.target.value)}
          />
        </div>
        <div className='flex flex-wrap gap-3 w-full items-center'>
          <h6 className='text-xs'>NEXT YEAR?</h6>
          <CheckBox
            name='endsNextYear'
            checked={modalData.endsNextYear}
            onChange={(e) => handleChange('endsNextYear', e.target.checked)}
          />
        </div>
        
        <div className='flex flex-col gap-2 w-full space-y-3'>
          <h6 className='text-xs'>ALLOW CARRYOVER</h6>
          <div className='flex flex-wrap gap-8'>
            <div className='flex flex-wrap items-center gap-2'>
              <CheckBox
                name='allowCarryOverYes'
                checked={modalData.allowCarryOver}
                onChange={(e) => {
                  handleChange('allowCarryOver', e.target.checked);
                  if (e.target.checked) {
                    handleChange('allowCarryOverNo', false);
                  }
                }}
              />
              <p className='text-green-700 text-sm font-semibold'>Yes</p>
            </div>
            <div className='flex flex-wrap items-center gap-2'>
              <CheckBox
                name='allowCarryOverNo'
                checked={!modalData.allowCarryOver}
                onChange={(e) => {
                  handleChange('allowCarryOver', !e.target.checked);
                  if (!e.target.checked) {
                    handleChange('allowCarryOverYes', false);
                  }
                }}
              />
              <p className='text-pink-100 text-sm font-semibold'>No</p>
            </div>
          </div>
        </div>

        <div className='flex gap-4 flex-wrap justify-end'>
          <Button
            className='border border-pink-100'
            text='Cancel'
            onClick={closeModal}
            isTransparent
          />
          <Button
            text='Submit'
            type='submit'
            onClick={submitData}
            isPurple
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default AddLeaveTypeModal;
