import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import BaseModal from '../../../../components/base/modal';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import Select from '../../../../components/input/Select';
import { getOvertimeOption } from '../../../../helpers/functions';
import { RATE_TYPE, DURATION_TYPE } from '../../../../helpers/constant';
import { API } from '../../../../utils/apis';

const AddOvertimeDetailsModal = ({ show, closeModal }) => {
  const [compensationDetail, setCompensationDetail] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [modalData, setModalData] = useState({
    employeeId: id,
    id: 0,
    componentId: 0,
    name: '',
    durationType: 0,
    rateType: 0,
    value: 0,
    dependantType: 1,
    duration: ''
  });

  const DEPENDENT_TYPE = [
    {
      label: 'None',
      value: 1
    },
    {
      label: 'Gross',
      value: 2
    },
    {
      label: 'Other Components',
      value: 3,
      children: [
        { label: 'Basic', value: '31' },
        { label: 'Training', value: '32' },
        { label: 'Housing', value: '33' },
        { label: 'Transport', value: '34' }
      ]
    }
  ];

  const [loading, setLoading] = useState(false);
  const [overtimeOption, setOvertimeOption] = useState([]);

  const loadOvertimeOptions = async () => {
    const res = await API.createOvertime();
    setOvertimeOption(getOvertimeOption(res));
  };

  const handleChange = (name, value) => {
    if (name === 'value' && modalData.rateType === 1 && value > 100) {
      alert('Value cannot exceed 100%');
      return;
    }
    setModalData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const submitData = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Step 1: Create Overtime
      const overtimeData = {
        name: modalData.name,
        durationType: modalData.durationType,
        rateType: modalData.rateType,
        value: modalData.value
      };
      const overtimeResponse = await API.createOvertime(overtimeData);

      // Step 2: Use response from createOvertime to createOvertimeComponent
      setModalData((prevData) => ({
        ...prevData,
        id: overtimeResponse.id
      }));

      const overtimeComponentData = {
        ...modalData,
        id: overtimeResponse.id
      };
      console.log(overtimeComponentData);
      const overtimeComponentResponse = await API.createOvertimeComponent(overtimeComponentData);
      console.log(overtimeComponentResponse);

      closeModal();
    } catch (error) {
      console.error('Error creating overtime component:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadOvertimeOptions();
  }, []);

  const renderDropdownOptions = (options) => {
    return options.map((option) => {
      if (option.children) {
        return (
          <Select.OptionGroup key={option.value} label={option.label}>
            {renderDropdownOptions(option.children)}
          </Select.OptionGroup>
        );
      } else {
        return (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        );
      }
    });
  };

  return (
    <BaseModal title={'Add Overtime'} show={show} onClose={closeModal}>
      <div className='space-y-10 rounded-lg w-full sm:w-[400px] overflow-hidden'>
        <Input
          withLabel
          value={modalData.name}
          label='Name'
          containerClass='w-full sm:w-auto'
          name='name'
          onChange={(e) => handleChange('name', e.target.value)}
        />

        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>DEPENDENT TYPE</h6>
          <Select
            selectedOption={modalData.dependantType}
            className='w-full sm:w-auto'
            options={DEPENDENT_TYPE}
            onChange={(e) => handleChange('dependantType', e?.value)}
          />
          {modalData.dependantType === 3 && (
            <div className='ml-4'>
              <Select
                selectedOption={modalData.dependantType}
                className='w-full sm:w-auto'
                options={DEPENDENT_TYPE.find((option) => option.value === 3)?.children || []}
                onChange={(e) => handleChange('dependantType', e?.value)}
              >
                {renderDropdownOptions(DEPENDENT_TYPE.find((option) => option.value === 3)?.children || [])}
              </Select>
            </div>
          )}
        </div>

        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>RATE TYPE</h6>
          <Select
            selectedOption={modalData.rateType}
            className='w-full sm:w-auto'
            options={RATE_TYPE}
            onChange={(e) => handleChange('rateType', e?.value)}
          />
        </div>

        <Input
          withLabel
          value={modalData.value}
          label={modalData.rateType === 1 ? 'Value' : 'Amount'}
          containerClass='w-full sm:w-auto'
          name='value'
          onChange={(e) => handleChange('value', e.target.value)}
        />

        <div className='flex flex-col gap-2 w-full'>
          <h6 className='text-xs'>DURATION TYPE</h6>
          <Select
            selectedOption={modalData.durationType}
            className='w-full sm:w-auto'
            options={DURATION_TYPE}
            onChange={(e) => handleChange('durationType', e?.value)}
          />
        </div>

        <Input
          withLabel
          value={modalData.duration}
          label='Duration'
          containerClass='w-full sm:w-auto'
          name='duration'
          onChange={(e) => handleChange('duration', e.target.value)}
        />

        <div className='flex gap-4 flex-wrap justify-end'>
          <Button
            className='border border-pink-100'
            text='Cancel'
            onClick={closeModal}
            isTransparent
          />
          <Button
            text='Submit'
            type='submit'
            onClick={submitData}
            isPurple
            loading={loading}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default AddOvertimeDetailsModal;
