import React, { useState } from 'react';
import BaseModal from '../../../../../components/base/modal';
import Button from '../../../../../components/button';
import { Toast } from '../../../../../components/toast/toast';
import { apiService } from '../../../../../helpers/api';

const RejectPerformanceModal = ({
  showRejectModal,
  toggleModal,
  performances,
  activePerformanceRequest,
  setPerformances,
  setTechnicalObjs,
  technicalObjs
}) => {
  const [loading, setLoading] = useState(false);

  const rejectPerformanceRequest = async () => {
    setLoading(true);
    try {
      const res = await apiService.post(
        `Performance/Goal/Approve?Id=${activePerformanceRequest?.performanceId}&approve=false`
      );
      const filteredRequests = performances.filter(
        ({ performanceId }) => performanceId != activePerformanceRequest?.performanceId
      );
      if (res) {
        setPerformances(filteredRequests);
        setTechnicalObjs([...technicalObjs, activePerformanceRequest]);
        toggleModal();
        Toast({
          type: 'success',
          message: 'Leave Request Rejected'
        });
      }
    } catch (err) {
      console.log('Error in rejecting performance request: ', err)
    } finally {
      setLoading(false);
    }
  };
  return (
    <BaseModal
      title={'Are you sure you want to '}
      coloredText='Reject? '
      show={showRejectModal}
      onClose={toggleModal}>
      <div className='flex gap-4 flex-wrap mt-6 justify-end'>
        <Button className={''} text='Go back' onClick={toggleModal} isGray />
        <Button
          text='Reject'
          isPink
          className='bg-opacity-80'
          loading={loading}
          onClick={rejectPerformanceRequest}
        />
      </div>
    </BaseModal>
  );
};

export default RejectPerformanceModal;
