import React from 'react';
import { Link } from 'react-router-dom';
import StatsCard from '../../../components/cards/StatsCard';
import { HiArrowRight } from 'react-icons/hi';
import { numberWithCommas } from '../../../helpers/functions';
import { ReactComponent as PayrollIcon } from '../../../assets/icons/objective.svg';
import { ReactComponent as EmployeeIcon } from '../../../assets/icons/people.svg';
import { MANAGE_COMPENSATION, MANAGE_EMPLOYEES, REPORTS } from '../../../router/router';

const OverviewHeader = ({ requestCount }) => {

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = monthNames[currentDate.getMonth()];
  const paymentDate = `25th of ${month}, ${year}`;

  return (
    <div className='flex flex-wrap lg:flex-nowrap gap-6'>
      <div
        className={
          'mini-card-bg flex font-medium text-white-100 flex-col space-y-3 rounded-lg p-8 '
        }>
        <h4 className='text-white-100 mdLg:text-2xl font-medium'>Welcome back to your dashboard</h4>
        <span className=' text-xs'>Catch up on new updates and reports</span>
        <Link to={REPORTS} className='text-xs underline text-orange-100 '>
          View Reports
        </Link>
      </div>

      <div className='flex flex-col gap-4'>
        <div className='flex  flex-wrap mdLg:flex-nowrap justify-between gap-4'>
          <StatsCard
            isBlue
            title='TOTAL EMPLOYEES'
            value={numberWithCommas(requestCount.employeesCount)}
            className='w-full '
            icon={<EmployeeIcon />}
            incrementArrow
            percent='2.5'
          />
          <StatsCard
            isBlue
            title='Payrolls created'
            value={numberWithCommas('0')}
            className='w-full '
            icon={<PayrollIcon />}
          />
        </div>

        <div className='flex flex-wrap mdLg:grid mdLg:grid-cols-6 gap-4'>
          <div className='bg-blue-100 text-yellow-100 p-6  gap-2 font-semibold w-[220px] mdLg:w-full  rounded-sm flex items-start flex-col'>
            <h6 className='text-white-100'>requests</h6>
            <p className='text-3xl'>{requestCount?.leaveRequests}</p>
          </div>
          <div className='bg-blue-100 text-yellow-100 p-6  gap-2 font-semibold w-[220px] mdLg:w-full  rounded-sm flex items-start flex-col'>
            <h6 className='text-white-100'>events</h6>
            <p className='text-3xl'>0</p>
          </div>
          <Link
            to={MANAGE_EMPLOYEES}
            className='bg-yellow-100 p-6  gap-2 font-semibold w-[220px] mdLg:w-full  rounded-sm flex items-start flex-col text-white-100 px-3'>
            <h6 className='text-white-100'>view all employees</h6>
            <HiArrowRight />
          </Link>
          <div className='bg-blue-100 p-6  gap-2 font-semibold w-[220px] mdLg:w-full  rounded-sm flex items-start flex-col col-span-2 text-white-100'>
            <h6 className='text-white-100'>NEXT PAYMENT DATE</h6>
            <p>{paymentDate}</p>
          </div>
          <Link
            to={MANAGE_COMPENSATION}
            className='bg-pink-100 p-6  gap-2 font-semibold w-[220px] mdLg:w-full  rounded-sm flex items-start flex-col text-white-100 px-3'>
            <h6 className='text-white-100'>view payrolls</h6>
            <HiArrowRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OverviewHeader;
