import React, { useState } from 'react';
import BaseModal from '../../../../../components/base/modal';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import Select from '../../../../../components/input/Select';
import { COMPONENT_TYPE, RATE_TYPE } from '../../../../../helpers/constant';
import { API } from '../../../../../utils/apis';

const AddTaxReliefModal = ({ show, closeModal }) => {
  const DEPENDENT_TYPE = [
    {
      label: 'None',
      value: 1
    },
    {
      label: 'Gross',
      value: 2
    },
    {
      label: 'Other Components',
      value: 3,
      children: [
        { label: 'Basic', value: '31' },
        { label: 'Training', value: '32' },
        { label: 'Housing', value: '33' },
        { label: 'Transport', value: '34' }
      ]
    }
  ];

  const [modalData, setModalData] = useState([
    {
      componentId: 0,
      name: '',
      value: 0,
      dependantComponentType: 0,
      dependant: '',
      duration: 0,
      componentType: 0,
      withinGross: true,
      isCompulsory: true
    }
  ]);
  const [loading, setLoading] = useState(false);

  const handleChange = (index, field, value) => {
    const updatedData = [...modalData];
    updatedData[index] = {
      ...updatedData[index],
      [field]: value
    };
    setModalData(updatedData);
  };

  const submitData = async (e) => {
    setLoading(true);
    console.log(modalData);
    await API.createAdditionalComponent(modalData);
    setLoading(false);
    e.preventDefault();
  };

  const renderDropdownOptions = (options) => {
    return options.map((option) => {
      if (option.children) {
        return (
          <Select.OptionGroup key={option.value} label={option.label}>
            {renderDropdownOptions(option.children)}
          </Select.OptionGroup>
        );
      } else {
        return (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        );
      }
    });
  };
  

  return (
    <BaseModal title={'Add Component'} show={show} onClose={closeModal}>
      <div className='space-y-10 rounded-lg w-full sm:w-[400px] overflow-hidden'>
        {modalData.map((data, index) => (
          <React.Fragment key={index}>
            <Input
              withLabel
              value={data.name}
              label='Name'
              containerClass='w-full sm:w-auto'
              name='name'
              onChange={(e) => handleChange(index, 'name', e.target.value)}
            />
            <div className='flex flex-col gap-2 w-full'>
              <h6 className='text-xs'>TYPE</h6>
              <Select
                selectedOption={data.componentType}
                className='w-full sm:w-auto'
                options={COMPONENT_TYPE}
                onChange={(e) => handleChange(index, 'componentType', e?.value)}
              />
            </div>
            <div className='flex flex-col gap-2 w-full'>
              <h6 className='text-xs'>DEPENDENT TYPE</h6>
              <Select
                selectedOption={data.dependantComponentType}
                className='w-full sm:w-auto'
                options={DEPENDENT_TYPE}
                onChange={(e) =>
                  handleChange(index, 'dependantComponentType', e?.value)
                }
              />
              {data.dependantComponentType === 3 && (
               <div className='ml-4'>
                 <Select
                    selectedOption={data.dependantComponentType}
                    className='w-full sm:w-auto'
                    options={DEPENDENT_TYPE.find((option) => option.value === 3)?.children || []}
                    onChange={(e) => handleChange(index, 'dependantComponentType', e?.value)}
                  >
                    {renderDropdownOptions(DEPENDENT_TYPE.find((option) => option.value === 3)?.children || [])}
                 </Select>  
                </div>
              )}
            </div>
            <div className='flex flex-col gap-2 w-full'>
              <h6 className='text-xs'>RATE TYPE</h6>
              <Select
                selectedOption={data.dependant}
                className='w-full sm:w-auto'
                options={RATE_TYPE}
                onChange={(e) => handleChange(index, 'dependant', e?.value)}
              />
            </div>
            <Input
              withLabel
              value={data.value}
              label='Value'
              containerClass='w-full sm:w-auto'
              name='value'
              onChange={(e) => handleChange(index, 'value', e.target.value)}
            />
            <Input
              withLabel
              value={data.duration}
              label='Duration'
              containerClass='w-full sm:w-auto'
              name='duration'
              onChange={(e) => handleChange(index, 'duration', e.target.value)}
            />
          </React.Fragment>
        ))}
        <div className='flex gap-4 flex-wrap justify-end'>
          <Button
            className='border border-pink-100'
            text='Cancel'
            onClick={closeModal}
            isTransparent
          />
          <Button text='Submit' type='submit' onClick={submitData} isPurple />
        </div>
      </div>
    </BaseModal>
  );
};

export default AddTaxReliefModal;
