import React from 'react';
import { useState } from 'react';
import Button from '../../../../../components/button';
import Input, { TextAreaWithFormatting } from '../../../../../components/input';
import CheckBox from '../../../../../components/input/CheckBox';
import './styles.css';

const CreateJobFirstPage = ({ step, setStep }) => {
  const [newSkill, setNewSkill] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [skills, setSkills] = useState([
    { skills: 'communication' },
    { skills: 'creative' },
    { skills: 'problem solving' },
    { skills: 'critical thinking' }
  ]);

  const addNewSkills = () => {
    setSkills([
      ...skills,
      {
        skills: newSkill
      }
    ]);
    setNewSkill('');
    setShowInput(!showInput);
  };

  return (
    <>
      {step === 0 && (
        <div className="space-y-10 mt-6 px-2 pb-10">
          <div className="flex justify-between items-center">
            <h4 className="text-pink-100"> Job Opening Information</h4>

            <h4 className="text-black-100">1 Of 2: Job Details</h4>
          </div>

          <form className="space-y-10" onSubmit={(e) => e.preventDefault()}>
            <div className="flex flex-wrap gap-3">
              <Input
                withLabel
                label={'Job Title'}
                required
                isHorizontal
                normalLabel
                height="h-9"
                labelClassName="w-[30%]"
              />
              <Input
                withLabel
                label={'Job Title'}
                required
                isHorizontal
                normalLabel
                height="h-9"
                labelClassName="w-[30%]"
              />

              <Input
                withLabel
                label={'Job Title'}
                required
                isHorizontal
                normalLabel
                height="h-9"
                labelClassName="w-[30%]"
              />

              <Input
                withLabel
                label={'Job Title'}
                required
                isHorizontal
                normalLabel
                height="h-9"
                labelClassName="w-[30%]"
              />

              <Input
                withLabel
                label={'Job Title'}
                required
                isHorizontal
                normalLabel
                height="h-9"
                labelClassName="w-[30%]"
              />

              <Input
                withLabel
                label={'Job Title'}
                required
                isHorizontal
                normalLabel
                height="h-9"
                labelClassName="w-[30%]"
              />

              <Input
                withLabel
                label={'Job Title'}
                required
                isHorizontal
                normalLabel
                height="h-9"
                labelClassName="w-[30%]"
              />
              <Input
                withLabel
                label={'Job Title'}
                required
                isHorizontal
                normalLabel
                height="h-28"
                containerClass="w-[50%]"
                labelClassName="w-[20%]"
              />

              <Input
                withLabel
                label={'Job Title'}
                required
                isHorizontal
                normalLabel
                height="h-9"
                labelClassName="w-[30%]"
              />
              <CheckBox title={'Remote Job'} className="text-blue-100 font-semibold text-sm" />
            </div>

            <div className="space-y-4">
              <h4 className="text-pink-100"> Job Description Information</h4>

              <div
                className="flex flex-col md:flex-row gap-4
           w-full">
                <TextAreaWithFormatting
                  label="Custom Footer"
                  labelClassName="text-gray-200"
                  withLabel
                  className={'w-full md:w-1/2'}
                />
                <TextAreaWithFormatting
                  label="Custom Footer"
                  labelClassName="text-gray-200"
                  withLabel
                  className={'w-full md:w-1/2'}
                />
              </div>
            </div>

            <div className=" space-y-2">
              <h4 className="text-pink-100"> Add Skills </h4>
              <p className="text-gray-200 font-medium text-sm">
                Choose Skills you want your candidates to possess
              </p>

              <div className="flex gap-2 flex-wrap ">
                {skills.map((item, idx) => {
                  return (
                    <>
                      <p className="w-fit p-1 px-2 bg-purple-60 rounded-[5px] font-medium text-black-100 flex justify-center items-center capitalize">
                        + {item.skills}
                      </p>
                    </>
                  );
                })}
              </div>
              <Button
                type={'button'}
                text="Add New Skill"
                className="cursor-pointer w-fit p-1 px-2 border-2 bg-white-100 border-purple-60 rounded-[3px] font-medium text-black-100"
                onClick={() => setShowInput(!showInput)}
              />

              {showInput && (
                <div className="flex items-center gap-2 flex-wrap">
                  <Input
                    required
                    normalLabel
                    height="h-9"
                    className="w-[200px]"
                    placeholder={'skill name'}
                    onChange={(e) => setNewSkill(e.target.value)}
                  />
                  <Button
                    text="Done"
                    type={'button'}
                    className="cursor-pointer w-fit p-1 px-2 border-2 bg-white-100 border-purple-60 rounded-[3px] font-medium text-black-100"
                    onClick={addNewSkills}
                  />
                </div>
              )}
            </div>

            <div className="ml-auto flex justify-end">
              <Button
                isPurple
                text="Next"
                className={' rounded-4xl '}
                onClick={() => setStep(step + 1)}
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default CreateJobFirstPage;
