import React, { useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiLoaderAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../components/general/pagination';
import EditEmployeeGroupModal from './employee-group-modal/edit-group-modal';
import EditEmployeeGradeModal from './employee-grade-modal/edit-grade-modal';
import DeleteEmployeeGroupModal from './employee-group-modal/delete-group-modal';
import DeleteEmployeeGradeModal from './employee-grade-modal/delete-grade-modal';

const EmployeeGradeGroup = ({ id, loading, employeeGroups = [], employeeGrades = [] }) => {
  const navigate = useNavigate();
  const [showEditEmployeeGroupModal, setShowEditEmployeeGroupModal] = useState(false);
  const [showEditEmployeeGradeModal, setShowEditEmployeeGradeModal] = useState(false);
  const [showDeleteGradeModal, setShowDeleteGradeModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [editEmployeeGroupInfo, setEditEmployeeGroupInfo] = useState(null);
  const [editEmployeeGradeInfo, setEditEmployeeGradeInfo] = useState(null);
  const [deleteEmployeeGroupInfo, setDeleteEmployeeGroupInfo] = useState(null);
  const [deleteEmployeeGradeInfo, setDeleteEmployeeGradeInfo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentEmployeeGroups = Array.isArray(employeeGroups)
    ? employeeGroups.slice(indexOfFirstItem, indexOfLastItem)
    : []
  ;
  const currentEmployeeGrades = Array.isArray(employeeGrades)
    ? employeeGrades.slice(indexOfFirstItem, indexOfLastItem)
    : []
  ;

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const closeModal = () => {
    setShowEditEmployeeGroupModal(false);
    setShowEditEmployeeGradeModal(false);
    setShowDeleteGroupModal(false);
    setShowDeleteGradeModal(false);
  };

  return (
    <div className='rounded-[20px] bg-purple-30 p-10 my-6'>
      <div>
        <div className='whitespace-nowrap overflow-x-scroll'>
          <table className='w-full'>
            <thead className='border-collapse border-red-500'>
              {/* Conditional rendering of headers for Employee Groups */}
              {currentEmployeeGroups.length > 0 && (
                <tr className='w-[100%] text-left uppercase tracking-[3px] text-blue-100 font-semibold text-2xs pl-28 mb-4'>
                  <th className='p-6'>#</th>
                  <th className='p-6 text-center'>NAME</th>
                  <th className='p-3 px-6 text-right'>ACTION</th>
                </tr>
              )}
              {/* Conditional rendering of headers for Employee Grades */}
              {currentEmployeeGrades.length > 0 && (
                <tr className='w-[100%] text-left uppercase tracking-[3px] text-blue-100 font-semibold text-2xs pl-28 mb-4'>
                  <th className='p-6'>#</th>
                  <th className='p-6 text-center'>NAME</th>
                  <th className='p-6 text-center'>DESCRIPTION</th>
                  <th className='p-3 px-6 text-right'>ACTION</th>
                </tr>
              )}
            </thead>
            <tbody className='rounded-sm'>
              {/* Render Employee Groups */}
              {currentEmployeeGroups.map((employeeGroup, i) => (
                <tr
                  className='cursor-pointer border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                  key={employeeGroup?.id}
                  onClick={() => navigate(``)}
                >
                  <td className='p-3 px-6 '>{i + 1}.</td>
                  <td className='p-3 px-6 text-center'>
                    {employeeGroup.gradeGroupName !== null ? employeeGroup.gradeGroupName : 'nil'}
                  </td>
                  <td className='p-3 px-6 grid place-items-end mr-3'> {/* Flexbox for Edit/Delete */}
                    <div className='flex justify-between gap-1'>
                      <FiEdit
                        className='cursor-pointer hover:bg-pink-30 transition-all'
                        onClick={() => {
                          setShowEditEmployeeGroupModal(true);
                          setEditEmployeeGroupInfo(employeeGroup);
                        }}
                      />
                      <RiDeleteBinLine
                        className='cursor-pointer hover:bg-pink-30 transition-all'
                        onClick={() => {
                          setShowDeleteGroupModal(true);
                          setDeleteEmployeeGroupInfo(employeeGroup.id);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}

              {/* Render Employee Grades */}
              {currentEmployeeGrades.map((employeeGrade, i) => (
                <tr
                  className='cursor-pointer border-b-[15px] border-pink-30 bg-white-100 mb-6 pb-6 p-4 font-semibold rounded-[5px] capitalize'
                  key={employeeGrade?.id}
                  onClick={() => navigate(``)}
                >
                  <td className='p-3 px-6 '>{i + 1}.</td>
                  <td className='p-3 px-6 text-center'>
                    {employeeGrade.gradeName !== null ? employeeGrade.gradeName : 'nil'}
                  </td>
                  <td className='p-3 px-6 text-center'>
                    {employeeGrade.gradeGroupName !== null ? employeeGrade.gradeGroupName : 'nil'}
                  </td>
                  <td className='p-3 px-9 grid place-items-end'> {/* Flexbox for Edit/Delete */}
                    <div className='flex justify-between gap-1'>
                      <FiEdit
                        className='cursor-pointer hover:bg-pink-30 transition-all'
                        onClick={() => {
                          setShowEditEmployeeGradeModal(true);
                          setEditEmployeeGradeInfo(employeeGrade);
                        }}
                      />
                      <RiDeleteBinLine
                        className='cursor-pointer hover:bg-pink-30 transition-all'
                        onClick={() => {
                          setShowDeleteGradeModal(true);
                          setDeleteEmployeeGradeInfo(employeeGrade.id);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='pb-10 flex flex-col items-center'>
            {loading && (
              <BiLoaderAlt
                className='animate-spin my-10 flex text-center text-blue-100 justify-center mx-auto'
                size={30}
              />
            )}
            {!loading && employeeGroups.length === 0 && employeeGrades.length === 0 && (
              <span className='p-3'>There are currently no employee group/grade settings to show</span>
            )}
          </div>
        </div>
      </div>
      <Pagination
        totalPages={Math.ceil((employeeGroups.length || employeeGrades.length) / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

      <EditEmployeeGroupModal
        show={showEditEmployeeGroupModal}
        editEmployeeGroupInfo={editEmployeeGroupInfo}
        closeModal={closeModal}
        id={id}
      />

      <EditEmployeeGradeModal
        show={showEditEmployeeGradeModal}
        editEmployeeGradeInfo={editEmployeeGradeInfo}
        closeModal={closeModal}
        id={id}
      />

      <DeleteEmployeeGroupModal
        showDeleteGroupModal={showDeleteGroupModal}
        deleteEmployeeGroupInfo={deleteEmployeeGroupInfo}
        closeModal={closeModal}
      />

      <DeleteEmployeeGradeModal
        showDeleteGradeModal={showDeleteGradeModal}
        deleteEmployeeGradeInfo={deleteEmployeeGradeInfo}
        closeModal={closeModal}
      />
    </div>
  );
};

export default EmployeeGradeGroup;
