import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input, { TextArea } from '../../../../components/input';
import Select from '../../../../components/input/Select';
import { fetchAllEmployeesById } from '../../../../utils/apis/api-modules/employee';
import BackWithText from '../../../../components/general/BackWithText';
import { LEAVE_TYPE_OPTIONS, STORAGE_KEYS } from '../../../../helpers/constant';
import Button from '../../../../components/button';
import { apiService } from '../../../../helpers/api';
import { MANAGE_ABSENCE } from '../../../../router/router';
import { getEmployeesOption } from '../../../../helpers/functions';

const AssignLeave = () => {
  const staffId = localStorage.getItem(STORAGE_KEYS?.USER_ID);
  const emptyForm = {
    employeeId: '',
    leaveType: '',
    startDate: '',
    endDate: '',
    description: '',
    approverId: staffId,
    reliefEmployeeId: '',
    alternatePhone: ''
  };
  const [formData, setFormData] = useState(emptyForm);
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employeesArray, setEmployeesArray] = useState([]);
  const navigate = useNavigate();

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    return date.toISOString().split('T')[0]; // Formats the date as 'yyyy-mm-dd'
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = (name === 'startDate' || name === 'endDate') ? formatDate(value) : value;
    setFormData({
      ...formData,
      [name]: formattedValue
    });
  };

  const loadData = async () => {
    setLoading(true);
    const res = await fetchAllEmployeesById();
    if (res) setEmployees(res);
    setLoading(false);
  };

  const requestLeave = async (e) => {
    e.preventDefault();
    setRequestLoading(true);
    console.log(formData);
    const res = await apiService.post('Leave/Request', formData, true);
    if (res) navigate(MANAGE_ABSENCE);
    setRequestLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setEmployeesArray(getEmployeesOption(employees));
  }, [employees]);

  return (
    <div className='space-y-6'>
      <BackWithText goBack className={'text-pink-100'} />
      <div className='space-y-6'>
        <h4 className=''>Assign Leave</h4>
        <form onSubmit={requestLeave} className='space-y-12'>
          <div className='w-full grid md:grid-cols-2 gap-8 items-end'>
            <div className='flex flex-col gap-2'>
              <h6 className='text-xs'>Select Employee</h6>
              <Select
                selectedOption={formData.employeeId}
                options={employeesArray}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    employeeId: e?.value
                  })
                }
              />
            </div>
            <div className='flex flex-col gap-2'>
              <h6 className='text-xs'>Relief Staff</h6>
              <Select
                selectedOption={formData.reliefEmployeeId}
                options={employeesArray}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    reliefEmployeeId: e?.value
                  })
                }
              />
            </div>
          </div>
          <div className='w-full grid md:grid-cols-3 gap-8 items-end'>
            <Input
              withLabel
              onChange={handleChange}
              value={formData.startDate}
              label='Start Date'
              className=''
              containerClass=''
              name={'startDate'}
              type='date'
            />
            <Input
              withLabel
              onChange={handleChange}
              value={formData.endDate}
              label='End Date'
              className=''
              containerClass=''
              name={'endDate'}
              type='date'
            />
            <div className='flex flex-col gap-2'>
              <h6 className='text-xs'>Leave Type</h6>
              <Select
                selectedOption={formData.leaveType}
                className=''
                options={LEAVE_TYPE_OPTIONS}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    leaveType: e?.value
                  })
                }
              />
            </div>
          </div>
          <TextArea
            withLabel
            onChange={handleChange}
            value={formData.description}
            label='Description'
            className=''
            containerClass=''
            name={'description'}
          />

          <div className='mt-6 flex justify-end'>
            <Button
              text={'Assign Leave'}
              disabled={requestLoading}
              loading={requestLoading}
              isPurple
              type='submit'
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AssignLeave;
