import React, {useState} from 'react';
import moment from 'moment';
import Input from '../../../../components/input';
import Select from '../../../../components/input/Select';
import { ReactComponent as UserIcon } from '../../../../assets/img/user.svg';
import { ReactComponent as MailIcon } from '../../../../assets/img/mail.svg';
import { GENDER } from '../../../../helpers/constant';

const PersonalInfoForm = ({ employee, handleChange, formData, setFormData }) => {
  const [type, setType] = useState('text');

  return (
    <div className=' space-y-10'>
      <h4 className='text-[#404040] uppercase text-sm'>PERSONAL INFORMATION</h4>
      <div className=' space-y-12'>
        <div className=' w-full grid md:grid-cols-3 gap-8 items-end'>
          <Input
            withLabel
            onChange={handleChange}
            placeholder={employee.firstName}
            label='First Name'
            containerClass=' '
            name={'firstName'}
            withIcon
            icon={<UserIcon />}
          />
          <Input
            withLabel
            onChange={handleChange}
            placeholder={employee.middleName}
            label='Middle Name'
            className=''
            containerClass=' w-full'
            name={'middleName'}
            withIcon
            icon={<UserIcon />}
          />
          <Input
            withLabel
            onChange={handleChange}
            placeholder={employee.lastName}
            label='Last Name'
            className=''
            containerClass=' w-full'
            name={'lastName'}
            withIcon
            icon={<UserIcon />}
          />
        </div>

        <div className=' grid md:grid-cols-3 gap-8 items-end '>
          <div className=' flex flex-col gap-2'>
            <h6 className=' text-xs'> Gender</h6>
            <Select
              selectedOption={employee.gender}
              className=''
              options={GENDER}
              onChange={(selectedOption) =>
                setFormData({
                  ...formData,
                  gender: selectedOption.value
                })
              }
            />
          </div>
          <Input
            withLabel
            onChange={handleChange}
            placeholder={employee.officeEmail}
            label='email'
            className=''
            containerClass=''
            name={'email'}
            withIcon
            icon={<MailIcon />}
          />
          <Input
            onClick={() => setType('date')}
            withLabel
            onChange={handleChange}
            on
            value={moment(employee.dob).format('DD/MM/YYYY')}
            label='DOB'
            className=''
            containerClass=''
            name={'dob'}
            type={type}
          />
        </div>

        <div className='w-full grid md:grid-cols-3 gap-8 items-end'>
          <Input
            withLabel
            onChange={handleChange}
            placeholder={employee.homeAddress}
            label='Home Address'
            className=''
            containerClass='md:col-span-2'
            name={'homeAddress'}
          />
          <Input
            withLabel
            onChange={handleChange}
            placeholder={employee.phoneNumber}
            label='Phone Number'
            className=''
            containerClass=''
            name={'phoneNumber'}
            type='number'
          />
        </div>

        <div className='w-full grid md:grid-cols-3 gap-8 items-end'>
          <Input
            withLabel
            onChange={handleChange}
            placeholder={employee.otherAddress}
            label='Alternate Address'
            className=''
            containerClass='md:col-span-2'
            name={'otherAddress'}
          />
          <Input
            withLabel
            onChange={handleChange}
            placeholder={employee.alternateNumber}
            label='Alternate Number'
            className=''
            containerClass=''
            name={'alternateNumber'}
            type='number'
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoForm;
