import React from 'react';
import { useNavigate } from 'react-router';
import BaseModal from '../../../../components/base/modal';
import Button from '../../../../components/button';
import { TRAINING_REVIEW, FEEDBACK_DETAILS } from '../../../../router/router';

const PreviousTrainingModal = ({ show, closeModal, eventId }) => {
  const navigate = useNavigate();

  return (
    <BaseModal title='Training details' show={show} onClose={closeModal}>
      <div className='w-full flex flex-wrap items-start justify-between'>
       <Button
          className='border border-pink-100 w-[46%]'
          text='Training Review'
          onClick={() => navigate(`${TRAINING_REVIEW}/${eventId}`)}
          isPink
        />
        <Button 
          text='Provide Feedback'
          className='w-[46%]'
          onClick={() => navigate(`${FEEDBACK_DETAILS}/${eventId}`)} 
          isPurple 
        />
      </div>
    </BaseModal>
  );
};

export default PreviousTrainingModal;
