import React, { useState, useEffect } from 'react';
import Input from '../../../../components/input';
import Select from '../../../../components/input/Select';
import { STATUS, POSITION_IN_COMPANY, JOB_TYPE } from '../../../../helpers/constant';
import moment from 'moment';
import { getEmployeeType } from '../../../../helpers/functions';
import { fetchAllEmployeesById } from '../../../../utils/apis/api-modules/employee';

const EmploymentInfoForm = ({ employee, employeesArray, employeeGroupArray, employeeGradeArray, handleChange, formData, setFormData }) => {
  const [type, setType] = useState('text');
  /*const [employees, setEmployees] = useState([]);

  const fetchEmployees = async () => {
    setLoading(true);
    const res = await fetchAllEmployeesById();
    if (res) setEmployees(res);
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployees();
  }, []);*/

  return (
    <div className=' space-y-10'>
      <h4 className='text-[#404040] uppercase text-sm'>Employment Information</h4>
      <div className=' space-y-12'>
        <div className=' w-full flex items-end justify-between flex-wrap gap-6'>
          <Input
            onClick={() => setType('date')}
            withLabel
            onChange={handleChange}
            on
            value={moment(employee?.startDate).format('DD/MM/YYYY')}
            label='start date'
            containerClass=' w-full md:w-[45%]'
            name={'startDate'}
            type={type}
          />
          <Input
            withLabel
            onChange={handleChange}
            value={employee?.id}
            label='employee id'
            className=''
            containerClass=' w-full md:w-[45%]'
            name={'employeeId'}
          />
        </div>

        <div className=' w-full flex items-end justify-between flex-wrap gap-6'>
          <Input
            withLabel
            onChange={handleChange}
            value={employee?.department?.name}
            label='Department'
            className=''
            containerClass=' w-full md:w-[45%]'
            name={'departmentName'}
          />

          <div className=' flex flex-col gap-2 w-full md:w-[45%]'>
            <h6 className=' text-xs'> Employment Type</h6>
            <Select
              selectedOption={/*{
                label: getEmployeeType(*/employee?.employeeType/*)
              }*/}
              className=' w-full'
              options={JOB_TYPE}
              handleChange={(e) =>
                setFormData({
                  ...formData,
                  employeeType: e?.value
                })
              }
            />
          </div>
        </div>

        <div className=' w-full flex items-end justify-between flex-wrap gap-6'>
          <div className=' flex flex-col gap-2 w-full md:w-[45%]'>
            <Input
              withLabel
              onChange={handleChange}
              value={employee?.jobRole}
              label='Role Title'
              className=''
              containerClass=' w-full'
              name={'jobRole'}
            />
          </div>
          <div className=' flex flex-col gap-2 w-full md:w-[45%]'>
            <h6 className=' text-xs'> Employment Status</h6>
            <Select
              selectedOption={employee?.status}
              className=' w-full'
              options={STATUS}
              handleChange={(selectedOption) =>
                setFormData({
                  ...formData,
                  status: selectedOption.value
                })
              }
            />
          </div>

          <div className=' w-full flex items-end justify-between flex-wrap gap-6'>
            <Select
              placeholder={'Select employee group'}
              className=' w-full'
              options={employeeGroupArray}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  gradeGroup: e?.label
                })
              }
            />

           <Select
              placeholder={'Select employee grade'}
              className=' w-full'
              options={employeeGradeArray}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  grade: e?.label
                })
              }
            />
          </div>
        </div>

        <div className=' w-full flex items-end justify-between flex-wrap gap-6'>
         <Input
            withLabel
            onChange={handleChange}
            value={employee?.bankName}
            label='Bank Name'
            className=''
            containerClass=' w-full md:w-[45%]'
            name={'bankName'}
          />

          <Input
            withLabel
            onChange={handleChange}
            value={employee?.accountNumber}
            label='Account Number'
            className=''
            containerClass=' w-full md:w-[45%]'
            name={'accountNumber'}
          />
        </div>
        <div className=' flex flex-col gap-2'>
        <h6 className=' text-xs'> Supervisor </h6>
        <Select
          selectedOption={employee.supervisorName}
          options={employeesArray}
          handleChange={(selectedOption) =>
            setFormData({
              ...formData,
              supervisorName: selectedOption.label || ''
            })
          }
        />
      </div>
      </div>
    </div>
  );
};

export default EmploymentInfoForm;
