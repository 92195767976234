import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../components/button';
import { FiEdit } from 'react-icons/fi';
import { APPLICANTS } from '../../../../router/router';
import { apiService } from '../../../../helpers/api';
import { API } from '../../../../utils/apis';
import { getEmployeeType, getJobStatus, getJobType } from '../../../../helpers/functions';
import Loader from '../../../../components/base/loader';
import MarkdownPreview from '@uiw/react-markdown-preview';
import './styles.scss';
import EditVacancy from '../add-vacancy/EditVacancy';

const VacancyDetails = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [vacancyDetails, setVacancyDetails] = useState({});
  const [departments, setDepartments] = useState([]);
  const [departmentName, setDepartmentName] = useState('');
  const [departmentsOption, setDepartmentOption] = useState([]);
  const [editVacancyDetails, setEditVacancyDetails] = useState(false);
  const [showEditVacancyDetails, setShowEditVacancyDetails] = useState(false);
  const { id } = useParams();
  
  // Fetch Vacancy Details
  const fetchVacancyDetails = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        `JobVacancy/FetchAllJobVacancyByCompanyIdAndVacancyId`,
        { vacancyId: id },
        true
      );
      const response = res?.data?.data?.data;
      setVacancyDetails(response);
      fetchDepartmentName(response?.department); // Fetch department name
    } catch (err) {
      console.log('Error in fetching vacancy details: ', err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch Department Name using the departmentId
  const fetchDepartmentName = async (department) => {
    try {
      const res = await apiService.get(
        `Department/FetchDepartmentByCompanyIdAndDepartmentId/${department}`, // Replace with correct API endpoint
        {},
        true
      );
      const departmentName = res?.data?.data?.data?.name;
      setDepartmentName(departmentName); // Assuming the department name is in res.data.data.name
    } catch (err) {
      console.log('Error fetching department name:', err);
    }
  };

  useEffect(() => {
    fetchVacancyDetails();
  }, []);

  const jobType = getJobType(vacancyDetails?.jobType)
    ? getEmployeeType(vacancyDetails?.jobType)
    : vacancyDetails?.jobType;

  const jobStatus = getJobStatus(vacancyDetails?.jobStatus);

  const loadData = async () => {
    const departmentRes = await API.fetchAllDepartment();
    if (departmentRes) setDepartments(departmentRes);
  };

  const getDepartments = (department) => {
    let DEPARTMENT_ARRAY = [];
    for (let i = 0; i < department.length; i++) {
      DEPARTMENT_ARRAY.push({ label: department[i]?.name, value: department[i]?.id });
    }
    setDepartmentOption(DEPARTMENT_ARRAY);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    getDepartments(departments);
  }, [departments]);

  if (showEditVacancyDetails) {
    return <EditVacancy editVacancyDetails={editVacancyDetails} departments={departmentsOption} />;
  }

  return (
    <>
      {loading ? (
        <Loader size={24} className='text-black-100' />
      ) : (
        <div className='rounded-[20px] bg-white-100 p-5 md:p-10 md:pt-8 my-6 space-y-6 lg:pb-36 '>
          <div className='flex gap-3 justify-center mdLg:justify-between flex-wrap'>
            <div className='flex flex-wrap justify-between'>
              <div className='flex flex-col gap-3'>
                <h4 className=' mdLg:text-left text-center'>VC-{vacancyDetails?.vacancyId}</h4>
                <div className='flex gap-2 flex-wrap'>
                  <h4 className='font-semibold text-lg'>{vacancyDetails?.jobTitle}</h4>
                  <span className='bg-yellow-100 text-white-100 px-4 text-xs flex justify-center items-center py-[2px] rounded-[20px] capitalize'>
                    {departmentName || vacancyDetails?.department}
                  </span>
                </div>
              </div>

              <div className='flex flex-wrap gap-2 justify-center'>
                <Button
                  className='flex items-center space-x-2  '
                  withIcon
                  icon={<FiEdit size={16} />}
                  text='EDIT DETAILS'
                  onClick={()=> {
                    setShowEditVacancyDetails(true);
                    setEditVacancyDetails(vacancyDetails);
                  }}
                />

                <Button
                  className='flex items-center '
                  text='VIEW APPLICANTS'
                  isPurple
                  onClick={() => navigate(APPLICANTS)}
                />
                {showEditVacancyDetails && 
                 <EditVacancy 
                   editVacancyDetails={editVacancyDetails} 
                   departments={departmentsOption} 
                  />
                }
              </div>
            </div>
          </div>

          <div className='space-y-4'>
            <h6 className=' mdLg:text-left text-center'>VACANCY DESCRIPTION</h6>

            <div className='flex flex-wrap gap-x-12 gap-3 justify-center  w-full mdLg:w-[50%] mdLg:justify-between'>
              <div className='space-y-1 flex flex-col'>
                <span className='text-gray-100 font-semibold text-xs '>Job type</span>
                <span className='capitalize'>{jobType}</span>
              </div>
              <div className='space-y-1 flex flex-col'>
                <span className='text-gray-100 font-semibold text-xs capitalize'>Status</span>
                <span>{jobStatus}</span>
              </div>
            </div>

            <div className='flex flex-wrap gap-x-12 gap-3 justify-center text-center mdLg:text-left mdLg:justify-start'>
              {vacancyDetails?.jobDescription && (
                <div className='space-y-2'>
                  <span className='text-gray-100 font-semibold text-xs '>Job Description</span>

                  <MarkdownPreview
                    source={vacancyDetails?.jobDescription}
                    className='markdown-preview'
                  />
                </div>
              )}

              {vacancyDetails?.jobRequirement && (
                <div className='space-y-2'>
                  <span className='text-gray-100 font-semibold text-xs '>Job Requirement</span>

                  <MarkdownPreview
                    source={vacancyDetails?.jobRequirement}
                    className='markdown-preview'
                  />
                </div>
              )}

              <div className='flex flex-col space-y-4'>
                {vacancyDetails?.benefitsAndCompensation && (
                  <div className='space-y-2'>
                    <span className='text-gray-100 font-semibold text-xs '>
                      Benefits & Compensation
                    </span>

                    <ul className='text-sm text-gray-30 font-semibold flex flex-col space-y-2'>
                      <li>{vacancyDetails?.benefitsAndCompensation.toLocaleString()}</li>
                    </ul>
                  </div>
                )}

              {vacancyDetails?.keyResponsibility && (
                  <div className='space-y-2'>
                    <span className='text-gray-100 font-semibold text-xs '>
                      Key Responsibilities
                    </span>

                    <ul className='text-sm text-gray-30 font-semibold flex flex-col space-y-2'>
                      <li>{vacancyDetails?.keyResponsibility}</li>
                    </ul>
                  </div>
                )}

              {vacancyDetails?.keyRequirement && (
                  <div className='space-y-2'>
                    <span className='text-gray-100 font-semibold text-xs '>
                      Key Requirements
                    </span>

                    <ul className='text-sm text-gray-30 font-semibold flex flex-col space-y-2'>
                      <li>{vacancyDetails?.keyRequirement}</li>
                    </ul>
                  </div>
                )}

                {vacancyDetails?.skillRequirement && (
                  <div className='space-y-2'>
                    <span className='text-gray-100 font-semibold text-xs '>
                      Skill Requirements
                    </span>

                    <ul className='text-sm text-gray-30 font-semibold flex flex-col space-y-2'>
                      <li>{vacancyDetails?.skillRequirement}</li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VacancyDetails;
