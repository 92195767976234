import React, { useState } from 'react';
import BaseModal from '../../../../components/base/modal';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import Select from '../../../../components/input/Select';
import { COMPETENCY_TYPE } from '../../../../helpers/constant';
import { API } from '../../../../utils/apis';

const AddCompetencyModal = ({ show, closeModal }) => {
  const [modalData, setModalData] = useState({
    Name: '',
    Description: '',
    category: 1,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setModalData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  const submitData = async (e) => {
    e.preventDefault(); // Prevent the form from submitting
    setLoading(true);
  
    // Destructure modalData to pass individual properties
    const { Name, Description, category } = modalData;
  
    try {
      // Call the API with destructured parameters
      console.log(modalData);
      await API.addCompetency(Name, Description, category);
    } finally {
      setLoading(false);
    }
    window.location.reload();
  };
  

  return (
    <BaseModal title={'Add Competency'} show={show} onClose={closeModal}>
      <div className='space-y-10 rounded-lg w-full sm:w-[400px] overflow-hidden'>
        <div className='flex flex-col gap-2 w-full'>
          <Select
            withLabel
            selectedOption={modalData.category}
            options={COMPETENCY_TYPE}
            label='Competency Type*'
            containerClass='w-full sm:w-auto'
            name='category'
            onChange={(e) => handleChange('category', e?.value)}
          />
        </div>
    
        <Input
          withLabel
          value={modalData.Name}
          label='Competency Title'
          containerClass='w-full sm:w-auto'
          name='Name'
          onChange={(e) => handleChange('Name', e.target.value)}
        />

        <Input
          withLabel
          value={modalData.Description}
          label='Description'
          containerClass='w-full sm:w-auto'
          name='Description'
          onChange={(e) => handleChange('Description', e.target.value)}
        />

        <div className='flex gap-4 flex-wrap justify-end'>
          <Button
            className='border border-pink-100'
            text='Cancel'
            onClick={closeModal}
            isTransparent
          />
          <Button
            text='Submit'
            type='submit'
            onClick={submitData}
            isPurple
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default AddCompetencyModal;
