import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import CheckBox from '../../../../../components/input/CheckBox';
import Select from '../../../../../components/input/Select';

const CreateJobSecondPage = ({ step, setStep }) => {
  let shortlist = [
    {
      field: 'Educational Qualification',
      details: [
        {
          question: '',
          response: '',
          qualification: false,
          option: [
            { label: 'ond', value: 'ond' },
            { label: 'bsc', value: 'bsc' }
          ],
          weight: ''
        }
      ]
    }
  ];
  const [shortlistQuestions, setShortlistQuestions] = useState(shortlist);

  const [suggestedField, setSuggestedField] = useState([
    {
      field: 'Relevant Experience',
      details: [
        {
          question: '',
          response: '',
          qualification: false,
          option: [
            { label: 'wfh', value: 'ond' },
            { label: 'onsite', value: 'bsc' }
          ],
          weight: ''
        }
      ]
    },
    {
      field: 'Language',
      details: [
        {
          question: '',
          response: '',
          weight: '',
          qualification: false,
          option: [
            { label: 'english', value: 'ond' },
            { label: 'igbo', value: 'bsc' }
          ]
        }
      ]
    },
    {
      field: 'Professional Qualification',
      details: [
        {
          question: '',
          response: '',
          qualification: false,
          option: [
            { label: '0-1', value: 'ond' },
            { label: '1-5', value: 'bsc' }
          ]
        },
        {
          question: '',
          response: '',
          qualification: false,
          option: [
            { label: '0-1', value: 'ond' },
            { label: '1-5', value: 'bsc' }
          ]
        }
      ]
    }
  ]);

  const emptyForm = {
    question: '',
    response: ''
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [fields, setFields] = useState([]);
  // const [formData, setFormData] = useState(emptyForm);
  // const [questions, setQuestions] = useState(null);
  // const [currentField, setCurrentField] = useState();
  const [current, setCurrent] = useState(
    shortlistQuestions.map((item) => item?.details.map((itm) => itm))
  );
  const [valueT, setValue] = useState('');

  console.log('selectedOption', valueT, current, selectedOption);

  const addQuestions = (item, index) => {
    setCurrent((prev) => [
      ...prev,
      { question: '', response: '', qualification: false, option: [] }
    ]);
    // let list = [...shortlistQuestions];
    let particularList = shortlistQuestions[index];
    particularList.details.push({
      question: '',
      response: '',
      qualification: false,
      option: []
    });
    // setShortlistQuestions((prev) => [...prev]);
  };

  // const addField = () => {
  //   // setShortlistQuestions((prev) => [
  //   // 	...prev,

  //   // {
  //   // 	details: [],
  //   // 	field: "",
  //   // 	question: "",
  //   // 	response: "",
  //   // 	qualification: false,
  //   // 	option: [],
  //   // },
  //   // ]);
  //   setShortlistQuestions([
  //     ...shortlistQuestions,
  //     {
  //       details: [{ question: '', response: '', qualification: false, option: [] }],
  //       field: ''
  //     }
  //   ]);
  // };

  // get options for select

  const getSelectOptions = (item = suggestedField) => {
    let selectOptions = [];
    let i = 0;
    for (i = 0; i < item.length; i++) {
      selectOptions.push({
        value: item[i].field,
        label: item[i].field,
        ...suggestedField[i]
      });
    }
    setFields(selectOptions);
  };

  const addSuggestedField = (item) => {
    // let newShorlistArray = [...shortlistQuestions, item];
    let newSuggestedField = suggestedField.filter((itm) => itm !== item);
    setSuggestedField(newSuggestedField);
    setShortlistQuestions((prev) => [...prev, item]);
  };

  useEffect(() => {
    getSelectOptions();
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setValue({ [name]: value });
  }
  return (
    <>
      {step === 1 && (
        <div className='space-y-10 mt-6 px-2 pb-10 w-full'>
          <div className='flex justify-between flex-wrap items-center'>
            <h4 className='text-pink-100'> How did you hear about us?</h4>
            <h4 className='text-black-100'>2 Of 2: Job Details</h4>
          </div>

          <div className='flex gap-10 flex-wrap items-center'>
            <p className='text-pink-100 font-semibold'> Add Shortlisting Questions</p>
            {/*<Button
							onClick={addField}
							text={"+ Add New Job Category"}
							className="cursor-pointer w-fit p-1 px-2 border-2 bg-white-100 border-purple-60 rounded-[3px] font-medium text-black-100"
			/>*/}
            <Select
              className='w-full md:w-[240px]'
              options={fields}
              onChange={(e) => addSuggestedField(e)}
            />
          </div>
          {/*	<div className="flex gap-10 flex-wrap items-center">
						<p className="text-pink-100 font-semibold">
							{" "}
							Suggested Job Categories
						</p>

						{suggestedField.map((item) => (
							<Button
								onClick={() => addSuggestedField(item)}
								text={item?.field}
								className="cursor-pointer w-fit p-1 px-2 border-2 bg-white-100 border-purple-60 rounded-[3px] font-medium text-black-100"
							/>
						))}
						</div>*/}

          {shortlistQuestions?.map((item, idx) => {
            return (
              <div
                className='rounded-lg border border-gray-20'
                key={idx}
                onClick={() => {
                  setSelectedOption(item);
                }}>
                <div className='flex justify-between flex-wrap items-center bg-pink-40 p-2 rounded-[3px]'>
                  <p className='text-gray-100 font-semibold'>
                    {item?.field}
                    <span className=' text-pink-100'> *</span>
                  </p>
                  {shortlistQuestions.length > 1 && (
                    <h4
                      className='text-gray-100 text-base sm:text-lg cursor-pointer hover:text-red-500'
                      onClick={() => {
                        let newShortlist = shortlistQuestions.filter((itm, i) => idx !== i);

                        setShortlistQuestions(newShortlist);
                      }}>
                      x
                    </h4>
                  )}
                </div>

                {item?.details.map((itm, index) => {
                  return (
                    <div className='p-4 pb-8 space-y-4' key={index}>
                      {' '}
                      <div className='flex flex-wrap gap-4 items-center  text-gray-200 '>
                        <Input
                          onChange={handleChange}
                          className='pl-[10px]'
                          // value={itm?.question}
                          name='questions'
                          height='h-10'
                          placeholder={`Question: ${item?.field}`}
                        />

                        <Select options={itm?.option} className='w-full md:w-[240px]' />
                        <Input
                          onChange={handleChange}
                          className='pl-[10px] justify-self-end'
                          // value={itm?.weight}
                          height='h-10'
                          placeholder={`Weight score`}
                          name='weight'
                        />
                        <CheckBox
                          title={'Must have qualification'}
                          className='text-gray-100 font-semibold text-sm'
                          checked={item?.details?.qualification}
                        />
                      </div>
                      {item?.details.length - 1 === index && (
                        <>
                          <Input
                            onChange={handleChange}
                            className='pl-[10px] w-auto mt-4'
                            // value={itm?.weight}
                            height='h-10'
                            placeholder={`Total Weight score`}
                            name='total_weight'
                          />
                          <div
                            className='flex gap-2 items-center text-gray-200 font-semibold cursor-pointer'
                            onClick={() => {
                              addQuestions(item, idx, itm);
                            }}>
                            <p className='rounded-full border-[2px] border-gray-200  w-6 h-6 flex justify-center items-center '>
                              +
                            </p>
                            <p>Add New Questions</p>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}

          <div className='ml-auto flex justify-start'>
            <Button
              isPurple
              text='Prev'
              className={' rounded-4xl '}
              onClick={() => setStep(step - 1)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CreateJobSecondPage;
