import React, { Fragment, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { apiService } from '../../../helpers/api';
import EmployeeContent from './EmployeeContent';
import EmployeesHeader from './EmployeesHeader';
import { fetchAllEmployeesById, fetchEmployeeByCompanyIdAndEmployeeId, fetchEmployeeProfileDetail } from '../../../utils/apis/api-modules/employee';

const EmployeesLayout = () => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState({
    employeeName: '',
    employeeId: '',
    employeeEmail: '',
    department: '',
    role: '',
    gender: ''
  });
  const [error, setError] = useState('');
  const [employees, setEmployees] = useState([]);
  const [employeesSearch, setEmployeesSearch] = useState([]);
  const [employeesProfile, setEmployeesProfile] = useState('');
  const [employeesEmail, setEmployeesEmail] = useState('');
  

  const fetchEmployees = async () => {
    setLoading(true);
    const res = await fetchAllEmployeesById();
    if (res) setEmployees(res);
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const getEmployeeById = async (employeeId) => {
    setLoading(true);
    try {
      //const res = await fetchEmployeeProfileDetail(employeeId);
      const res = await apiService.get(
        `Employee/FetchEmployeeByCompanyIdAndEmployeeId/${employeeId}`,
        {
          //employeeId: employeeId
        },
        true
      );
      const response = res?.data?.data?.data;
      console.log('Employee data: ', response);
      if (response) setEmployeesProfile(response);
    } catch (err) {
      console.log('Error in fetching employee by ID: ', err);
    }
    setLoading(false);
  };

  const getEmployeeByEmail = async (email) => {
    setLoading(true);
    try {
      //const res = await fetchEmployeeProfileDetail(employeeId);
      const res = await apiService.get(
        `Employee/FetchEmployeeByCompanyIdAndEmail/${email}`,
        {
          //employeeId: employeeId
        },
        true
      );
      const response = res?.data?.data?.data;
      console.log('Employee email: ', response);
      if (response) setEmployeesEmail(response);
    } catch (err) {
      console.log('Error in fetching employee by email: ', err);
    }
    setLoading(false);
  };

  const getEmployeesBySearch = async (val) => {
    setLoading(true);
    try {
      const res = await apiService.get(
        'Employee/Search',
        {
          SearchParam: val
        },
        true
      );
      const response = res?.data?.data;
      console.log(response);
      if (response) setEmployeesSearch(response);
    } catch (err) {
      console.log('Error in fetching search results: ', err);
    }
    setLoading(false);
  };

  const debouncedSearch = useRef(debounce((val, type) => {
    if (type === 'employeeId') {
      getEmployeeById(val);
    } else if (type === 'employeeEmail') {
      getEmployeeByEmail(val);
    } else {
      getEmployeesBySearch(val);
    }
  }, 500)).current;

  const handleSearchChange = async (e) => {
    setSearchValue(e.target.value);
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  
    if (e.target.name === 'employeeId') {
      if (e.target.value.length >= 2) {
        await debouncedSearch(e.target.value, 'employeeId');
        setError('');
      } else {
        setError('Enter two characters or more to search');
        setEmployeesProfile([]);
      }
    } else if (e.target.name === 'employeeName' && e.target.value.length >= 2) {
      await debouncedSearch(e.target.value, 'employeeName');
      setError('');
    } else if (e.target.name === 'employeeEmail' && e.target.value.length >= 2) {
      await debouncedSearch(e.target.value, 'employeeEmail');
      setError('');
    } else {
      setError('Enter two characters or more to search');
      setEmployeesSearch([]);
    }
  };

  useEffect(() => {
    if (!searchValue) setError('');
  }, [searchValue]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <Fragment>
      <div className='bg-white-100 rounded-sm py-5 px-4 sm:px-12 space-y-10'>
        <EmployeesHeader
          handleSearchChange={handleSearchChange}
          error={error}
          searchData={searchData}
          setSearchData={setSearchData}
        />
        <EmployeeContent
          loading={loading}
          employees={employees}
          employeesSearch={employeesSearch}
          employeesProfile={employeesProfile}
          employeesEmail={employeesEmail}
          searchValue={searchValue}
        />
      </div>
    </Fragment>
  );
};

export default EmployeesLayout;
