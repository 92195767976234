import React from 'react';
import { useState } from 'react';
// import { IoBriefcaseOutline } from 'react-icons/io5';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
const ApplicationFormLeft = ({ onClick }) => {
  const [educationalBackground, setEducationalBackground] = useState([
    {
      institution: '',
      course: '',
      degree: '',
      start_date: '',
      end_date: '',
      class: '',
      certificate: ''
    }
  ]);
  const [workExperience, setWorkExperience] = useState([
    {
      institution: '',
      course: '',
      degree: '',
      start_date: '',
      end_date: '',
      class: '',
      certificate: '',
      leadership: '',
      generation: ''
    }
  ]);

  const addEducationalBackground = () => {
    setEducationalBackground([
      ...educationalBackground,
      {
        institution: '',
        course: '',
        degree: '',
        start_date: '',
        end_date: '',
        class: '',
        certificate: ''
      }
    ]);
  };

  const addWorkExperience = () => {
    setWorkExperience([
      ...workExperience,
      {
        institution: '',
        course: '',
        degree: '',
        start_date: '',
        end_date: '',
        class: '',
        certificate: '',
        leadership: '',
        generation: '',
        fund: '',
        diligence: '',
        language: ''
      }
    ]);
  };
  return (
    <div className=' space-y-4 p-8 bg-white-100'>
      <form className='space-y-10'>
        <div className='flex flex-wrap gap-4 justify-between'>
          <div className='flex flex-col w-full md:w-[45%] gap-3'>
            <Input
              withLabel
              label={'Resume'}
              required
              isHorizontal
              normalLabel
              height='h-9'
              labelClassName='w-[40%]'
            />
            <Input
              withLabel
              label={'First Name'}
              required
              isHorizontal
              normalLabel
              height='h-9'
              labelClassName='w-[40%]'
            />

            <Input
              withLabel
              label={'Email'}
              required
              isHorizontal
              normalLabel
              height='h-9'
              type='email'
              labelClassName='w-[40%]'
            />

            <Input
              withLabel
              label={'Username'}
              required
              isHorizontal
              normalLabel
              height='h-9'
              labelClassName='w-[40%]'
            />

            <Input
              withLabel
              label={' Years of Experience'}
              required
              isHorizontal
              normalLabel
              height='h-9'
              labelClassName='w-[40%]'
            />
          </div>
          <div className='flex flex-col w-full md:w-[45%] gap-3'>
            <Input
              withLabel
              label={'How did you hear of this vacancy? '}
              required
              isHorizontal
              normalLabel
              height='h-9'
              labelClassName='w-[40%]'
            />
            <Input
              withLabel
              label={'Last Name'}
              required
              isHorizontal
              normalLabel
              height='h-9'
              labelClassName='w-[40%]'
            />

            <Input
              withLabel
              label={'Phone Number'}
              required
              isHorizontal
              normalLabel
              height='h-9'
              type='email'
              labelClassName='w-[40%]'
            />

            <Input
              withLabel
              label={'Create a Password'}
              required
              isHorizontal
              normalLabel
              height='h-9'
              labelClassName='w-[40%]'
            />

            <Input
              withLabel
              label={'Current Company'}
              isHorizontal
              normalLabel
              height='h-9'
              labelClassName='w-[40%]'
            />
          </div>
        </div>

        <div className='space-y-6'>
          <h4 className='text-pink-100'> Educational Background</h4>

          {educationalBackground?.map((item, i) => {
            return (
              <div key={i} className='flex flex-wrap gap-4 justify-between'>
                <div className='flex flex-col w-full md:w-[45%] gap-3'>
                  <Input
                    withLabel
                    label={'Resume'}
                    required
                    isHorizontal
                    normalLabel
                    height='h-9'
                    labelClassName='w-[40%]'
                    value={item?.institution}
                  />
                  <Input
                    withLabel
                    label={'First Name'}
                    required
                    isHorizontal
                    normalLabel
                    height='h-9'
                    labelClassName='w-[40%]'
                    value={item?.degree}
                  />

                  <div className='flex w-full items-center flex-wrap gap-4'>
                    <Input
                      className={'w-[]'}
                      withLabel
                      label={'Period Of Study'}
                      required
                      isHorizontal
                      normalLabel
                      height='h-9'
                      type='date'
                      labelClassName='w-[40%]'
                      value={item?.start_date}
                    />
                    <p>__</p>
                    <Input
                      className={'w-[]'}
                      isHorizontal
                      normalLabel
                      height='h-9'
                      type='date'
                      value={item?.end_date}
                    />
                  </div>
                </div>
                <div className='flex flex-col w-full md:w-[45%] gap-3'>
                  <Input
                    withLabel
                    label={'How did you hear of this vacancy? '}
                    required
                    isHorizontal
                    normalLabel
                    height='h-9'
                    labelClassName='w-[40%]'
                    value={item?.course}
                  />
                  <Input
                    withLabel
                    label={'Class of Degree'}
                    required
                    isHorizontal
                    normalLabel
                    height='h-9'
                    labelClassName='w-[40%]'
                    value={item?.class}
                  />

                  <Input
                    withLabel
                    label={'Professional Certificate '}
                    required
                    isHorizontal
                    normalLabel
                    height='h-9'
                    type='email'
                    labelClassName='w-[40%]'
                    value={item?.certificate}
                  />
                </div>
              </div>
            );
          })}

          <Button
            className={' text-pink-100 flex items-center justify-center mx-auto mt-1'}
            text='+ Add Education'
            isTransparent
            onClick={addEducationalBackground}
          />
        </div>

        <div className='space-y-6'>
          <h4 className='text-pink-100'> Private Equity Experience</h4>

          {workExperience?.map((item, i) => {
            return (
              <div key={i} className='flex flex-wrap gap-4 justify-between'>
                <div className='flex flex-col w-full md:w-[45%] gap-3'>
                  <Input
                    withLabel
                    label={'Company Name'}
                    required
                    isHorizontal
                    normalLabel
                    height='h-9'
                    labelClassName='w-[40%]'
                    value={item?.leadership}
                  />
                  <div className='flex w-full items-center flex-wrap gap-4'>
                    <Input
                      className={'w-[]'}
                      withLabel
                      label={'Period Of Work'}
                      required
                      isHorizontal
                      normalLabel
                      height='h-9'
                      type='date'
                      labelClassName='w-[40%]'
                      value={item?.start_date}
                    />
                    <p>__</p>
                    <Input
                      className={'w-[]'}
                      isHorizontal
                      normalLabel
                      height='h-9'
                      type='date'
                      value={item?.end_date}
                    />
                  </div>
                </div>
                <div className='flex flex-col w-full md:w-[45%] gap-3'>
                  <Input
                    withLabel
                    label={'Position held  '}
                    required
                    isHorizontal
                    normalLabel
                    height='h-9'
                    labelClassName='w-[40%]'
                    value={item?.fund}
                  />
                  <Input
                    withLabel
                    label={'Duties Carried Out '}
                    required
                    isHorizontal
                    normalLabel
                    height='h-32'
                    labelClassName='w-[40%]'
                    value={item?.diligence}
                  />
                </div>
              </div>
            );
          })}

          <Button
            className={' text-pink-100 flex items-center justify-center mx-auto mt-1'}
            text='+ Add Education'
            isTransparent
            onClick={addWorkExperience}
          />
        </div>

        <div className='space-y-6'>
          <h4 className='text-pink-100'> Private Equity Experience</h4>

          <div className='flex flex-wrap gap-4 justify-between'>
            <div className='flex flex-col w-full md:w-[45%] gap-3'>
              <Input
                withLabel
                label={'Years of Leadership Experience'}
                required
                isHorizontal
                normalLabel
                height='h-9'
                labelClassName='w-[40%]'
              />
              <Input
                withLabel
                label={'Deal Generation'}
                required
                isHorizontal
                normalLabel
                height='h-9'
                labelClassName='w-[40%]'
              />
              <Input
                withLabel
                label={'Deal Screening & Investment Qualification'}
                required
                isHorizontal
                normalLabel
                height='h-9'
                labelClassName='w-[40%]'
              />
              <Input
                withLabel
                label={'Asset Management 	& Reporting'}
                required
                isHorizontal
                normalLabel
                height='h-9'
                labelClassName='w-[40%]'
              />
              <Input
                withLabel
                label={'Leadership & People `Management'}
                required
                isHorizontal
                normalLabel
                height='h-9'
                labelClassName='w-[40%]'
              />
            </div>
            <div className='flex flex-col w-full md:w-[45%] gap-3'>
              <Input
                withLabel
                label={'Strategic Planning'}
                required
                isHorizontal
                normalLabel
                height='h-9'
                labelClassName='w-[40%]'
              />
              <Input
                withLabel
                label={'Fund Raising'}
                required
                isHorizontal
                normalLabel
                height='h-9'
                labelClassName='w-[40%]'
              />
              <Input
                withLabel
                label={'Investment Exit Management'}
                required
                isHorizontal
                normalLabel
                height='h-9'
                labelClassName='w-[40%]'
              />

              <Input
                withLabel
                label={'Due Diligence, Structuring, Negotiation and Closing '}
                required
                isHorizontal
                normalLabel
                height='h-9'
                type='email'
                labelClassName='w-[40%]'
              />
              <Input
                withLabel
                label={'Start up PE Experience '}
                required
                isHorizontal
                normalLabel
                height='h-9'
                labelClassName='w-[40%]'
              />
              <Input
                withLabel
                label={'Language'}
                required
                isHorizontal
                normalLabel
                height='h-9'
                labelClassName='w-[40%]'
              />
            </div>
          </div>
        </div>

        <div className='ml-auto flex justify-end'>
          <Button isPink text='submit' className={' rounded-4xl '} onClick={onClick} />
        </div>
      </form>
    </div>
  );
};

export default ApplicationFormLeft;
