import React, { useState } from 'react';
import Button from '../../../../components/button';
import { EVENT_STATUS } from '../../../../helpers/constant';
import Announcements from '../announcements';
import AddEvent from '../events-modal/add-event';
import UpcomingAnnouncements from '../upcoming-announcements';
import UpcomingBirthdays from '../upcoming-birthdays';
import UpcomingMeetings from '../upcoming-meetings';
import UpcomingOutings from '../upcoming-outings';

const LeftEventsOverview = () => {
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [active, setActive] = useState(EVENT_STATUS.UPCOMING);

  function handleSetUpcoming() {
    setActive(EVENT_STATUS.UPCOMING);
  }

  function handleSetYear() {
    setActive(EVENT_STATUS.THIS_YEAR);
  }

  function handleSetMonth() {
    setActive(EVENT_STATUS.THIS_MONTH);
  }

  return (
    <div className='w-[65%] space-y-5'>
      <div className='flex justify-between gap-1'>
        <div className='font-semibold tracking-tight text-blue-100 uppercase text-2xs bg-orange-50 rounded-[22px] px-5 py-2 gap-6 flex flex-wrap items-center justify-center text-center md:w-[40%] lg:w-[45%] '>
          <span
            className={`${
              active === EVENT_STATUS.UPCOMING &&
              'text-white-100 bg-yellow-100 rounded-[12.63px] px-6 py-[11px] '
            } cursor-pointer  transition-all duration-700 text-center`}
            onClick={handleSetUpcoming}>
            {EVENT_STATUS.UPCOMING}
          </span>
          <span
            className={`${
              active === EVENT_STATUS.THIS_MONTH &&
              'text-white-100 bg-yellow-100 rounded-[12.63px] px-6 py-[11px] '
            } cursor-pointer transition-all duration-700 text-center`}
            onClick={handleSetMonth}>
            {EVENT_STATUS.THIS_MONTH}
          </span>
          <span
            className={`${
              active === EVENT_STATUS.THIS_YEAR &&
              ' text-white-100 bg-yellow-100 rounded-[12.63px] px-6 py-[11px] '
            } cursor-pointer transition-all duration-700 text-center`}
            onClick={handleSetYear}>
            {EVENT_STATUS.THIS_YEAR}
          </span>
        </div>
        <Button
          text={'+ Create An Event'}
          className='h-14 text-sm'
          isPink
          onClick={() => setShowAddEventModal(true)}
        />
      </div>
      <div className=' bg-blue-100 rounded-sm text-white-100 p-6 flex flex-wrap gap-3 justify-between  '>
        <div className=' space-y-3 w-full md:w-[60%]'>
          <UpcomingOutings />
          <UpcomingAnnouncements />
        </div>
        <UpcomingMeetings />
      </div>
      <div className='flex flex-wrap gap-3 justify-between'>
        <UpcomingBirthdays />
        <Announcements />
      </div>

      <AddEvent
        show={showAddEventModal}
        closeModal={() => setShowAddEventModal(!setShowAddEventModal)}
      />
    </div>
  );
};

export default LeftEventsOverview;
