const peopleRecruitInfo = [
  {
    role: 'Product Manager',
    company: 'Revohub Technologies',
    state: 'Lagos',
    status: ' Full Time',
    dept: 'Information Technology'
  }
];

const candidates = [
  {
    name: 'Adaezinne Sinminsola',
    role: 'Product Manager',
    grade: 'Second Class (upper division)',
    email: 'sinmin4you@gmail.com',
    phoneNumber: '08127382734',
    rating1: '2.4',
    rating2: '5.0',
    rating3: '0.0',
    rating4: '2.0',
    totalRating: '9.4',
    years_of_experiece: '3',
    institution: 'Federal University of Technology,Akure, Ondo state.',
    course: 'Mechanical Engineering',
    degree: 'Bachelor of Engineering (B.Eng)',
    year_of_grad: '2013',
    qualification: 'BTech',
    score: 'passed',
    work_experience: [
      {
        org: 'Anakle Limited',
        role: 'Product Manager',
        start_date: 'May 2020',
        end_date: 'Jun 2022',
        duties: [
          'Directed the activities of product development team',
          'Managed sprints for the development team'
        ]
      },
      {
        org: 'Trace Nigeria',
        role: 'Sales Manager',
        start_date: 'July 2020',
        end_date: 'August 2021',
        duties: [
          'Directed the activities of sales development team',
          'Managed sprints for the sales team'
        ]
      }
    ]
  },
  {
    name: 'Taye Jidebenson',
    role: 'Frontend Developer',
    grade: 'First Class',
    email: 'taye@gmail.com',
    phoneNumber: '09142367123',
    rating1: '3.6',
    rating2: '4.5',
    rating3: '0.8',
    rating4: '2.0',
    totalRating: '6.4',
    years_of_experiece: '1',
    institution: 'Federal University of Agriculture,Abeokuta.',
    course: 'Mechanical Engineering',
    degree: 'Bachelor of Science (B.Sc)',
    year_of_grad: '2018',
    qualification: 'BSC',
    score: 'failed',
    work_experience: [
      {
        org: 'Trace Nigeria',
        role: 'Sales Manager',
        start_date: 'July 2020',
        end_date: 'August 2021',
        duties: [
          'Directed the activities of sales development team',
          'Managed sprints for the sales team'
        ]
      }
    ]
  },
  {
    name: 'Adaezinne Sinminsola',
    role: 'Product Manager',
    grade: 'Second Class (upper division)',
    email: 'sinmin4you@gmail.com',
    phoneNumber: '08127382734',
    rating1: '2.4',
    rating2: '5.0',
    rating3: '0.0',
    rating4: '2.0',
    totalRating: '9.4',
    years_of_experiece: '3',
    institution: 'Federal University of Technology,Akure, Ondo state.',
    course: 'Mechanical Engineering',
    degree: 'Bachelor of Engineering (B.Eng)',
    year_of_grad: '2013',
    qualification: 'BTech',
    score: 'passed',
    work_experience: [
      {
        org: 'Anakle Limited',
        role: 'Product Manager',
        start_date: 'May 2020',
        end_date: 'Jun 2022',
        duties: [
          'Directed the activities of product development team',
          'Managed sprints for the development team'
        ]
      }
    ]
  },
  {
    name: 'Taye Jidebenson',
    role: 'Frontend Developer',
    grade: 'First Class',
    email: 'taye@gmail.com',
    phoneNumber: '09142367123',
    rating1: '3.6',
    rating2: '4.5',
    rating3: '0.8',
    rating4: '2.0',
    totalRating: '6.4',
    years_of_experiece: '1',
    institution: 'Federal University of Agriculture,Abeokuta.',
    course: 'Mechanical Engineering',
    degree: 'Bachelor of Science (B.Sc)',
    year_of_grad: '2018',
    qualification: 'BSC',
    score: 'failed',
    work_experience: [
      {
        org: 'Trace Nigeria',
        role: 'Sales Manager',
        start_date: 'July 2020',
        end_date: 'August 2021',
        duties: [
          'Directed the activities of sales development team',
          'Managed sprints for the sales team'
        ]
      }
    ]
  },
  {
    name: 'Adaezinne Sinminsola',
    role: 'Product Manager',
    grade: 'Second Class (upper division)',
    email: 'sinmin4you@gmail.com',
    phoneNumber: '08127382734',
    rating1: '2.4',
    rating2: '5.0',
    rating3: '0.0',
    rating4: '2.0',
    totalRating: '9.4',
    years_of_experiece: '3',
    institution: 'Federal University of Technology,Akure, Ondo state.',
    course: 'Mechanical Engineering',
    degree: 'Bachelor of Engineering (B.Eng)',
    year_of_grad: '2013',
    qualification: 'BTech',
    score: 'passed',
    work_experience: [
      {
        org: 'Anakle Limited',
        role: 'Product Manager',
        start_date: 'May 2020',
        end_date: 'Jun 2022',
        duties: [
          'Directed the activities of product development team',
          'Managed sprints for the development team'
        ]
      }
    ]
  },
  {
    name: 'Taye Jidebenson',
    role: 'Frontend Developer',
    grade: 'First Class',
    email: 'taye@gmail.com',
    phoneNumber: '09142367123',
    rating1: '3.6',
    rating2: '4.5',
    rating3: '0.8',
    rating4: '2.0',
    totalRating: '6.4',
    years_of_experiece: '1',
    institution: 'Federal University of Agriculture,Abeokuta.',
    course: 'Mechanical Engineering',
    degree: 'Bachelor of Science (B.Sc)',
    year_of_grad: '2018',
    qualification: 'BSC',
    score: 'failed',
    work_experience: [
      {
        org: 'Trace Nigeria',
        role: 'Sales Manager',
        start_date: 'July 2020',
        end_date: 'August 2021',
        duties: [
          'Directed the activities of sales development team',
          'Managed sprints for the sales team'
        ]
      }
    ]
  },
  {
    name: 'Adaezinne Sinminsola',
    role: 'Product Manager',
    grade: 'Second Class (upper division)',
    email: 'sinmin4you@gmail.com',
    phoneNumber: '08127382734',
    rating1: '2.4',
    rating2: '5.0',
    rating3: '0.0',
    rating4: '2.0',
    totalRating: '9.4',
    years_of_experiece: '3',
    institution: 'Federal University of Technology,Akure, Ondo state.',
    course: 'Mechanical Engineering',
    degree: 'Bachelor of Engineering (B.Eng)',
    year_of_grad: '2013',
    qualification: 'BTech',
    score: 'passed',
    work_experience: [
      {
        org: 'Anakle Limited',
        role: 'Product Manager',
        start_date: 'May 2020',
        end_date: 'Jun 2022',
        duties: [
          'Directed the activities of product development team',
          'Managed sprints for the development team'
        ]
      }
    ]
  },
  {
    name: 'Taye Jidebenson',
    role: 'Frontend Developer',
    grade: 'First Class',
    email: 'taye@gmail.com',
    phoneNumber: '09142367123',
    rating1: '3.6',
    rating2: '4.5',
    rating3: '0.8',
    rating4: '2.0',
    totalRating: '6.4',
    years_of_experiece: '1',
    institution: 'Federal University of Agriculture,Abeokuta.',
    course: 'Mechanical Engineering',
    degree: 'Bachelor of Science (B.Sc)',
    year_of_grad: '2018',
    qualification: 'BSC',
    score: 'failed',
    work_experience: [
      {
        org: 'Trace Nigeria',
        role: 'Sales Manager',
        start_date: 'July 2020',
        end_date: 'August 2021',
        duties: [
          'Directed the activities of sales development team',
          'Managed sprints for the sales team'
        ]
      }
    ]
  }
];

export { peopleRecruitInfo, candidates };
