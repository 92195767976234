import React from 'react';
import AdminCard from '../../../components/cards/AdminCard';

const LeftAuditHeader = () => {
  return (
    <div className="flex  gap-4 ">
      <AdminCard isActive name={'Charlie Torff'} />
      <AdminCard isActive name={'Rayna Philips'} />

      <AdminCard name={'Giana Vaccaro'} />
      <div className="bg-pink-30 w-full flex flex-col p-6 space-y-3 items-center justify-center">
        <div className="bg-pink-100 bg-opacity-20 rounded-circle h-8 w-8 flex  justify-center items-center text-pink-100 text-xl font-bold">
          +
        </div>
        <p className="capitalize text-gray-100 text-sm font-medium rounded-sm text-center ">
          Add New Admin
        </p>
      </div>
    </div>
  );
};

export default LeftAuditHeader;
