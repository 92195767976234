import React from 'react';
import { FiEdit } from 'react-icons/fi';
import { candidates } from '../../../../utils/peopleRecruitArrays';

const ScheduleLeft = () => {
  return (
    <div className="w-full overflow-scroll whitespace-nowrap no-scroll-bar ">
      <table className="  w-full table-auto ">
        <tbody>
          {candidates.map((candidate, i) => {
            return (
              <tr className="text-gray-600" key={i}>
                <td className="p-3 ">{i + 1}.</td>

                <td className="p-3 text-black-100">{candidate.name}</td>
                <td className="p-3 underline text-blue-light">
                  {' '}
                  <a href={`mailto:${candidate.email}`}> {candidate.email}</a>
                </td>
                <td className="p-3 italic "> 23/04/2002, 8:00am</td>
                <td className="p-3 text-pink-100">
                  <FiEdit />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ScheduleLeft;
