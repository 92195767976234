import React, { useState, useEffect } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../components/button';
import { BiChevronDown } from 'react-icons/bi';
import { API } from '../../../../../utils/apis';
import { STORAGE_KEYS } from '../../../../../helpers/constant';

const GoalsLayout = ({
  submitData,
  title,
  children
}) => {
  const [option, setOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const option = localStorage.getItem(STORAGE_KEYS.PERFORMANCE_CYCLE);
    setOption(parseInt(option));
  }, []);

  let cycleToDisplay;
  switch (option) {
    case 1:
      cycleToDisplay = 'Quarterly';
      break;
    case 2:
      cycleToDisplay = 'Trimester';
      break;
    case 3:
      cycleToDisplay = 'Biannual';
      break;
    case 4:
      cycleToDisplay = 'Annual';
      break;
    default:
      cycleToDisplay = 'Quarterly';
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    await submitData(e);
    setLoading(false);
  };

  return (
    <div className='bg-pink-30 rounded-sm pt-4 pb-14 px-4 lg:px-12 space-y-4'>
      <div
        className='uppercase text-xs flex items-center justify-start space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer'
        onClick={() => navigate(-1)}>
        <MdOutlineArrowBack /> <span className='text-blue-100'> BACK</span>
      </div>

      <div className='flex flex-col gap-4 justify-between mdLg:flex-row '>
        <div className='pl-1 space-y-6 w-full mdlg:w-[80%]'>
          <div className='flex flex-wrap items-center gap-6 mb-9'>
            <h4 className=' text-center mdLg:text-left'>Edit Goals Input</h4>
          </div>
          <div className='flex flex-row gap-5'>
            <span>
              <div className='font-semibold tracking-tight text-blue-100 uppercase text-2xs bg-white-100 rounded-4xl p-3 flex  items-center justify-center text-center w-fit px-5'>
              {title}
              </div>
            </span>
            <span>
              <div className="flex flex-col items-center text-white-100 bg-pink-100 rounded-4xl p-2 px-4 font-semibold justify-between gap-1 mt-1">
              <h6 className='flex flex-row dropdown-header text-white-100'>
                {cycleToDisplay} <BiChevronDown /></h6>
              </div>
            </span>
          </div>
 
          {children}

        </div>

        <Button
          text={'SAVE INPUTS'}
          isPurple
          className='mx-auto mdLg:mx-0 mdLg:w-[200px]'
          type='submit'
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default GoalsLayout;
