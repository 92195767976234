import React from 'react';
import { Outlet } from 'react-router-dom';
// import Button from '../../../components/button';
// import { HeaderCard } from '../../../components/cards/Cards';
import RecruitLayoutWithSideBar from '../../../components/layout/RecruitLayoutWithSideBar';

// import { ADD_VACANCY } from '../../../router/router';

const Hiring = () => {
  // const { pathname } = useLocation();
  return (
    <RecruitLayoutWithSideBar hText={'Source And Attract'}>
      <Outlet />
    </RecruitLayoutWithSideBar>
  );
};

export default Hiring;
// <HeaderCard
// 				text={"Source And Attract"}
// 				withButton
// 				titleClassName={"font-bold"}
// 				button={<Button text={"ADD NEW VACANCY"} isPink />}
// 				className={""}
// 				link={ADD_VACANCY}
// 				linkClassName={`${pathname.includes(ADD_VACANCY) && "hidden"}`}
// 			/>
