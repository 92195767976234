import React, { useState, useEffect } from 'react';
import { BsKey } from 'react-icons/bs';
import { FaObjectGroup } from 'react-icons/fa';
import { GiFlagObjective } from 'react-icons/gi';
import { Toast } from '../../../../components/toast/toast';
import Button from '../../../../components/button';
import Select from '../../../../components/input/Select';
import { apiService } from '../../../../helpers/api';
import { CYCLE, PERFORMANCE_OPTION, STORAGE_KEYS } from '../../../../helpers/constant';
import ObjectiveModal from './objective-modal';

const PerformanceSettings = () => {
  const [option, setOption] = useState(() => {
    const storedTemplate = localStorage.getItem(STORAGE_KEYS.PERFORMANCE_OPTION);
    return storedTemplate ? storedTemplate : PERFORMANCE_OPTION[0];
  });
  const [cycle, setCycle] = useState(() => {
    const storedCycle = localStorage.getItem(STORAGE_KEYS.PERFORMANCE_CYCLE);
    return storedCycle ? storedCycle : CYCLE[0];
  });
  const [loading, setLoading] = useState(false);
  const [showObjectiveModal, setShowObjectiveModal] = useState(false);

  useEffect(() => {
    fetchPerformanceSetting();
  }, []);

  const fetchPerformanceSetting = async () => {
    try {
      const res = await apiService.get('Performance/Setting');
      const performanceTemplate = res.data.selectedOption;
      const performanceCycle = res.data.selectedCycle;

      setOption(performanceTemplate);
      setCycle(performanceCycle);
      localStorage.setItem(STORAGE_KEYS.PERFORMANCE_OPTION, performanceTemplate);
      localStorage.setItem(STORAGE_KEYS.PERFORMANCE_CYCLE, performanceCycle);
    } catch (err) {
      Toast.error(err.message);
    }
  };

  const handleOptionChange = async (e) => {
    const performanceTemplate = e?.value;

    try {
      setLoading(true);
      await apiService.post('Performance/Setting/Create', { performanceTemplate });
      setLoading(false);
      setOption(performanceTemplate);
      localStorage.setItem(STORAGE_KEYS.PERFORMANCE_OPTION, performanceTemplate);

      Toast.success('Performance preference saved successfully!');
    } catch (err) {
      setLoading(false);
      Toast.error(err.message);
    }
  };

  const handleCycleChange = async (e) => {
    const performanceCycle = e?.value;

    try {
      setLoading(true);
      await apiService.post('Performance/Setting/Create', { performanceCycle });
      setLoading(false);
      setCycle(performanceCycle);
      localStorage.setItem(STORAGE_KEYS.PERFORMANCE_CYCLE, performanceCycle);

      Toast.success('Performance cycle saved successfully!');
    } catch (err) {
      setLoading(false);
      Toast.error(err.message);
    }
  };

  return (
    <div className='space-y-14'>
      <div className='space-y-1'>
        <h4 className='text-[#353A3F] font-bold'>Setup Preference</h4>
        <p className='text-[#5c5c5c]'>Indicate the Performance Management (PM) option </p>
        <div className='mdLg:w-[70%] mt-3'>
          <Select
            selectedOption={option}
            options={PERFORMANCE_OPTION}
            onChange={handleOptionChange}
            className=' w-full'
          />
        </div>
      </div>
      <div className='space-y-1'>
        <h4 className='text-[#353A3F] font-bold'>Performance Cycle</h4>
        <p className='text-[#5c5c5c]'>Set up the number of Performance Cycles</p>
        <div className='mdLg:w-[70%] mt-3'>
          <Select
            selectedOption={cycle}
            options={CYCLE}
            onChange={handleCycleChange}
            className=' w-full'
          />
        </div>
      </div>
      {/* <Button
        isPurple
        text={'Set Objectives'}
        withIcon
        icon={<BsKey size={17} className='' />}
        className=' items-center flex justify-between gap-2 px-3 py-2'
        onClick={() => setShowObjectiveModal(true)}
      /> */}

      {/* <ObjectiveModal show={showObjectiveModal} toggleModal={() => setShowObjectiveModal(false)} /> */}
    </div>
  );
};

export default PerformanceSettings;
