import React from 'react';
import { Link } from 'react-router-dom';
import { CONTACT, FAQs, PRICING } from '../../router/landingPageRoutes';

const NavBar = () => {
  return (
    <div className="flex gap-4">
      <Link to={PRICING} className="text-white-100 font-semibold text-xs sm:text-base mdLg:text-lg">
        Pricing{' '}
      </Link>
      <Link to={FAQs} className="text-white-100 font-semibold text-xs sm:text-base mdLg:text-lg">
        FAQ{' '}
      </Link>
      <Link to={CONTACT} className="text-white-100 font-semibold text-xs sm:text-base mdLg:text-lg">
        Contact{' '}
      </Link>
    </div>
  );
};

export default NavBar;
