import React from 'react';
import LandingFooter from '../../../components/landing-page/LandingFooter';
import LandingPageHeader from '../../../components/landing-page/LandingPageHeader';
import Imageee from '../../../assets/img/landing/footer-img.png';
import Input from '../../../components/input';
import Button from '../../../components/button';
import { Link, useNavigate } from 'react-router-dom';
import { SIGN_UP } from '../../../router/router';
const Login = () => {
  const history = useNavigate();
  return (
    <div className='bg-blue-100'>
      <LandingPageHeader />

      <div className='px-12 py-20 flex justify-between'>
        <div className='flex text-white-100 font-bold flex-col gap-8'>
          <h2 className='text-4xl w-[65%] md:pl-16 leading-[45px]'>Connecting People With Work</h2>
          <img src={Imageee} className=' w-[450px]' alt='' />
        </div>

        <div className='space-y-12 w-[40%]'>
          <div className='flex justify-between px-6 md:px-20 items-center cursor-pointer pb-4 border-b-2 border-b-white-100 relative'>
            <h4 className='text-base text-white-100 font-bold active-login '>Login</h4>
            <h4 className='text-base text-white-100 font-bold'>Sign Up</h4>
          </div>

          <Input isWhiteLabel label={'full name'} withLabel className={'h-12 text-white-100'} />

          <div className='space-y-3'>
            <Input isWhiteLabel label={'Email Address'} withLabel className={'h-12'} />
            <div className='text-sm cursor-pointer  text-gray-100 flex justify-end'>
              Forgot Password ?{' '}
            </div>
            <Button isPurple text='Login' onClick={() => history('/dashboard')} />{' '}
            <p className='text-white-100'>
              Don’t have an account?{' '}
              <span className='text-orange-100'>
                {' '}
                <Link to={SIGN_UP}>Sign Up </Link>
              </span>{' '}
            </p>
          </div>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
};

export default Login;
