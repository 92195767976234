import React from 'react';
import Button from '../../../../components/button';

const RightAbsenceEmployeeInfoo = ({ leaveDetail, showApprovalModal, showRejectionModal }) => {
  return (
    <>
      {leaveDetail?.approvalStatus === 0 && (
        <div className="flex flex-wrap gap-4">
          <Button text={'reject'} isPink onClick={showRejectionModal} />
          <Button text={'approve'} isGreen onClick={showApprovalModal} />
        </div>
      )}
    </>
  );
};

export default RightAbsenceEmployeeInfoo;
