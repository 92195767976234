import React from 'react';
import { useState } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { BsClipboard } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/button';
import { PinkHeaderWithFilter } from '../../../../../components/cards/Cards';
import Input from '../../../../../components/input';
import Modal from '../../../../../components/modal';
import {
  ASSESSMENT_QUESTIONS,
  CANDIDATE_DETAILS,
  JOB_APPLICANTS,
  SHORTLIST
} from '../../../../../router/router';
import { candidates } from '../../../../../utils/peopleRecruitArrays';

const ScreeningScores = () => {
  const [resetModal, setResetModal] = useState(false);
  return (
    <div className="space-y-6">
      <div>
        <div className="flex gap-2 flex-wrap">
          <Link to={`${SHORTLIST}/${JOB_APPLICANTS}/${ASSESSMENT_QUESTIONS}`}>
            <Button
              icon={<BsClipboard />}
              withIcon
              text={'Assessment Questions'}
              className="flex items-center gap-2"
              isPink
            />
          </Link>
          <Link to={''}>
            <Button
              icon={<BsClipboard />}
              withIcon
              text={'Schedule Interview'}
              className="flex items-center gap-2"
              isPink
            />
          </Link>
        </div>
      </div>
      <PinkHeaderWithFilter
        withReset
        goBack
        text={'Product Manager (1024 candidates)'}
        onClick={() => setResetModal(!resetModal)}
      />

      <Modal
        showModal={resetModal}
        closeModal={() => setResetModal(!resetModal)}
        content={
          <div className=" no-scroll-bar overflow-scroll h-[80vh] space-y-6 pr-10">
            <h4> Reset Shortlist Criteria </h4>
            <div className=" space-y-3 pb-2">
              <Input height={'h-9'} label={'Educational Qualification'} withLabel normalLabel />

              <Input height={'h-9'} label={'Leadership Experience'} withLabel normalLabel />

              <Input height={'h-9'} label={'Relevant Experience'} withLabel normalLabel />

              <Input height={'h-9'} label={'Language'} withLabel normalLabel />

              <Input height={'h-9'} label={'Total Score'} withLabel normalLabel />
            </div>
            <Button text={'save'} isPurple />
          </div>
        }
      />

      <div className=" bg-white-100 rounded-md p-4 sm:px-10  space-y-6">
        <div className=" w-full overflow-scroll whitespace-nowrap no-scroll-bar ">
          <table className="w-full  border-spacing-2  table-auto">
            <thead className=" border-collapse border-red-500  ">
              <tr className="text-left text-blue-100">
                <th></th>
                <th className="p-3 ">Name</th>
                <th className="p-3 "> Position</th>
                <th className="p-3 "> Qualification</th>
                <th className="p-3 ">
                  {' '}
                  Educational
                  <br />
                  Qualification
                </th>
                <th className="p-3 ">
                  Leadership <br />
                  Experience
                </th>
                <th className="p-3 ">
                  Relevant <br />
                  Experience
                </th>
                <th className="p-3 "> Language </th>
                <th className="p-3 text-pink-100 "> Total Score</th>
              </tr>
            </thead>

            <tbody className="text-gray-100">
              {candidates.map((candidate, i) => {
                return (
                  <tr className="" key={i}>
                    <td className="p-3">{i + 1}.</td>
                    <td className="p-3">{candidate.name}</td>
                    <td className="p-3">{candidate.role}</td>
                    <td className="p-3">{candidate.qualification}</td>
                    <td className="p-3 text-ellipsis">{candidate.rating1}</td>
                    <td className="p-3">{candidate.rating2}</td>
                    <td className="p-3 capitalize">{candidate.rating3}</td>
                    <td className="p-3 capitalize">{candidate.rating4}</td>
                    <td className="p-3 capitalize">{candidate.totalRating}</td>
                    <td className="p-3 text-pink-100 italic">
                      {' '}
                      <Link to={`${SHORTLIST}/${JOB_APPLICANTS}/${CANDIDATE_DETAILS}`}>
                        view details{' '}
                      </Link>
                    </td>
                    <td className="p-1 py-3">
                      {' '}
                      <BiDotsVerticalRounded />{' '}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ScreeningScores;
