import React, { useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../../assets/img/logo.svg';
import Button from '../../../components/button';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/base/loader';
import { RESET_PASSWORD } from '../../../router/router';
import authService from '../../../services/auth.service';
import { BiMailSend } from 'react-icons/bi';

const VerifyUserEmail = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const { email, token } = useParams();
  const navigate = useNavigate();

  const verifyEmailAddress = async () => {
    setLoading(true);
    const res = await authService.verifyEmail(email, token);
    if (res) {
      setStatus(1);
    } else {
      setStatus(2);
    }
    setLoading(false);
  };

  const resendVerificationEmail = async () => {
    setLoading(true);
    const res = await authService.resendVerificationEmail(email);
    if (res?.data) {
      setStatus(3);
    } else {
      setStatus(2);
    }
    setLoading(false);
  };

  useEffect(() => {
    verifyEmailAddress();
  }, []);
  return (
    <div className='h-[100vh] flex flex-col items-center pt-32 space-y-16 px-6'>
      <Logo />
      {loading ? (
        <Loader size={34} />
      ) : status === 1 ? (
        <div className='space-y-16'>
          <div className=' text-center space-y-4'>
            <h4 className=' text-xl md:text-2xl'>Email Successfully Verified!!</h4>
            <p className=' text-black-200 text-sm'>Please reset your default password!</p>
          </div>

          <Button
            text={'Reset Your Password'}
            isPink
            className={'h-14 w-full sm:w-[300px] mx-auto'}
            onClick={() => navigate(RESET_PASSWORD)}
          />
        </div>
      ) : status === 2 ? (
        <div className='space-y-16'>
          <h4 className=' text-red-700 text-xl md:text-2xl text-center'>
            Email Successfully Failed! Please Try Again.
          </h4>
          <Button
            text={'Resend Email'}
            isTransparent
            className={
              'h-14 w-full sm:w-[300px] mx-auto border text-red-700 border-red-700 hover:bg-inherit hover:text-red-700 '
            }
            onClick={resendVerificationEmail}
            disabled={loading}
            loading={loading}
          />
        </div>
      ) : (
        status === 3 && (
          <div className='space-y-16'>
            <h4 className='  text-xl md:text-2xl text-center flex items-center gap-1 '>
              Please check your mail
              <span>
                <BiMailSend size={18} />
              </span>
            </h4>
          </div>
        )
      )}
    </div>
  );
};

export default VerifyUserEmail;
