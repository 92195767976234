import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { Toast } from '../../../../../components/toast/toast';
import { apiService } from '../../../../../helpers/api';
import PerformanceDetails from './PerformanceDetails';
import ApprovalRejectionDetails from './ApprovalRejectionDetails';
import { BiLoaderCircle } from 'react-icons/bi';

const PerformanceInfo = ({performanceDetail, fetchPerformanceDetail}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [showComponentModal, setShowComponentModal] = useState(false);
  const [showOvertimeModal, setShowOvertimeModal] = useState(false);
  const [showSalaryComponentContent, setShowSalaryComponentContent] = useState(false);

  const closeModal = () => {
    setShowComponentModal(false);
    setShowOvertimeModal(false);
  };

  const rejectPerformanceRequest = async () => {
    setRequestLoading(true);
    try {
      const res = await apiService.post(`Performance/Goal/Approve?Id=${id}&approve=false`);
      if (res) {
        await fetchPerformanceDetail();
        setShowRejectModal(!showRejectModal);
        Toast({
          type: 'success',
          message: 'Performance Request Rejected'
        });
      }
    } catch (err) {
      console.log('Error in rejecting performance request: ', err)
    } finally {
      setRequestLoading(false);
    }
  };

  const approvePerformanceRequest = async () => {
    setRequestLoading(true);
    try {
      const res = await apiService.post(`Performance/Goal/Approve?Id=${id}&approve=true`);
      if (res) {
        await fetchPerformanceDetail();
        setShowApproveModal(!showApproveModal);
        Toast({
          type: 'success',
          message: 'Performance Request Approved'
        });
      }
    } catch (err) {
      console.log('Error in approving performance request: ', err)
    } finally {
      setRequestLoading(false);
    }
  };


  return (
    <div className="mx-auto lg:mx-0 space-y-6">
      <div
        className="uppercase text-xs flex items-center justify-between space-x-2 text-pink-100 tracking-[3px] font-bold cursor-pointer">
        <div className='flex flex-row gap-2' onClick={() => navigate(-1)}>
          <MdOutlineArrowBack /> <span className="text-blue-100"> BACK</span>
        </div>
      </div>

      <div className="space-y-6 flex flex-wrap justify-between items-start">
        {loading ? (
          <BiLoaderCircle size={16} className="mx-auto animate-spin-slow" />
        ) : (
          <>
            <PerformanceDetails 
              performanceDetail={performanceDetail}
              //rejectPerformanceRequest={rejectPerformanceRequest}
            />
            {/*<ApprovalRejectionDetails
              performanceDetail={performanceDetail}
              showRejectionModal={() => setShowRejectModal(!showRejectModal)}
              showApprovalModal={() => setShowApproveModal(!showApproveModal)}
            />*/}
          </>
        )}
      </div>

      <Modal
        title={'Are you sure you want to '}
        coloredTitle="Approve? "
        showModal={showApproveModal}
        closeModal={() => setShowApproveModal(!showApproveModal)}
        content={
          <div className="flex gap-4 flex-wrap">
            <Button
              className={''}
              text="Go back"  
              onClick={() => setShowApproveModal(!showApproveModal)}
              isGray
            />
            <Button
              text="Approve"
              isGreen
              className="bg-opacity-80"
              loading={requestLoading}
              onClick={approvePerformanceRequest}
            />
          </div>
        }
      />
      <Modal
        title={'Are you sure you want to '}
        coloredTitle="Reject? "
        showModal={showRejectModal}
        closeModal={() => setShowRejectModal(!showRejectModal)}
        content={
          <div className="flex gap-4 flex-wrap">
            <Button
              className={''}
              text="Go back"
              onClick={() => setShowRejectModal(!showRejectModal)}
              isGray
            />
            <Button
              text="Reject"
              isPink
              className="bg-opacity-80"
              loading={requestLoading}
              onClick={rejectPerformanceRequest}
            />
          </div>
        }
      />

      
      
    </div>
  );
};

export default PerformanceInfo;
